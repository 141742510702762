/* eslint-disable no-loop-func */
import axios from "axios";
import { todayDate } from "../utils/utils";
import EnvService from "./EnvService";
import SharedStateService from "./SharedStateService";

class ApiService {
  public static RESPONSE_ERROR = "error";
  public static RESPONSE_SUCCESS = "success";
  public static RESPONSE_UNAUTHENTICATED = "unauthenticated";
  public static RESPONSE_TRANSACTION_ERROR = "transaction_error";
  public static RESPONSE_CARD_WITHDRWAN = "card_withdrawn";
  public static RESPONSE_SUSPENDED_USER = "user_suspended";
  private sharedStateService: SharedStateService;
  private endpoint: string;
  private eposId: string;

  constructor() {
    this.sharedStateService = new SharedStateService();
    this.eposId = EnvService.currentEposId();
    this.endpoint = EnvService.apiEndpoint();
  }

  attachBearerToken() {
    axios.interceptors.request.use(
      (config) => {
        const token = this.sharedStateService.getAuthToken();

        if (token) {
          config.headers["Authorization"] = `Bearer ${token}`;
        }

        return config;
      },

      (error) => {
        //return Promise.reject(error)
      }
    );
  }

  isJson(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  async get(uri, params) {
    this.attachBearerToken();
    const result = await axios.get(
      this.endpoint + uri,
      params ? { params: params } : null
    );

    if (result.status === 200) return result.data;
    return [];
  }

  async post(uri, params) {
    this.attachBearerToken();
    const result = await axios.post(this.endpoint + uri, params);
    return result.data;
  }

  async patch(uri, params) {
    this.attachBearerToken();
    const result = await axios.patch(this.endpoint + uri, params);
    return result.data;
  }

  /**
   * Authenticates the manager, the returned auth token will be used
   * to authenticate the EPOS API calls
   *
   * @param eposId
   * @param managerToken
   * @returns
   */
  async authenticateEpos(
    eposId: string,
    managerToken: string
  ): Promise<ApiEposAuthResponse> {
    let data: EposAuthData = {
      clientId: "8647de00-9054-44b1-86e0-649806c4ddd1",
      clientSecret: managerToken,
      grantType: "eposToken",
      scope: "restApi",
    };

    if (eposId !== "") {
      data.eposId = eposId;
      console.log("SHOULD RE-AUTHENTICATE ", data);
    } else {
      console.log("SHOULD AUTHENTICATE ", data);
    }

    try {
      const apiResponse = await this.post("/auth/connect/token", data);

      if (apiResponse.accessToken) {
        const response: ApiEposAuthResponse = {
          status: "success",
          authToken: apiResponse.accessToken,
        };
        return response;
      }
    } catch (e) {
      console.log("API Exception: ", e);

      if (e.response.status === 400) {
        // !!! 400 means the token has expired !!!
        return {
          status: ApiService.RESPONSE_ERROR,
          message: e.response.data.error.message,
          code: e.response.data.error.code,
        };
      }

      // Any other exception
      return {
        status: ApiService.RESPONSE_ERROR,
        message: e.response.data.error.message,
        code: e.response.data.error.code,
      };
    }

    const errorResponse: ApiEposAuthResponse = {
      status: ApiService.RESPONSE_ERROR,
      message: "authentication failed",
    };
    return errorResponse;
  }

  /**
   * Register EPOS
   */
  async registerEpos(
    schoolId: string,
    meta: EposMeta
  ): Promise<ApiFindOrCreateEposIdResponse> {
    const eposName = meta.computerName;
    let eposPayload: ApiEpos = { schoolId, name: eposName, metaData: meta };
    eposPayload = { schoolId, name: eposName, metaData: meta };
    try {
      const apiCreateEposResponse = await this.createEpos(
        schoolId,
        eposName,
        meta
      );

      if (apiCreateEposResponse.status === ApiService.RESPONSE_SUCCESS) {
        eposPayload.id = apiCreateEposResponse.id;
        this.sharedStateService.setEpos(eposPayload);
        this.sharedStateService.clearSchoolData();
        return {
          status: ApiService.RESPONSE_SUCCESS,
          id: apiCreateEposResponse.id,
        };
      }
    } catch (e) {
      console.log("API Exception:", e);
    }

    return {
      status: ApiService.RESPONSE_ERROR,
    };
  }

  /**
   * Finds the EPOS ID if it exists, or creates a new one if it does not
   */
  async findEpos(eposID: string): Promise<ApiFindEposByIdResponse> {
    const apiFindEposResponse = await this.fetchEposbyID(eposID);

    let eposPayload: ApiEpos = null;

    if (apiFindEposResponse.status === ApiService.RESPONSE_SUCCESS) {
      eposPayload = apiFindEposResponse.epos;
      this.sharedStateService.setEpos(eposPayload);
      return {
        status: ApiService.RESPONSE_SUCCESS,
        epos: apiFindEposResponse.epos,
      };
    }
    return {
      status: ApiService.RESPONSE_ERROR,
    };
  }

  /**
   * Finds the EPOS ID if it exists, or creates a new one if it does not
   */
  async findOrCreateEposId(
    schoolId: string,
    meta: EposMeta
  ): Promise<ApiFindOrCreateEposIdResponse> {
    console.log("Find or Create Epos");
    console.log("1. Finding Epos");
    const eposName = meta.computerName;
    const apiFindEposResponse = await this.fetchEpos(schoolId, eposName);

    console.log(apiFindEposResponse);

    let eposPayload: ApiEpos = null;

    if (apiFindEposResponse.status === ApiService.RESPONSE_SUCCESS) {
      eposPayload = apiFindEposResponse.epos;
      this.sharedStateService.setEpos(eposPayload);
      this.updateEposMeta(eposPayload.id, meta);
      return {
        status: ApiService.RESPONSE_SUCCESS,
        id: apiFindEposResponse.epos.id,
      };
    }

    eposPayload = { schoolId, name: eposName, metaData: meta };

    console.log("2. Creating EPOS");

    try {
      const apiCreateEposResponse = await this.createEpos(
        schoolId,
        eposName,
        meta
      );

      if (apiCreateEposResponse.status === ApiService.RESPONSE_SUCCESS) {
        eposPayload.id = apiCreateEposResponse.id;
        this.sharedStateService.setEpos(eposPayload);
        return {
          status: ApiService.RESPONSE_SUCCESS,
          id: apiCreateEposResponse.id,
        };
      }
    } catch (e) {
      console.log("API Exception:", e);
    }

    return {
      status: ApiService.RESPONSE_ERROR,
    };
  }

  /**
   * Fetches an EPOS by school ID, computer name and status "active"
   *
   * @param eposId
   * @returns
   */
  async fetchEposbyID(eposId: string): Promise<ApiFetchEposResponse> {
    const data = {
      id: eposId,
    };

    // console.log('API Epos: ', data)

    try {
      const apiResponse = await this.get("/epos", data);

      if (apiResponse.length > 0) {
        return {
          status: ApiService.RESPONSE_SUCCESS,
          epos: apiResponse[0],
        };
      }
    } catch (e) {
      console.log("API Exception:", e);
    }

    return {
      status: ApiService.RESPONSE_ERROR,
      message: "epos fetch id failed",
    };
  }

  /**
   * Fetches an EPOS by school ID, computer name and status "active"
   *
   * @param schoolId
   * @returns
   */
  async fetchEpos(
    schoolId: string,
    name: string
  ): Promise<ApiFetchEposResponse> {
    const data = {
      schoolId: schoolId,
      name: name,
      status: "active",
    };

    // console.log('API Epos: ', data)

    try {
      const apiResponse = await this.get("/epos", data);

      if (apiResponse.length > 0) {
        return {
          status: ApiService.RESPONSE_SUCCESS,
          epos: apiResponse[0],
        };
      }
    } catch (e) {
      console.log("API Exception:", e);
    }

    return {
      status: ApiService.RESPONSE_ERROR,
      message: "epos fetch id failed",
    };
  }

  /**
   * Fetches an Org by organisation ID
   *
   * @param organisationId
   * @returns
   */
  async fetchOrg(organisationId: string): Promise<ApiFetchOrgResponse> {
    const data = {
      id: organisationId,
    };

    // console.log('Org => ', data)

    try {
      const apiResponse = await this.get("/organisations", data);

      if (apiResponse.length > 0) {
        return {
          status: ApiService.RESPONSE_SUCCESS,
          orgs: apiResponse,
        };
      }
    } catch (e) {
      console.log("API Exception:", e);
    }

    return {
      status: ApiService.RESPONSE_ERROR,
    };
  }

  /**
   * Fetches school by school ID
   *
   * @param schoolId
   * @returns
   */
  async fetchSchool(schoolId: string): Promise<ApiFetchSchoolResponse> {
    const data = {
      id: schoolId,
    };

    // console.log(data)

    try {
      const apiResponse = await this.get("/schools", data);

      if (apiResponse.results.length > 0) {
        return {
          status: ApiService.RESPONSE_SUCCESS,
          results: apiResponse.results,
        };
      }
    } catch (e) {
      console.log("API Exception:", e);
    }

    return {
      status: ApiService.RESPONSE_ERROR,
      results: [],
    };
  }

  /**
   * Createa a new EPOS and returs the EPOS ID
   *
   * We pass the computer name when we generate the ID
   * so that the backend users know which EPOS it is
   *
   * WARNING!!!
   * Always make sure that you check an EPOS with this name
   * DOES NOT exist for this schoolId as SCHOOL - EPOS must
   * be unique
   *
   * @param schoolId
   * @returns
   */
  async createEpos(
    schoolId: string,
    eposName: string,
    meta: EposMeta
  ): Promise<ApiCreateEposResponse> {
    const data = {
      schoolId: schoolId,
      name: eposName,
      metaData: meta,
      rules: {},
      status: "active",
    };

    const apiResponse = await this.post("/epos", data);

    if (apiResponse.id) {
      const response: ApiCreateEposResponse = {
        status: "success",
        id: apiResponse.id,
      };
      return response;
    }

    console.log("API Error:", apiResponse);

    const errorResponse: ApiCreateEposResponse = {
      status: "error",
      message: "epos create id failed",
    };

    return errorResponse;
  }

  /**
   * Update EPOS metadata
   *
   * @param eposId
   * @param meta
   * @returns
   */
  async updateEposMeta(
    eposId: string,
    meta: EposMeta
  ): Promise<ApiUpdateEposResponse> {
    const data = {
      metaData: meta,
      id: eposId,
    };

    const apiResponse = await this.patch(`/epos/${eposId}`, data);

    if (apiResponse.message?.toLowerCase().indexOf("success") > -1) {
      const response: ApiUpdateEposResponse = {
        status: "success",
        message: apiResponse.message,
      };
      return response;
    }

    console.log("API Error:", apiResponse);

    const errorResponse: ApiUpdateEposResponse = {
      status: "error",
      message: "epos update meta failed",
    };

    return errorResponse;
  }

  /**
   * Fetches the EPOS users for the specified school
   * This is so that we can display the names for the V2 of the
   * operator login page
   * @param string
   * @returns
   */
  async fetchEposLayouts(schoolId: string): Promise<ApiEposLayoutsResponse> {
    const data = {
      schoolId: schoolId,
      status: "active",
    };

    try {
      const apiResponse = await this.get("/epos-layouts", data);

      console.log("API Response: ", apiResponse);

      if (apiResponse.length > 0) {
        const response: ApiEposLayoutsResponse = {
          status: ApiService.RESPONSE_SUCCESS,
          eposLayouts: apiResponse,
        };
        return response;
      }

      const response: ApiEposLayoutsResponse = {
        status: ApiService.RESPONSE_ERROR,
      };
      return response;
    } catch (e) {
      console.log("API Exception: ", e);
      if (e.response.status === 401) {
        PubSub.publish("unauthenticated");
        const response: ApiEposUsersResponse = {
          status: ApiService.RESPONSE_UNAUTHENTICATED,
        };
        return response;
      } else {
        const response: ApiEposLayoutsResponse = {
          status: ApiService.RESPONSE_ERROR,
        };

        return response;
      }
    }
  }

  /**
   * Fetches the EPOS users for the specified school
   * This is so that we can display the names for the V2 of the
   * fetch epos users
   * @param schoolId
   * @param string
   * @returns
   */
  async fetchEposUsers(schoolId: string): Promise<ApiEposUsersResponse> {
    const data = {
      schoolId,
    };

    try {
      const apiResponse = await this.get("/epos-users", data);

      console.log("API Response: ", apiResponse);

      if (apiResponse.length > 0) {
        const response: ApiEposUsersResponse = {
          status: ApiService.RESPONSE_SUCCESS,
          eposUsers: apiResponse,
        };
        return response;
      }

      const response: ApiEposUsersResponse = {
        status: ApiService.RESPONSE_ERROR,
      };
      return response;
    } catch (e) {
      console.log("API Exception: ", e);
      if (e.response.status === 401) {
        PubSub.publish("unauthenticated");
        const response: ApiEposUsersResponse = {
          status: ApiService.RESPONSE_UNAUTHENTICATED,
        };
        return response;
      }
    }

    const response: ApiEposUsersResponse = {
      status: "error",
    };

    return response;
  }

  /**
   * Fetches the menus bu school ID and data
   * The EPOS only needs the menus for today
   * @param schoolId
   * @param date
   * @returns
   */
  async fetchMenusBySchoolId(
    schoolId: string,
    date: string
  ): Promise<ApiFetchMenusBySchoolIdResponse> {
    const data = {
      schoolId: schoolId,
      date: date,
    };

    try {
      const apiResponse = await this.get("/menus", data);
      console.log("API Response:", apiResponse);
      /*console.log('JSON => ',this.isJson(apiResponse));
      
      if(!this.isJson(apiResponse)){
        const errorResponse: ApiFetchMenusBySchoolIdResponse = {
          status: ApiService.RESPONSE_ERROR,
        }
        return errorResponse
      }*/

      if (apiResponse.length > -1) {
        // !!! strange not a paginated result, but straight array !!!
        const successResponse: ApiFetchMenusBySchoolIdResponse = {
          status: ApiService.RESPONSE_SUCCESS,
          menus: apiResponse,
        };
        return successResponse;
      }
    } catch (e) {
      console.log("API Exception: ", e);
      if (e.response.status === 401) {
        PubSub.publish("unauthenticated");
        const response: ApiEposUsersResponse = {
          status: ApiService.RESPONSE_UNAUTHENTICATED,
        };
        return response;
      }
    }

    const errorResponse: ApiFetchMenusBySchoolIdResponse = {
      status: ApiService.RESPONSE_ERROR,
    };

    return errorResponse;
  }

  /**
   * Fetches the menu item by ID
   * @param schoolId
   * @param date
   * @returns
   */
  async fetchMenuItemById(
    menuItemId: string
  ): Promise<ApiFetchMenuItemResponse> {
    const data = {
      id: menuItemId,
    };

    try {
      const apiResponse = await this.get("/menu-items", data);
      console.log("API Response:", apiResponse);

      if (apiResponse.length > 0) {
        // !!! strange not a paginated result, but straight array !!!
        const successResponse: ApiFetchMenuItemResponse = {
          status: ApiService.RESPONSE_SUCCESS,
          menuItem: apiResponse,
        };
        return successResponse;
      }
    } catch (e) {
      console.log("API Exception: ", e);
      if (e.response.status === 401) {
        PubSub.publish("unauthenticated");
        const response: ApiEposUsersResponse = {
          status: ApiService.RESPONSE_UNAUTHENTICATED,
        };
        return response;
      }
    }

    const errorResponse: ApiFetchMenuItemResponse = {
      status: ApiService.RESPONSE_ERROR,
    };

    return errorResponse;
  }

  /**
   * Fetches the menu categories
   * @param schoolId
   * @param date
   * @returns
   */
  async fetchMenuCategories(organisationId): Promise<ApiFetchMenuCategoriesResponse> {
    const data = { organisationId };

    try {
      const apiResponse = await this.get(
        "/menu-categories?status=active",
        data
      );
      console.log("API Response:", apiResponse);

      if (apiResponse.length > -1) {
        // !!! strange not a paginated result, but straight array !!!
        const successResponse: ApiFetchMenuCategoriesResponse = {
          status: ApiService.RESPONSE_SUCCESS,
          menuCategories: apiResponse,
        };
        return successResponse;
      }
    } catch (e) {
      console.log("API Exception: ", e);
      if (e.response.status === 401) {
        PubSub.publish("unauthenticated");
        const response: ApiEposUsersResponse = {
          status: ApiService.RESPONSE_UNAUTHENTICATED,
        };
        return response;
      }
    }

    const errorResponse: ApiFetchMenuCategoriesResponse = {
      status: ApiService.RESPONSE_ERROR,
    };

    return errorResponse;
  }

  /**
  * Fetches the school users
  * @param schoolId
  * @returns
  */
  async fetchSchoolUsers(): Promise<ApiUsersResponse> {
    const data = { schoolId: this.sharedStateService.getSchoolId(), type: ['student', 'staff'], page: 0, size: 10000 };

    try {
      const apiResponse = await this.get(
        "/users",
        data
      );

      if (apiResponse.results && apiResponse.results.length > -1) {
        // !!! strange not a paginated result, but straight array !!!
        const successResponse: ApiUsersResponse = {
          status: ApiService.RESPONSE_SUCCESS,
          users: apiResponse.results,
        };
        return successResponse;
      }
    } catch (e) {
      console.log("API Exception: ", e);
      if (e.response.status === 401) {
        PubSub.publish("unauthenticated");
        const response: ApiEposUsersResponse = {
          status: ApiService.RESPONSE_UNAUTHENTICATED,
        };
        return response;
      }
    }

    const errorResponse: ApiUsersResponse = {
      status: ApiService.RESPONSE_ERROR,
    };

    return errorResponse;
  }

  /**
   * Fetches the suitabilities
   * @param organisationId
   * @returns
   */
  async fetchSuitabilities(organisationId): Promise<ApiFetchSuitabilityResponse> {
    const data = { organisationId };

    try {
      const apiResponse = await this.get(
        "/suitabilities",
        data
      );

      if (apiResponse.length > -1) {
        const successResponse: ApiFetchSuitabilityResponse = {
          status: ApiService.RESPONSE_SUCCESS,
          menuCategories: apiResponse,
        };
        return successResponse;
      }
    } catch (e) {
      console.log("API Exception: ", e);
      if (e.response.status === 401) {
        PubSub.publish("unauthenticated");
        const response: ApiEposUsersResponse = {
          status: ApiService.RESPONSE_UNAUTHENTICATED,
        };
        return response;
      }
    }

    const errorResponse: ApiFetchSuitabilityResponse = {
      status: ApiService.RESPONSE_ERROR,
    };

    return errorResponse;
  }

  /**
   * Fetches the menu promotions
   * @param schoolId
   * @param date
   * @returns
   */
  async fetchMenuPromotions(schoolId: string): Promise<ApiFetchMenuPromotionsResponse> {
    const data = { schoolId, status: 'active' };

    try {
      const apiResponse = await this.get(
        "/menu-promotions",
        data
      );
      console.log("API Response:", apiResponse);

      if (apiResponse.length > -1) {
        // !!! strange not a paginated result, but straight array !!!
        const successResponse: ApiFetchMenuPromotionsResponse = {
          status: ApiService.RESPONSE_SUCCESS,
          menuPromotions: apiResponse,
        };
        return successResponse;
      }
    } catch (e) {
      console.log("API Exception: ", e);
      if (e.response.status === 401) {
        PubSub.publish("unauthenticated");
        const response: ApiEposUsersResponse = {
          status: ApiService.RESPONSE_UNAUTHENTICATED,
        };
        return response;
      }
    }

    const errorResponse: ApiFetchMenuPromotionsResponse = {
      status: ApiService.RESPONSE_ERROR,
    };

    return errorResponse;
  }

  /**
   * Fetches the menu items
   * @returns
   */
  async fetchMenuItems(): Promise<ApiFetchMenuItemsResponse> {
    const data = {};

    try {
      const apiResponse = await this.get("/menu-items?status=active", data);
      console.log("API Response:", apiResponse);

      if (apiResponse.length > -1) {
        // !!! strange not a paginated result, but straight array !!!
        const successResponse: ApiFetchMenuItemsResponse = {
          status: ApiService.RESPONSE_SUCCESS,
          menuItems: apiResponse,
        };
        return successResponse;
      }
    } catch (e) {
      console.log("API Exception: ", e);
      if (e.response.status === 401) {
        PubSub.publish("unauthenticated");
        const response: ApiEposUsersResponse = {
          status: ApiService.RESPONSE_UNAUTHENTICATED,
        };
        return response;
      }
    }

    const errorResponse: ApiFetchMenuItemsResponse = {
      status: ApiService.RESPONSE_ERROR,
    };

    return errorResponse;
  }

  /**
   * Fetches the Allergens
   * @returns
   */
  async fetchAllergens(
    organisationId: string
  ): Promise<ApiFetchAllergensResponse> {
    const data = { organisationId };

    try {
      const apiResponse = await this.get("/allergens", data);
      console.log("API Response:", apiResponse);

      if (apiResponse.length > -1) {
        // !!! strange not a paginated result, but straight array !!!
        const successResponse: ApiFetchAllergensResponse = {
          status: ApiService.RESPONSE_SUCCESS,
          allergens: apiResponse,
        };
        return successResponse;
      }
    } catch (e) {
      console.log("API Exception: ", e);
      if (e.response.status === 401) {
        PubSub.publish("unauthenticated");
        const response: ApiFetchAllergensResponse = {
          status: ApiService.RESPONSE_UNAUTHENTICATED,
        };
        return response;
      }
    }

    const errorResponse: ApiFetchAllergensResponse = {
      status: ApiService.RESPONSE_ERROR,
    };

    return errorResponse;
  }

  /**
   * Fetches the Product Ingredients
   * @returns
   */
  async fetchProductIngredients(
    organisationId: string
  ): Promise<ApiFetchProductIngredientResponse> {
    const data = { organisationId };

    try {
      const apiResponse = await this.get("/product-ingredients", data);
      console.log("API Response:", apiResponse);

      if (apiResponse.length > -1) {
        // !!! strange not a paginated result, but straight array !!!
        const successResponse: ApiFetchProductIngredientResponse = {
          status: ApiService.RESPONSE_SUCCESS,
          ingredients: apiResponse,
        };
        return successResponse;
      }
    } catch (e) {
      console.log("API Exception: ", e);
      if (e.response.status === 401) {
        PubSub.publish("unauthenticated");
        const response: ApiFetchProductIngredientResponse = {
          status: ApiService.RESPONSE_UNAUTHENTICATED,
        };
        return response;
      }
    }

    const errorResponse: ApiFetchProductIngredientResponse = {
      status: ApiService.RESPONSE_ERROR,
    };

    return errorResponse;
  }

  /**
   * Fetches the Ingredients
   * @returns
   */
  async fetchIngredients(
    organisationId: string
  ): Promise<ApiFetchIngredientResponse> {
    const data = { organisationId };

    try {
      const apiResponse = await this.get("/ingredients", data);
      console.log("API Response:", apiResponse);

      if (apiResponse.length > -1) {
        // !!! strange not a paginated result, but straight array !!!
        const successResponse: ApiFetchIngredientResponse = {
          status: ApiService.RESPONSE_SUCCESS,
          ingredients: apiResponse,
        };
        return successResponse;
      }
    } catch (e) {
      console.log("API Exception: ", e);
      if (e.response.status === 401) {
        PubSub.publish("unauthenticated");
        const response: ApiFetchIngredientResponse = {
          status: ApiService.RESPONSE_UNAUTHENTICATED,
        };
        return response;
      }
    }

    const errorResponse: ApiFetchIngredientResponse = {
      status: ApiService.RESPONSE_ERROR,
    };

    return errorResponse;
  }

  /**
   * Fetches the menu sittings bu school ID and data
   *
   * @param schoolId
   * @param date
   * @returns
   */
  async fetchMenuSittingsBySchoolId(
    schoolId: string
  ): Promise<ApiFetchMenuSittingsBySchoolIdResponse> {
    const data = {
      schoolId: schoolId,
    };

    try {
      const apiResponse = await this.get("/menu-sittings?status=active", data);
      console.log("API Response:", apiResponse);

      if (apiResponse.length > -1) {
        // !!! strange not a paginated result, but straight array !!!
        const successResponse: ApiFetchMenuSittingsBySchoolIdResponse = {
          status: ApiService.RESPONSE_SUCCESS,
          menuSittings: apiResponse,
        };
        return successResponse;
      }
    } catch (e) {
      console.log("API Exception: ", e);
      if (e.response.status === 401) {
        PubSub.publish("unauthenticated");
        const response: ApiEposUsersResponse = {
          status: ApiService.RESPONSE_UNAUTHENTICATED,
        };
        return response;
      }
    }

    const errorResponse: ApiFetchMenuSittingsBySchoolIdResponse = {
      status: ApiService.RESPONSE_ERROR,
    };

    return errorResponse;
  }

  /**
   * Fetches the pricings by school ID
   * @param schoolId
   * @returns
   */
  async fetchPricingsBySchoolId(
    schoolId: string
  ): Promise<ApiFetchPricingsResponse> {
    const data = {
      schoolId: schoolId,
    };

    try {
      const apiResponse = await this.get("/pricings", data);
      console.log("API Response:", apiResponse);

      if (apiResponse.length > -1) {
        // !!! strange not a paginated result, but straight array !!!
        return {
          status: ApiService.RESPONSE_SUCCESS,
          pricings: apiResponse,
        };
      }
    } catch (e) {
      console.log("API Exception: ", e);

      if (e.response.status === 401) {
        PubSub.publish("unauthenticated");
        return {
          status: ApiService.RESPONSE_UNAUTHENTICATED,
        };
      }
    }

    return {
      status: ApiService.RESPONSE_ERROR,
    };
  }

  /**
   * Fetches the purder for the specified user ID
   * @param userId
   * @returns
   */
  async fetchPurseByUserId(userId: string): Promise<ApiFetchPurseResponse> {
    const data = {
      userId: userId,
    };

    try {
      const apiResponse = await this.get("/purses", data);
      console.log("API Response:", apiResponse);

      if (apiResponse.length > -1) {
        // !!! strange not a paginated result, but straight array !!!
        return {
          status: ApiService.RESPONSE_SUCCESS,
          purse: apiResponse[0],
        };
      }
    } catch (e) {
      console.log("API Exception: ", e);

      if (e.response.status === 401) {
        PubSub.publish("unauthenticated");
        return {
          status: ApiService.RESPONSE_UNAUTHENTICATED,
        };
      }

      if (e.response.status === 400) {
        // !!! 400 means there is no purse in the DB for this user yet. Defaulting to 0 !!!
        return {
          status: ApiService.RESPONSE_SUCCESS,
          purse: {
            userId: userId,
            balance: 0,
          },
        };
      }
    }

    return {
      status: ApiService.RESPONSE_ERROR,
    };
  }

  /**
   * Fetches the purder for the specified user ID
   * @param userId
   * @returns
   */
  async fetchSubsidByUserId(userId: string): Promise<ApiFetchSubsidyResponse> {
    const data = {
      userId,
    };

    try {
      const apiResponse = await this.get("/subsidies", data);
      console.log("API Response:", apiResponse);

      if (apiResponse.length > -1) {
        // !!! strange not a paginated result, but straight array !!!
        return {
          status: ApiService.RESPONSE_SUCCESS,
          subsidies: apiResponse,
        };
      }
    } catch (e) {
      console.log("API Exception: ", e);

      if (e.response.status === 401) {
        PubSub.publish("unauthenticated");
        return {
          status: ApiService.RESPONSE_UNAUTHENTICATED,
        };
      }

      if (e.response.status === 400) {
        // !!! 400 means there is no purse in the DB for this user yet. Defaulting to 0 !!!
        return {
          status: ApiService.RESPONSE_SUCCESS,
          subsidies: [],
        };
      }
    }

    return {
      status: ApiService.RESPONSE_ERROR,
    };
  }

  /**
   * Returns the items for the Refund
   * This is just DEMO data for now, awaiting API implementation
   * @returns
   */
  fetchRefundItems() {
    const items = [
      {
        id: "123456",
        title: "Bottle Water",
        price: 2,
      },
      {
        id: "234567",
        title: "Crisps",
        price: 1.3,
      },
      {
        id: "345678",
        title: "Meal Deal",
        price: 2.8,
      },
    ];

    const serverResponse = {
      status: "success",
      data: {
        items: items,
      },
    };

    const myPromise = new Promise(function (myResolve /*, myReject*/) {
      setTimeout(function () {
        myResolve(serverResponse);
      }, 1000);
    });

    return myPromise;
  }

  /**
   * Finds a class by its class ID
   * @param classId
   * @returns
   */
  async findClassByClassId(
    classId: string
  ): Promise<ApiFindClassByClassIdResponse> {
    const data = {
      id: classId,
    };

    try {
      const apiResponse = await this.get("/classes?status=active", data);
      console.log("API Response:", apiResponse);

      if (apiResponse.length > 0) {
        // !!! strange not a paginated result, but straight array !!!
        const successResponse: ApiFindClassByClassIdResponse = {
          status: ApiService.RESPONSE_SUCCESS,
          class: apiResponse[0],
        };
        return successResponse;
      }
    } catch (e) {
      console.log("API Exception: ", e);
      if (e.response.status === 401) {
        PubSub.publish("unauthenticated");
        const response: ApiEposUsersResponse = {
          status: ApiService.RESPONSE_UNAUTHENTICATED,
        };
        return response;
      }
    }

    const errorResponse: ApiFindClassByClassIdResponse = {
      status: ApiService.RESPONSE_ERROR,
    };

    return errorResponse;
  }

  async findCardbySerialNumber(
    cardSerialNumber: string
  ): Promise<ApiFindCardBySerialNumer> {
    const data = {};

    try {
      const apiResponse = await this.get("/cards/" + cardSerialNumber, data);
      console.log("API Response:", apiResponse);

      if (apiResponse.id) {
        const successResponse: ApiFindCardBySerialNumer = {
          status: ApiService.RESPONSE_SUCCESS,
          cardId: apiResponse.id,
          cardStatus: apiResponse.status,
        };
        return successResponse;
      }
    } catch (e) {
      console.log("API Exception: ", e);
      if (e.response.status === 401) {
        PubSub.publish("unauthenticated");
        const response: ApiEposUsersResponse = {
          status: ApiService.RESPONSE_UNAUTHENTICATED,
        };
        return response;
      }
      throw e;
    }

    const errorResponse: ApiFindCardBySerialNumer = {
      status: ApiService.RESPONSE_ERROR,
    };

    return errorResponse;
  }

  /**
   * Finds transactions for the day for the Epos User
   * @param schoolId
   * @returns transactions
   */
  async fetchTransactionsBySchoolID(
    schoolId: string,
    page: number
  ): Promise<ApiFetchTransactionResponse> {
    const data = {
      schoolId,
      page,
      size: 2000,
      createdAt: todayDate(),
    };

    try {
      const apiResponse = await this.get("/transactions", data);
      console.log("API Response:", apiResponse);

      if (apiResponse.results && apiResponse.results.length > -1) {
        return {
          status: ApiService.RESPONSE_SUCCESS,
          transactions: apiResponse.results,
          totalItems: apiResponse.totalItems,
          totalPages: apiResponse.totalPages,
          currentPage: apiResponse.currentPage,
        };
      }
    } catch (e) {
      console.log("API Exception: ", e);

      if (e.response.status === 401) {
        PubSub.publish("unauthenticated");
        return {
          status: ApiService.RESPONSE_UNAUTHENTICATED,
        };
      }
    }

    return {
      status: ApiService.RESPONSE_ERROR,
      message: "error fetching transactions",
    };
  }

  /**
   * Finds transactions for the day for the Epos User
   * @param eposUserId
   * @returns transactions
   */
  async fetchTransactionsByEposUserID(
    eposUserId: string,
    page: number
  ): Promise<ApiFetchTransactionResponse> {
    const data = {
      eposUserId,
      page,
      size: 2000,
      createdAt: todayDate(),
    };

    try {
      const apiResponse = await this.get("/transactions", data);
      console.log("API Response:", apiResponse);

      if (apiResponse.results.length > -1) {
        return {
          status: ApiService.RESPONSE_SUCCESS,
          transactions: apiResponse.results,
          totalItems: apiResponse.totalItems,
          totalPages: apiResponse.totalPages,
          currentPage: apiResponse.currentPage,
        };
      }
    } catch (e) {
      console.log("API Exception: ", e);

      if (e.response.status === 401) {
        PubSub.publish("unauthenticated");
        return {
          status: ApiService.RESPONSE_UNAUTHENTICATED,
        };
      }
    }

    return {
      status: ApiService.RESPONSE_ERROR,
      message: "error fetching transactions",
    };
  }

  /**
   * Finds transactions for the day for the Epos User
   * @param userId
   * @returns transactions
   */
  async fetchTransactionsByUserID(
    userId: string,
    page: number,
    size: number
  ): Promise<ApiFetchTransactionResponse> {
    const data = {
      userId,
      page,
      size,
    };

    try {
      const apiResponse = await this.get("/transactions", data);
      console.log("API Response:", apiResponse);

      if (apiResponse.results.length > -1) {
        return {
          status: ApiService.RESPONSE_SUCCESS,
          transactions: apiResponse.results,
          totalItems: apiResponse.totalItems,
          totalPages: apiResponse.totalPages,
          currentPage: apiResponse.currentPage,
        };
      }
    } catch (e) {
      console.log("API Exception: ", e);

      if (e.response.status === 401) {
        PubSub.publish("unauthenticated");
        return {
          status: ApiService.RESPONSE_UNAUTHENTICATED,
        };
      }
    }

    return {
      status: ApiService.RESPONSE_ERROR,
      message: "error fetching transactions",
    };
  }

  /**
   * Finds a customer by card serial number
   * @param cardNumber
   * @returns
   */
  async findCustomerByCard(cardNumber: string): Promise<ApiCustomerResponse> {
    const errorResponse: ApiCustomerResponse = {
      status: ApiService.RESPONSE_ERROR,
      message: "Customer may be Inactive or, Suspended",
    };

    const unauthenticatedResponse: ApiCustomerResponse = {
      status: ApiService.RESPONSE_UNAUTHENTICATED,
      message: "Request not authenticated",
    };

    const cardApiResponse = await this.findCardbySerialNumber(cardNumber);

    if (cardApiResponse.status === ApiService.RESPONSE_ERROR) {
      return errorResponse;
    }

    if (cardApiResponse.status === ApiService.RESPONSE_UNAUTHENTICATED) {
      return unauthenticatedResponse;
    }

    if(cardApiResponse.cardStatus === 'withdrawn'){
      const withdrawnResponse: ApiCustomerResponse = {
        status: ApiService.RESPONSE_CARD_WITHDRWAN,
        message: "FOB has been withdrawn",
      };

      return withdrawnResponse
    }

    const cardId = cardApiResponse.cardId;

    try {
      const data = {
        cardId: cardId,
      };
      const apiResponse = await this.get("/users", data);
      console.log("API Response:", apiResponse);

      if (apiResponse.results.length > 0) {
        const result = apiResponse.results[0];
        if(result.status !== 'active'){
          return errorResponse
        }
        const apiUser: any = {
          allergenIds: result.allergenIds,
          avatar: result.avatar,
          backendAssociatedSchoolIds: result.backendAssociatedSchoolIds,
          displayName: result.displayName,
          schoolId: result.schoolId,
          cardId: result.cardId,
          classId: result.classId,
          email: result.email,
          firstName: result.firstName,
          id: result.id,
          languageId: result.languageId,
          lastName: result.lastName,
          groupIds: result.groupIds,
          lastTokenIssuedAt: result.lastTokenIssuedAt,
          organisationId: result.organisationId,
          phoneNumber: result.phoneNumber,
          status: result.status,
          title: result.title,
          type: result.type,
          userRoleId: result.userRoleId,
          subsidyBalance: result.subsidyBalance,
          purseBalance: result.purseBalance,
          maximumDeficit: result.maximumDeficit,
          isGuest: result.isGuest
        };

        const customer: Customer = {
          id: apiUser.id,
          display_name: apiUser.displayName,
          first_name: apiUser.firstName,
          last_name: apiUser.lastName,
          class_id: apiUser.classId,
          class: "NA", // dynamic, will be populated later, if available
          last_seen: "01.01.1111|00:00", // TODO (where do we get this from?)
          available_balance: apiUser.purseBalance / 100.0, // TODO (where do we get this from?)
          subsidy: apiUser.subsidyBalance / 100.0, // TODO (where do we get this from?)
          card_id: apiUser.cardId,
          card_number: cardNumber,
          school_id: apiUser.schoolId ? apiUser.schoolId : "noschool",
          type: apiUser.type,
          allergenIds: apiUser.allergenIds,
          maximumDeficit: apiUser.maximumDeficit ? apiUser.maximumDeficit / 100.0 : 0,
          avatar:apiUser.avatar,
          status:apiUser.status,
          isGuest: apiUser.isGuest
        };

        /*const apiClassResponse: ApiFindClassByClassIdResponse = await this.findClassByClassId(
          customer.school_id,
        )

        if (apiClassResponse.status === ApiService.RESPONSE_SUCCESS) {
          customer.class = apiClassResponse.class.name
        }*/

        // DEBUG: console.log(result, apiUser, customer)
        // DEBUG: console.log('API User =>', apiUser)
        // DEBUG: console.log('Customer =>', customer)

        const purse: ApiPurse = {
          userId: customer.id,
          schoolId: customer.school_id,
          organisationId: apiUser.organisationId,
          balance: apiUser.purseBalance / 100.0,
        };
        this.sharedStateService.setPurse(purse);

        /*const subsidyResponse:ApiFetchSubsidyResponse = await this.fetchSubsidByUserId(customer.id);

        if(subsidyResponse.status === ApiService.RESPONSE_SUCCESS){
          const modifiedSubsidies:ApiSubsidy[]=[]
          subsidyResponse.subsidies.forEach((subsidy)=>{
            const newSubsidy={...subsidy, subsidyBalance:subsidy.subsidyBalance / 100.0}
            modifiedSubsidies.push(newSubsidy);
          })
          this.sharedStateService.setSubsidies(modifiedSubsidies);
        }*/

        const subsidy: ApiSubsidy = {
          userId: customer.id,
          userGroupId: null,
          schoolId: customer.school_id,
          organisationId: apiUser.organisationId,
          subsidyBalance: apiUser.subsidyBalance / 100.0,
        };
        this.sharedStateService.setSubsidy(subsidy);

        return {
          status: ApiService.RESPONSE_SUCCESS,
          message: "Customer found",
          customer: customer,
        };
      }
    } catch (e) {
      console.log("API Exception: ", e);
      if (e.response.status === 401) {
        PubSub.publish("unauthenticated");
        return unauthenticatedResponse;
      }
    }

    return errorResponse;
  }

  /**
   * Creates a new transaction.
   * This replaces the create order
   * @param transactionPostRequest
   */
  async postRefundTransaction(
    transactionPostRequest: ApiRefundTransactionPostRequest
  ): Promise<ApiResponse> {
    try {
      const apiResponse = await this.post(
        "/transactions",
        transactionPostRequest
      );

      console.log("API Response: ", apiResponse);

      return {
        status: ApiService.RESPONSE_SUCCESS,
        message: "Refund Successful",
      };
    } catch (e) {
      console.log("API Exception: ", e);
      if (e.response.status === 401) {
        PubSub.publish("unauthenticated");
        return {
          status: ApiService.RESPONSE_UNAUTHENTICATED,
          message: "Session Expired",
          code: e.response.data.error.code,
        };
      } else if (e.response.status === 422) {
        return {
          status: ApiService.RESPONSE_TRANSACTION_ERROR,
          message: e.response.data.error.detail,
          code: e.response.data.error.code,
          errorStatusCode: e.response.status
        };
      } else if (e.response.data && e.response.data.error) {
        return {
          status: ApiService.RESPONSE_ERROR,
          message: e.response.data.error.detail,
          code: e.response.data.error.code,
          errorStatusCode: e.response.status
        };
      }
    }

    return {
      status: ApiService.RESPONSE_ERROR,
      message: "Refund Failed",
    };
  }

  async postTransactionWith422Retry(
    transactionPostRequest: ApiTransactionPostRequest, maxTries = 5
  ): Promise<ApiResponse> {
    let delay = 1000;
    for (let i = 0; i < maxTries; ++i) {
      const result = await this.postTransaction(transactionPostRequest);
      if (result.status === ApiService.RESPONSE_TRANSACTION_ERROR) {
        await new Promise(timeout => setTimeout(timeout, delay));
        console.log(`retrying in: ${delay}ms`)
        delay *= 2;
      } else {
        return result;
      }
    }
    // we don't return the 422 as this would result in the transaction being deleted locally.
  }
  /**
   * Creates a new transaction.
   * This replaces the create order
   * @param transactionPostRequest
   */
  async postTransaction(
    transactionPostRequest: ApiTransactionPostRequest
  ): Promise<ApiResponse> {
    try {
      const apiResponse = await this.post(
        "/transactions",
        transactionPostRequest
      );

      console.log("API Response: ", apiResponse);

      return {
        id: apiResponse?.id || null,
        status: ApiService.RESPONSE_SUCCESS,
        message: "Payment Successful",
      };
    } catch (e) {
      console.log("API Exception: ", e);
      if (e.response.status === 401) {
        PubSub.publish("unauthenticated");
        return {
          status: ApiService.RESPONSE_UNAUTHENTICATED,
          message: "Session Expired",
          code: e.response.data.error.code,
        };
      } else if (e.response.status === 422) {
        return {
          status: ApiService.RESPONSE_TRANSACTION_ERROR,
          message: e.response.data.error.detail,
          code: e.response.data.error.code,
          errorStatusCode: e.response.status
        };
      } else if (e.response.data && e.response.data.error) {
        return {
          status: ApiService.RESPONSE_ERROR,
          message: e.response.data.error.detail,
          code: e.response.data.error.code,
          errorStatusCode: e.response.status
        };
      }
    }

    return {
      status: ApiService.RESPONSE_ERROR,
      message: "Payment Failed",
    };
  }

  /**
   * Creates a new order.
   * The order is created by placing a transaction in the API
   *
   * @param pin
   * @returns
   */
  async DEPRECATED_orderCreate(
    userId: string,
    totalCost
  ): Promise<ApiResponse> {
    const purseResponse = await this.fetchPurseByUserId(userId);
    if (purseResponse.status !== ApiService.RESPONSE_SUCCESS) {
      return {
        status: ApiService.RESPONSE_ERROR,
        message: "Purse failed to be fetched",
      };
    }

    const newPurseBalance = purseResponse.purse.balance - totalCost;
    const data = {
      userId: userId,
      cashBalance: newPurseBalance.toFixed(2),
    };

    try {
      const apiResponse = await this.patch(
        "/purses/" + purseResponse.purse.id,
        data
      );

      console.log("API Response: ", apiResponse);

      return {
        status: ApiService.RESPONSE_SUCCESS,
        message: "successful payment",
      };
    } catch (e) {
      console.log("API Exception: ", e);
      if (e.response.status === 401) {
        PubSub.publish("unauthenticated");
        return {
          status: ApiService.RESPONSE_UNAUTHENTICATED,
          message: "session expired",
        };
      }
    }

    return {
      status: ApiService.RESPONSE_ERROR,
      message: "payment error",
    };
  }

  /**
   * Logs the operator using a PIN
   * @param pin
   * @returns
   */
  async operatorLogin(
    eposUserId: string,
    pin: string
  ): Promise<ApiOperatorLoginResponse> {
    // https://api.staging.lunchclub.cloud/epos-users?pin=4163&schoolId=ce5421f4-13bf-4ea6-95c9-4ca1ca98890c

    const data = {
      id: eposUserId,
      pin: pin,
      // "schoolId": EnvService.currentEposId() // on staging school does not match the manager schoolId
    };

    try {
      const apiResponse = await this.get("/epos-users", data);

      console.log("API Response: ", apiResponse);

      if (apiResponse.length > 0) {
        const response: ApiOperatorLoginResponse = {
          status: ApiService.RESPONSE_SUCCESS,
          eposUser: apiResponse[0],
        };
        return response;
      }

      const response: ApiOperatorLoginResponse = {
        status: ApiService.RESPONSE_ERROR,
        authenticated: false,
      };
      return response;
    } catch (e) {
      console.log("API Exception: ", e);
      if (e.response.status === 401) {
        PubSub.publish("unauthenticated");
        const response: ApiEposUsersResponse = {
          status: ApiService.RESPONSE_UNAUTHENTICATED,
        };
        return response;
      }
    }

    const response: ApiOperatorLoginResponse = {
      status: ApiService.RESPONSE_ERROR,
    };

    return response;
  }

  /**
   * TODO. Awaiting API implementation. For now returns a successful promise only
   * @param pendingOrders
   * @returns
   */
  syncPendingOrders(pendingOrders: PendingOrder[]): Promise<ApiResponse> {
    const serverResponse: ApiResponse = {
      status: "success",
      message: "Pending orders successfully processed",
      data: {},
    };

    const myPromise: Promise<ApiResponse> = new Promise(function (
      myResolve /*, myReject*/
    ) {
      setTimeout(function () {
        myResolve(serverResponse);
      }, 1000);
    });

    return myPromise;
  }

  async createConnectionToken(): Promise<ApiResponse> {

    const response = await this.post(
      "/stripe/connection-token",
      {}
    );

    return response;
  }

  async createPaymentIntent(amount, currency, userId): Promise<any> {

    const response = await this.post(
      "/stripe/payment-intent-card-present",
      {
        amount, currency, userId
      }
    );

    return response;
  }
}

export default ApiService;
