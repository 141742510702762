import { DialogTitle, styled } from "@mui/material";
import { appFonts } from "../../utils/ThemeManager";

export const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
    textAlign: "center",
    fontSize: 25,
    fontWeight: "bold",
    margin: "0px 0px 0px 0px",
    padding: "5px",
    color: "white",
    background:
    "linear-gradient(180deg, #FF4747 0%, #D00000 100%)",
}));

export const StyledDialogTitleNormal = styled(DialogTitle)(({ theme }) => ({
    textAlign: 'center',
    fontSize: '35px',
    fontFamily: appFonts.Montserrat,
    fontWeight: 'bold',
    margin: '20px 0px 0px 0px'
}));