import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import React from 'react'
import PubSub from 'pubsub-js'
import './DialogPayment.css'
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace'
import { StyledDialogTitle, StyledDialogTitleNormal } from '../../components/Dialog/StyledDialogTitle'
import { StyledDialogCancelButton } from '../../components/Dialog/DialogButton'

type DialogScanState = {
  // [x:string]:any,
  showDialog?: boolean
  message?: string
}

interface DialogScanProps {
  onCloseKiosk: () => void
}

class DialogScanError extends React.Component<
DialogScanProps,
DialogScanState
> {
  constructor(props) {
    super(props)
    this.state = {
      showDialog: false,
    }
  }

  componentDidMount(): void {
    PubSub.subscribe('dialog-scan-error-show', (msg: string, data: any) => {
      this.setState({ message: data }, () => this.show())

    });

  }

  /**
   * Shows the dialog
   */
  show(): void {
    this.setState({
      showDialog: true,
    });
  }

  /**
   * Closes the dialog
   */
  closeDialog(): void {
    this.setState({
      showDialog: false,
    });
  }

  /**
   * Publishes event that payment is clicked
   * and closes the dialog
   */
  proceedClose(): void {
    this.props.onCloseKiosk()
    this.closeDialog()
  }

  componentWillUnmount(): void { }

  /**
   * Renders the dialog
   * @returns
   */
  render(): ReactJSXElement {
    return (
      <>
        <Dialog
          id="DialogPayment"
          open={this.state.showDialog}
          keepMounted
          onClose={() => this.closeDialog()}
        >
          <StyledDialogTitleNormal>ERROR</StyledDialogTitleNormal>
          <DialogContent>
            <DialogContentText className="DialogContentText">{this.state.message}</DialogContentText>
          </DialogContent>
          <DialogActions className="DialogActions">
            <StyledDialogCancelButton
              onClick={() => this.proceedClose()}
            >
              Close
            </StyledDialogCancelButton>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

export default DialogScanError
