import { Box, Typography } from "@mui/material"
import { avatarIcon, icon } from "../../utils/utils"

interface ICompProps{
    title:string
    iconName:string
    onAction:any
    style:any
}

export default function GuestOption({title, iconName, onAction, style}:ICompProps){
    return (
        <Box display="flex" sx={style}  flexDirection="column" justifyContent="flex-end" alignItems="flex-end" flex="none" order={0} flexGrow={0} gap={10}>
            <Box 
                boxSizing='border-box' 
                display="flex" 
                flexDirection="row" 
                justifyContent="flex-end" 
                alignItems="center" 
                padding={'0px 0px 0px 10px'} 
                sx={{cursor:'pointer',borderColor:'white', border:2, borderRadius:50, height:46}} 
                flex="none" 
                order={2} 
                flexGrow={0} 
                onClick={onAction}
            >
                <Typography color={'white'} lineHeight={'22px'} flex={'none'} order={0} flexGrow={0} mr={1}>{title}</Typography>
                <Box flex={'none'} order={1} flexGrow={0} width={'46px'} height={'46px'} position={'relative'}>
                    <Box position='absolute' top={0} right={-2}>
                        {avatarIcon(iconName, 46, false)}
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}