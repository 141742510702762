import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import React from 'react'
import PubSub from 'pubsub-js'
import './DialogRefund.css'
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace'
import { StyledDialogTitle, StyledDialogTitleNormal } from '../../components/Dialog/StyledDialogTitle'
import { StyledDialogCancelButton, StyledDialogOKButton } from '../../components/Dialog/DialogButton'
import { StyledDialogText } from '../../components/Dialog/DialogText'

type DialogRefundState = {
  // [x:string]:any,
  showDialog:boolean,
}

interface DialogRefundProps{
  showDialog: boolean;
}

class DialogRefund extends React.Component<DialogRefundProps, DialogRefundState> {

  constructor(props) {
    super(props)
    this.state = {
      showDialog: false,
    }
  }

  componentDidMount(): void {
    PubSub.subscribe('dialog-refund-show', (msg: string, data: any) => {
      this.show()
    })
    PubSub.subscribe('dialog-refund-close', (msg: string, data: any) => {
      this.closeDialog()
    })
  }

  componentWillUnmount(): void {
    PubSub.unsubscribe('dialog-refund-show')
    PubSub.unsubscribe('dialog-refund-close')
  }

  /**
   * Shows the dialog
   */
  show(): void {
    this.setState({
      showDialog: true,
    })
  }

  /**
   * Closes the dialog
   */
  closeDialog(): void {
    this.setState({
      showDialog: false,
    })
  }

  /**
   * Publishes event that refund is clicked
   * and closes the dialog
   */
  refundNow(): void {
    PubSub.publish('dialog-refund-refund-now', {})
    this.closeDialog()
  }

  /**
   * Renders the dialog
   * @returns
   */
  render(): ReactJSXElement {
    return (
      <>
        <Dialog
          id="DialogRefund"
          open={this.state.showDialog}
          keepMounted
          onClose={() => this.closeDialog()}
        >
          <StyledDialogTitleNormal>READY TO REFUND?</StyledDialogTitleNormal>
          <DialogContent>
            <DialogContentText className="DialogContentText">
              <StyledDialogText>
                Click the Refund button to confirm and complete the refund or
                click the Cancel button to close this dialog
              </StyledDialogText>
            </DialogContentText>
          </DialogContent>
          <DialogActions className="DialogActions">
            <StyledDialogCancelButton
              onClick={() => this.closeDialog()}
            >
              Cancel
            </StyledDialogCancelButton>
            <StyledDialogOKButton
              onClick={() => this.refundNow()}
            >
              Refund
            </StyledDialogOKButton>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

export default DialogRefund
