import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { secondaryColor } from '../../utils/ThemeManager'

interface IScreenProps{
  global?:boolean
}

const NetworkStatus = ({global}:IScreenProps) => {
  const [status, setStatus] = useState(navigator.onLine)

  useEffect(() => {
    function changeStatus() {
      const online = navigator.onLine
      PubSub.publish('isonline', online)
      setStatus(online)
    }
    window.addEventListener('online', changeStatus)
    window.addEventListener('offline', changeStatus)
    return () => {
      window.removeEventListener('online', changeStatus)
      window.removeEventListener('offline', changeStatus)
    }
  }, [])

  return (
    <Box
      px={1}
      sx={{
        backgroundColor: secondaryColor,
        color: 'white',
        opacity: status ? 0.0 : 0.5,
        height: 30
      }}
      display="flex"
      alignItems="center"
    >
      OFFLINE
    </Box>
  )
}

export default NetworkStatus
