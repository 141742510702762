import React from 'react';
import Lottie from 'react-lottie-player';
import lottieJson from './card-scan-lottie.json';
import LunchClubLogo from '../../components/LunchClubLogo/LunchClubLogo';
import SchoolFoodLogo from '../../components/SchoolFoodLogo/SchoolFoodLogo';
import Layout from '../../components/Layout/Layout';
import PageService from '../../services/PageService';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import ApiService from '../../services/ApiService';
import SharedStateService, {
  keyUpdateCartToCustomer,
} from '../../services/SharedStateService';
// import SpeedDialIcon from '@mui/material/SpeedDialIcon';
// import AccountBalanceWalletRoundedIcon from '@mui/icons-material/AccountBalanceWalletRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
// import EnvService from '../../services/EnvService'
import {
  Box,
  CircularProgress,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { appFonts } from '../../utils/ThemeManager';
// import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';

import AlertBox from '../../components/Custom/AlertBox';
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded';
// import DialogSupport, { DialogScreenType } from "../KioskPage/DialogSupport";
import SummarizeRoundedIcon from '@mui/icons-material/SummarizeRounded';
// import DialogDailySummary from "./DialogDailySummary_Obselete";
import DialogDailyTransactions from './DialogDailyTransactions';
import EnvService from '../../services/EnvService';
import SittingManager from '../../services/SittingManager';
import DialogSupport, {
  DialogScreenType,
} from '../../components/DialogSupport/DialogSupport';
import AudioManager from '../../services/AudioManager';
// import CardScanImage from "../../components/CardScanImage/CardScanImage";
import KioskPage from '../KioskPage/KioskPage';
import { OperationMode, removeEmptyFields } from '../../utils/utils';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import GuestOption from './GuestOption';
import { StyledIconButton } from '../../components/Custom/StyledIconButton';
import ElectronService from '../../services/ElectronService';

const debounce = <T extends (...args: any[]) => ReturnType<T>>(
  callback: T,
  timeout: number
): ((...args: Parameters<T>) => void) => {
  let timer: ReturnType<typeof setTimeout>;

  return (...args: Parameters<T>) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      callback(...args);
    }, timeout);
  };
};

type CardScanPageState = {
  error: {
    status: boolean;
    message: string;
  };
  processing: boolean;
  showDialogDailySummary: boolean;
  showBarcodeScannerInput: boolean;
  showKiosk: boolean;
  cart: Cart;
  customer: Customer;
  operationMode: string;
  anchorEl: null | HTMLElement;
};

type CardScanPageProps = {
  barcode?: string;
};

class CardScanPage extends React.Component<
  CardScanPageProps,
  CardScanPageState
> {
  private barcodeReaderInterval: any = null;
  private apiService: ApiService = new ApiService();
  private pageService: PageService = new PageService();
  private sharedStateService: SharedStateService = new SharedStateService();
  private locked: boolean;

  constructor(props) {
    super(props);
    const err = this.sharedStateService.get('error');
    this.state = {
      error: err || {
        status: false,
        message: '',
      },
      processing: false,
      showDialogDailySummary: false,
      showBarcodeScannerInput: false,
      showKiosk: false,
      cart: null,
      customer: null,
      operationMode: OperationMode.local,
      anchorEl: null,
    };
    this.sharedStateService.set('error', null);
    this.locked = false;
    this.stopFocusing = this.stopFocusing.bind(this);
  }

  /**
   * Start keeping the barcode reader focused when the component is loaded
   */
  async componentDidMount(): Promise<void> {
    this.addBarcodeReaderFocuser();

    //If in case this page is visible due to any reason on Kiosk the customer should see scan page
    if (
      this.pageService.getCustomerPage() !==
      this.pageService.CUSTOMER_CARD_SCAN_PAGE
    ) {
      this.pageService.setCustomerPage(
        this.pageService.CUSTOMER_CARD_SCAN_PAGE
      );
    }

    SittingManager.sharedManager().setCallback(null);

    PubSub.subscribe('isonline', (msg, online: boolean) => {
      if (this.state.operationMode === OperationMode.online && !online) {
        this.setState({ operationMode: OperationMode.local });
      }
    });
    this.locked = false;
  }

  /**
   * Clear the barcode reading interval when page unmounts
   */
  componentWillUnmount(): void {
    this.stopFocusing();
  }

  stopFocusing() {
    clearInterval(this.barcodeReaderInterval);
  }

  /**
   * Focuses the scanner reader input
   * so that it can capture the scan
   */
  addBarcodeReaderFocuser() {
    this.barcodeReaderInterval = setInterval(() => {
      const isCardScanPage =
        this.pageService.getOperatorPage() !==
        this.pageService.OPERATOR_CARD_SCAN_PAGE;

      if (isCardScanPage) {
        // console.log('barcode skip focus')
        return true;
      }

      const reader = document.getElementById('ScannerReader');
      if (reader) {
        reader.focus({ preventScroll: true });
      } else {
        console.log('Barcode Reader not found');
      }
    }, 1000);
  }

  /**
   * Just the beep sound
   */
  beep(): void {
    AudioManager.sharedManager().beep();
    // const sound: HTMLVideoElement = document.getElementById(
    //   "audiobeep"
    // ) as HTMLVideoElement;
    // if (sound) {
    //   sound.play();
    // }
  }

  /**
   * Opens the manual entry page
   */
  startManualEntry(): void {
    this.beep();
    this.pageService.setCustomerPage(
      this.pageService.CUSTOMER_MANUAL_ENTRY_PAGE
    );
    this.pageService.setOperatorPage(
      this.pageService.OPERATOR_MANUAL_ENTRY_PAGE
    );
  }

  /**
   * Called when the scanner input receives text
   * Activates the clearance of the input
   * @param {*} barcode
   * @returns
   */
  async onChangeBarcode(barcode) {
    if (this.locked) return;

    barcode = barcode.split('@').join('"'); // some readers read " in json with @ (UK keyboard layout instead of US)
    barcode = barcode.split('-').join(''); // remove dashes
    barcode = barcode.toUpperCase(); // card number is uppercase
    console.log('Barcode:', barcode);
    /* 
        setTimeout(() => {
          this.clearBarcodeReaderInput();
        }, 2000);
     */
    // Is it old format? 8 digits, separated by a dash.
    // We remove the dashes above, so only 8 digits should remain
    if (barcode.length === 8) {
      let isnum = /^\d+$/.test(barcode);
      if (isnum) {
        this.setState({
          error: {
            status: true,
            message: 'This is the old QR format. Please request a new QR',
          },
          processing: false,
        });
      }
      return false;
    }

    let barcodeLengthTreshold = 12;
    if (barcode.indexOf('-') > -1) {
      barcodeLengthTreshold = 14;
    }

    if (barcode.length < barcodeLengthTreshold) {
      // Ignore everything below a threshold
      console.log('Barcode length ' + barcode.length + ' invalid. SKIPPED');
      this.setState({
        error: {
          status: true,
          message: 'Barcode length ' + barcode.length + ' invalid',
        },
        processing: false,
      });
      return false;
    }

    console.log('Barcode length ' + barcode.length + ' valid. PROCEEDING');
    // this.setState({ processing: true });

    this.locked = true;

    await this.checkBarcode(barcode);
    this.clearBarcodeReaderInput();
    this.locked = false;
    return true;
  }

  /**
   * Update the cart to show Wallet of customer on Customer screen
   * @param customer
   */
  broadcastDefaultCart(customer) {
    // DEBUG: console.log("BROADCAST DEFAULT CART");

    const org = this.sharedStateService.getOrg();
    const supportedCurrency = org.supportedCurrencies[0];

    const cart: Cart = {
      menuId: null,
      currencySymbol: supportedCurrency.symbol,
      items: [],
      purseBefore: customer.available_balance,
      purseAfter: customer.available_balance,
      subsidyBefore: customer.subsidy,
      subsidyAfter: 0.0,
      totalPurse: customer.available_balance,
      totalSubsidy: customer.subsidy,
      total: 0.0,
    };

    this.sharedStateService.setCart(cart);
    PubSub.publishSync('cart_updated', keyUpdateCartToCustomer);

    this.setState({ cart, customer });
  }

  redirectToManagerLoginPage() {
    this.sharedStateService.deleteCustomer();
    this.pageService.setOperatorPage(this.pageService.OPERATOR_INIT_PAGE);
    this.pageService.setCustomerPage(this.pageService.CUSTOMER_INIT_PAGE);
    this.setState({ processing: false });
  }

  /**
   * Anonymous customer is generated when in offline mode.
   * This custimer does not really have an ID, only a card number
   * @param barcode
   * @returns
   */
  generateAnonymousCustomer(barcode: string): Customer {
    const anonymousCustomer: Customer = {
      id: '',
      display_name: 'Offline Customer',
      first_name: 'Offline',
      last_name: 'Customer',
      class_id: 'Offline',
      class: 'Offline',
      last_seen: '',
      available_balance: 0,
      subsidy: 0,
      card_id: '',
      card_number: barcode,
      school_id: '',
      type: 'offline',
      maximumDeficit: 0,
      allergenIds: [],
      avatar: 'default',
      status: 'active',
      isGuest: false,
    };

    return anonymousCustomer;
  }

  async reSyncUser(barcode) {
    this.apiService.findCustomerByCard(barcode).then((response) => {
      if (response.status === ApiService.RESPONSE_SUCCESS) {
        const customer = response.customer;
        //Update user in storage
        let allUsers = this.sharedStateService.getSchoolUsers();

        const userIndex = allUsers.findIndex((user) => user.id === customer.id);
        allUsers[userIndex] = customer;

        this.sharedStateService.setSchoolUsers(allUsers);
      } else {
        if (response.status === ApiService.RESPONSE_CARD_WITHDRWAN) {
          const e = {
            status: true,
            message: response.message,
          };
          this.sharedStateService.set('error', e);

          this.setState({
            processing: false,
            error: e,
          });
        }
      }
    });
  }

    // As a log
    recordCardScanToDevice(card) {
      try {
        ElectronService.recordCardScan(card);
      } catch (error) {
        console.error('Error in recording card scan to device', error);
      }
    }

  /**
   * Check the validity of the barcode and procees if valid
   *
   * 1. Check if there is valid auth token, redirect to manager login page
   * 2. Call the API
   * 3. On unauthenticated response, redirect to manager login page
   * If we are in offline mode, we turn into "trust mode" and
   * login the user only with the card number
   *
   * @param {*} barcode
   */
  async checkBarcode(barcode) {
    if (!this.sharedStateService.hasAuthToken()) {
      this.redirectToManagerLoginPage();
      return false;
    }

    const metaData = this.sharedStateService.getSystemInfo();
    metaData.platform = 'win32';
    metaData.offline = EnvService.isOffline();
    removeEmptyFields(metaData);

    this.recordCardScanToDevice({
      createdAt: new Date(),
      organisationId: this.sharedStateService.getOrg()?.id,
      schoolId: this.sharedStateService.getSchoolId(),
      eposId: this.sharedStateService.getEposId(),
      eposUserId: this.sharedStateService.getOperator().id,
      cardSerialNumber: barcode,
      metaData,
    });

    if (
      EnvService.isOffline() ||
      this.sharedStateService.getOperationMode() === OperationMode.local
    ) {
      // || this.sharedStateService.getOperationMode() === OperationMode.local
      // this.pageService.setOperatorPage(this.pageService.OPERATOR_KIOSK_PAGE);

      const schoolUsers = this.sharedStateService.getSchoolUsers();
      const users = schoolUsers.filter((user) => user.card_number === barcode);
      if (users.length > 0) {
        const customer = users[0];
        if (customer.status === 'active') {
          this.sharedStateService.setCustomer(customer);
          this.broadcastDefaultCart(customer);
          this.setState({ showKiosk: true, showBarcodeScannerInput: false });
          PubSub.publish('onKiosk', true);
          this.stopFocusing();

          this.pageService.setCustomerPage(this.pageService.CUSTOMER_TILL_PAGE);

          // @TODO
          // This action is disabled on 01/09/2023 - JMa8
          // Remove if not required or enable
          // if (EnvService.isOnline()) {
          //   console.log('Sync in background');

          //   this.apiService.findCustomerByCard(barcode).then((response) => {
          //     if (response.status === ApiService.RESPONSE_SUCCESS) {
          //       const customer = response.customer;
          //       //Update user in storage
          //       let allUsers = this.sharedStateService.getSchoolUsers();

          //       const userIndex = allUsers.findIndex(
          //         (user) => user.id === customer.id
          //       );
          //       allUsers[userIndex] = customer;

          //       //Update customer
          //       this.sharedStateService.setCustomer(customer);

          //       this.sharedStateService.setSchoolUsers(allUsers);

          //       /** It updates latest customer to the cart */
          //       PubSub.publish('update_customer_after_sync');
          //     } else {
          //       PubSub.publish('handle_scan_error', {
          //         message: response.message,
          //       });
          //     }
          //   });
          // }

        } else if (customer.status === 'suspended') {
          const e = {
            status: true,
            message: 'FOB "' + barcode + '" has been suspended',
          };
          this.sharedStateService.set('error', e);

          this.setState({
            processing: false,
            error: e,
          });
          this.reSyncUser(customer.card_number);
          return false;
        } else {
          const e = {
            status: true,
            message: 'Couldn\'t validate FOB "' + barcode + '"',
          };
          this.sharedStateService.set('error', e);

          this.setState({
            processing: false,
            error: e,
          });
          this.reSyncUser(customer.card_number);
          return false;
        }
      } else {
        // const anonymousCustomer = this.generateAnonymousCustomer(barcode);
        // this.sharedStateService.setCustomer(anonymousCustomer);
        // this.broadcastDefaultCart(anonymousCustomer);

        const e = {
          status: true,
          message: 'Couldn\'t validate FOB "' + barcode + '"',
        };
        this.sharedStateService.set('error', e);

        this.setState({
          processing: false,
          error: e,
        });
        this.reSyncUser(barcode);
        return false;
      }
      // this.pageService.setOperatorPage(this.pageService.OPERATOR_KIOSK_PAGE);

      return true;
    }

    try {
      // this.pageService.setOperatorPage(this.pageService.OPERATOR_KIOSK_PAGE);

      const response: ApiCustomerResponse =
        await this.apiService.findCustomerByCard(barcode);

      if (response.status === ApiService.RESPONSE_UNAUTHENTICATED) {
        this.redirectToManagerLoginPage();
        return false;
      }

      if (response.status !== ApiService.RESPONSE_SUCCESS) {
        this.pageService.setOperatorPage(
          this.pageService.OPERATOR_CARD_SCAN_PAGE
        );
        this.pageService.setCustomerPage(
          this.pageService.CUSTOMER_CARD_SCAN_PAGE
        );
        const e = {
          status: true,
          message: 'Couldn\'t validate FOB "' + barcode + '"',
        };
        this.sharedStateService.set('error', e);
        this.setState({
          processing: false,
          error: e,
        });
        return false;
      }

      const customer = response.customer;
      const school = this.sharedStateService.getSchool();

      if (school.id !== customer.school_id) {
        this.pageService.setOperatorPage(
          this.pageService.OPERATOR_CARD_SCAN_PAGE
        );
        this.pageService.setCustomerPage(
          this.pageService.CUSTOMER_CARD_SCAN_PAGE
        );
        const e = {
          status: true,
          message: 'Couldn\'t validate FOB "' + barcode + '" with the School',
        };
        this.sharedStateService.set('error', e);

        this.setState({
          processing: false,
          error: e,
        });

        /*   this.setState({
          error: {
            status: true,
            message:
              "Couldn't validate card \"" + barcode + '" with the School',
          },
          processing: false,
        }); */
        return false;
      } else {
        //If scanned customer card matches with logged in school let in else throw error
        this.sharedStateService.setCustomer(customer);
        this.broadcastDefaultCart(customer);
        this.pageService.setCustomerPage(this.pageService.CUSTOMER_TILL_PAGE);
        // this.pageService.setOperatorPage(this.pageService.OPERATOR_KIOSK_PAGE);

        this.setState({ showKiosk: true, showBarcodeScannerInput: false });
        PubSub.publish('onKiosk', true);

        //Update user in storage
        let allUsers = this.sharedStateService.getSchoolUsers();

        const userIndex = allUsers.findIndex((user) => user.id === customer.id);
        allUsers[userIndex] = customer;

        this.sharedStateService.setSchoolUsers(allUsers);

        console.log('ONLINE CUSTOMER purse => ', customer.available_balance);

        this.setState({ processing: false });
      }
    } catch (error) {
      console.log(error);
      this.pageService.setOperatorPage(
        this.pageService.OPERATOR_CARD_SCAN_PAGE
      );
      this.pageService.setCustomerPage(
        this.pageService.CUSTOMER_CARD_SCAN_PAGE
      );
      const e = {
        status: true,
        message: "Couldn't process the FOB, try again.",
      };
      this.sharedStateService.set('error', e);

      this.setState({
        processing: false,
        error: e,
      });
      return false;
    }
  }

  /**
   * Fetches the purse for the user and sets the state of the initial customer purse
   * @param userId
   * @returns void
   */
  async fetchPurse(userId: string): Promise<ApiPurse> {
    const response: ApiFetchPurseResponse =
      await this.apiService.fetchPurseByUserId(userId);

    if (response.status === ApiService.RESPONSE_UNAUTHENTICATED) {
      this.sharedStateService.deleteCustomer();
      this.pageService.setOperatorPage(this.pageService.OPERATOR_LOGIN_PAGE);
      this.pageService.setCustomerPage(this.pageService.CUSTOMER_LOCKED_PAGE);
      return null;
    }

    try {
      if (response.status === ApiService.RESPONSE_SUCCESS) {
        const purse = response.purse;
        this.sharedStateService.setPurse(purse);
        return purse;
      } else {
        return null;
      }
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  /**
   * Fetches the subsidy for the user
   * @param userId
   * @returns void
   */
  async fetchSubsidies(userId: string): Promise<ApiSubsidy> {
    const response: ApiFetchSubsidyResponse =
      await this.apiService.fetchSubsidByUserId(userId);

    if (response.status === ApiService.RESPONSE_UNAUTHENTICATED) {
      this.sharedStateService.deleteCustomer();
      this.pageService.setOperatorPage(this.pageService.OPERATOR_LOGIN_PAGE);
      this.pageService.setCustomerPage(this.pageService.CUSTOMER_LOCKED_PAGE);
      return null;
    }

    try {
      if (response.status === ApiService.RESPONSE_SUCCESS) {
        const subsidies = response.subsidies;
        this.sharedStateService.setSubsidies(subsidies);
        return null;
      } else {
        return null;
      }
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  clearError = () => {
    this.setState({
      error: {
        status: false,
        message: '',
      },
    });
    this.sharedStateService.set('error', null);
  };

  /**
   * Clears the scanner imput
   */
  clearBarcodeReaderInput() {
    if (document.getElementById('ScannerReader')) {
      (document.getElementById('ScannerReader') as HTMLInputElement).value = '';
    }
  }

  /**
   * Returns the style for the barcode scanner
   *
   * !!! Warning. Do not user display:none, or visibility:hidden or will not be focused
   */
  barcodeScannerInputStyle(): React.CSSProperties {
    const visible = this.state.showBarcodeScannerInput;
    if (visible) {
      return {
        height: 50,
        width: 300,
        display: 'block',
        marginTop: -10,
        marginBottom: 0,
        // marginLeft: "auto",
        // marginRight: "auto",
        zIndex: 1000,
        position: 'absolute',
        // left: 150,
      };
    } else {
      return { position: 'absolute', left: -5000 };
    }
    // const inputStyle: React.CSSProperties = EnvService.isLocal()
    //   ? { height: 50, width: 300, position: 'absolute', left: 20 }
    //   : { position: 'absolute', left: -5000 }
    // return inputStyle
  }

  onSupport = () => {
    this.beep();
    PubSub.publish('dialog-support-show', '');
  };

  onDailyTransactions = async () => {
    this.beep();
    PubSub.publish('dialog-daily-transactions-show', {});
  };

  onShowManualCardEntry = async () => {
    this.beep();
    this.pageService.setCustomerPage(
      this.pageService.CUSTOMER_MANUAL_ENTRY_PAGE
    );
    this.pageService.setOperatorPage(
      this.pageService.OPERATOR_MANUAL_ENTRY_PAGE
    );
  };

  handleChange = (
    event: React.MouseEvent<HTMLElement>,
    operationMode: string
  ) => {
    this.setState({ operationMode });
    this.sharedStateService.setOperationMode(operationMode);
  };

  onLoginGuest(user: Customer) {
    this.checkBarcode(user.card_number);
    this.setState({ anchorEl: null });
  }

  onOpenEventWallet() {}

  render(): ReactJSXElement {
    try {
      const inputStyle: React.CSSProperties = this.barcodeScannerInputStyle();
      const users = this.sharedStateService.getSchoolUsers();
      const guestUsers = users?.filter((user) => user.isGuest);

      return (
        <>
          <div id="LoginPage">
            {/* <DialogDailySummary
            showDialog={this.state.showDialogDailySummary}
          ></DialogDailySummary> */}
            <DialogDailyTransactions />
            <DialogSupport
              showDialog={false}
              screenType={DialogScreenType.kiosk}
            ></DialogSupport>
            <Layout
              top={<LunchClubLogo />}
              center={
                this.state.error.status ? (
                  <AlertBox
                    autoclose={false}
                    message={this.state.error.message}
                    closeButtonText="CLOSE"
                    onClose={this.clearError}
                  />
                ) : (
                  <>
                    <div>
                      {this.state.processing ? (
                        <div style={{ position: 'relative', height: '295px' }}>
                          <CircularProgress sx={{ color: 'white' }} />
                          <div
                            style={{
                              position: 'absolute',
                              top: '25%',
                              textAlign: 'center',
                              width: '100%',
                              fontSize: 50,
                              fontFamily: appFonts.Paytone,
                              fontWeight: 'bold',
                            }}
                          >
                            Processing
                          </div>
                        </div>
                      ) : (
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Typography color="white" fontSize={30}>
                              Waiting for
                            </Typography>
                            <Typography variant="h3" color="white">
                              QR Scan
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Lottie
                              key={2}
                              loop
                              animationData={lottieJson}
                              play
                              style={{
                                width: 300,
                                height: 300,
                                margin: '0px auto',
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                this.setState({
                                  showBarcodeScannerInput:
                                    !this.state.showBarcodeScannerInput,
                                });
                              }}
                              onDoubleClick={() => {
                                this.clearBarcodeReaderInput();
                              }}
                            />
                            {/* <CardScanImage
                                  onClick={() => {
                                    this.setState({
                                      showBarcodeScannerInput:
                                        !this.state.showBarcodeScannerInput,
                                    });
                                  }}
                                  onDoubleClick={() => {
                                    this.clearBarcodeReaderInput();
                                  }}
                                /> */}
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            position="relative"
                            display="flex"
                            justifyContent="center"
                          >
                            <input
                              key="scanner_reader"
                              id="ScannerReader"
                              value={this.props.barcode}
                              style={inputStyle}
                              onChange={debounce(
                                (evt) => this.onChangeBarcode(evt.target.value),
                                200
                              )}
                              // !!! START: Keep this code until its fully decided the method to be used
                              // onChange={(evt) => {
                              //   const text = evt.target.value
                              //   let barcodeLengthTreshold = 12
                              //   if (text.indexOf('-') > -1) {
                              //     barcodeLengthTreshold = 14
                              //   }

                              //   if (text.length < barcodeLengthTreshold) {
                              //     return false
                              //   }
                              //   this.onChangeBarcode(text)
                              // }}
                              // !!! END: Keep this code until its fully decided the method to be used
                            />
                            <div
                              key="scanner_captured"
                              id="ScannerCaptured"
                            ></div>
                          </Grid>
                        </Grid>
                      )}
                      {/* {this.state.processing ? null : (
                      <div style={{ padding: 30 }}>
                        <Button
                          sx={{ textDecoration: 'underline', color: 'white' }}
                          variant="text"
                          onClick={this.startManualEntry}
                        >
                          Enter Manually
                        </Button>
                      </div>
                    )} 
                    <input
                      key="scanner_reader"
                      id="ScannerReader"
                      value={this.props.barcode}
                      style={inputStyle}
                      onChange={debounce(
                        (evt) => this.onChangeBarcode(evt.target.value),
                        200
                      )}
                      // !!! START: Keep this code until its fully decided the method to be used
                      // onChange={(evt) => {
                      //   const text = evt.target.value
                      //   let barcodeLengthTreshold = 12
                      //   if (text.indexOf('-') > -1) {
                      //     barcodeLengthTreshold = 14
                      //   }
  
                      //   if (text.length < barcodeLengthTreshold) {
                      //     return false
                      //   }
                      //   this.onChangeBarcode(text)
                      // }}
                      // !!! END: Keep this code until its fully decided the method to be used
                    />
                    <div key="scanner_captured" id="ScannerCaptured"></div>
                    */}
                    </div>
                  </>
                )
              }
              bottom={
                this.state.processing ? null : (
                  <Box
                    position={'relative'}
                    sx={{
                      opacity: this.state.showBarcodeScannerInput ? 0.0 : 1.0,
                    }}
                  >
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignContent="center"
                    >
                      <Stack direction="row" gap={2}>
                        <StyledIconButton
                          onClick={() => {
                            this.pageService.setOperatorPage(
                              this.pageService.OPERATOR_LOGIN_PAGE
                            );
                            this.pageService.setCustomerPage(
                              this.pageService.CUSTOMER_HOME_PAGE
                            );

                            this.beep();
                          }}
                        >
                          <LogoutRoundedIcon sx={{ color: 'white' }} />
                        </StyledIconButton>
                        <StyledIconButton onClick={this.onSupport}>
                          <SupportAgentRoundedIcon sx={{ color: 'white' }} />
                        </StyledIconButton>
                        <StyledIconButton onClick={this.onDailyTransactions}>
                          <SummarizeRoundedIcon sx={{ color: 'white' }} />
                        </StyledIconButton>
                        {/* <IconButton
                        sx={{ border: "white 1px solid" }}
                        onClick={this.onShowManualCardEntry}
                      >
                        <PersonSearchRoundedIcon sx={{ color: "white" }} />
                      </IconButton> */}
                      </Stack>
                    </Box>
                    {/* </Grid> */}
                    {/* <Grid item xs={6} display="flex" justifyContent="flex-start" alignContent="center">
                    <Divider orientation="vertical" sx={{ color:'white', mr:3}} />
                      <ToggleButtonGroup
                        sx={{ backgroundColor:'white'}}
                        value={this.state.operationMode}
                        exclusive
                        onChange={this.handleChange}
                        aria-label="Mode"
                        color="primary"
                      >
                          <ToggleButton value={OperationMode.local}>Local</ToggleButton>
                          <ToggleButton value={OperationMode.online}>Online</ToggleButton>
                      </ToggleButtonGroup>
                    </Grid> */}
                    {/* <Grid item xs> */}
                    {/* <IconButton sx={{ 
                          position: 'absolute', 
                          bottom: 55, 
                          right: 60, 
                          color:'white',
                          borderColor:'white !important',
                          borderRadius: '50%',
                          border:2,
                          padding: 0,
                          minWidth: 0,
                          width: '56px',
                          height: '56px',
                          zIndex: 1050,
                          boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12);' }}
                          onClick={(e)=>{
                            if(Boolean(this.state.anchorEl)){
                              this.setState({anchorEl:null})
                            }else{
                              this.setState({anchorEl:e.currentTarget})
                            }
                            
                          }}
                        >
                          {Boolean(this.state.anchorEl)?<CloseRoundedIcon />:<PermIdentityIcon/>}
                      </IconButton> */}
                    {/* <SpeedDial
                        ariaLabel="Guest Mode Options" 
                        sx={{ position: 'absolute', bottom: 50, right: 60, color:'white' }}
                        icon={<SpeedDialIcon color="white" />}>
                          <SpeedDialAction 
                                key={'eventwallet'}
                                icon={<SvgIcon sx={{
                                                    fontSize:20, 
                                                    filter: 'drop-shadow(0px 2px 2px rgb(0 0 0 / 0.15))', 
                                                    border: 'none', 
                                                    borderRadius: 25,
                                                    backgroundColor:'white',
                                                    width:50,
                                                    height:50,
                                                  }} 
                                      component={AccountBalanceWalletRoundedIcon} inheritViewBox />}
                                tooltipOpen
                                tooltipTitle={"Event Wallet"}
                                sx={{my:2}}
                                onClick={()=>{this.onOpenEventWallet()}}
                              />
                         {
                            guestUsers.map((user)=>(
                              <SpeedDialAction 
                                key={user.id}
                                icon={avatarIcon(user.avatar,50)}
                                tooltipOpen
                                tooltipTitle={user.display_name}
                                sx={{my:2}}
                                onClick={()=>{this.onLoginGuest(user)}}
                              />
                            ))
                          }
                        </SpeedDial> */}
                    {/* {
                          this.state.anchorEl?
                            <Box sx={{position:'absolute', bottom:120, left:60, flexDirection:'column-reverse'}}>
                              {
                              guestUsers.map((user)=>(
                                <Box key={user.id} onClick={()=>{this.onLoginGuest(user)}} sx={{borderColor:'white', border:1, borderRadius:20, my:2, cursor:'pointer'}} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                  <Box display={'flex'} px={1}><Typography color={'white'} textAlign={'right'}>{`${user.display_name} ${user.display_name}`}</Typography></Box>
                                  <Box justifyContent={'flex-end'} mt={0.2} mr={0.2}>{avatarIcon(user.avatar,52)}</Box>
                                </Box>
                              ))
                            }
                          </Box>
                          :null
                        } */}

                    {/* </Grid>
                  </Grid> */}
                    <SchoolFoodLogo />
                    {guestUsers?.length > 0 ? (
                      <StyledIconButton
                        sx={{
                          position: 'absolute',
                          bottom: 62,
                          right: 60,
                          zIndex: 1050,
                          border: 2,
                        }}
                        onClick={(e) => {
                          if (Boolean(this.state.anchorEl)) {
                            this.setState({ anchorEl: null });
                          } else {
                            this.setState({ anchorEl: e.currentTarget });
                          }
                        }}
                      >
                        {Boolean(this.state.anchorEl) ? (
                          <CloseRoundedIcon />
                        ) : (
                          <PermIdentityIcon />
                        )}
                      </StyledIconButton>
                    ) : null}
                    <Box
                      sx={{
                        position: 'absolute',
                        bottom: 120,
                        right: 60,
                        flexDirection: 'column-reverse',
                      }}
                    >
                      <Box
                        display="flex"
                        gap={2}
                        p={0}
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="flex-end"
                      >
                        {guestUsers?.map((user, index) => (
                          <GuestOption
                            key={user.id}
                            title={user.display_name}
                            iconName={user.avatar}
                            onAction={() => {
                              if (this.state.anchorEl) {
                                this.onLoginGuest(user);
                              }
                            }}
                            style={{
                              opacity: this.state.anchorEl ? 1.0 : 0.0,
                              transitionProperty: 'opacity',
                              transitionDuration: `${
                                0.25 + (index + 1) * 0.2
                              }s`,
                            }}
                          />
                        ))}
                        {/* <GuestOption key='wallet' title="Event Wallet" iconName={Icons.wallet} onAction={()=>{ this.onOpenEventWallet() }}/> */}
                      </Box>
                    </Box>
                  </Box>
                )
              }
            ></Layout>
          </div>
          <Box
            sx={{
              display: this.state.showKiosk ? 'block' : 'none',
              position: 'absolute',
              top: 0,
              left: 0,
              zIndex: 1099,
              width: window.innerWidth,
              height: window.innerHeight,
            }}
          >
            <KioskPage
              cart={this.state.cart}
              customer={this.state.customer}
              onClose={() => {
                PubSub.publish('onKiosk', false);
                this.setState({ showKiosk: false, customer: null, cart: null });
                this.addBarcodeReaderFocuser();
              }}
            />
          </Box>
        </>
      );
    } catch (error) {
      console.error('Error in rendering card scan page', error);
    }
  }
}

export default CardScanPage;
