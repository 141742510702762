import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import React from 'react'
import PubSub from 'pubsub-js'
import './DialogPayment.css'
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace'
import { StyledDialogTitle, StyledDialogTitleNormal } from '../../components/Dialog/StyledDialogTitle'
import { StyledDialogText } from '../../components/Dialog/DialogText'
import { StyledDialogCancelButton } from '../../components/Dialog/DialogButton'
// import number_format from 'locutus/php/strings/number_format'

type DialogPromoExhaustedState = {
  // [x:string]:any,
  showDialog?: boolean
}

interface DialogPromoExhaustedProps {
  // cart?: Cart
  // showDialog: boolean
}

class DialogPromoExhausted extends React.Component<
  DialogPromoExhaustedProps,
  DialogPromoExhaustedState
> {
  constructor(props) {
    super(props)
    this.state = {
      showDialog: false,
    }
  }

  componentDidMount(): void {
    PubSub.subscribe('dialog-promo-exhausted', (msg: string, data: any) => {
      this.show()
    })
  }

  componentWillUnmount(): void {
    PubSub.unsubscribe('dialog-promo-exhausted')
  }

  /**
   * Shows the dialog
   */
  show(): void {
    this.setState({
      showDialog: true,
    })
  }

  /**
   * Closes the dialog
   */
  closeDialog(): void {
    this.setState({
      showDialog: false,
    })
  }

  /**
   * Renders the dialog
   * @returns
   */
  render(): ReactJSXElement {
    return (
      <>
        <Dialog
          id="DialogPayment"
          open={this.state.showDialog}
          keepMounted
          onClose={()=>this.closeDialog}
        >
          <StyledDialogTitleNormal>PROMO EXHAUSTED</StyledDialogTitleNormal>
          <DialogContent>
            <DialogContentText className="DialogContentText">
              <StyledDialogText>
                Maximum available Promo for the item already applied
              </StyledDialogText>
            </DialogContentText>
          </DialogContent>
          <DialogActions className="DialogActions">
            <StyledDialogCancelButton
              onClick={() => this.closeDialog()}
            >
              OK
            </StyledDialogCancelButton>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

export default DialogPromoExhausted
