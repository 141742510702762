import SharedStateService from './SharedStateService'

class EnvService {
  static init() {
    const sharedStateService = new SharedStateService()

    const hostname = window.location.hostname
    //const devEndpoint = "https://api.dev.lunchclub.ie"; // default
    const devEndpoint = 'https://api.staging.lunchclub.cloud' // 'https://api.lunchclub.cloud';// "http://localhost:3001";//  // staging is the new endpoint even for development
    const liveEndpoint = 'https://api.lunchclub.cloud'
    const stagingEndpoint = 'https://api.staging.lunchclub.cloud'

    let endpoint = liveEndpoint // default
    let environment = 'live' // default

    if (this.isLocal()) {
      endpoint = devEndpoint
      environment = 'development'
    }

    if (this.isDev()) {
      endpoint = devEndpoint
      environment = 'development'
    }

    if (this.isStaging()) {
      endpoint = stagingEndpoint
      environment = 'staging'
    }

    if (this.isLive()) {
      endpoint = liveEndpoint
      environment = 'live'
    }

    const eposId = sharedStateService.get('epos_id')

    // TILL_ID: 'testtill1_0000000000000000000001',

    return {
      hostname,
      endpoint,
      environment,
      eposId,
    }
  }

  static isDev(): boolean {
    return this.isLocal()
  }

  /**
   * Returns whether the application is online
   * @returns
   */
  static isOnline(): boolean {
    return navigator.onLine
  }

  /**
   * Returns whether the application is offline
   * @returns
   */
  static isOffline(): boolean {
    return this.isOnline() === false
  }

  static isLocal(): boolean {
    // return window.location.hostname === 'localhost' || window.location.hostname.includes('192.168.0.') || window.location.hostname.includes('127.0.0.');
    return false
  }

  static isLive(): boolean {
    // return window.location.hostname === 'epos.lunchclub.cloud'
    return true
  }

  static isStaging(): boolean {
    // return window.location.hostname === 'epos.staging.lunchclub.cloud'
    return false
  }

  static apiEndpoint(): string {
    const result = this.init()
    return result.endpoint
  }

  static currentEnvironment(): string {
    const result = this.init()
    return result.environment
  }

  static currentEposId(): string {
    const result = this.init()
    return result.eposId
  }
}

export default EnvService
