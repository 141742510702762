import React from 'react'
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace'
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded'
// import TableChartRoundedIcon from '@mui/icons-material/TableChartRounded'
// import MoneyOutlinedIcon from '@mui/icons-material/MoneyOutlined'
import SwapHorizRoundedIcon from '@mui/icons-material/SwapHorizRounded'
// import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import { Divider } from '@mui/material'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import CancelRounded from '@mui/icons-material/CancelRounded'
import SummarizeRoundedIcon from '@mui/icons-material/SummarizeRounded'

interface PanelStaffMenuProps {
  onDailySummary?: Function
  onDailyTransaction?: Function
  onHide?: Function
  onLogout?: Function
  onSupport?: Function
  onSwitchStudent?: Function
}

type PanelStaffMenuState = {}

class PanelStaffMenu extends React.Component<
  PanelStaffMenuProps,
  PanelStaffMenuState
> {
  componentDidMount(): void {}

  componentWillUnmount(): void {}

  render(): ReactJSXElement {
    return (
      <div id="PanelStaffMenu" style={{ zIndex:9999 }}>
        <div className="title">
          Staff Menu
          <span
            onClick={() => {
              this.props.onHide()
            }}
            style={{ float: 'right', cursor: 'pointer' }}
          >
            <CloseRoundedIcon />
          </span>
        </div>
        <div className="body">
          <table style={{ width: '100%' }}>
            <tbody>
              {/* <tr>
              <td className="icon">
                <IconEvents />
              </td>
              <td className="label">Events</td>
            </tr> */}
              {/* <tr>
              <td className="icon">
                <IconSettings />
              </td>
              <td className="label">Settings</td>
            </tr> */}
              <tr
                onClick={() => {
                  this.props.onSupport()
                }}
              >
                <td className="iconColor">
                  <SupportAgentRoundedIcon
                    sx={{
                      color:
                        'linear-gradient(to bottom, rgba(255, 71, 71, 1) 0%, rgba(208, 0, 0, 1) 100%)',
                    }}
                  />
                </td>
                <td className="label">Support</td>
              </tr>

              <tr
                onClick={() => {
                  this.props.onDailyTransaction()
                }}
              >
                <td className="iconColor">
                  <SummarizeRoundedIcon
                    sx={{
                      color:
                        'linear-gradient(to bottom, rgba(255, 71, 71, 1) 0%, rgba(208, 0, 0, 1) 100%)',
                    }}
                  />
                </td>
                <td className="label">Transactions</td>
              </tr>
              {/* <tr
                onClick={() => {
                  this.props.onDailyTransaction()
                }}
              >
                <td className="iconColor">
                  <MoneyOutlinedIcon
                    sx={{
                      color:
                        'linear-gradient(to bottom, rgba(255, 71, 71, 1) 0%, rgba(208, 0, 0, 1) 100%)',
                    }}
                  />
                </td>
                <td className="label">Daily Summary</td>
              </tr> */}

              <tr>
                <td style={{ width: '100%', paddingBottom: '5px' }} colSpan={2}>
                  <Divider />
                </td>
              </tr>

              <tr
                onClick={() => {
                  this.props.onSwitchStudent()
                }}
              >
                <td className="icon">
                  <CancelRounded sx={{ color: 'rgba(0,0,0,0.54)' }} />
                </td>
                <td className="label">Cancel Transaction</td>
              </tr>

              <tr
                onClick={() => {
                  this.props.onLogout()
                }}
              >
                <td className="icon">
                  <SwapHorizRoundedIcon sx={{ color: 'rgba(0,0,0,0.54)' }} />
                </td>
                <td className="label">Switch Operator</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

export default PanelStaffMenu
