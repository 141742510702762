import PubSub from "pubsub-js";
// import EnvService from './EnvService'
import SharedStateService from "./SharedStateService";

class PageService {
  CUSTOMER_CARD_SCAN_PAGE: string = "card-scan-page";
  CUSTOMER_INIT_PAGE: string = "init-page";
  CUSTOMER_LOCKED_PAGE: string = "locked-page";
  CUSTOMER_MANUAL_ENTRY_PAGE: string = "manual-entry-page";
  CUSTOMER_TILL_PAGE: string = "till-page";
  CUSTOMER_SCREENSAVER_PAGE: string = "screensaver-page";

  OPERATOR_CARD_SCAN_PAGE: string = "card-scan-page";
  OPERATOR_INIT_PAGE: string = "init-page";
  OPERATOR_LOGIN_PAGE: string = "login-page";
  OPERATOR_KIOSK_PAGE: string = "kiosk-page";
  OPERATOR_MANUAL_ENTRY_PAGE: string = "manual-entry-page";
  OPERATOR_SCREENSAVER_PAGE: string = "screensaver-page";

  OPERATOR_HOME_PAGE = this.OPERATOR_LOGIN_PAGE;
  CUSTOMER_HOME_PAGE = this.CUSTOMER_LOCKED_PAGE;

  private sharedStateService: SharedStateService;
  // private eposId: string;

  constructor() {
    this.sharedStateService = new SharedStateService();

    // this.eposId = EnvService.currentEposId()
  }

  /**
   * The purpose of this method is to keep the customer side in sync
   * with the operator side.
   *
   * This is in case the pub-sub gets lost, i.e. unexpected exception
   *
   * Syncs every 10s
   */
  public startSyncing() {
    setInterval(() => {
      const operatorPage = this.getOperatorPage();
      if (operatorPage === this.OPERATOR_INIT_PAGE) {
        this.setCustomerPage(this.CUSTOMER_INIT_PAGE);
      }
      if (operatorPage === this.OPERATOR_LOGIN_PAGE) {
        this.setCustomerPage(this.CUSTOMER_LOCKED_PAGE);
      }
      if (operatorPage === this.OPERATOR_CARD_SCAN_PAGE) {
        this.setCustomerPage(this.CUSTOMER_CARD_SCAN_PAGE);
      }
      if (operatorPage === this.OPERATOR_KIOSK_PAGE) {
        this.setCustomerPage(this.CUSTOMER_TILL_PAGE);
      }
    }, 10 * 1000);
  }

  public setCustomerPage(page: string): void {
    this.sharedStateService.setCustomerPage(page);
    PubSub.publish("customer_page_updated", page);
  }

  // Not needed for Operator side
  // getCustomerPage():string {
  //   const page = this.sharedStateService.get('customer_page')
  //   if (this.eposId === null) {
  //     return this.CUSTOMER_INIT_PAGE
  //   }
  //   if (page == null) {
  //     return this.CUSTOMER_HOME_PAGE
  //   }
  //   return page
  // }

  public setOperatorPage(page: string): void {
    // DEBUG: console.log(page)
    this.sharedStateService.setOperatorPage(page);
    PubSub.publish("operator_page_updated");
  }

  public isScreensaverPage() {
    const page = this.getOperatorPageFromState();
    return page === this.OPERATOR_SCREENSAVER_PAGE;
  }

  private getOperatorPageFromState() {
    return this.sharedStateService.getOperatorPage();
  }

  public getCustomerPage(): string {
    const page = this.sharedStateService.getCustomerPage();
    return page;
  }

  public getOperatorPage(): string {
    //return window.appData.operatorPage
    const page = this.getOperatorPageFromState();
    // console.log("GET OPERATOR PAGE:", page);

    if (!this.isScreensaverPage() && !this.sharedStateService.hasAuthToken()) {
      return this.OPERATOR_INIT_PAGE;
    }

    if (page == null) {
      return this.OPERATOR_HOME_PAGE;
    }

    return page;
  }
}

export default PageService;
