import { determineCurrentSitting, getTime } from "../utils/utils";
import SharedStateService from "./SharedStateService";

class SittingManager{
    static _theinstance = null;

    static sharedManager() {
        if (SittingManager._theinstance == null) {
            SittingManager._theinstance = new SittingManager();
        }

        return this._theinstance;
    }
    
    private sharedStateService:SharedStateService;
    private sittingInterval: NodeJS.Timer;
    private currentTime:string
    private sittingId:string
    private terminated:boolean
    private normalInterval: NodeJS.Timer;
    public autoSittingSelectionCallback:(sittingId:string)=>void

    constructor() {
        this.sharedStateService = new SharedStateService();
        this.sittingId = ""
        this.autoSittingSelectionCallback = null
    }

    initService(){
        this.normalInterval = setInterval(() => {
            const time = getTime();
            this.currentTime = time
        }, 1000);
    }

    public changeSitting(sittingId){
        this.sittingId = sittingId
        this.sharedStateService.setCurrentSittingId(sittingId);
        if(this.sittingInterval){
            clearInterval(this.sittingInterval);
            clearInterval(this.normalInterval);
            this.terminated = true
        }
    }

    public setCallback(callBack:any){
        this.autoSittingSelectionCallback = callBack
    }

    public initSittingTimer(){
        console.log("Trying to initialize Sitting auto selection timer");
        
        if(!this.sittingInterval){
            const newSittingId = determineCurrentSitting("");
            this.sharedStateService.setCurrentSittingId(newSittingId);
            
            //Auto sitting selection based on current time
            this.sittingInterval = setInterval(() => {
                const sittingId  = this.sittingId;
                const currentSittingId = sittingId ? sittingId : "";
                const newSittingId = determineCurrentSitting(sittingId);

                if (currentSittingId !== newSittingId) {
                    this.sittingId = newSittingId
                    // PubSub.publish("selected_sittingId", {sittingId:newSittingId,manual:false});
                    if(this.autoSittingSelectionCallback){
                        this.autoSittingSelectionCallback(newSittingId);
                    }
                    this.sharedStateService.setCurrentSittingId(newSittingId);
                }
            }, 5000);
        }else{
            console.log('Timers already started');
            
        }
    }

    
}

export default SittingManager;