class SessionManager{
    static _theinstance = null;

    private operatorSessionMaxAge: number = 6 * 60 * 60 * 1000; // 6 hrs in seconds //6 * 60 * 
    private operatorIntervalTimer: NodeJS.Timer = null;

    private managerSessionMaxAge: number = 4 * 60 * 60 * 1000; // 4 hrs in seconds
    private managerIntervalTimer: NodeJS.Timer = null;

    static sharedManager() {
        if (SessionManager._theinstance == null) {
            SessionManager._theinstance = new SessionManager();
        }

        return this._theinstance;
    }

    /**
     * Method to stop the operator screen session 
     */

    stopOperatorSession(){
        if(this.operatorIntervalTimer){
            clearInterval(this.operatorIntervalTimer);
        }
    }


    /**
     * Method to start the operator screen session 
     */
    startOperatorSession(){
        // this.stopManagerSession();
        this.stopOperatorSession();

        this.operatorIntervalTimer = setInterval(()=>{
            console.log("OPERATOR SESSION EXPIRED");
            
            PubSub.publish("operator_session_expired");
            this.stopOperatorSession();
        }, this.operatorSessionMaxAge)
    }


    /**
     * Method to stop the manager screen session 
     */

    // stopManagerSession(){
    //     if(this.managerIntervalTimer){
    //         clearInterval(this.managerIntervalTimer);
    //     }
    // }


    /**
     * Method to start the manager screen session 
     */

    // startManagerSession(){
    //     this.stopManagerSession();
    //     this.stopOperatorSession();

    //     this.managerIntervalTimer = setInterval(()=>{
    //         PubSub.publish("manager_session_expired");
    //         this.stopManagerSession();
    //     }, this.managerSessionMaxAge)
    // }
    
}

export default SessionManager;