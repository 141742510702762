import PubSub from "pubsub-js";
import SharedStateService from "./SharedStateService";

let token;
/**
 * The customer session expires 5 minutes after the customer
 * has logged in and the transaction has started.
 * Which is why it starts on the Kiosk page.
 * The session is kept in memory, as it should not persist between pages.
 * Starts when transaction starts and times out after 5 minutes.
 */
class CustomerSessionService {
  private sharedStateService: SharedStateService;
  private sessionStartedAt: Date;
  private sessionMaxAge: number = 5 * 60; // 5 mins in seconds
  private warningBeforeExpireSeconds: number = 12; // to allow 10 minutes for user to take action
  private interval;
  private isWarningTriggered = false;

  constructor() {

    if(token)PubSub.unsubscribe(token);

    token = PubSub.subscribe("customer-session-extend", () => {
      console.log("SESSION EXTENDED");
      this.sessionStartedAt = new Date();
      this.isWarningTriggered = false;
    });
  }

  /**
   * Checks the manager session every 10 seconds
   * and notifies if expired
   */
  private startMonitor(): void {
    this.interval = setInterval(() => this.checkIfExpiredAndNotify(), 2 * 1000); // every 10s
  }

  public start() {
    this.sessionStartedAt = new Date();
    // console.log("CUSTOMER SESSION STARTED at ", this.sessionStartedAt);
    this.startMonitor();
  }

  public stop() {
    // console.log("CUSTOMER SESSION STOPPED at ", new Date());
    this.sessionStartedAt = null;
    this.isWarningTriggered = false;
    clearInterval(this.interval);
  }

  private checkIfExpiredAndNotify() {
    // console.log("CUSTOMER SESSION STARTED AT", this.sessionStartedAt);
    if (this.sessionStartedAt == null) {
      return true; // session has not started
    }
    // const expiresAt = new Date(
    //   this.sessionStartedAt.getTime() + this.sessionMaxAge * 1000
    // );
    // console.log("CUSTOMER SESSION EXPIRES IN", expiresAt);

    if (this.isExpireWarning()) {
      PubSub.publish("customer_session_expire_warning");
      console.log("EXPIRE WARNING TRIGGERED");
      return true;
    }

    if (this.isExpired() === false) {
      return true; // session is valid
    }

    PubSub.publish("customer_session_expired");
  }

  /**
   * Duration -30s
   * @returns
   */
  private isExpireWarning() {
    if (this.isWarningTriggered) {
      return false; // already triggered
    }

    const duration = this.getSessionDurationInSeconds();
    if (duration == null || duration === -1) {
      return false; // session has not started
    }
    // console.log(
    //   "Session expires at:",
    //   duration,
    //   "Warning in",
    //   duration + this.warningBeforeExpireSeconds
    // );
    if (duration < this.sessionMaxAge - this.warningBeforeExpireSeconds) {
      return false; // session is valid
    }

    this.isWarningTriggered = true;
    return true;
  }

  /**
   * Returns whether the session has expired
   *
   * Business logic:
   * 1. If the session has not started, it is considered expired
   * 2. If its has passed the max duration, it is consider expired
   */
  private isExpired(): boolean {
    const duration = this.getSessionDurationInSeconds();

    // console.log("CUSTOMER SESSION DURATION", duration, "seconds");

    if (duration == null || duration === -1) {
      return false; // session has not started
    }

    if (duration < this.sessionMaxAge) {
      return false; // session is valid
    }

    return true;
  }

  /**
   * Returns the duration of the session in seconds
   */
  private getSessionDurationInSeconds(): number {
    if (this.sessionStartedAt == null) {
      return -1;
    }

    const duration = new Date().getTime() - this.sessionStartedAt.getTime();
    return duration / 1000;
  }
}

export default CustomerSessionService;
