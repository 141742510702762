import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import React from 'react'
import PubSub from 'pubsub-js'
import './DialogPayment.css'
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace'
// import number_format from 'locutus/php/strings/number_format'
import SharedStateService from '../../services/SharedStateService'
import { number_format } from 'locutus/php/strings'
import { StyledDialogTitle, StyledDialogTitleNormal } from '../../components/Dialog/StyledDialogTitle'
import { StyledDialogHighlightText, StyledDialogText } from '../../components/Dialog/DialogText'
import { StyledDialogCancelButton, StyledDialogOKButton } from '../../components/Dialog/DialogButton'

type DialogInsufficientSubsidyState = {
  // [x:string]:any,
  showDialog?: boolean
  org: ApiOrgs
  selectedMenuItem?: ApiMenu
}

interface DialogInsufficientSubsidyProps {
  cart?: Cart
  showDialog: boolean
  onAddSubsidyToPurse: (selectedMenuItem:ApiMenu)=>void
}

class DialogInsufficientSubsidy extends React.Component<
  DialogInsufficientSubsidyProps,
  DialogInsufficientSubsidyState
> {
  private sharedStateService: SharedStateService

  constructor(props) {
    super(props)
    this.sharedStateService = new SharedStateService()
    this.state = {
      showDialog: false,
      org: this.sharedStateService.getOrg(),
      selectedMenuItem: null,
    }
  }

  componentDidMount(): void {
    PubSub.subscribe(
      'dialog-insufficient-subsidy-show',
      (msg: string, data: any) => {
        this.setState({selectedMenuItem:data.selectedMenuItem})
        this.show()
      },
    )
    PubSub.subscribe(
      'dialog-insufficient-subsidy-close',
      (msg: string, className: string) => {
        this.closeDialog()
      },
    )
  }
  
  componentWillUnmount(): void {

    PubSub.unsubscribe(
      'dialog-insufficient-subsidy-show'
    )
    PubSub.unsubscribe(
      'dialog-insufficient-subsidy-close'
    )

  }
  /**
   * Shows the dialog
   */
  show(): void {
    this.setState({
      showDialog: true,
    })
  }

  /**
   * Closes the dialog
   */
  closeDialog(): void {
    this.setState({
      showDialog: false,
    })
  }

  /**
   * Publishes event that payment is clicked
   * and closes the dialog
   */
  payNow(): void {
    this.closeDialog()
  }


  /**
   * Renders the dialog
   * @returns
   */
  render(): ReactJSXElement {
    const { org, selectedMenuItem } = this.state
    const supportedCurrencies = org.supportedCurrencies[0]
    return (
      <>
        <Dialog
          id="DialogPayment"
          open={this.state.showDialog}
          keepMounted
          onClose={() => this.closeDialog()}
        >
          <StyledDialogTitleNormal>INSUFFICIENT SUBSIDY</StyledDialogTitleNormal>
          <DialogContent>
            {
              selectedMenuItem && 
              <DialogContentText className="DialogContentText">
                <Box>
                  <StyledDialogText sx={{display:'inline'}}>Would you like to take</StyledDialogText> <StyledDialogHighlightText sx={{display:'inline'}}>{supportedCurrencies.symbol}{number_format(selectedMenuItem.subsidyValue, 2)}</StyledDialogHighlightText> <StyledDialogText sx={{display:'inline'}}>from your purse?</StyledDialogText>
                </Box>
              </DialogContentText>
            }
          </DialogContent>
          <DialogActions className="DialogActions">
            <StyledDialogCancelButton
              onClick={() => this.closeDialog()}
            >
              Close
            </StyledDialogCancelButton>
            {<StyledDialogOKButton
              onClick={() => {
                this.props.onAddSubsidyToPurse(selectedMenuItem)
                this.closeDialog()
              }}
            >
              Confirm
            </StyledDialogOKButton>}
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

export default DialogInsufficientSubsidy
