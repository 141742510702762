import { ErrorResponse, loadStripeTerminal, Reader, Terminal } from '@stripe/terminal-js';

function isErrorResponse(obj: any): obj is ErrorResponse {
  return typeof obj.error === 'string'
}

let connected = false, terminal = null, reader: Reader = null;

export async function connectReader(terminal: Terminal, eposId: string, useSimulated: boolean) {
  // useSimulated=true;
  console.log('connectReader: existing reader:', reader);

  if (!reader || reader.status !== 'online' || terminal.getConnectionStatus() !== 'connected') {
    reader = await getReader(eposId, terminal, useSimulated);
    const connectResult = await terminal.connectReader(reader);
    console.log('connectResult:', connectResult);
    if (isErrorResponse(connectResult)) {
      throw new Error(connectResult.error.message);
    } else {
      if (connectResult) {
        console.log('Connected to reader: ', connectResult);
        connected = true;
        console.log('connected:', connected);
      }
    }
  }
  //console.log('reader:', reader);
  console.log('connected:', connected);

  return reader;
}

export async function getReader(eposId: string, terminal: Terminal, useSimulated: boolean) {
  //  useSimulated=false;
  console.log('GETTING READER: existing reader:', reader)
  // if (reader) return reader;
  const config = { simulated: useSimulated };


  const discoverResult = await terminal.discoverReaders(config);

  console.log('discovered readers:', discoverResult);

  if (isErrorResponse(discoverResult)) {
    throw new Error(discoverResult.error.message);
  }
  if (discoverResult.discoveredReaders.length === 0) {
    throw new Error('No available readers.');
  }
  console.log('finding reader with eposId:', eposId);

  reader = config.simulated ? discoverResult.discoveredReaders[0] : discoverResult.discoveredReaders.find(r => r.label === eposId);
  console.log('found reader with eposId:', reader);
  if (!reader) {
    throw new Error('no reader!')
  }

  return reader;
}

export async function getTerminal(onFetchConnectionToken, onUnexpectedReaderDisconnect): Promise<Terminal> {
  if (terminal && terminal.getConnectionStatus() === 'connected') return terminal;
  const stripeTerminal = await loadStripeTerminal();
  terminal = stripeTerminal.create({
    onFetchConnectionToken,
    onUnexpectedReaderDisconnect: () => {
      console.log('onUnexpectedReaderDisconnect!')
      connected = false;
      onUnexpectedReaderDisconnect();
    },
  });
  return terminal;

}

/* export async function collectPaymentMethod(terminal, clientSecret) {
  // clientSecret is the client_secret from the PaymentIntent you created in Step 1.
  const result = await terminal.collectPaymentMethod(clientSecret);
  if (result.error) {
    // Placeholder for handling result.error
  } else {
    // Placeholder for processing result.paymentIntent
  }
}

export async function processPayment(terminal, paymentIntent) {
  const result = await terminal.processPayment(paymentIntent);
  if (result.error) {
    // Placeholder for handling result.error
  } else if (result.paymentIntent) {
    // Placeholder for notifying your backend to capture result.paymentIntent.id
  }
} */