import React from "react";
import "./ManualEntryPage.css";
import LunchClubLogo from "../../components/LunchClubLogo/LunchClubLogo";
import SchoolFoodLogo from "../../components/SchoolFoodLogo/SchoolFoodLogo";
import Layout from "../../components/Layout/Layout";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
// import Alert from '@mui/material/Alert'
import { Autocomplete, Box, createFilterOptions, ListItemText, MenuItem } from "@mui/material";
import ApiService from "../../services/ApiService";
import PageService from "../../services/PageService";
import SharedStateService, { keyUpdateCartToCustomer } from "../../services/SharedStateService";
import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import ErrorAlert from "../../components/Custom/ErrorAlert";
import AudioManager from "../../services/AudioManager";
import EnvService from "../../services/EnvService";
import KioskPage from "../KioskPage/KioskPage";
import { OperationMode } from "../../utils/utils";

interface ManualEntryPageState {
  [x: number]: any;
  cardNumber: string;
  entryType: string;
  studentId: string;
  studentList: Customer[];
  student: any;
  error: {
    status: boolean;
    message: string;
    data?: any;
  };
  isLoading: boolean;
  success: boolean;
  showKiosk: boolean
  cart: Cart
  customer: Customer
}
const filterOptions = createFilterOptions({
  matchFrom: 'any',
  stringify: (option:Customer) => option.display_name + option.card_number,
});

class ManualEntryPage extends React.Component<{}, ManualEntryPageState> {
  TYPE_CARD_INPUT: string;
  TYPE_LIST_DROPDOWN: string;
  pageService: any;
  apiService: ApiService;
  sharedStateService: any;

  constructor(props) {
    super(props);

    this.TYPE_CARD_INPUT = "card_input";
    this.TYPE_LIST_DROPDOWN = "list_dropdown";

    this.sharedStateService = new SharedStateService();

    const schoolUsers:Customer[] = this.sharedStateService.getSchoolUsers();

    const students = schoolUsers.filter((user)=>user.type ==='student');
    const staffs = schoolUsers.filter((user)=>user.type ==='staff');

    const studentList = [...students, ...staffs];

    this.state = {
      cardNumber: "", // dynamically pupulated by the input
      studentList,
      student: null,
      studentId: "", // dynamically pupulated by the autocomplete
      isLoading: false,
      error: {
        status: false,
        message: "",
      },
      success: false,
      entryType: this.TYPE_LIST_DROPDOWN,
      showKiosk: false,
      cart: null,
      customer: null,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleDropdownChange = this.handleDropdownChange.bind(this);
    this.onSubmitButtonClicked = this.onSubmitButtonClicked.bind(this);
    this.pageService = new PageService();
    this.apiService = new ApiService();
    this.sharedStateService.set('error', null)
  }

  componentDidMount() {
    // DEBUG: console.log('Staff. ManualEntryPage did mount')
    this.populateStudentList();
  }

  componentWillUnmount() {
    // DEBUG: console.log('Staff. ManualEntryPage did unmount')
  }

  cancelManualEntry() {
    this.beep();
    this.pageService.setOperatorPage(this.pageService.OPERATOR_CARD_SCAN_PAGE);
    this.pageService.setCustomerPage(this.pageService.CUSTOMER_LOCKED_PAGE);
  }

  beep() {
    AudioManager.sharedManager().beep();
    // const sound: HTMLVideoElement = document.getElementById(
    //   "audiobeep"
    // ) as HTMLVideoElement;
    // if (sound) {
    //   sound.play();
    // }
  }

  broadcastDefaultCart(customer) {
    // DEBUG: console.log("BROADCAST DEFAULT CART");

    const org = this.sharedStateService.getOrg();
    const supportedCurrency = org.supportedCurrencies[0];

    const cart: Cart = {
      menuId: null,
      currencySymbol: supportedCurrency.symbol,
      items: [],
      purseBefore: customer.available_balance,
      purseAfter: customer.available_balance,
      subsidyBefore: customer.subsidy,
      subsidyAfter: 0.0,
      totalPurse: customer.available_balance,
      totalSubsidy: customer.subsidy,
      total: 0.0,
    };

    this.sharedStateService.setCart(cart);
    PubSub.publishSync("cart_updated", keyUpdateCartToCustomer);

    this.setState({cart, customer});
  }

  async onSubmitButtonClicked() {
    this.beep();
    //console.log("Form submitted")
    const studentId = this.state.studentId;
    
    const entryType = this.state.entryType;

    if (studentId == null && entryType === this.TYPE_LIST_DROPDOWN) {
      return this.setState({
        error: {
          status: true,
          message: "Please select a student",
        },
      });
    }
    let customer = this.state.studentList.filter((student)=>student.id === studentId)[0];
    this.sharedStateService.setCustomer(customer);
    
    // this.pageService.setOperatorPage(this.pageService.OPERATOR_KIOSK_PAGE);
    // this.pageService.setCustomerPage(this.pageService.CUSTOMER_TILL_PAGE);

    if (EnvService.isOffline() || this.sharedStateService.getOperationMode() === OperationMode.local) {
      const student: Customer = customer;
      this.broadcastDefaultCart(student);
      this.setState({showKiosk:true})
      // this.pageService.setOperatorPage(this.pageService.OPERATOR_KIOSK_PAGE);
    }else{

      let response = await this.apiService.findCustomerByCard(customer.card_number);

      if (response.status === ApiService.RESPONSE_UNAUTHENTICATED) {
        this.sharedStateService.deleteCustomer();
        this.pageService.setOperatorPage(this.pageService.OPERATOR_INIT_PAGE);
        this.pageService.setCustomerPage(this.pageService.CUSTOMER_INIT_PAGE);
        return false;
      }

      customer = response.customer;
      const student: Customer = customer;
      this.sharedStateService.setCustomer(student);
      this.broadcastDefaultCart(student);

      //Update the local storage
      let allUsers = this.sharedStateService.getSchoolUsers();

      const userIndex = allUsers.findIndex((user)=>user.id === customer.id);
      allUsers[userIndex]=customer

      this.sharedStateService.setSchoolUsers(allUsers);

      this.setState({showKiosk:true})

      // this.pageService.setOperatorPage(this.pageService.OPERATOR_KIOSK_PAGE);
    }

    
    
    // let response = await this.apiService.findCustomerByCard(cardNumber);

    /*if (cardNumber === "" && entryType === this.TYPE_CARD_INPUT) {
      return this.setState({
        error: {
          status: true,
          message: "Please enter student card number",
        },
      });
    }*/

    

    // if (entryType === this.TYPE_LIST_DROPDOWN) {
    //   response = await this.apiService.findStudentById(studentId)
    // }

    // if (entryType === this.TYPE_CARD_INPUT) {
    //   response = await this.apiService.findCustomerByCard(cardNumber);
    // }

    // console.log(response);

    /*if (response.status === ApiService.RESPONSE_UNAUTHENTICATED) {
      this.sharedStateService.deleteCustomer();
      this.pageService.setOperatorPage(this.pageService.OPERATOR_INIT_PAGE);
      this.pageService.setCustomerPage(this.pageService.CUSTOMER_INIT_PAGE);
      return false;
    }

    if (response.status !== ApiService.RESPONSE_SUCCESS) {
      this.pageService.setOperatorPage(this.pageService.OPERATOR_CARD_SCAN_PAGE);
      this.pageService.setCustomerPage(this.pageService.CUSTOMER_CARD_SCAN_PAGE);
      const e = {
        status: true,
        message:  "Couldn't validate card \"" + cardNumber + '" with the School',
      };
      this.sharedStateService.set('error', e);
      return false;
    }*/

    
    

    /*const school = this.sharedStateService.getSchool();

    if (school.id === student.school_id) {
      //If scanned student card matches with logged in school let in else throw error
      this.sharedStateService.setCustomer(student);
      this.broadcastDefaultCart(student);
      this.pageService.setOperatorPage(this.pageService.OPERATOR_KIOSK_PAGE);
      this.pageService.setCustomerPage(this.pageService.CUSTOMER_TILL_PAGE);
    } else {
      this.pageService.setOperatorPage(this.pageService.OPERATOR_CARD_SCAN_PAGE);
      this.pageService.setCustomerPage(this.pageService.CUSTOMER_CARD_SCAN_PAGE);
      const e = {
        status: true,
        message:  "Couldn't validate card \"" + cardNumber + '" with the School',
      };
      this.sharedStateService.set('error', e);
      return false
    }*/
  }

  async handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    this.setState({
      [name]: value,
      error: {
        status: false,
        message: "",
      },
    });
  }

  async handleDropdownChange(e, student) {
    this.setState({
      studentId: student.id,
      error: {
        status: false,
        message: "",
      },
    });
  }

  handleKeyPressed = (e) => {
    if (e.key === "Enter") {
      this.onSubmitButtonClicked();
    }
  };

  async populateStudentList(): Promise<void> {
    // this.setState({ isLoading: true })
    // const response = await this.apiService.listAllStudents()
    // this.setState({ isLoading: false })
    // console.log(response)
    // if (response.status === 'success') {
    //   const students = response.data.students
    //   this.setState({
    //     studentList: students.map((student) => {
    //       student.label = student.first_name + ' ' + student.last_name
    //       return student
    //     }),
    //   })
    // }
  }

  errorClear = () => {
    this.setState({
      error: {
        status: false,
        message: "",
      },
    });
  };
  

  render(): ReactJSXElement {
    return (
      <>
      <div
        id="ManualEntryPage"
        // style={{ backgroundImage: `url(${background})` }}
      >
        <Layout
          // studentList={this.state.studentList}
          top={<LunchClubLogo />}
          center={
            <>
              <div>
                {/* START: show error message */}
                {this.state.error.status ? (
                  <ErrorAlert
                    errorMessage={this.state.error.message}
                    onClear={this.errorClear}
                    show={true}
                  />
                ) : null}
                {/* END: show error message */}
                <div className="row" style={{ margin: "10px auto" }}>
                  {this.state.entryType === this.TYPE_CARD_INPUT && (
                    <TextField
                      id="card_number"
                      label="Enter Child Code"
                      variant="filled"
                      style={{
                        width: "350px",
                        background: "white",
                        borderRadius: "4px 4px 0px 0px",
                      }}
                      placeholder="Enter Child Code"
                      name="cardNumber"
                      // value={cardNumber}
                      // onKeyDown={this.handleKeyPressed}
                      onChange={this.handleInputChange}
                      disabled={this.state.isLoading}
                    />
                  )}
                  {this.state.entryType === this.TYPE_LIST_DROPDOWN && (
                    <Autocomplete
                      disablePortal
                      id="student_dropdown"
                      // name="student"
                      // variant="filled"
                      filterOptions={filterOptions}
                      options={this.state.studentList}
                      getOptionLabel={(option:Customer) => option.display_name}
                      disabled={this.state.isLoading}
                      style={{
                        width: "350px",
                        background: "white",
                        borderRadius: "4px 4px 0px 0px",
                        display: "inline",
                      }}
                      // onKeyDown={(event) => {
                      //   if (event.key === 'Enter') {
                      //     // Prevent's default 'Enter' behavior.
                      //     event.defaultMuiPrevented = true
                      //     // your handler code
                      //   }
                      // }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Student"
                          style={{
                            width: "350px",
                            background: "white",
                            borderRadius: "4px 4px 0px 0px",
                          }}
                        />
                      )}
                      onChange={this.handleDropdownChange}

                      renderOption={(props, option:Customer, { selected }) => (
                        <MenuItem {...props} key={option.id} value={option.id} sx={{textAlign:'left'}}>
                          <ListItemText
                            primary={option.display_name}
                            secondary={option.card_number}
                          />
                        </MenuItem>
                      )}
                    />
                  )}
                </div>
              </div>
              <div className="row" style={{ margin: "10px auto" }}>
                <Button
                  className="ButtonEnter"
                  variant="contained"
                  onClick={this.onSubmitButtonClicked}
                >
                  Enter
                </Button>
              </div>
              <div style={{ padding: 30 }}>
                <span
                  style={{
                    textDecoration: "underline",
                    fontFamily: "Montserrat",
                    fontSize: 17,
                    cursor: "pointer",
                  }}
                  // onClick={() => this.showManualEntry()}
                  onClick={() => this.cancelManualEntry()}
                >
                  Cancel
                </span>
              </div>
              {this.state.entryType === this.TYPE_LIST_DROPDOWN && (
                <div className="row" style={{ margin: "10px auto" }}>
                  <span
                    style={{ cursor: "pointer", textDecoration: "underline" }}
                    onClick={() => {
                      this.populateStudentList();
                    }}
                  >
                    Refresh student list
                  </span>
                </div>
              )}
            </>
          }
          bottom={<SchoolFoodLogo />}
        ></Layout>
      </div>
      <Box sx={{ display: this.state.showKiosk? 'block' : 'none' , position:'absolute', top:0, left: 0, zIndex: 1099,  width: window.innerWidth, height: window.innerHeight}}>
       <KioskPage 
          cart={this.state.cart} 
          customer={this.state.customer} 
          onClose={()=>{  
            PubSub.publish("onKiosk", false); 
            this.setState({showKiosk:false, customer:null, cart:null })
          }}
        />
     </Box>
     </>
    );
  }
}

export default ManualEntryPage;
