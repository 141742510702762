import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Pagination,
  Paper,
  Typography,
} from '@mui/material'
import React from 'react'
import PubSub from 'pubsub-js'

import { ReactJSXElement } from '@emotion/react/types/jsx-namespace'
import SharedStateService from '../../services/SharedStateService'
import ApiService from '../../services/ApiService'
import { secondaryColor, themeColor } from '../../utils/ThemeManager'
import { formatDateTime } from '../../utils/utils'
import { StyledDialogTitle } from '../../components/Dialog/StyledDialogTitle'
import { StyledDialogOKButton } from '../../components/Dialog/DialogButton'

type DialogTransactionsState = {
  // [x:string]:any,
  showDialog: boolean
  org: ApiOrgs
  currentPage: number
  totalPages: number
  transactions: Transaction[]
  fetching: boolean
}

type DialogTransactionsProps = {
}

class DialogTransactions extends React.Component<
  DialogTransactionsProps,
  DialogTransactionsState
> {
  private apiService: ApiService
  private sharedStateService: SharedStateService

  constructor(props) {
    super(props)
    this.apiService = new ApiService()
    this.sharedStateService = new SharedStateService()
    this.state = {
      showDialog: false,
      org: this.sharedStateService.getOrg(),
      currentPage: 0,
      totalPages: 0,
      transactions: [],
      fetching: true,
    }
  }

  async fetchTransactions(page){
    
    try {
      const customer = this.sharedStateService.getCustomer()
      const response: ApiFetchTransactionResponse = await this.apiService.fetchTransactionsByUserID(
        customer.id,
        page,
        10
      )
      console.log('IN TRANSACTIONS RESPONSE: ', response)
      if (response.status === 'success') {
        this.setState({
          transactions: response.transactions,
          totalPages: response.totalPages,
          fetching: false
        })
      }else{
        this.setState({
          transactions: [],
          totalPages: 0,
          fetching: false
        })
      }
      
    } catch (error) {
      console.log("ERROR ",error);
      
      this.setState({
        transactions: [],
        totalPages: 0,
        fetching: false
      })
    }
     
  }

  componentDidMount() {

    PubSub.subscribe('dialog-show-transactions', (msg, data) => {
      this.show()
      console.log("dialog-show-transactions");
      
      this.fetchTransactions(this.state.currentPage)
    })
    // DEBUG: console.log('Staff. DialogDailySummary did mount')
  }

  componentWillUnmount() {
    // DEBUG: console.log('Staff. DialogDailySummary did unmount')
    PubSub.unsubscribe('dialog-show-transactions')
  }

  //   componentWillReceiveProps(nextProps) {
  //     this.setState({ showDialog: nextProps.showDialog })
  //   }

  //   shouldComponentUpdate(nextProps) {
  //     console.log('Staff. DialogDailySummary should update?')
  //     console.log(nextProps)
  //     return this.state.showDialog !== nextProps.showDialog
  //   }

  show() {
    this.setState({
      showDialog: true,
    })
  }

  close() {
    this.setState({
      showDialog: false,
    })
  }

  async onPageChange(page) {
    console.log(page)
    page = page - 1
    this.setState({ currentPage: page })
    this.fetchTransactions(page)
  }

  render(): ReactJSXElement {
    const { org, fetching, transactions } = this.state
    const supportedCurrencies = org.supportedCurrencies[0]
    // const transactions = []
    const page = this.state.currentPage + 1 // pages in MUI start at 1
    const totalPages = this.state.totalPages

    /*let totalAmount = 0.0

    this.state.transactions.forEach((data, index) => {
      totalAmount = totalAmount + parseFloat(data.amount)
      transactionRows.push(
        <tr key={'rowid_' + index} style={{ color: '#999', fontSize: '14px' }}>
          <td style={{ textAlign: 'left', borderBottom: '1px solid #ccc' }}>
            {data.id}
          </td>
          <td style={{ textAlign: 'center', borderBottom: '1px solid #ccc' }}>
            {supportedCurrencies.symbol}
            {data.amount ? (parseFloat(data.amount) / 100).toFixed(2):'0.00'}
          </td>
          <td style={{ textAlign: 'right', borderBottom: '1px solid #ccc' }}>
            {supportedCurrencies.symbol}
            {data.purseAmountBefore ? (parseFloat(data.purseAmountBefore) / 100).toFixed(2):'0.00'}
          </td>
          <td style={{ textAlign: 'right', borderBottom: '1px solid #ccc' }}>
            {supportedCurrencies.symbol}
            {data.purseAmountAfter ? (parseFloat(data.purseAmountAfter) / 100).toFixed(2):'0.00'}
          </td>
        </tr>,
      )
    })*/

    return (
      <>
        <Dialog
          id="DialogDailySummary"
          open={this.state.showDialog}
          // TransitionComponent={Transition}
          keepMounted
          // onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          style={{
            position: 'absolute',
            // left: 'calc(75% - 450px)',
            fontFamily: 'Montserrat',
          }}
        >
          <StyledDialogTitle>
            Transactions
          </StyledDialogTitle>
          <DialogContent>
            {
              fetching ? <Box display="flex" justifyContent="center" alignItems="center" height={200} width={450}><CircularProgress color='primary' /></Box>  : 
              <>
              { transactions.length === 0 ?
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height={200}
                    width={450}
                  >
                  <Typography variant='h4' sx={{opacity:0.5}}>No Transaction(s)</Typography>
                </Box>
                :
                transactions.map((transaction)=>{

                  let event = transaction.event

                  if(transaction.refundType && transaction.refundType === 'partial'){
                    event = 'Partial Refund';
                  }

                  return (
                    <Box m={2} key={transaction.id}>
                      <Paper sx={{ p: 2 }} elevation={3}>
                        <Grid container>
                          <Grid item xs={12} mb={1}>
                            <Grid container spacing={2}>
                              <Grid item xs={6}>
                                <Typography color={secondaryColor} sx={{opacity:0.5}}>{formatDateTime(transaction.receipt?.dateTime)}</Typography>
                              </Grid>
                              <Grid item xs={6} display="flex" justifyContent="flex-end" alignItems={"center"}>
                                {
                                  transaction.event === 'epos' && transaction.refundType === null ?
                                  <StyledDialogOKButton 
                                    onClick={()=>{
                                      PubSub.publish('refund_initiated', transaction)
                                      this.close()
                                    }}>
                                      Refund
                                  </StyledDialogOKButton>
                                  : <Typography sx={{backgroundColor:'#417b5a', px:2}} textAlign="center" color="white" fontSize={12}>{ transaction.refundType === null?event.toUpperCase():`${transaction.refundType.toUpperCase()} REFUND`}</Typography>
                                }
                              </Grid>
                            </Grid>
                          </Grid>
                          {(transaction.event === 'manual')?
                            <Grid item xs={12}>
                              <Box>
                                <Typography sx={{ display: 'inline' }}>{transaction.description}</Typography>
                              </Box>
                            </Grid>
                            :null
                          }
                          {
                            transaction.receipt.items?.map((item) => {
                              if (item.type === 'menuItem') {
                                return (
                                  <Grid item xs={12} key={item.name}>
                                    <Box display="flex">
                                      <Typography>{`${item.quantity} x`}</Typography>
                                      <Typography sx={{ display: 'inline', ml:0.5 }}>{item.name}</Typography>
                                    </Box>
                                  </Grid>
                                );
                              }
                              return (
                                <Grid item xs={12} key={item.name}>
                                  <Box display="flex">
                                    <Typography sx={{ display: 'inline', color: themeColor }}>{item.name}</Typography>
                                  </Box>
                                  <Box ml={1}>
                                    {
                                      item.menuPromotionItems?.map((promotion,index) => (
                                        <Box display="flex" key={`${promotion.id}-${index}`}>
                                          <Typography fontSize={14}>{`${promotion.quantity} x`}</Typography>
                                          <Typography fontSize={14} sx={{ ml: 0.5, display: 'inline' }}>{promotion.name}</Typography>
                                        </Box>
                                      ))
                                    }
                                  </Box>
                                </Grid>
                              );
                            })
                          }
                          <Grid item xs={12} mt={2}>
                            <Box fontWeight="bold">
                              Total
                              {' '}
                              <Typography sx={{ display: 'inline', color:(transaction.event === 'refund' || transaction.event === 'manual' || transaction.event === 'top-up-manual-terminal')?'#417b5a':themeColor }}>{(transaction.event === 'refund' || transaction.event === 'manual' || transaction.event === 'top-up-manual-terminal')?`+`:`-`}{supportedCurrencies.symbol}{(parseFloat(transaction.amount)/100.0).toFixed(2)}</Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Box>
                  )
                })
              }
              
                {/* <table
                  style={{textTransform: 'uppercase' }}
                  cellSpacing={4}
                  cellPadding={6}
                >
                <thead>
                  <tr
                    style={{
                      textTransform: 'none',
                    }}
                  >
                    <th
                      style={{
                        textAlign: 'center',
                        borderBottom: '1px solid #ccc',
                      }}
                    >
                      Transaction ID
                    </th>
                    <th
                      style={{
                        width: 1,
                        textAlign: 'center',
                        borderBottom: '1px solid #ccc',
                      }}
                    >
                      Amount
                    </th>
                    <th
                      style={{
                        width: 1,
                        textAlign: 'center',
                        borderBottom: '1px solid #ccc',
                      }}
                    >
                      Purse Before
                    </th>
                    <th
                      style={{
                        width: 1,
                        textAlign: 'center',
                        borderBottom: '1px solid #ccc',
                      }}
                    >
                      Purse After
                    </th>
                  </tr>
                </thead>
                <tbody>{transactionRows}</tbody>
                <tfoot style={{ fontWeight: 'bold', textTransform: 'none' }}>
                  <tr>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>Total</td>
                    <td style={{ textAlign: 'right' }}>
                      {supportedCurrencies.symbol}
                      {(totalAmount / 100).toFixed(2)}
                    </td>
                  </tr>
                </tfoot>
              </table> */}
              
            </>
            }
            <DialogContentText
              id="alert-dialog-slide-description"
              style={{
                textAlign: 'center',
                fontSize: '17px',
                margin: '0px 20px',
              }}
            ></DialogContentText>
          </DialogContent>
          <DialogActions
            style={{ justifyContent: 'center', margin: '20px 0px 20px 0px' }}
          >
            <Grid container>
              <Grid item xs={9} display="flex" alignItems="center">
                { transactions.length === 0 ? 
                  null : 
                    <Pagination
                      count={totalPages}
                      color="primary"
                      page={page}
                      onChange={(evt, page) => {
                        this.onPageChange(page)
                      }}
                    />
                }
              </Grid>
              <Grid item xs={3} textAlign="right">
                <StyledDialogOKButton
                  onClick={() => this.close()}
                >
                  Close
                </StyledDialogOKButton>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

export default DialogTransactions
