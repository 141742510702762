import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  // Divider,
  Grid,
  MenuItem,
  Select,
  // Stack,
  // Pagination,
  Typography,
} from "@mui/material";
import React from "react";
import PubSub from "pubsub-js";

import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import SharedStateService from "../../services/SharedStateService";
import ApiService from "../../services/ApiService";
import BootstrapField from "../../components/Custom/BootstrapField";
import { StyledDialogTitle } from "../../components/Dialog/StyledDialogTitle";
// import { themeColor } from '../../utils/ThemeManager'

interface PriceProps {
  price: number;
  count: number;
}

interface SummaryProps {
  itemName: string;
  prices: PriceProps[];
}

type DialogDailyTransactionsState = {
  // [x:string]:any,
  showDialog: boolean;
  org: ApiOrgs;
  currentPage: number;
  totalPages: number;
  transactions: SummaryProps[];
  allTransactions: Transaction[];
  fetching: boolean;
  sittingId: string;
  sittings: ApiMenuSitting[];
};

type DialogDailyTransactionsProps = {};

class DialogDailyTransactions extends React.Component<
  DialogDailyTransactionsProps,
  DialogDailyTransactionsState
> {
  private apiService: ApiService;
  private operator: ApiEposUser;
  private sharedStateService: SharedStateService;
  // private epos: ApiEpos;

  constructor(props) {
    super(props);
    this.apiService = new ApiService();
    this.sharedStateService = new SharedStateService();

    const sittings = this.sharedStateService.getSittings();
    this.state = {
      showDialog: false,
      org: this.sharedStateService.getOrg(),
      currentPage: 0,
      totalPages: 0,
      transactions: [],
      fetching: true,
      sittingId: sittings[0].id,
      sittings,
      allTransactions: [],
    };
    // this.operator = this.sharedStateService.getOperator();
    
  }

  onChange = (value) => {
    this.setState({ sittingId: value });
    this.formatData(this.state.allTransactions, value);
  };

  async fetchTransactions(page) {
    try{
      const epos = this.sharedStateService.getEpos();
      const response: ApiFetchTransactionResponse =
        await this.apiService.fetchTransactionsBySchoolID(
          epos.schoolId,
          page
        );

      if (response.status === "success") {
        this.setState({
          totalPages: response.totalPages,
          allTransactions: response.transactions,
        });

        this.formatData(response.transactions, this.state.sittingId);
      } else {
        this.setState({ transactions: [], fetching: false });
      }
    }catch(error) {
      console.log("ERROR => ",error);
      
      this.setState({ transactions: [], fetching: false });
    }
  }

  formatData = (allTransactions: Transaction[], sittingId: string) => {
    //Filter only epos transactions
    const eposTransactions = allTransactions.filter(
      (transaction) => transaction.event === "epos"
    );

    //Filter current sitting
    const transactions = eposTransactions.filter(
      (transaction) => transaction.menuSittingId === sittingId
    );

    //loop through all receipts and find items with all price changes with quantity
    const uniqueItemIds: string[] = [];
    transactions.forEach((transaction) => {
      if (transaction.event !== "refund") {
        transaction.receipt.items.forEach((item) => {
          if (!uniqueItemIds.includes(item.id)) {
            uniqueItemIds.push(item.id);
          }
        });
      }
    });

    const formattedData = [];
    //Calculate
    let prices: PriceProps[] = [];
    // let lastPrice: string = ''
    let count: number = 0;
    let itemName: string = "";

    uniqueItemIds.forEach((id) => {
      count = 0;
      prices = [];
      itemName = "";
      transactions.forEach((transaction) => {
        transaction.receipt.items.forEach((item) => {
          if (item.id === id) {
            itemName = item.name;
            const hasPrice =
              prices.filter((price) => price.price === item.purseValue)
                .length !== 0;
            const hasSubsidy =
              prices.filter((price) => price.price === item.subsidyValue)
                .length !== 0;

            if (!hasPrice) {
              prices.push({ price: item.purseValue, count: 0 });
            } else if (!hasSubsidy) {
              prices.push({ price: item.subsidyValue, count: 0 });
            }
            count = count + parseInt(item.quantity);
          }
        });
      });
      if (prices.length > 0) {
        prices[prices.length - 1].count = count;
      }
      const data: SummaryProps = { itemName, prices };
      formattedData.push(data);
    });

    this.setState({ transactions: formattedData, fetching: false });
  };

  componentDidMount() {
    PubSub.subscribe("dialog-daily-transactions-show", (msg, data) => {
      this.show();

      this.fetchTransactions(this.state.currentPage);
    });
  }

  componentWillUnmount() {
    // DEBUG: console.log('Staff. DialogDailySummary did unmount')
    PubSub.unsubscribe("dialog-daily-transactions-show");
  }

  show() {
    this.setState({
      showDialog: true,
    });
  }

  close() {
    this.setState({
      showDialog: false,
    });
  }

  async onPageChange(page) {
    /*console.log(page)
    page = page - 1
    this.setState({ currentPage: page })
    this.fetchTransactions(page)*/
  }

  render(): ReactJSXElement {
    const { org, fetching } = this.state;
    const supportedCurrencies = org.supportedCurrencies[0];
    const transactionRows = [];

    let totalAmount = 0.0;

    this.state.transactions.forEach((data, index) => {
      const price = data.prices.reduce((total, { price }) => price + total, 0);
      const quantity = data.prices.reduce(
        (total, { count }) => count + total,
        0
      );

      totalAmount = totalAmount + price * quantity;

      transactionRows.push(
        <tr key={"rowid_" + index} style={{ color: "#999", fontSize: "14px" }}>
          <td style={{ textAlign: "left", borderBottom: "1px solid #ccc" }}>
            {data.itemName}
          </td>
          <td style={{ textAlign: "center", borderBottom: "1px solid #ccc" }}>
            {quantity}
          </td>
          <td style={{ textAlign: "right", borderBottom: "1px solid #ccc" }}>
            {supportedCurrencies.symbol}
            {(price / 100).toFixed(2)}
          </td>
          <td style={{ textAlign: "right", borderBottom: "1px solid #ccc" }}>
            {supportedCurrencies.symbol}
            {((price * quantity) / 100).toFixed(2)}
          </td>
        </tr>
      );
    });

    return (
      <>
        <Dialog
          id="DialogDailySummary"
          open={this.state.showDialog}
          // TransitionComponent={Transition}
          keepMounted
          // onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          style={{
            position: "absolute",
            // left: 'calc(75% - 450px)',
            fontFamily: "Montserrat",
          }}
        >
          <StyledDialogTitle>
            <Grid container>
              <Grid
                item
                xs={8}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Typography variant="h6" color="white">
                  Daily Transactions(X Report)
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Select
                  id="sitting-select"
                  value={this.state.sittingId}
                  defaultValue=""
                  onChange={(event) => {
                    this.onChange(event.target.value);
                  }}
                  sx={{ mx: 2 }}
                  input={<BootstrapField />}
                >
                  {this.state.sittings.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
          </StyledDialogTitle>
          <DialogContent>
            {fetching ? (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height={200}
                width={500}
              >
                <CircularProgress color="primary" />
              </Box>
            ) : (
              <Box>
                {transactionRows.length > 0 ? (
                  <Box>
                    <table
                      style={{ textTransform: "uppercase", minWidth: 500 }}
                      cellSpacing={4}
                      cellPadding={6}
                    >
                      <thead>
                        <tr
                          style={{
                            textTransform: "none",
                          }}
                        >
                          <th
                            style={{
                              textAlign: "center",
                              borderBottom: "1px solid #ccc",
                            }}
                          >
                            Product
                          </th>
                          <th
                            style={{
                              width: 1,
                              textAlign: "center",
                              borderBottom: "1px solid #ccc",
                            }}
                          >
                            Quantity
                          </th>
                          <th
                            style={{
                              width: 1,
                              textAlign: "center",
                              borderBottom: "1px solid #ccc",
                            }}
                          >
                            Cost
                          </th>
                          <th
                            style={{
                              width: 1,
                              textAlign: "center",
                              borderBottom: "1px solid #ccc",
                            }}
                          >
                            Total
                          </th>
                        </tr>
                      </thead>
                      <tbody>{transactionRows}</tbody>
                      <tfoot
                        style={{ fontWeight: "bold", textTransform: "none" }}
                      >
                        <tr>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>Total</td>
                          <td style={{ textAlign: "right" }}>
                            {supportedCurrencies.symbol}
                            {(totalAmount / 100).toFixed(2)}
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </Box>
                ) : (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height={200}
                    width={500}
                  >
                    <Typography variant="h4" sx={{ opacity: 0.5 }}>
                      No Transaction(s)
                    </Typography>
                  </Box>
                )}
              </Box>
            )}
            <DialogContentText
              id="alert-dialog-slide-description"
              style={{
                textAlign: "center",
                fontSize: "17px",
                margin: "0px 20px",
              }}
            ></DialogContentText>
          </DialogContent>
          <DialogActions
            style={{ justifyContent: "center", margin: "0px 0px 20px 0px" }}
          >
            <Grid container>
              <Grid item xs={9} display="flex" alignItems="center">
                {/*transactionRows.length>0 
                  ? <Pagination
                      count={totalPages}
                      color="primary"
                      page={page}
                      onChange={(evt, page) => {
                        this.onPageChange(page)
                      }}
                    /> 
                  : null
                  */}
              </Grid>
              <Grid item xs={3} textAlign="right">
                <Button
                  className="ButtonRedSolid"
                  onClick={() => this.close()}
                  variant="contained"
                >
                  Close
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export default DialogDailyTransactions;
