import { Box, Grid, styled, Typography } from '@mui/material'
import { ImageComponent, ImageComponentWithShadow } from '../../components/Custom/ImageComponent'
import { promoColor, secondaryColor, themeColor } from '../../utils/ThemeManager'
import LoyaltyRoundedIcon from '@mui/icons-material/LoyaltyRounded'
import ReadMoreRoundedIcon from '@mui/icons-material/ReadMoreRounded'
import SharedStateService from '../../services/SharedStateService'
import { MenuItemSelectionType } from '../../utils/utils'
import ImageManager, { blankImage, IImageProps } from '../../services/ImageManager'
import { useState } from 'react'
import useLongPress from '../../components/useLongPress'
// import { useEffect, useState } from "react";

enum EposItemType {
  item = 'item',
  promo = 'promo',
  layout = 'layout',
  blank = 'blank',
}

const StyledGridItem = styled(Grid)({
  width: 100,
  height: 100,
  margin: 4,
  position: 'relative',
  ':hover': {
    opacity: 0.9,
    cursor: 'pointer',
  },
})

interface IScreenProps {
  button: ApiButton
  menuCategories: ApiMenuCategory[]
  menuItems: ApiMenuItem[]
  menuPromos: ApiMenuPromotion[]
  isOnline: boolean
  sittingId: string
  onFoodItemSelection:(item:any)=>void
  onPromoItemSelection:(promoId:string)=>void
  onShowFoodItemInfo:(item:ApiMenuItem)=>void
}

function EposLayoutItem({
  button,
  menuCategories,
  menuItems,
  menuPromos,
  isOnline,
  sittingId,
  onFoodItemSelection,
  onPromoItemSelection,
  onShowFoodItemInfo,
}: IScreenProps) {
  const sharedStateService = new SharedStateService()

  const [images] = useState<Array<IImageProps>>(ImageManager.sharedManager().getImages());

  const onLongPress = useLongPress();

  const menuCategory = (itemData: ApiMenuItem) => {
    if (!itemData) return ''
    const allCategories: ApiMenuCategory[] = []
    itemData.menuCategoryIds.forEach((categoryId) => {
      const category = menuCategories.filter((cat) => cat.id === categoryId)
      if (category.length > 0) {
        allCategories.push(category[0])
      }
    })

    return allCategories.length>0 ? allCategories[0].defaultColour : ''
  }

  const getMenuItem = (id: string) => {
    const filter = menuItems.filter((menuItem) => menuItem.id === id)
    if (filter.length > 0) {
      return filter[0]
    }

    return undefined
  }

  const getMenuPromoName = (id: string) => {
    const filter = menuPromos.filter((promoItem) => promoItem.id === id)
    if (filter.length > 0) {
      return filter[0].name
    }

    return ''
  }

  const getMenuPromo = (id: string) => {
    const filter = menuPromos.filter((promoItem) => promoItem.id === id)
    if (filter.length > 0) {
      return filter[0]
    }

    return null
  }

  const getEposLayoutName = (id: string) => {
    return 'More' //TO-DO get linked layout name
  }

  const itemAvaiableToday = () => {
    const todayMenu = sharedStateService.getTodayMenu()
    const sittingMenuItems = todayMenu.filter((menu)=>menu.menuSittingId === sittingId);

    if(sittingMenuItems.length === 0) return false
    
    let available = true
    if (button.type === EposItemType.item) {
      const filterItems = sittingMenuItems.filter(
        (item) => item.menuItemId === button.menuItemId,
      )
      available = filterItems.length > 0
    } else if (button.type === EposItemType.promo) {
      const filterItems = sittingMenuItems.filter(
        (item) => item.menuPromotionId === button.menuPromotionId,
      )
      available = filterItems.length > 0
    }

    return available
  }

  const categoryColor = button.menuItemId
    ? menuCategory(getMenuItem(button.menuItemId))
    : ''
  const hasData = button.position !== '0'
  const dataColor = button.eposLayoutId
    ? '#cccccc'
    : button.menuItemId
    ? categoryColor
    : promoColor
  const isMenuItem = button.type === EposItemType.item

  const menuItem = getMenuItem(button.menuItemId)
  const promoItem = getMenuPromo(button.menuPromotionId);
  const available = itemAvaiableToday()

  const getImageById = (menuItemId) =>{
    const image=images.filter((img)=>img.id === menuItemId)[0]
    // console.log('IMAGE => ', image);
    if(!image.loaded){
      return blankImage;
    }
    return image
  }

  const processSelection = () =>{
    console.log('TIME CLICKED => ', new Date(), new Date().getMilliseconds());
    console.log('button', button);
          
    if (button.type === EposItemType.item) {
      onFoodItemSelection({id:button.menuItemId,type:MenuItemSelectionType.normal});
    } else if (button.type === EposItemType.promo) {
      onPromoItemSelection(button.menuPromotionId)
    } else if (button.type === EposItemType.layout) {
      PubSub.publish('layout_selected', button.eposLayoutId)
    }
  }

  return (
    <StyledGridItem item>
      <Box
        boxShadow={4}
        borderRadius={2}
        sx={{
          backgroundColor: isMenuItem ? 'white' : dataColor,
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundImage: button.eposLayoutId
            ? 'linear-gradient(to bottom, #ff4747 0%, #d00000 100%)'
            : 'none', //Link button
          opacity: available ? 1 : 0.3,
        }}
        onClick={() => {
          if(available){
            processSelection()
          }
        }}
        {...onLongPress(()=>{onShowFoodItemInfo(menuItem)})}
      >
        {isMenuItem && menuItem? (
          <ImageComponent
            src={menuItem && getImageById(menuItem.id).url}
            alt={menuItem.name}
            width={100}
            height={'100%'}
          />
        ) : button.type === EposItemType.promo ? (promoItem.imageUri ? 
              <ImageComponent
                src={getMenuPromo(button.menuPromotionId!).imageUri} 
                alt={getMenuPromo(button.menuPromotionId!).name}
                width={100}
                height={'100%'}
              />
            :
            <LoyaltyRoundedIcon
              sx={{
                color: secondaryColor,
                position: 'absolute',
                top: 15,
                fontSize: 40,
              }}
            />)
          : (
          <ReadMoreRoundedIcon
            sx={{
              color: 'white',
              position: 'absolute',
              top: 15,
              fontSize: 40,
            }}
          />
        )}
        {
          button.type === EposItemType.promo?
          <Box
            sx={{
              backgroundColor: dataColor,
              borderBottomRightRadius: 8,
              borderBottomLeftRadius: 8,
              opacity: hasData ? 1 : 0,
            }}
            position="absolute"
            bottom={0}
            left={0}
            height={hasData ? 34 : 0}
            width="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            overflow="hidden"
          >
            <Typography
                sx={{
                  display: '-webkit-box',
                  overflow: 'hidden',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 2,
                }}
                textAlign="center"
                fontSize={10}
                color={secondaryColor}
              >
                {getMenuPromoName(button.menuPromotionId!)}
            </Typography>
          </Box>
          :
          <Box
            sx={{
              backgroundColor:
                button.type === EposItemType.layout ? 'none' : dataColor,
              borderBottomRightRadius: 8,
              borderBottomLeftRadius: 8,
              opacity: hasData ? 1 : 0,
            }}
            position="absolute"
            bottom={0}
            left={0}
            height={hasData ? 34 : 0}
            width="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            overflow="hidden"
          >
            <Typography
              fontSize={10}
              textAlign="center"
              color={
                button.type === EposItemType.promo ? secondaryColor : 'white'
              }
              sx={{
                display: '-webkit-box',
                overflow: 'hidden',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 2,
              }}
            >
              {button.type === EposItemType.item && menuItem
                ? getMenuItem(button.menuItemId).shortName
                : button.type === EposItemType.promo
                ? getMenuPromoName(button.menuPromotionId!)
                : getEposLayoutName(button.eposLayoutId!)}
            </Typography>
          </Box>
        }
      </Box>
    </StyledGridItem>
  )
}

export default EposLayoutItem
