import { Box } from "@mui/material";
// import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
// import { CloseRounded } from "@mui/icons-material";
import { useEffect } from "react";
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';

interface IScreenProps{
    errorMessage:string
    onClear?:()=>void
    show:boolean
}

function ErrorAlert({errorMessage, onClear, show}:IScreenProps){

    useEffect(()=>{
        if(onClear){//Auto close
            setTimeout(() => {
                onClear()
            }, 3000);
        }
    },[onClear])

    return (
        <div style={{ position:'absolute', top:0, left:'25%', height:52, width:'50%', opacity:show?1.0:0.0 }}>
            <Box 
                sx={{ backgroundColor:'#FFF4E5', px:1.5, py:1.5, borderRadius:2 }} 
                display="flex" 
                alignItems="center" 
                justifyContent="flex-start"
                color={'#663C00'}
            >
                <WarningAmberOutlinedIcon sx={{ color:'#ED6C02',mr:1 }} /> 
                {errorMessage}
                {/* {
                    onClear?
                    <IconButton onClick={onClear}>
                        <CloseRounded sx={{color:'white'}} />
                    </IconButton>:null
                }  */}
            </Box>
        </div>
    )
}

export default ErrorAlert;