import React from 'react'
// import '../SchoolFoodLogo.css'


type LayoutPageState = {}

type LayoutPageProps = {
  top: any,
  center: any,
  bottom: any,
}

class Layout extends React.Component<LayoutPageProps, LayoutPageState> {
  // constructor(props) {
  //   super(props)
  // }

  render() {
    return (
      <table
        key="Layout"
        style={{
          width: '100%',
          height: '100%',
        }}
        cellPadding="0"
        cellSpacing="0"
      >
        <tbody key="LayoutTbody">
          <tr key="LayoutTbodyTrHeader">
            <td
              key="LayoutTbodyTdHeader"
              style={{
                height: '1px',
                textAlign: 'center',
                verticalAlign: 'middle',
                padding: 0,
              }}
            >
              {this.props.top}
            </td>
          </tr>
          <tr key="LayoutTbodyTrCenter">
            <td
              key="LayoutTbodyTdCenter"
              style={{ textAlign: 'center', verticalAlign: 'middle' }}
            >
              {this.props.center}
            </td>
          </tr>
          <tr key="LayoutTbodyTrFooter">
            <td
              key="LayoutTbodyTdFooter"
              style={{
                height: '1px',
                textAlign: 'center',
                verticalAlign: 'middle',
                padding: 0,
              }}
            >
              {this.props.bottom}
            </td>
          </tr>
        </tbody>
      </table>
    )
  }
}

export default Layout
