import React from "react";
import "./LoginPage.css";
import Layout from "../../components/Layout/Layout";
import LunchClubLogo from "../../components/LunchClubLogo/LunchClubLogo";
import SchoolFoodLogo from "../../components/SchoolFoodLogo/SchoolFoodLogo";
import Grid from "@mui/material/Grid";
import ApiService from "../../services/ApiService";
import PageService from "../../services/PageService";
import OperatorSessionService from "../../services/OperatorSessionService";
import SharedStateService from "../../services/SharedStateService";
import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import {
  Box,
  // Button,
  CircularProgress,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import SittingManager from "../../services/SittingManager";
import ImageManager from "../../services/ImageManager";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
// import HowToRegRoundedIcon from "@mui/icons-material/HowToRegRounded";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import SupportAgentRoundedIcon from "@mui/icons-material/SupportAgentRounded";
import AlertBox from "../../components/Custom/AlertBox";
import ErrorAlert from "../../components/Custom/ErrorAlert";
// import DialogSupport, { DialogScreenType } from '../KioskPage/DialogSupport'
import DataSyncService from "../../services/DataSyncService";
import DialogSupport, {
  DialogScreenType,
} from "../../components/DialogSupport/DialogSupport";
import AudioManager from "../../services/AudioManager";
import SessionManager from "../../services/SessionManager";
import EnvService from "../../services/EnvService";

import { styled } from '@mui/material/styles';

import bcrypt from "bcryptjs";
import { StyledIconButton, StyledIconWhiteButton } from "../../components/Custom/StyledIconButton";

export const StyledGridItem = styled(Grid)(({ theme }) => ({
  cursor: 'pointer',
  display:'flex',
  justifyContent:'center',
  alignContent:'center'
}));

interface LoginPageProps {
  name?: string;
}

type LoginState = {
  [x: string]: any;
  error: {
    status: boolean;
    message: string;
  };
  pinerror: {
    status: boolean;
    message: string;
  };
  success: boolean;
  eposUserId: string;
  pin: string;
  eposUsers: ApiEposUser[];
  fetching: boolean;
  showDialogSupport: boolean;
};

class LoginPage extends React.Component<LoginPageProps, LoginState> {
  private apiService: ApiService;
  private operatorSessionService: OperatorSessionService;
  private pageService: PageService;
  private sharedStateService: SharedStateService;
  private eposUsers: ApiEposUser[] = [];
  private syncService: DataSyncService;

  constructor(props) {
    super(props);
    this.state = {
      pin: "",
      error: {
        status: false,
        message: "",
      },
      pinerror: {
        status: false,
        message: "",
      },
      success: false,
      eposUserId: "",
      eposUsers: [],
      fetching: true,
      showDialogSupport: false,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    // this.onLoginButtonClicked = this.onLoginButtonClicked.bind(this)
    this.apiService = new ApiService();
    this.pageService = new PageService();
    this.sharedStateService = new SharedStateService();
    this.syncService = new DataSyncService();
    // this.operatorSessionService = new OperatorSessionService();
  }

  componentDidMount() {
    // this.sharedStateService.setOperatorSessionStartedAt(new Date());

    this.sharedStateService.set('error',null);

    //We already have data in case of if Till is offline don't refetch
    if (EnvService.isOffline() && this.sharedStateService.dataFetched()) {
      const eposUsers = this.sharedStateService.getOperators();
      this.setState({fetching:false, eposUsers});
    }else{
      this.syncService.syncData(async (completed) => {
        if (completed) {
          this.sharedStateService.setDataFetched("1");

          console.log("All data fetched");

          //Initialize the automatic timer
          SittingManager.sharedManager().initSittingTimer();

          ImageManager.sharedManager().preloadImages();

          const eposUsers = this.sharedStateService.getOperators();

          if (eposUsers.length>0) {
            this.setState({
              eposUsers,
              fetching: false,
            });
          } else {
            this.setState({
              error: {
                status: true,
                message: 'Please setup "OPERATORS" to proceed futher. Thanks.',
              },
              fetching: false,
            });
          }
        } else {
          let eposUsers = this.sharedStateService.getOperators();
          eposUsers = eposUsers ? eposUsers : []
          if (eposUsers.length === 0) {
            this.setState({
              error: {
                status: true,
                message: 'Please setup "OPERATORS" to proceed futher. Thanks.',
              },
              fetching: false,
            });
          }else{
            this.setState({
              error: {
                status: true,
                message: "EPOS not available.",
              },
              fetching: false,
            });
          }
        }
      });
  }
  }

  componentWillUnmount() {
    // console.log('Staff. LoginPage did unmount')
  }

  handleInputChange = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;

    this.setState({
      [name]: value,
      error: {
        status: false,
        message: "",
      },
    });
  };

  eposUserClicked(eposUser): void {
    console.log(eposUser.id);
    this.beep();
    this.setState({
      eposUserId: eposUser.id,
    });
    this.sharedStateService.setOperator(eposUser);
  }

  // handleKeyPressed = (e:any) => {
  //   if (e.key === 'Enter') {
  //     this.onLoginButtonClicked()
  //   }
  // }

  async padClicked(number) {
    this.beep();
    const pin = this.state.pin;
    const newPin = pin + "" + number;
    this.setState({ pin: newPin });

    if (this.state.eposUserId === "") {
      this.setState({
        pinerror: {
          status: true,
          message: "Select User first",
        },
      });
      this.padEmpty();
    }

    if (newPin.length > 3) {
      // console.log("Validating PIN: ", newPin);
      if (await this.pinValidate(this.state.eposUserId, newPin)) {
        // this.operatorSessionService.start();

        SessionManager.sharedManager().startOperatorSession();

        this.pageService.setOperatorPage(
          this.pageService.OPERATOR_CARD_SCAN_PAGE
        );
        this.pageService.setCustomerPage(
          this.pageService.CUSTOMER_CARD_SCAN_PAGE
        );
      } else {
        this.setState({
          pinerror: {
            status: true,
            message: "Invalid pin",
          },
        });
      }
    }

    // clear error message
    setTimeout(() => {
      this.setState({
        pinerror: {
          status: false,
          message: "",
        },
      });
    }, 2000);
  }

  /**
   * Validates the PIN vs the API. If successful a
   */
  async pinValidate(eposUserId: string, pin: string): Promise<Boolean> {

    if(EnvService.isOffline()){
      const eposUsers = this.sharedStateService.getOperators();
      const eposUser = eposUsers.filter((user)=>user.id === eposUserId)[0]
      const hasResult = await bcrypt.compare(pin, eposUser.pin);

      if(hasResult){
        this.padEmpty();
        return true;
      }

      this.beep();
      this.beep();
      this.beep();
      this.padEmpty();

      return false;
    }

    const result = await this.apiService.operatorLogin(eposUserId, pin);

    if (result.status === ApiService.RESPONSE_SUCCESS) {
      this.padEmpty();
      return true;
    }

    if (result.status === ApiService.RESPONSE_UNAUTHENTICATED) {
      this.beep();
      this.beep();
      this.beep();
      this.padEmpty();
      this.pageService.setOperatorPage(this.pageService.OPERATOR_INIT_PAGE);
      this.pageService.setCustomerPage(this.pageService.CUSTOMER_INIT_PAGE);
      return false;
    }

    this.beep();
    this.beep();
    this.beep();
    this.padEmpty();

    return false;
  }

  padEmpty(): void {
    this.beep();
    const newPin = "";
    this.setState({ pin: newPin });
  }

  beep(): void {
    AudioManager.sharedManager().beep();
    // const sound: HTMLVideoElement = document.getElementById(
    //   "audiobeep"
    // ) as HTMLVideoElement;
    // if (sound) {
    //   sound.play();
    // }
  }

  onSupport=()=> {
    this.beep()
    PubSub.publish("dialog-support-show", "");
  }

  render(): ReactJSXElement {
    const normalStyle = {
      display: "flex",
      justifyContent: "center",
    };
    const spacing=2.15
    return (
      <div id="LoginPage">
        <DialogSupport
          showDialog={this.state.showDialogSupport}
          screenType={DialogScreenType.operatorLogin}
        ></DialogSupport>
        <Layout
          top={<LunchClubLogo />}
          center={
            this.state.fetching ? (
              <Box sx={normalStyle}>
                <CircularProgress sx={{ color: "white" }} />
              </Box>
            ) : this.state.error.status ? (
              <AlertBox
                autoclose={false}
                message={this.state.error.message}
                closeButtonText="CLOSE"
                onClose={() => {
                  this.sharedStateService.clear();
                  this.pageService.setOperatorPage(
                    this.pageService.OPERATOR_INIT_PAGE
                  );
                  this.pageService.setCustomerPage(
                    this.pageService.CUSTOMER_INIT_PAGE
                  );
                }}
              />
            ) : (
              <Grid container>
                <Grid item xs={8}>
                  <Typography
                    color="white"
                    fontSize={22}
                    fontWeight={500}
                    sx={{ textTransform: "uppercase" }}
                  >
                    SELECT NAME
                  </Typography>
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    display="flex"
                  >
                    {this.state.eposUsers.map((eposUser) => {
                      const selected = this.state.eposUserId === eposUser.id
                      return (
                        <Grid
                          key={eposUser.id}
                          sx={{
                            width: 120,
                            height: 100,
                            margin: 4,
                            position: "relative",
                            border: selected ? 'white' : 'none',
                            borderWidth:selected ? 2 : 0,
                            borderStyle: selected ? 'solid' : 'none'
                          }}
                          className="operatorname"
                          onClick={() => this.eposUserClicked(eposUser)}
                        >
                          <Typography color={"white"} fontWeight={700}>
                            {eposUser.name}
                          </Typography>
                          {/* {this.state.eposUserId === eposUser.id ? (
                            <HowToRegRoundedIcon
                              color="primary"
                              sx={{ position: "absolute", bottom: 2, right: 2 }}
                            />
                          ) : null} */}
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    borderLeft: "#ECECEC 1px solid",
                    height: "calc(100% - 100px)",
                  }}
                >
                  <div className="row" style={{display:'flex', justifyContent:'center', flexDirection:'column'}}>
                    <Box>
                        <Typography
                          color="white"
                          fontSize={22}
                          fontWeight={500}
                          sx={{ textTransform: "uppercase" }}
                        >
                          PASSCODE
                        </Typography>
                        <div className="display">
                          <span
                            className={
                              this.state.pin.length > 0 ? "dot active" : "dot"
                            }
                          >
                            &nbsp;
                          </span>
                          <span
                            className={
                              this.state.pin.length > 1 ? "dot active" : "dot"
                            }
                          >
                            &nbsp;
                          </span>
                          <span
                            className={
                              this.state.pin.length > 2 ? "dot active" : "dot"
                            }
                          >
                            &nbsp;
                          </span>
                          <span
                            className={
                              this.state.pin.length > 3 ? "dot active" : "dot"
                            }
                          >
                            &nbsp;
                          </span>
                          <ErrorAlert errorMessage={this.state.pinerror.message} show={this.state.pinerror.status} />
                        </div>
                    </Box>
                    <Box display="flex" justifyContent="center">
                      <Grid container spacing={2} width={450}>
                          <Grid item xs={12}>
                              <Grid container spacing={2}>
                                <Grid item xs />
                                <StyledGridItem item xs={spacing}>
                                  <StyledIconButton onClick={() => this.padClicked(1)}>1</StyledIconButton>
                                </StyledGridItem>
                                <StyledGridItem item xs={spacing}>
                                  <StyledIconButton onClick={() => this.padClicked(2)}>2</StyledIconButton>
                                </StyledGridItem>
                                <StyledGridItem item xs={spacing}>
                                  <StyledIconButton onClick={() => this.padClicked(3)}>3</StyledIconButton>
                                </StyledGridItem>
                                <Grid item xs />
                              </Grid>
                          </Grid>
                          <Grid item xs={12}>
                              <Grid container spacing={2}>
                                <Grid item xs />
                                <StyledGridItem item xs={spacing}>
                                  <StyledIconButton onClick={() => this.padClicked(4)}>4</StyledIconButton>
                                </StyledGridItem>
                                <StyledGridItem item xs={spacing}>
                                  <StyledIconButton onClick={() => this.padClicked(5)}>5</StyledIconButton>
                                </StyledGridItem>
                                <StyledGridItem item xs={spacing}>
                                  <StyledIconButton onClick={() => this.padClicked(6)}>6</StyledIconButton>
                                </StyledGridItem>
                                <Grid item xs />
                              </Grid>
                          </Grid>
                          <Grid item xs={12}>
                              <Grid container spacing={2}>
                                <Grid item xs />
                                <StyledGridItem item xs={spacing}>
                                  <StyledIconButton onClick={() => this.padClicked(7)}>7</StyledIconButton>
                                </StyledGridItem>
                                <StyledGridItem item xs={spacing}>
                                  <StyledIconButton onClick={() => this.padClicked(8)}>8</StyledIconButton>
                                </StyledGridItem>
                                <StyledGridItem item xs={spacing}>
                                  <StyledIconButton onClick={() => this.padClicked(9)}>9</StyledIconButton>
                                </StyledGridItem>
                                <Grid item xs />
                              </Grid>
                          </Grid>
                          <Grid item xs={12}>
                              <Grid container spacing={2}>
                                <Grid item xs />
                                <Grid item xs={spacing} />
                                <StyledGridItem item xs={spacing}>
                                  <StyledIconButton onClick={() => this.padClicked(0)}>0</StyledIconButton>
                                </StyledGridItem>
                                <StyledGridItem item xs={spacing}>
                                  <StyledIconWhiteButton onClick={() => this.padEmpty()}>
                                    <CloseRoundedIcon
                                      sx={{ fontSize: 24 }}
                                      color="primary"
                                    />
                                  </StyledIconWhiteButton>
                                </StyledGridItem>
                                <Grid item xs />
                              </Grid>
                          </Grid>
                      </Grid>
                    </Box>
                  </div>
                </Grid>
              </Grid>
            )
          }
          bottom={
            <Box position={"relative"}>
              <Box display={'flex'} alignContent={'center'} justifyContent={'center'}>
                <Stack direction={'row'}>
                  <StyledIconButton
                    sx={{ mx: 1 }}
                    onClick={() => {
                      this.sharedStateService.clear();
                      this.pageService.setOperatorPage(
                        this.pageService.OPERATOR_INIT_PAGE
                      );
                      this.pageService.setCustomerPage(
                        this.pageService.CUSTOMER_INIT_PAGE
                      );
                      PubSub.publish("terminate_timer", "");
                      this.beep()
                    }}
                  >
                    <LogoutRoundedIcon sx={{ color: "white" }} />
                  </StyledIconButton>
                  <StyledIconButton
                    sx={{  mx: 1 }}
                    onClick={this.onSupport}
                  >
                    <SupportAgentRoundedIcon sx={{ color: "white" }} />
                  </StyledIconButton>
                </Stack>
              </Box>
              <SchoolFoodLogo />
            </Box>
          }
        ></Layout>
      </div>
    );
  }
}

export default LoginPage;
