import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import React from 'react'
import PubSub from 'pubsub-js'
import './DialogPayment.css'
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace'
import number_format from 'locutus/php/strings/number_format'
import SharedStateService from '../../services/SharedStateService'
import ApiService from '../../services/ApiService'
import { connectReader, getTerminal } from '../../services/CardReaderService'
import { ErrorResponse, ICancelResponse, ISdkManagedPaymentIntent } from '@stripe/terminal-js'
import EnvService from '../../services/EnvService'
import { OperationMode } from '../../utils/utils'
import { StyledDialogTitle } from '../../components/Dialog/StyledDialogTitle'
import { StyledDialogCancelButton, StyledDialogOKButton } from '../../components/Dialog/DialogButton'
import { StyledDialogText } from '../../components/Dialog/DialogText'

const stripeMinimums = {
  USD: 0.50,
  AED: 2.00,
  AUD: 0.50,
  BGN: 1.00,
  BRL: 0.50,
  CAD: 0.50,
  CHF: 0.50,
  CZK: 15,
  DKK: 2.50,
  EUR: 0.50,
  GBP: 0.30,
  HKD: 4.00,
  HRK: 0.50,
  HUF: 175.00,
  INR: 0.50,
  JPY: 50,
  MXN: 10,
  MYR: 2,
  NOK: 3.00,
  NZD: 0.50,
  PLN: 2.00,
  RON: 2.00,
  SEK: 3.00,
  SGD: 0.50,
  THB: 10,
};

type DialogTopupState = {
  // [x:string]:any,
  showDialog?: boolean
  waiting?: boolean
  org: ApiOrgs
  maximumDeficit: number,
  title: string,
  content: string,
  canCancel: boolean,
}

interface DialogTopupProps {
  cart?: Cart
  showDialog: boolean
  onPayment?: Function;
}
const title = 'PAY WITH CARD';
const content = 'Please choose one the following options';
class DialogTopup extends React.Component<
  DialogTopupProps,
  DialogTopupState
> {
  private sharedStateService: SharedStateService
  apiService: ApiService

  constructor(props) {
    super(props)
    this.sharedStateService = new SharedStateService();
    this.apiService = new ApiService();
    this.state = {
      showDialog: false,
      org: this.sharedStateService.getOrg(),
      maximumDeficit: this.sharedStateService.getCustomer()?.maximumDeficit,
      waiting: false,
      title,
      content,
      canCancel: true,
    }
  }

  componentDidMount(): void {
    PubSub.subscribe(
      'dialog-topup-show',
      (msg: string, data: any) => {
        this.show()
      },
    )
    PubSub.subscribe(
      'dialog-topup-close',
      (msg: string, className: string) => {
        this.closeDialog()
      },
    )
  }

  /**
   * Shows the dialog
   */
  show(): void {
    this.setState({
      showDialog: true,
      waiting: false
    });
  }

  /**
   * Closes the dialog
   */
  closeDialog(): void {
    this.setState({
      showDialog: false,
      waiting: false,
      canCancel: true,
      title,
      content,
    });
  }

  /**
   * Publishes event that payment is clicked
   * and closes the dialog
   */
  isErrorResponse(obj: any): obj is ErrorResponse {
    return typeof obj.error === 'object'
  }

  onError(err: ErrorResponse) {
    if(err.error.message.includes('No established connection')){
      PubSub.publish("dialog-payment-error-show", 'Please check that the card reader is connected to the same network as the till.');
    }
    else {
      PubSub.publish("dialog-payment-error-show", err.error.message);
    }
    console.log(err);
    this.closeDialog();
  }

  async beginCardPayment(amountPennies): Promise<void> {
    PubSub.publish("command_out", {
      command: "dialog_cardpayment_show",
      data: { amountInPennies: amountPennies, status: 'Please follow instructions on the card reader' },
    });
    this.setState({ waiting: true })

    const terminal = await this.getTerminal();
    /* 
    // this will result in a declined payment:
       const testCardNumber = '4000000000000002';
       alert('Using test card ' + testCardNumber)
       terminal.setSimulatorConfiguration({ testCardNumber })  */

    const readerPromise = connectReader(terminal, this.sharedStateService.getEposId(), EnvService.isLocal());
    const timeoutPromise = new Promise(timeout => setTimeout(timeout, 10000));

    const reader = await Promise.race([readerPromise, timeoutPromise]);

    if (reader) {
      const customer = this.sharedStateService.getCustomer()

      try {
        const { paymentIntent } = await this.apiService.createPaymentIntent(amountPennies, this.state.org.supportedCurrencies[0].name, customer.id);

        const paymentTimeoutPromise: Promise<void> = new Promise(timeout => setTimeout(timeout, 30000));

        const paymentPromise = Promise.race([terminal.collectPaymentMethod(paymentIntent.client_secret), paymentTimeoutPromise]);

        const managedPaymentIntent: { paymentIntent: ISdkManagedPaymentIntent } | ErrorResponse | void = await paymentPromise;

        if (managedPaymentIntent && !this.isErrorResponse(managedPaymentIntent)) {
          // paymentIntent will be undefined if payment is cancelled
          if (managedPaymentIntent.paymentIntent) {
            this.setState({ canCancel: false });
            const finalResult = await terminal.processPayment(managedPaymentIntent.paymentIntent);
            if(!finalResult){
              throw new Error('Cannot process payment!')
            }
            if (this.isErrorResponse(finalResult)) {
              this.onError(finalResult)
            } else {
              if (EnvService.isOffline() || this.sharedStateService.getOperationMode() === OperationMode.local) {

                //Update the purse of the user offline
                const topupPounds = amountPennies / 100;

                const cart = this.sharedStateService.getCart();
                cart.purseAfter += topupPounds;
                cart.purseBefore += topupPounds;
                cart.totalPurse += topupPounds;
                this.sharedStateService.setCart(cart);

              }
              //purse is topped up, now proceed as normal
              await this.props.onPayment();
            }
          } else {
            // cancelled
            this.closeDialog();
            PubSub.publish("dialog-payment-error-show", 'Payment cancelled');
          }

          this.closeDialog();
        }
        else {
          if (managedPaymentIntent && this.isErrorResponse(managedPaymentIntent)) this.onError(managedPaymentIntent);
          else if (!managedPaymentIntent) {
           
            const cancelled: ICancelResponse | ErrorResponse = await terminal.cancelCollectPaymentMethod();

            if(this.isErrorResponse(cancelled)){
              throw new Error('Cannot cancel payment!')
            } else {
              this.closeDialog();
              PubSub.publish("dialog-payment-error-show", "Payment timed out and cancelled");
            }
          }
        }
      } catch (e) {
        this.closeDialog();
        PubSub.publish("dialog-payment-error-show", e.message);
        console.log(e.stack);
      }
    }
    else {
      // timed out
      this.closeDialog();
      PubSub.publish("dialog-payment-error-show", "Card reader not found");
    }


  }

  private async getTerminal() {
    return await getTerminal(async () => {
      const result = await this.apiService.createConnectionToken()
      return result.secret
    }, () => {
      // unexpected disconnection
      this.closeDialog();
      PubSub.publish("dialog-payment-error-show", "Card reader not found");
    })
  }

  componentWillUnmount(): void {
    PubSub.unsubscribe(
      'dialog-topup-show'
    )
    PubSub.unsubscribe(
      'dialog-topup-close'
    )
  }

  /**
   * Renders the dialog
   * @returns
   */
  render(): ReactJSXElement {
    const { org } = this.state
    const currency = org.supportedCurrencies[0]
    const purseAfter = this.props.cart.purseAfter * -1;


    const minPayment = stripeMinimums[currency.name];
    const amountToPayInPounds = Math.max(purseAfter, minPayment);

    if (!this.state.showDialog) return null;
    return (
      <>
        <Dialog
          id="DialogPayment"
          open={this.state.showDialog}
          keepMounted
          onClose={() => this.closeDialog()}
        >
          <StyledDialogTitle>{this.state.title}</StyledDialogTitle>
          <DialogContent sx={{ px: 2 }}>
            {

              <DialogContentText className="DialogContentText">
                <StyledDialogText sx={{pt:3}}>
                  {this.state.waiting ? 'Please follow instructions on the card reader' : this.state.content}
                </StyledDialogText>
              </DialogContentText>

            }
          </DialogContent>

          {
            this.state.waiting ?
              <>
                <Box display="flex" justifyContent="center" alignItems="center" height={200} width={450}>
                  <CircularProgress color='primary' />
                </Box>

                <DialogActions className="DialogActions">
                  <StyledDialogCancelButton
                    disabled={!this.state.canCancel}
                    onClick={async () => {
                      const t = await this.getTerminal();
                      const cancelled = await t.cancelCollectPaymentMethod();
                      if (!cancelled.error) {
                        this.setState({ waiting: false })
                      }
                    }}
                  >
                    Cancel
                  </StyledDialogCancelButton>

                </DialogActions>
              </> :

              <DialogActions className="DialogActions">

                <StyledDialogCancelButton
                  onClick={() => this.closeDialog()}
                >
                  Cancel
                </StyledDialogCancelButton>
                {this.state.title !== 'TOP UP' && <StyledDialogOKButton
                  onClick={() => {
                    this.setState({ title: 'TOP UP', content: 'Please choose an amount to top up your account' })

                  }}
                >
                  Top up
                </StyledDialogOKButton>}
                {this.state.title !== 'TOP UP' && <StyledDialogOKButton
                  onClick={() => this.beginCardPayment(Math.round(amountToPayInPounds * 100))}
                >
                  Pay {currency.symbol}{number_format(amountToPayInPounds, 2)}
                </StyledDialogOKButton>}


                {
                  this.state.title === 'TOP UP' && [10, 20, 30].map(amountPounds => <StyledDialogOKButton
                    key={amountPounds}
                    onClick={() => this.beginCardPayment(Math.round(amountPounds * 100))}
                  >
                    {currency.symbol}{amountPounds}
                  </StyledDialogOKButton>)
                }
              </DialogActions>
          }
        </Dialog>
      </>
    )
  }
}

export default DialogTopup
