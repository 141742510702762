import React from "react";
import "./InitPage.css";
import Lottie from "react-lottie-player";
import lottieJson from "./card-scan-lottie.json";
import LunchClubLogo from "../../components/LunchClubLogo/LunchClubLogo";
// import SchoolFoodLogo from '../../components/SchoolFoodLogo/SchoolFoodLogo'
import Layout from "../../components/Layout/Layout";
import PageService from "../../services/PageService";
import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import ApiService from "../../services/ApiService";
import SharedStateService from "../../services/SharedStateService";
import EnvService from "../../services/EnvService";
// import { appFonts } from "../../utils/ThemeManager";
import AlertBox from "../../components/Custom/AlertBox";
import { Box, Grid, IconButton, Stack, Typography } from "@mui/material";
import SupportAgentRoundedIcon from "@mui/icons-material/SupportAgentRounded";
import ShutDownIcon from "@mui/icons-material/PowerSettingsNew";
// import DialogSupport, { DialogScreenType } from "../KioskPage/DialogSupport";
import DialogShutdownConfirm from "./DialogShutdownConfirm";
import ManagerSessionService from "../../services/ManagerSessionService";
import DialogSupport, {
  DialogScreenType,
} from "../../components/DialogSupport/DialogSupport";
import AudioManager from "../../services/AudioManager";
import SessionManager from "../../services/SessionManager";
import { StyledIconButton } from "../../components/Custom/StyledIconButton";
// import CardScanImage from "../../components/CardScanImage/CardScanImage";

// const debounce = <T extends (...args: any[]) => ReturnType<T>>(
//   callback: T,
//   timeout: number,
// ): ((...args: Parameters<T>) => void) => {
//   let timer: ReturnType<typeof setTimeout>

//   return (...args: Parameters<T>) => {
//     clearTimeout(timer)
//     timer = setTimeout(() => {
//       callback(...args)
//     }, timeout)
//   }
// }

type InitPageState = {
  error: {
    status: boolean;
    message: string;
  };
  showDialogSupport: boolean;
  showBarcodeScannerInput: boolean;
  showShutdownButton: boolean;
  barcode?: string
};

type InitPageProps = {
};

/**
 * This page waits for the manager to scan his QR code
 * and setup the EPOS
 */
class InitPage extends React.Component<InitPageProps, InitPageState> {
  private apiService: ApiService;
  private managerSessionService: ManagerSessionService;
  private pageService: PageService;
  private sharedStateService: SharedStateService;
  private barcodeReaderInterval: NodeJS.Timer;

  constructor(props) {
    super(props);

    this.state = {
      error: {
        status: false,
        message: "",
      },
      showDialogSupport: false,
      showBarcodeScannerInput: false,
      showShutdownButton: false,
      barcode: '',
    };

    this.apiService = new ApiService();
    // this.managerSessionService = new ManagerSessionService();
    this.pageService = new PageService();
    this.sharedStateService = new SharedStateService();

    this.stopFocusing = this.stopFocusing.bind(this);
  }

  componentDidMount(): void {
    this.addBarcodeReaderFocuser();
    this.showShutdownButtonAsap();
    SessionManager.sharedManager().stopOperatorSession();
  }

  showShutdownButtonAsap() {
    for (var i = 0; i < 5; i++) {
      setTimeout(() => {
        const showShutdownButton =
          this.sharedStateService.getMacAddress() !== null &&
          this.sharedStateService.getMacAddress() !== "";
        // console.log(
        //   "showShutdownButton",
        //   showShutdownButton,
        //   this.sharedStateService.getMacAddress() !== null &&
        //     this.sharedStateService.getMacAddress() !== "",
        //   this.sharedStateService.getMacAddress()
        // );
        this.setState({
          showShutdownButton: showShutdownButton,
        });
      }, i * 1000);
    }
  }
  
  componentWillUnmount(): void {
    this.stopFocusing()
  }

  stopFocusing(){
    clearInterval(this.barcodeReaderInterval)
  }

  /**
   * Focuses the scanner reader input
   * so that it can capture the scan
   */
  addBarcodeReaderFocuser() {
    this.barcodeReaderInterval = setInterval(() => {
      // if (
      //   this.pageService.getOperatorPage() !==
      //   this.pageService.OPERATOR_INIT_PAGE
      // ) {
      //   //console.log('skip focus')
      //   return true
      // }

      const reader = document.getElementById(
        "ScannerReader"
      ) as HTMLInputElement;
      if (reader) {
        // console.log('Barcode reader focused')
        reader.focus({preventScroll:true});
        
      }
    }, 100);
  }

  /**
   * Called when the scanner input receives text
   * Activates the clearance of the input
   * @param {*} barcode
   * @returns
   */
  async onChangeBarcode(barcode) {
    console.log("Barcode => ",barcode);
    
    if (barcode.indexOf("@") > -1) {
      /* this.setState({
        error: {
          status: true,
          message: "Scan failed. Please check the keyboard layout!",
        },
      });
      return false; */
      barcode = barcode.split("@").join('"'); // some readers read " in json with @ (UK keyboard layout instead of US)
    }

    setTimeout(() => {
      this.clearBarcodeReaderInput();
    }, 2000);
    
    let schoolId = "";
    try {
      const barcodeObject = JSON.parse(barcode);
      console.log("Scanned barcode => ", barcodeObject);

      schoolId = barcodeObject.school_id || null;
      const managerToken = barcodeObject.manager_token || null;

      if (schoolId === null) {
        console.log("Barcode invalid. School ID is missing");
        this.setState({
          error: {
            status: true,
            message: "The QR is invalid. School ID is missing!",
          },
        });
        return false;
      }

      if (managerToken === null) {
        console.log("Barcode invalid. Manager token is missing");
        this.setState({
          error: {
            status: true,
            message: "The QR is invalid. Manager token is missing!",
          },
        });
        return false;
      }

      const authenticationResult = await this.authenticateEpos(
        schoolId,
        managerToken
      );
      if (authenticationResult === false) {
        return false;
      }

      const dataFetchedErrorMessage = await this.fetchRequiredData(
        schoolId,
        managerToken
      );

      if (dataFetchedErrorMessage !== null) {
        this.setState({
          error: {
            status: true,
            message: dataFetchedErrorMessage,
          },
        });
        return false;
      }

      // this.managerSessionService.start();
      this.pageService.setOperatorPage(this.pageService.OPERATOR_LOGIN_PAGE);
      this.pageService.setCustomerPage(this.pageService.CUSTOMER_HOME_PAGE);
    } catch (error) {
      console.log("Barcode invalid. SKIPPED", error);
      this.setState({
        error: {
          status: true,
          message: "It seems the QR is invalid!",
        },
      });
      return false;
    }
  }

  getSystemMeta(): EposMeta {
    let systemInfo = this.sharedStateService.getSystemInfo(true);
    let computerName = systemInfo.computerName;
    if (computerName === null) {
      if (EnvService.isDev()) {
        computerName = "DEVONLY";
      } else {
        computerName = "WEB";
      }
      systemInfo = { ...systemInfo, computerName };
      this.sharedStateService.setSystemInfo(systemInfo);
    }
    return systemInfo;
  }

  /**
   * Get required data to be used throughout the application
   * 1. Get school details
   * 2. Get organisation details
   * 3. Create EPOS ID (if missing)
   * @param schoolId
   * @returns
   */
  async fetchRequiredData(
    schoolId: string,
    managerToken: string
  ): Promise<string> {
    try {
      const systemInfo = this.getSystemMeta();
      let eposIds = this.sharedStateService.getEposIds();
      eposIds = eposIds ? eposIds : []
      
      if (eposIds.length === 0 && this.sharedStateService.getEposId() === null) { //Fresh Till
        const eposIdResponse = await this.apiService.registerEpos(
          schoolId,
          {...systemInfo, event:'epos_id_null'}
        );

        // const eposIdResponse = await this.apiService.eposCreateId(schoolId);

        if (eposIdResponse.status !== ApiService.RESPONSE_SUCCESS) {
          console.log(eposIdResponse);
          return "Failed to initiate EPOS.";
        }
        
        //Store any epos app registerd on the Till
        const eposId={schoolId, eposId:eposIdResponse.id}
        this.sharedStateService.setSchoolEposId(eposId);
        const result = this.reAuthenticateAndProcess(schoolId, managerToken);
        return result;
      } else {
        let schoolEposId = ''
        
        if(this.sharedStateService.getEposId() && eposIds.length === 0){
          //Support OLD Till where array of eposids doesn't exist
          //Make an entry to array of eposes
          schoolEposId=this.sharedStateService.getEposId()
          const eposId={schoolId,eposId:schoolEposId}
          this.sharedStateService.setSchoolEposId(eposId);
        }else{
          //New Till
          schoolEposId=this.sharedStateService.getSchoolEposId(schoolId);
        }

        if(schoolEposId !== ''){
          const eposPayload: ApiEpos = {
            id: this.sharedStateService.getSchoolEposId(schoolId),
            name: systemInfo.computerName,
            schoolId: schoolId,
          };
          this.sharedStateService.setEpos(eposPayload);
          const result = this.reAuthenticateAndProcess(schoolId, managerToken);
          return result;
        }else{
          //Register New epos APP
          const systemInfo = this.getSystemMeta();
          const eposIdResponse = await this.apiService.registerEpos(
            schoolId,
            {...systemInfo, event:'epos_notexist'}
          );
          if (eposIdResponse.status !== ApiService.RESPONSE_SUCCESS) {
            console.log(eposIdResponse);
            return "Failed to initiate EPOS.";
          }

          //Store any epos app registerd on the Till
          const eposId={schoolId, eposId:eposIdResponse.id}
          this.sharedStateService.setSchoolEposId(eposId);
          const result = this.reAuthenticateAndProcess(schoolId, managerToken);
          return result;
        }
      }
    } catch (error) {
      console.log(error);
      return "Failed to Process initialization.";
    }
  }

  async reAuthenticateAndProcess(schoolId: string, managerToken: string) {
    let eposID = this.sharedStateService.getSchoolEposId(schoolId);

    //Re authenticate to get new token with reference of eposID for all futher api calls
    const authenticationResult = await this.reAuthenticateEpos(
      eposID,
      managerToken
    );

    if (authenticationResult !== null) {
      if (authenticationResult === "EPDE-1001") {
        //If eposID was not available in db, create new app
        const systemInfo = this.getSystemMeta();
        const eposIdResponse = await this.apiService.registerEpos(
          schoolId,
          {...systemInfo, event:'epos_notexist'}
        );
        if (eposIdResponse.status !== ApiService.RESPONSE_SUCCESS) {
          console.log(eposIdResponse);
          return "Failed to initiate EPOS.";
        }

        //Store any epos app registerd on the Till
        const eposId={schoolId, eposId:eposIdResponse.id}
        this.sharedStateService.setSchoolEposId(eposId);
        const result = this.reAuthenticateAndProcess(schoolId, managerToken);
        return result;
      } else {
        return authenticationResult;
      }
    }

    eposID = this.sharedStateService.getSchoolEposId(schoolId);
    const eposResponse = await this.apiService.findEpos(eposID);

    if (eposResponse.status !== ApiService.RESPONSE_SUCCESS) {
      console.log(eposResponse);
      return "Couldn't access resources.";
    }

    const epos = this.sharedStateService.getEpos();

    if (epos.schoolId !== schoolId) {
      // Find and register new epos for the school and then proceed

      const systemInfo = this.getSystemMeta();
      const eposIdResponse = await this.apiService.registerEpos(
        schoolId,
        {...systemInfo, event:'school_mismatch'}
      );

      // const eposIdResponse = await this.apiService.eposCreateId(schoolId);

      if (eposIdResponse.status !== ApiService.RESPONSE_SUCCESS) {
        console.log(eposIdResponse);
        return "Failed to initiate EPOS.";
      }

      //Store any epos app registerd on the Till
      const eposId={schoolId, eposId:eposIdResponse.id}
      this.sharedStateService.setSchoolEposId(eposId);
      const result = this.reAuthenticateAndProcess(schoolId, managerToken);
      return result;
    }

    const schoolResponse = await this.apiService.fetchSchool(schoolId);
    if (schoolResponse.status !== ApiService.RESPONSE_SUCCESS) {
      console.log(schoolResponse);
      return "Couldn't access resources.";
    }
    const school = schoolResponse.results[0];

    this.sharedStateService.set("school", school);

    const { organisationId } = school;

    const orgResponse = await this.apiService.fetchOrg(organisationId);
    if (orgResponse.status !== ApiService.RESPONSE_SUCCESS) {
      console.log(orgResponse);
      return "Couldn't access resources.";
    }

    const org = orgResponse.orgs[0];
    this.sharedStateService.setOrg(org);

    //Update the meta 
    const systemInfo = this.getSystemMeta();
    this.apiService.updateEposMeta(eposID, systemInfo);

    // PubSub.publish("updateOrg", org); //Sending to Customer

    return null;
  }
  /**
   * Clears the scanner imput
   */
  clearBarcodeReaderInput() {
    if (document.getElementById("ScannerReader")) {
      (document.getElementById("ScannerReader") as HTMLInputElement).value = "";
    }
  }

  /**
   * Authenticates the EPOS and if successful, processes to the next page
   *
   * @param {*} barcode
   */
  async authenticateEpos(
    schoolId: string,
    managerToken: string
  ): Promise<Boolean> {
    try {
      const response: ApiEposAuthResponse =
        await this.apiService.authenticateEpos("", managerToken);

      if (response.status === ApiService.RESPONSE_SUCCESS) {
        this.sharedStateService.setAuthToken(response.authToken);
        this.sharedStateService.setAuthTokenLastUpdated(new Date().getTime());
        this.sharedStateService.setSchoolId(schoolId);
        this.sharedStateService.setManagerToken(managerToken);

        return true;
      }

      if (response.code === "EPTKE-1001") {
        this.setState({
          error: {
            status: true,
            message: "QR has expired. Please request a new one.",
          },
        });
        return false;
      }

      this.setState({
        error: {
          status: true,
          message: "Couldn't authenticate the QR.",
        },
      });
      return false;
    } catch (error) {
      console.log(error);
      this.setState({
        error: {
          status: true,
          message: "Couldn't authenticate the QR, Exception!",
        },
      });
      return false;
    }
  }

  /**
   * Re Authenticates the EPOS with eposId for all future api calls
   *
   * @param {*} barcode
   */
  async reAuthenticateEpos(
    eposId: string,
    managerToken: string
  ): Promise<string> {
    try {
      const response: ApiEposAuthResponse =
        await this.apiService.authenticateEpos(eposId, managerToken);

      if (response.status === ApiService.RESPONSE_SUCCESS) {
        this.sharedStateService.setAuthToken(response.authToken);
        this.sharedStateService.setAuthTokenLastUpdated(new Date().getTime());

        return null;
      }

      if (response.code === "EPTKE-1001") {
        /*this.setState({
          error: {
            status: true,
            message: 'QR has expired. Please request a new one.',
          },
        })*/
        return "QR has expired. Please request a new one.";
      } else if (response.code === "EPDE-1001") {
        return response.code;
      }

      // this.setState({
      //   error: {
      //     status: true,
      //     message: "Couldn't authenticate the QR.",
      //   },
      // })
      return "Couldn't authenticate the QR.";
    } catch (error) {
      console.log(error);
      // this.setState({
      //   error: {
      //     status: true,
      //     message: "Couldn't authenticate the QR, Exception!",
      //   },
      // })
      return "Couldn't authenticate the QR, Exception!";
    }
  }

  beep(): void {
    AudioManager.sharedManager().beep();
    // const sound: HTMLVideoElement = document.getElementById(
    //   "audiobeep"
    // ) as HTMLVideoElement;
    // if (sound) {
    //   sound.play();
    // }
  }

  /**
   * Returns the style for the barcode scanner
   *
   * !!! Warning. Do not user display:none, or visibility:hidden or will not be focused
   */
  barcodeScannerInputStyle(): React.CSSProperties {
    const visible = this.state.showBarcodeScannerInput;
    if (visible) {
      return {
        height: 50,
        width: 300,
        marginTop: 0,
        marginBottom: 0,
        position: "absolute",
        // left: 100,
      };
    } else {
      return { position: "absolute", left: -5000, height: 50, width: 300, marginTop: 0, marginBottom: 0, };
    }
  }

  clearError() {
    this.setState({
      error: {
        status: false,
        message: "",
      },
    });
  }

  onSupport=()=>{
    this.beep()
    PubSub.publish("dialog-support-show", "");
  }

  onShutDown=()=> {
    this.beep()
    PubSub.publish("dialog_shutdown_show", "");
  }

  render(): ReactJSXElement {
    const inputStyle: React.CSSProperties = this.barcodeScannerInputStyle();

    return (
      <div id="InitPage">
        <DialogSupport
          showDialog={this.state.showDialogSupport}
          screenType={DialogScreenType.managerScan}
        ></DialogSupport>
        <Layout
          top={<LunchClubLogo />}
          center={
            this.state?.error.status ? (
              <AlertBox
                autoclose={false}
                message={this.state.error.message}
                closeButtonText="CLOSE"
                onClose={() => this.clearError()}
              />
            ) : (
              <>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography color="white" fontSize={30}>
                      Waiting for
                    </Typography>
                    <Typography variant="h3" color="white">
                      Manager Scan
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Lottie
                      key={2}
                      loop
                      animationData={lottieJson}
                      play
                      style={{
                        width: 300,
                        height: 300,
                        margin: "0px auto",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        this.setState({
                          showBarcodeScannerInput:
                            !this.state.showBarcodeScannerInput,
                        });
                      }}
                      onDoubleClick={() => {
                        this.clearBarcodeReaderInput();
                      }}
                    />
                    {/* <CardScanImage
                        onClick={() => {
                          this.setState({
                            showBarcodeScannerInput:
                              !this.state.showBarcodeScannerInput,
                          });
                        }}
                        onDoubleClick={() => {
                          this.clearBarcodeReaderInput();
                        }}
                      /> */}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    position="relative"
                    display="flex"
                    justifyContent="center"
                  >
                    <input
                      key="scanner_reader"
                      id="ScannerReader"
                      multiple
                      // value={this.state.barcode}
                      style={inputStyle}
                      // !!! START: Keep this code until its fully decided the method to be used
                      // onChange={debounce(
                      //   (evt) => this.onChangeBarcode(evt.target.value),
                      //   1500,
                      // )}
                      // !!! END: Keep this code until its fully decided the method to be used

                      onChange={(evt) => {
                        const text = evt.target.value;
                        console.log("Scanned barcode text => ",text);
                        if (!text.includes("}")) {
                          return false;
                        }
                        this.onChangeBarcode(text);
                      }}
                    />
                    {/* <div key="scanner_captured" id="ScannerCaptured" /> */}
                  </Grid>
                </Grid>
                <DialogShutdownConfirm />
              </>
            )
          }
          bottom={
            <Box
              position={"relative"}
              sx={{ opacity: this.state.showBarcodeScannerInput ? 0.0 : 1.0 }}
            >
              <Box display={'flex'} alignContent={'center'} justifyContent={'center'}>
                <Stack direction="row">
                  <StyledIconButton
                    sx={{  mx: 1 }}
                    onClick={this.onSupport}
                  >
                    <SupportAgentRoundedIcon sx={{ color: "white" }} />
                  </StyledIconButton>
                  {this.state.showShutdownButton ? (
                    <StyledIconButton
                      sx={{  mx: 1 }}
                      onClick={this.onShutDown}
                    >
                      <ShutDownIcon sx={{ color: "white" }} />
                    </StyledIconButton>
                  ) : null}
                </Stack>
              </Box>
              <Box mb={5} />
              {/* <SchoolFoodLogo /> */}
            </Box>
          }
        ></Layout>
      </div>
    );
  }
}

export default InitPage;
