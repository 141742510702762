
import './SchoolFoodLogo.css'
import { Box } from '@mui/material'
import SharedStateService from '../../services/SharedStateService';


function SchoolFoodLogo () {
  const sharedStateService = new SharedStateService();
  const org = sharedStateService.getOrg();
  return (
    <Box sx={{ margin: '25px 0px 25px 0px' }}>
        {/* <div className="PoweredByText">Powered by</div> */}
        <img
          className="PoweredByLogo"
          src={org.logoLightUri}
          alt={org.name}
        />
    </Box>
  )
}

export default SchoolFoodLogo
