import { todayDate } from '../utils/utils'
import ApiService from './ApiService'
import SharedStateService from './SharedStateService'

class DataSyncService {
  private sharedStateService: SharedStateService
  private apiService: ApiService
  completion: (success: boolean) => void

  constructor() {
    this.sharedStateService = new SharedStateService()
    this.apiService = new ApiService()
  }

  syncData(completion) {
    this.completion = completion
    
    // First call should be to check if operators available for the school, if not we don't need to fetch other data
    this.fetchOperators()
    
    this.fetchUsers()

    // this.fetchTodaysMenu()
    // this.fetchCategories()
    // this.fetchMenuItems()
  }
 

  async fetchTodaysMenu() {
    const today = todayDate()
    // const today = '2022-10-28' // Friday, as no menu on weekends

    const response: ApiFetchMenusBySchoolIdResponse = await this.apiService.fetchMenusBySchoolId(
      this.sharedStateService.getSchoolId(),
      today,
    )

    if (response.status === ApiService.RESPONSE_UNAUTHENTICATED) {
      this.sharedStateService.deleteCustomer()
      this.completion(false)
    }

    try {
      if (response.status === ApiService.RESPONSE_SUCCESS) {
        const items = response.menus
        if (items.length > 0) {
          const modified:ApiMenu[]=[]
          items.forEach((item)=>{
            item.purseValue = item.purseValue / 100.0
            item.subsidyValue = item.subsidyValue / 100.0

            modified.push(item);
          })
          this.sharedStateService.setTodayMenu(modified)
        }
        this.fetchSittings()
        /*} else {
          console.log('FETCHING TODAYs MENU failed. No menus available')
          this.completion(false)
        }*/
      } else {
        console.log('Error: ', response)
        // this.completion(false)
        this.fetchSittings()
      }
    } catch (error) {
      console.log('Error: ', error)
      this.completion(false)
    }
  }

  async fetchSittings() {
    console.log('FETCHING Sitting')

    const response: ApiFetchMenuSittingsBySchoolIdResponse = await this.apiService.fetchMenuSittingsBySchoolId(
      this.sharedStateService.getSchoolId(),
    )

    if (response.status === ApiService.RESPONSE_UNAUTHENTICATED) {
      this.sharedStateService.deleteCustomer()
      this.completion(false)
    }

    try {
      if (response.status === ApiService.RESPONSE_SUCCESS) {
        const items = response.menuSittings

        if (items.length > 0) {
          const sortedSittings = items.sort(
            (a, b) =>
              Date.parse(
                `1970/01/01 ${a.startTime.slice(0, -2)} ${a.startTime.slice(
                  -2,
                )}`,
              ) -
              Date.parse(
                `1970/01/01 ${b.startTime.slice(0, -2)} ${b.startTime.slice(
                  -2,
                )}`,
              ),
          )

          this.sharedStateService.setSittings(sortedSittings)
          this.fetchCategories()
        } else {
          this.completion(false)
        }
      } else {
        this.completion(false)
      }
    } catch (error) {
      console.log(error)
      this.completion(false)
    }
  }

  /**
   * Fetches the menu categories
   */
  async fetchCategories() {
    const org = this.sharedStateService.getOrg()
    const response: ApiFetchMenuCategoriesResponse = await this.apiService.fetchMenuCategories(org.id)

    if (response.status === ApiService.RESPONSE_UNAUTHENTICATED) {
      this.sharedStateService.deleteCustomer()
      this.completion(false)
    }

    try {
      if (response.status === ApiService.RESPONSE_SUCCESS) {
        const items = response.menuCategories
        if (items.length > 0) {
          this.sharedStateService.setMenuCategories(items);
          this.fetchSuitabilities()
        } else {
          this.completion(false)
        }
      } else {
        this.completion(false)
      }
    } catch (error) {
      this.completion(false)
      console.log(error)
    }
  }

  /**
   * Fetches the users of the school
   */
  async fetchUsers() {
    const response: ApiUsersResponse = await this.apiService.fetchSchoolUsers()
    try {
      if (response.status === ApiService.RESPONSE_SUCCESS) {
        const items = response.users
        if (items.length > 0) {
          const customers:Customer[]=[]
          items.forEach((apiUser:User)=>{
            const customer: Customer = {
              id: apiUser.id,
              display_name: apiUser.displayName,
              first_name: apiUser.firstName,
              last_name: apiUser.lastName,
              class_id: apiUser.classId,
              class: "NA", // dynamic, will be populated later, if available
              last_seen: "01.01.1111|00:00", // TODO (where do we get this from?)
              available_balance: parseInt(apiUser.purseBalance) / 100.0, // TODO (where do we get this from?)
              subsidy: apiUser.subsidyBalance / 100.0, // TODO (where do we get this from?)
              card_id: apiUser.cardId,
              card_number: apiUser.serialNumber,
              school_id: apiUser.schoolId ? apiUser.schoolId : "noschool",
              type: apiUser.type,
              allergenIds: apiUser.allergenIds,
              maximumDeficit: apiUser.maximumDeficit ? apiUser.maximumDeficit / 100.0 : 0,
              avatar: apiUser.avatar,
              status: apiUser.status,
              isGuest: apiUser.isGuest
            };
            customers.push(customer);
          })
          this.sharedStateService.setSchoolUsers(customers);
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * Fetches all the operators for the school
   */
  async fetchOperators() {
    const response: ApiEposUsersResponse = await this.apiService.fetchEposUsers(this.sharedStateService.getSchoolId())
    if (response.status === ApiService.RESPONSE_UNAUTHENTICATED) {
      this.sharedStateService.deleteCustomer()
      this.completion(false)
    }
    try {
      if (response.status === ApiService.RESPONSE_SUCCESS) {
        this.sharedStateService.setOperators(response.eposUsers);
        this.fetchTodaysMenu();
      }else{
        this.completion(false)
      } 
    } catch (error) {
      this.completion(false)
    }
  }

  async fetchSuitabilities() {
    const org = this.sharedStateService.getOrg()
    const response: ApiFetchSuitabilityResponse = await this.apiService.fetchSuitabilities(org.id)

    if (response.status === ApiService.RESPONSE_UNAUTHENTICATED) {
      this.sharedStateService.deleteCustomer()
      this.completion(false)
    }

    try {
      if (response.status === ApiService.RESPONSE_SUCCESS) {
        const items = response.menuCategories
        if (items.length > 0) {
          this.sharedStateService.setSuitabilities(items)
          this.fetchEposLayoutsForSchool();
        } else {
          this.completion(false)
        }
      } else {
        this.completion(false)
      }
    } catch (error) {
      this.completion(false)
      console.log(error)
    }
  }

  /**
   * Fetches the menu items
   */
  async fetchMenuItems() {
    
    const response: ApiFetchMenuItemsResponse = await this.apiService.fetchMenuItems()

    if (response.status === ApiService.RESPONSE_UNAUTHENTICATED) {
      this.sharedStateService.deleteCustomer()
      this.completion(false)
    }

    try {
      if (response.status === ApiService.RESPONSE_SUCCESS) {
        const items = response.menuItems
        if (items.length > 0) {
          const modified:ApiMenu[]=[]
          items.forEach((item)=>{
            item.defaultPurseValue = item.defaultPurseValue / 100.0
            item.defaultSubsidyValue = item.defaultSubsidyValue / 100.0

            modified.push(item);
          })
          this.sharedStateService.setMenuItems(modified)
          this.completion(true)
        } else {
          this.completion(false)
        }
      } else {
        this.completion(false)
      }
    } catch (error) {
      this.completion(false)
      console.log(error)
    }
  }

  /**
   * Fetches the menu categories
   */
  async fetchMenuPromos() {
    const response: ApiFetchMenuPromotionsResponse = await this.apiService.fetchMenuPromotions(this.sharedStateService.getSchoolId())

    if (response.status === ApiService.RESPONSE_UNAUTHENTICATED) {
      this.sharedStateService.deleteCustomer()
    }

    try {
      if (response.status === ApiService.RESPONSE_SUCCESS) {
        const items = response.menuPromotions
        const modified:ApiMenuPromotion[]=[]
          items.forEach((item)=>{
            item.purseValue = item.purseValue / 100.0
            item.subsidyValue = item.subsidyValue / 100.0

            modified.push(item);
          })
        this.sharedStateService.setMenuPromos(modified)
      }
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * Fetches the Allergens
   */
   async fetchAllergens() {
    const {id} = this.sharedStateService.getOrg()
    const response: ApiFetchAllergensResponse = await this.apiService.fetchAllergens(id)

    if (response.status === ApiService.RESPONSE_UNAUTHENTICATED) {
      this.sharedStateService.deleteCustomer()
    }

    try {
      if (response.status === ApiService.RESPONSE_SUCCESS) {
        const items = response.allergens
        this.sharedStateService.setAllergens(items)
      }
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * Fetches the Product Ingredients
   */
  async fetchProductIngredients() {
    const {id} = this.sharedStateService.getOrg()
    const response: ApiFetchProductIngredientResponse = await this.apiService.fetchProductIngredients(id)

    if (response.status === ApiService.RESPONSE_UNAUTHENTICATED) {
      this.sharedStateService.deleteCustomer()
    }

    try {
      if (response.status === ApiService.RESPONSE_SUCCESS) {
        const items = response.ingredients
        this.sharedStateService.setProductIngredients(items)
      }
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * Fetches the Ingredients
   */
  async fetchIngredients() {
    const {id} = this.sharedStateService.getOrg()
    const response: ApiFetchIngredientResponse = await this.apiService.fetchIngredients(id)

    if (response.status === ApiService.RESPONSE_UNAUTHENTICATED) {
      this.sharedStateService.deleteCustomer()
    }

    try {
      if (response.status === ApiService.RESPONSE_SUCCESS) {
        const items = response.ingredients
        this.sharedStateService.setIngredients(items)
      }
    } catch (error) {
      console.log(error)
    }
  }

  fetchSupportingData=()=>{
    this.fetchAllergens()
    this.fetchMenuItems()
    this.fetchMenuPromos()

    this.fetchProductIngredients()
    this.fetchIngredients()
  }

   /**
   * Fetches all EPOS layouts for the school
   * 
   * @returns
   */
    async fetchEposLayoutsForSchool() {
      const response: ApiEposLayoutsResponse = await this.apiService.fetchEposLayouts(this.sharedStateService.getSchoolId())
  
      if (response.status === ApiService.RESPONSE_UNAUTHENTICATED) {
        this.sharedStateService.deleteCustomer()
        this.completion(false)
      }
  
      try {
        if (response.status === ApiService.RESPONSE_SUCCESS) {
          const items = response.eposLayouts
          //Layout may not be available
          if(items.length>0){
            this.sharedStateService.setEposLayouts(items);
          }
          this.fetchSupportingData()
        }else{
          this.fetchSupportingData()
        }
      } catch (error) {
        console.log(error)
        this.completion(false)
      }
    }
}

export default DataSyncService
