import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import React from 'react'
// import PubSub from 'pubsub-js'
// import AccountCircleIcon from '@mui/icons-material/AccountCircle'

import { ReactJSXElement } from '@emotion/react/types/jsx-namespace'
import { StyledDialogTitle } from '../../components/Dialog/StyledDialogTitle'
import { StyledDialogText } from '../../components/Dialog/DialogText'
import { StyledDialogCancelButton, StyledDialogOKButton } from '../../components/Dialog/DialogButton'

type DialogSystemDownState = {
  // [x:string]:any,
  showDialog: boolean
}

type DialogSystemDownProps = {
  showDialog: boolean
  onSave: Function
  onClose: Function
}

class DialogSystemDown extends React.Component<
  DialogSystemDownProps,
  DialogSystemDownState
> {
  onSaveFunction: any

  constructor(props) {
    super(props)

    this.state = {
      showDialog: props.showDialog,
    }

    this.onSaveFunction = props.onSave
  }

  componentDidMount(): void {}

  componentWillUnmount(): void {}

  componentWillReceiveProps(nextProps): void {
    this.setState({ showDialog: nextProps.showDialog })
  }

  show(): void {
    this.setState({
      showDialog: true,
    })
  }

  close(): void {
    this.setState({
      showDialog: false,
    })
    this.props.onClose();
  }

  save(): void {
    this.onSaveFunction()
    this.close()
  }

  render(): ReactJSXElement {
    return (
      <>
        <Dialog
          id="DialogSupport"
          open={this.state.showDialog}
          keepMounted
          // onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          style={{
            // position: 'absolute',
            left: 'calc(50% - 300px)',
            width: '600px',
            fontFamily: 'Montserrat',
          }}
        >
          <StyledDialogTitle>
            OFFLINE MODE
          </StyledDialogTitle>
          <DialogContent className="DialogContentText">
            <StyledDialogText>
              Save the transaction to be completed when the system is back
              online
            </StyledDialogText>
          </DialogContent>
          <DialogActions
            style={{ justifyContent: 'center', margin: '0px 0px 20px 0px' }}
          >
            <StyledDialogCancelButton
              onClick={() => this.close()}
            >
              Cancel
            </StyledDialogCancelButton>

            <StyledDialogOKButton
              onClick={() => this.save()}
            >
              Confirm
            </StyledDialogOKButton>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

export default DialogSystemDown
