import React from 'react'
import './ScreensaverPage.css'
// import Lottie from 'react-lottie-player'
// import lottieJson from './card-scan-lottie.json'
// import LunchClubLogo from '../../components/LunchClubLogo/LunchClubLogo'
// import SchoolFoodLogo from '../../components/SchoolFoodLogo/SchoolFoodLogo'
// import Layout from '../../components/Layout/Layout'
import PageService from '../../services/PageService'
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace'
import ApiService from '../../services/ApiService'
import SharedStateService from '../../services/SharedStateService'
// import { Box } from '@mui/material'
import Screensaver from '../../components/Screensaver/Screensaver'
// import { appFonts } from '../../utils/ThemeManager'

type ScreensaverPageState = {}

type ScreensaverPageProps = {}

class ScreensaverPage extends React.Component<
  ScreensaverPageProps,
  ScreensaverPageState
> {
  private barcodeReaderInterval: any
  private apiService: ApiService
  private pageService: PageService
  private sharedStateService: SharedStateService

  constructor(props) {
    super(props)

    this.state = {
      error: {
        status: false,
        message: '',
      },
      processing: false,
      showDialogDailySummary: false,
    }

    this.apiService = new ApiService()
    this.pageService = new PageService()
    this.sharedStateService = new SharedStateService()
    this.barcodeReaderInterval = null
  }

  /**
   * Start keeping the barcode reader focused when the component is loaded
   */
  async componentDidMount(): Promise<void> {
    this.startAnimation()
  }

  /**
   * Clear the barcode reading interval when page unmounts
   */
  componentWillUnmount(): void {
    clearInterval(this.barcodeReaderInterval)
  }

  startAnimation() {}

  render(): ReactJSXElement {
    return (
      <div id="ScreensaverPage">
        <Screensaver/>
      </div>
    )
  }
}

export default ScreensaverPage
