import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import React from 'react'
import PubSub from 'pubsub-js'
import './DialogPayment.css'
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace'
import { StyledDialogTitle, StyledDialogTitleNormal } from '../../components/Dialog/StyledDialogTitle'
import { StyledDialogCancelButton, StyledDialogOKButton } from '../../components/Dialog/DialogButton'

type DialogPaymentState = {
  // [x:string]:any,
  showDialog?: boolean
}

interface DialogPaymentProps {
  showDialog: boolean
  onPay:()=>void
}

class DialogPayment extends React.Component<
  DialogPaymentProps,
  DialogPaymentState
> {
  constructor(props) {
    super(props)
    this.state = {
      showDialog: false,
    }
  }

  componentDidMount(): void {
    PubSub.subscribe('dialog-payment-show', (msg: string, data: any) => {
      this.show()
    })
    PubSub.subscribe(
      'dialog-payment-close',
      (msg: string, className: string) => {
        this.closeDialog()
      },
    )
  }

  componentWillUnmount(): void {

    PubSub.unsubscribe('dialog-payment-show')
    PubSub.unsubscribe(
      'dialog-payment-close'
    )
  }


  /**
   * Shows the dialog
   */
  show(): void {
    this.setState({
      showDialog: true,
    })
  }

  /**
   * Closes the dialog
   */
  closeDialog(): void {
    this.setState({
      showDialog: false,
    })
  }

  /**
   * Publishes event thet payment is clicked
   * and closes the dialog
   */
  payNow(): void {
    this.props.onPay()
    this.closeDialog()
  }

  /**
   * Renders the dialog
   * @returns
   */
  render(): ReactJSXElement {
    return (
      <>
        <Dialog
          id="DialogPayment"
          open={this.state.showDialog}
          keepMounted
          onClose={() => this.closeDialog()}
        >
          <StyledDialogTitleNormal>READY TO PAY?</StyledDialogTitleNormal>
          <DialogContent>
            <DialogContentText className="DialogContentText"></DialogContentText>
          </DialogContent>
          <DialogActions className="DialogActions">
            <StyledDialogCancelButton
              onClick={() => this.closeDialog()}
            >
              Cancel
            </StyledDialogCancelButton>
            <StyledDialogOKButton
              onClick={() => this.payNow()}
            >
              Charge
            </StyledDialogOKButton>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

export default DialogPayment
