import SharedStateService from "./SharedStateService";

export interface IImageProps{
    url:string | null
    loaded: boolean
    id: string
}

export const blankImage:IImageProps = {url:null, loaded:false, id:''}

class ImageManager{
    static _theinstance = null;

    static sharedManager() {
        if (ImageManager._theinstance == null) {
            ImageManager._theinstance = new ImageManager();
        }

        return this._theinstance;
    }
    
    private images:IImageProps[]
    private suitabilityImages:IImageProps[]
    private sharedStateService:SharedStateService;

    constructor() {
        this.sharedStateService = new SharedStateService();
        this.images = [];
        this.suitabilityImages = [];
    }

    getImages(){
        return this.images
    }

    getSuitabilityImages(){
        return this.suitabilityImages
    }

    preloadImages = () => {
        const menuItems = this.sharedStateService.getMenuItems();

        this.images = menuItems.map((item:ApiMenuItem, i)=>{
             // create an Image instance
             var img = new Image()
             // setting handler for load complete
             img.onload = () => this.handleImageLoad(i)
             // set the src attribute for it. After this line image loading will start
             img.src = item.imageUri
             // return object with indicate of image loading statement
             return {
                 url: item.imageUri,
                 loaded: false,
                 id: item.id
             }
        })

        this.preloadSuitabilityImages()
    }
    
   
    handleImageLoad = index => {
        let imgs = this.images
        imgs[index].loaded = true
        this.images = imgs;
    }


    preloadSuitabilityImages = () => {
        const suitabilities = this.sharedStateService.getSuitabilities();

        this.suitabilityImages = suitabilities.map((item:ApiSuitability, i)=>{
             // create an Image instance
             var img = new Image()
             // setting handler for load complete
             img.onload = () => this.handleSuitabilityImageLoad(i)
             // set the src attribute for it. After this line image loading will start
             img.src = item.iconUri
             // return object with indicate of image loading statement
             return {
                 url: item.iconUri,
                 loaded: false,
                 id: item.id
             }
        })
    }
    
   
    handleSuitabilityImageLoad = index => {
        let imgs = this.suitabilityImages
        imgs[index].loaded = true
        this.suitabilityImages = imgs;
    }
}

export default ImageManager;