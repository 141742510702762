import React from "react";
import PubSub from "pubsub-js";
import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import { Box, IconButton, MenuItem, Select, Typography } from "@mui/material";
import BootstrapField from "../../components/Custom/BootstrapField";
import SharedStateService from "../../services/SharedStateService";
import { getTime } from "../../utils/utils";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import NetworkStatus from "../../components/Custom/NetworkStatus";

interface PanelHeaderProps {
  student?: any;
  sittings?: any;
  onSittingChange?: (sittingId:string)=>void
}

type PanelHeaderState = {
  currentTime: any;
  sittingId: any;
  sittings: any;
};

class PanelHeader extends React.Component<PanelHeaderProps, PanelHeaderState> {
  sittingInterval: NodeJS.Timer;
  sharedStateService: SharedStateService;

  constructor(props) {
    super(props);
    this.sharedStateService = new SharedStateService();

    this.state = {
      currentTime: getTime(),
      sittings: props.sittings,
      sittingId: this.sharedStateService.getCurrentSittingId(),
    };
  }

  componentDidMount(): void {
    setInterval(()=>{
      this.setState({currentTime: getTime()})
    },1000)
  }

  componentWillUnmount(): void {}

  /**
   * Triggers the event for opening the dialog with info
   * for the currently logged in customer
   */
  onDialogCustomerInfoShow(): void {
    // const firstName = this.props.student ? this.props.student.first_name : 0;
    // const lastName = this.props.student ? this.props.student.last_name : 0;
    // const className = this.props.student ? this.props.student.class : 0;
    // const lastSeen = this.props.student ? this.props.student.last_seen : "";

    PubSub.publish("dialog-customer-info-show", this.props.student);
  }

  onChange = (value) => {
    //Invalidate the automatic timer if the Sititng is manually toggled
    // PubSub.publish("terminate_timer", {});
    // console.log("SELECT SITTING FROM HEADER ",value);
    
    this.setState({ ...this.state, sittingId: value });
    this.sharedStateService.setCurrentSittingId(value);
    this.props.onSittingChange(value);
  };

  render(): ReactJSXElement {
    // const firstName = this.props.student ? this.props.student.first_name : ''
    // const lastName = this.props.student ? this.props.student.last_name : ''
    const { sittingId, currentTime } = this.state;
    const todayMenu = this.sharedStateService.getTodayMenu();
    const menuAvailable = todayMenu && todayMenu.length > 0;
    return (
      <div id="PanelHeader" style={{}}>
        <div id="Header">
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            onClick={() => {
              this.onDialogCustomerInfoShow();
            }}
          >
            {this.props.student && (
              <Typography color={"white"} variant="h6">
                Customer:{" "}
                <Typography display="inline" color={"white"} fontSize={20}>
                  {this.props.student.display_name}
                </Typography>{" "}
                <Typography
                  display="inline"
                  fontWeight="700"
                  fontSize={20}
                  color={"white"}
                >
                  {"[Student]"}
                </Typography>{" "}
              </Typography>
            )}
          </Box>
          <Box sx={{ flexGrow: 1 }} />
          <Box display="flex" justifyContent="center" alignItems="center">
            <NetworkStatus />
            <Select
              id="sitting-select"
              disabled={!menuAvailable}
              value={sittingId}
              defaultValue=""
              onChange={(event) => {
                this.onChange(event.target.value);
              }}
              input={<BootstrapField />}
              sx={{ mx: 2 }}
            >
              {this.props.sittings.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </Select>
            <Typography color={"white"}>{currentTime}</Typography>
            <IconButton
              onClick={() => {
                PubSub.publish("toggle_help", true);
              }}
            >
              <HelpOutlineRoundedIcon sx={{ color:'white' }} />
            </IconButton>
          </Box>
        </div>
      </div>
    );
  }
}

export default PanelHeader;
