import { Box, Grid, styled, Typography } from '@mui/material'
import { ImageComponent } from '../../components/Custom/ImageComponent'
import useLongPress from '../../components/useLongPress'
import { MenuItemSelectionType } from '../../utils/utils'
// import { themeColor } from "../../utils/ThemeManager";

const StyledGridItem = styled(Grid)({
  width: 100,
  height: 100,
  margin: 4,
  position: 'relative',
  ':hover': {
    opacity: 0.9,
    cursor: 'pointer',
  },
})

interface IScreenProps {
  menu: ApiMenu
  menuCategories: ApiMenuCategory[]
  isOnline:boolean
  onFoodItemSelection:(item:any)=>void
  onShowFoodItemInfo:(item:ApiMenu)=>void
}

function FoodMenuItem({ menu, menuCategories, isOnline, onFoodItemSelection, onShowFoodItemInfo, }: IScreenProps) {
  const onLongPress = useLongPress();

  const menuCategory = () => {
    const allCategories: ApiMenuCategory[] = []
    menu.menuCategoryIds.forEach((categoryId) => {
      const category = menuCategories.filter((cat) => cat.id === categoryId)
      if (category.length > 0) {
        allCategories.push(category[0])
      }
    })

    return allCategories[0].defaultColour
  }

  const categoryColor = menuCategory()

  return (
    <StyledGridItem item>
      <Box
        boxShadow={4}
        borderRadius={2}
        sx={{
          backgroundColor: 'white',
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        onClick={() => {
          if(menu.rule){
            onFoodItemSelection({id:menu.menuItemId,item:menu, type:MenuItemSelectionType.promo})
          }else{
            onFoodItemSelection({id:menu.menuItemId,type:MenuItemSelectionType.normal})
          }
        }}
        {...onLongPress(()=>{onShowFoodItemInfo({id:menu.menuItemId})})}
      >
        <ImageComponent
          src={menu.menuItemImageUri} 
          alt={menu.menuItemName}
          width={100}
          height={'100%'}
        />
        <Box
          sx={{
            backgroundColor: categoryColor,
            borderBottomRightRadius: 8,
            borderBottomLeftRadius: 8,
          }}
          position="absolute"
          bottom={0}
          left={0}
          height={34}
          width="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
        >
          <Typography
            fontSize={10}
            textAlign="center"
            color={'white'}
            sx={{
              display: '-webkit-box',
              overflow: 'hidden',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 2,
            }}
          >
            {menu.menuItemShortName}
          </Typography>
        </Box>
      </Box>
    </StyledGridItem>
  )
}

export default FoodMenuItem
