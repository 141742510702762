import React from 'react'

class LunchClubLogo extends React.Component {
  render() {
    return (
      <img
        src="images/logos/Lunch-Club-Logo-White.svg"
        alt="Lunch Club Logo"
        style={{ height: '88px', margin: '40px 0px 40px 0px' }}
      />
    )
  }
}

export default LunchClubLogo
