import { Box, Grid, styled, Typography } from '@mui/material'
import { promoColor } from '../../utils/ThemeManager'
import { MenuItemSelectionType } from '../../utils/utils'
import { ImageComponent } from '../../components/Custom/ImageComponent'
// import { themeColor } from "../../utils/ThemeManager";

const StyledGridItem = styled(Grid)({
  width: 100,
  height: 100,
  margin: 4,
  position: 'relative',
  ':hover': {
    opacity: 0.9,
    cursor: 'pointer',
  },
})

interface IScreenProps {
  menu: ApiMenu
  onFoodItemSelection:(item:any)=>void
}

function FoodPromoItem({ menu, onFoodItemSelection }: IScreenProps) {
  const categoryColor = promoColor
  
  return (
    <StyledGridItem item>
      <Box
        boxShadow={4}
        borderRadius={2}
        sx={{
          backgroundColor: 'white',
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        onClick={() => {
          //To-DO check if it works
          onFoodItemSelection({id:menu.id, item:menu, type:MenuItemSelectionType.promo})
        }}
      >
        <ImageComponent
          src={menu.menuPromotionImageUri} 
          alt={menu.menuPromotionName}
          width={100}
          height={'100%'}
        />
        <Box
          sx={{
            backgroundColor: categoryColor,
            borderBottomRightRadius: 8,
            borderBottomLeftRadius: 8,
          }}
          position="absolute"
          bottom={0}
          left={0}
          height={34}
          width="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
        >
          <Typography
            fontSize={10}
            textAlign="center"
            color={'white'}
            sx={{
              display: '-webkit-box',
              overflow: 'hidden',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 2,
            }}
          >
            {menu.menuPromotionName}
          </Typography>
        </Box>
      </Box>
    </StyledGridItem>
  )
}

export default FoodPromoItem
