import {
  alpha,
  SvgIcon,
} from '@mui/material';

import DateObject from "react-date-object";
import SharedStateService from "../services/SharedStateService";

import { ReactComponent as Gluten } from '../assets/suitability/gluten.svg';
import { ReactComponent as Halal } from '../assets/suitability/halal.svg';
import { ReactComponent as Vegan } from '../assets/suitability/vegan.svg';
import { ReactComponent as Lactose } from '../assets/suitability/lactose.svg';
import { ReactComponent as Vegeterian } from '../assets/suitability/vegeterian.svg';

// Avatars
import { ReactComponent as Apple } from '../assets/avatars/apple.svg';
import { ReactComponent as Banana } from '../assets/avatars/banana.svg';
import { ReactComponent as Blueberry } from '../assets/avatars/blueberry.svg';
import { ReactComponent as Grape } from '../assets/avatars/grape.svg';
import { ReactComponent as Kiwi } from '../assets/avatars/kiwi.svg';
import { ReactComponent as Lemon } from '../assets/avatars/lemon.svg';
import { ReactComponent as Nectar } from '../assets/avatars/nectar.svg';
import { ReactComponent as Orange } from '../assets/avatars/orange.svg';
import { ReactComponent as Pear } from '../assets/avatars/pear.svg';
import { ReactComponent as Raspberry } from '../assets/avatars/raspberry.svg';
import { ReactComponent as Strawberry } from '../assets/avatars/strawberry.svg';
import { ReactComponent as Watermelon } from '../assets/avatars/watermelon.svg';
import { ReactComponent as Default } from '../assets/avatars/default.svg';

//Icons
import { ReactComponent as Student } from '../assets/icons/student.svg';
import { ReactComponent as Staff } from '../assets/icons/staff.svg';
import { ReactComponent as Wallet } from '../assets/icons/wallet.svg';

import { themeColor } from './ThemeManager';

export enum Avatars {
  apple = 'apple',
  banana = 'banana',
  blueberry = 'blueberry',
  grape = 'grape',
  kiwi = 'kiwi',
  lemon = 'lemon',
  nectar = 'nectar',
  orange = 'orange',
  pear = 'pear',
  raspberry = 'raspberry',
  strawberry = 'strawberry',
  watermelon = 'watermelon',
  default = 'default',
}

export enum Icons {
  student = 'student',
  staff = 'staff',
  wallet = 'wallet'
}

const sharedStateService = new SharedStateService()

export const suitabilityIcon = (iconName:string) => {
  switch (iconName) {
    case 'gluten free':
      return <SvgIcon component={Gluten} inheritViewBox />;
    case 'vegan':
      return <SvgIcon component={Vegan} inheritViewBox />;
    case 'halal':
      return <SvgIcon component={Halal} inheritViewBox />;
    case 'vegetarian':
      return <SvgIcon component={Vegeterian} inheritViewBox />;
    case 'lactose':
      return <SvgIcon component={Lactose} inheritViewBox />;
    default:
      break;
  }
};
// sx={{ fontSize, border: `${alpha(themeColor, 0.5)}1px solid`, borderRadius: 20 }}

export const avatarIcon = (avatar:string, fontSize:number, addShadow:boolean, border?:boolean) => {
  let style = {
    fontSize, filter: 'drop-shadow(0px 2px 2px rgb(0 0 0 / 0.15))', border: 'none', borderRadius: 0,
  };
  if(!addShadow){
    style = {
      fontSize, border: 'none', borderRadius: 0, filter: 'none'
    }
  }
  if (border) {
    style = { ...style, border: `${alpha(themeColor, 0.5)}1px solid`, borderRadius: fontSize / 2 };
  }
  switch (avatar) {
    case Avatars.apple:
      return <SvgIcon sx={style} component={Apple} inheritViewBox />;
    case Avatars.banana:
      return <SvgIcon sx={style} component={Banana} inheritViewBox />;
    case Avatars.blueberry:
      return <SvgIcon sx={style} component={Blueberry} inheritViewBox />;
    case Avatars.grape:
      return <SvgIcon sx={style} component={Grape} inheritViewBox />;
    case Avatars.kiwi:
      return <SvgIcon sx={style} component={Kiwi} inheritViewBox />;
    case Avatars.lemon:
      return <SvgIcon sx={style} component={Lemon} inheritViewBox />;
    case Avatars.nectar:
      return <SvgIcon sx={style} component={Nectar} inheritViewBox />;
    case Avatars.orange:
      return <SvgIcon sx={style} component={Orange} inheritViewBox />;
    case Avatars.pear:
      return <SvgIcon sx={style} component={Pear} inheritViewBox />;
    case Avatars.raspberry:
      return <SvgIcon sx={style} component={Raspberry} inheritViewBox />;
    case Avatars.strawberry:
      return <SvgIcon sx={style} component={Strawberry} inheritViewBox />;
    case Avatars.watermelon:
      return <SvgIcon sx={style} component={Watermelon} inheritViewBox />;
    default:
      return <SvgIcon sx={style} component={Default} inheritViewBox />;
  }
};


export const icon = (avatar:string, fontSize:number, border?:boolean) => {
  let style = {
    fontSize, border: 'none', borderRadius: 0,
  };
  if (border) {
    style = { ...style, border: `${alpha(themeColor, 0.5)}1px solid`, borderRadius: fontSize / 2 };
  }
  switch (avatar) {
    case Icons.staff:
      return <SvgIcon sx={style} component={Staff} inheritViewBox />;
    case Icons.student:
      return <SvgIcon sx={style} component={Student} inheritViewBox />;
    case Icons.wallet:
      return <SvgIcon sx={style} component={Wallet} inheritViewBox />;
    
  }
};

export enum MenuItemSelectionType {
  normal = 'NORMAL',
  promo = 'PROMO'
}

export enum OperationMode {
  local = 'local',
  online = 'online'
}

export const removeEmptyFields = (data:any) => {
    Object.keys(data).forEach((key) => {
        if (data[key] === '' || data[key] == null || data[key].length === 0) {
        delete data[key];
        }
    });
};

export const todayDate = () => {
    const date = new Date()
    date.setHours(0, 0, 0, 0)

    function padTo2Digits(num) {
      return num.toString().padStart(2, '0')
    }

    function formatDate(date) {
      return [
        date.getFullYear(),
        padTo2Digits(date.getMonth() + 1),
        padTo2Digits(date.getDate()),
      ].join('-')
    }

    return formatDate(date)
}

export const getTime = () =>   {
  return new Date().toTimeString().split(' ')[0].substring(0, 5)
}

export const formatDateTime = (dateString: string) => new DateObject(dateString)
  .format('DD/MM/YYYY HH:mm:ss');


export const determineCurrentSitting=(sittingId:string)=>{
  const sittings = sharedStateService.getSittings()
  if(sittings === null)return''
  if(sittings.length === 0)return''
  const time = getTime()
  let currentSittingId = sittingId ? sittingId : ''

  const allFinishTimes=[]
  let matchedSitting:ApiMenuSitting = null;
  const curTime = Date.parse(`1970/01/01 ${time}`)
  sittings.forEach(element => {
    if(matchedSitting === null){
      const startTime = Date.parse(`1970/01/01 ${element.startTime}`)
      const finishTime = Date.parse(`1970/01/01 ${element.finishTime}`)
      let diff = curTime-finishTime
      if(diff<0){
        diff = diff * -1
      }
      
      allFinishTimes.push(diff)
      if(curTime>=startTime && curTime<=finishTime){
        matchedSitting = element;
      }
    }
  });

  if(matchedSitting === null && sittings.length>0){
      //Find the closest finish time to pick closest sitting
      const minTime = Math.min(...allFinishTimes)
      const index = allFinishTimes.findIndex((time) => time  === minTime)
      matchedSitting = sittings[index]
  }

  if(matchedSitting.id !== currentSittingId){
      //If previous and current is not same, means there's change in time and sitting
      const newSittingId = matchedSitting.id
      currentSittingId=newSittingId
  }

  return currentSittingId
}


  