/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";

interface IScreenProps{
    page:string
}

function Navigate({page}:IScreenProps){

    const [curPage] = useState(page);
    const navigate = useNavigate();

    useEffect(()=>{
        if(curPage !== page){
            navigate(page)
        }
    },[page]);

    return null

}

export default Navigate;