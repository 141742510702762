// import * as React from 'react';
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import { appFonts } from '../../utils/ThemeManager';

const BootstrapField = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    // backgroundColor: theme.palette.primary.main,
    // border: '1px solid white',
    fontSize: 16,
    width: 'auto',
    padding: '10px 12px',
    color: 'white',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      appFonts.Montserrat, 'Open Sans',
    ].join(','),
    // '&:focus': {
    //   boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
    //   // borderColor: theme.palette.primary.main,
    // },
  },
  '& .MuiSvgIcon-root': {
    color: 'white',
  },
}));

export default BootstrapField;
