import {
  alpha, Box, BoxProps, SvgIcon,
} from '@mui/material';
import { ReactComponent as WhiteLogo } from '../../assets/whiteLogo.svg';
import { secondaryColor } from '../../utils/ThemeManager';

const emptyLogo = <SvgIcon sx={{ fontSize: 40, pl: 0.5 }} color="primary" component={WhiteLogo} inheritViewBox />;

type ImgProps = {
  alt?: string;
  src: string;
};

export function ImageComponent(props: BoxProps & ImgProps) {
  const comp = props.src ? <Box component="img" {...props} boxShadow={1} borderRadius={2} /> : <Box {...props} sx={{ backgroundColor: alpha(secondaryColor, 0.25) }} boxShadow={1} borderRadius={2}>{emptyLogo}</Box>;
  return comp;
}

export function ImageComponentWithShadow(props: BoxProps & ImgProps) {
  const comp = props.src ? <Box component="img" {...props}/> : <Box {...props} sx={{ backgroundColor: alpha(secondaryColor, 0.25) }}>{emptyLogo}</Box>;
  return comp;
}

ImageComponent.defaultProps = {
  alt: '',
};
