import {
  // Button,
  CircularProgress,
  Dialog,
  // DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material'
import React from 'react'
import PubSub from 'pubsub-js'
import './DialogPayment.css'
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace'
import {
  successColor,
  themeColor,
  // warningColor,
} from '../../utils/ThemeManager'
import TaskAltRoundedIcon from '@mui/icons-material/TaskAltRounded'
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded'
import { StyledDialogTitle } from '../../components/Dialog/StyledDialogTitle'

export enum OrderProcessType {
  void = 'VOID',
  init = 'INIT',
  success = 'SUCCESS',
  failed = 'FAILED',
}

export enum ProcessMode {
  order = 'ORDER',
  refund = 'REFUND'
}

interface OrderProcess {
  type: OrderProcessType
  status: string
  processMode: ProcessMode
}

type DialogState = {
  // [x:string]:any,
  showDialog?: boolean
  orderProcess: OrderProcess
}

class DialogOrdering extends React.Component<{}, DialogState> {
  constructor(props) {
    super(props)
    this.state = {
      showDialog: false,
      orderProcess: {
        status: '',
        type: OrderProcessType.void,
        processMode: ProcessMode.order,
      },
    }
  }

  componentDidMount(): void {
    PubSub.subscribe('order-processes', (msg: string, data: OrderProcess) => {
      if (data.type === OrderProcessType.void) {
        this.closeDialog()
        setTimeout(() => {
          this.setState({ orderProcess: data })
        }, 50)
      } else {
        this.setState({ orderProcess: data })
        this.show()
      }
    })
  }

  componentWillUnmount(): void {
    PubSub.unsubscribe('order-processes');
  }

  /**
   * Shows the dialog
   */
  show(): void {
    this.setState({
      showDialog: true,
    })
  }

  /**
   * Closes the dialog
   */
  closeDialog(): void {
    this.setState({
      showDialog: false,
    })
  }

  /**
   * Renders the dialog
   * @returns
   */
  render(): ReactJSXElement {
    const { orderProcess } = this.state
    const color =
      orderProcess.type === OrderProcessType.init
        ? themeColor
        : orderProcess.type === OrderProcessType.success
          ? successColor
          : themeColor
    return (
      <>
        <Dialog
          id="DialogOrdering"
          open={this.state.showDialog}
          keepMounted
          onClose={() => this.closeDialog()}
        >
          <StyledDialogTitle
            sx={{ display: 'flex', justifyContent: 'center' }}
          >
            {orderProcess.processMode === ProcessMode.order ? 'ORDER' : 'REFUND'}
          </StyledDialogTitle>
          <DialogContent>
            <DialogContentText className="DialogContentText">
              <Grid container>
                <Grid item xs={12} display="flex" justifyContent="center">
                  {orderProcess.type === OrderProcessType.init ? (
                    <CircularProgress sx={{ color: themeColor }} />
                  ) : orderProcess.type === OrderProcessType.success ? (
                    <TaskAltRoundedIcon
                      sx={{ color: successColor, fontSize: 40 }}
                    />
                  ) : orderProcess.type === OrderProcessType.failed ? (
                    <HighlightOffRoundedIcon
                      sx={{ color: themeColor, fontSize: 40 }}
                    />
                  ) : null}
                </Grid>
                <Grid item xs={12} display="flex" justifyContent="center">
                  <Typography sx={{ color }}>{orderProcess.status}</Typography>
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </>
    )
  }
}

export default DialogOrdering
