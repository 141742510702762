import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import PubSub from "pubsub-js";
import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import { themeColor } from "../../utils/ThemeManager";
import SharedStateService from "../../services/SharedStateService";
import { StyledDialogCancelButton, StyledDialogOKButton } from "../Dialog/DialogButton";
import { StyledDialogTitle } from "../Dialog/StyledDialogTitle";

export enum DialogScreenType {
  managerScan = "managerScan",
  operatorLogin = "operatorLogin",
  customerScan = "customerScan",
  kiosk = "kiosk",
}

interface DialogStats {
  title: string;
  value: string;
}

type DialogSupportState = {
  showDialog: boolean;
  stats: DialogStats[];
};

type DialogSupportProps = {
  showDialog: boolean;
  screenType: DialogScreenType;
};

class DialogSupport extends React.Component<
  DialogSupportProps,
  DialogSupportState
> {
  private school_name: string;
  private till_id: string;
  private till_operator_name: string;
  sharedStateService: SharedStateService;
  constructor(props) {
    super(props);
    this.sharedStateService = new SharedStateService();
    this.state = {
      showDialog: props.showDialog,
      stats: [],
    };
    this.school_name = "";
    this.till_id = "";
    this.till_operator_name = "";
  }

  getStats = () => {
    const stats: DialogStats[] = [];
    const { computerName, macAddress, ipAddress, ipAddressList } =
      this.sharedStateService.getSystemInfo();
    const version = this.sharedStateService.version();
    if (this.props.screenType === DialogScreenType.managerScan) {
      stats.push({ title: "Till Name", value: computerName });
    } else if (this.props.screenType === DialogScreenType.operatorLogin) {
      stats.push({ title: "Till ID", value: this.till_id });
      if (this.sharedStateService.getEpos()) {
        stats.push({ title: "Till Name", value: computerName });
      }
    } else if (this.props.screenType === DialogScreenType.kiosk) {
      stats.push({ title: "Till ID", value: this.till_id });
      if (this.sharedStateService.getEpos()) {
        stats.push({ title: "Till Name", value: computerName });
      }
      stats.push({ title: "Operator", value: this.till_operator_name });
    }

    stats.push({
      title: "Screen Size",
      value: `${window.innerWidth} x ${window.innerHeight}`,
    });
    stats.push({ title: "MAC Address", value: macAddress });
    stats.push({ title: "IP Address", value: ipAddress });
    stats.push({ title: "IP List", value: (ipAddressList ?? []).join(", ") });
    stats.push({ title: "Version", value: `v${version}` });
    this.setState({ stats });
  };

  processShowDialog(){
    if(this.state.showDialog)return;
    const schoolName = this.sharedStateService.getSchool()
        ? this.sharedStateService.getSchool().name
        : "";
      const eposId = this.sharedStateService.getEposId();
      const operator = this.sharedStateService.getOperator();
      const operatorName = operator ? operator.name : "NA";
      if (schoolName) {
        this.school_name = schoolName;
      }
      if (eposId) {
        this.till_id = eposId;
      }

      this.till_operator_name = operatorName;
      this.getStats();
      this.show();
  }

  componentDidMount(): void {
    PubSub.subscribe("dialog-support-show", (msg, data) => {
      this.processShowDialog();
    });
  }

  componentWillUnmount(): void {
    PubSub.unsubscribe("dialog-support-show");
  }

  show(): void {
    console.log("SHOW CALLED");
    this.setState({
      showDialog: true,
    });
  }

  close(): void {
    this.setState({
      showDialog: false,
    });
  }

  render(): ReactJSXElement {
    return (
      <>
        <Dialog
          id="DialogSupport"
          open={this.state.showDialog}
          // TransitionComponent={Transition}
          keepMounted
          // onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          style={{
            position: "absolute",
            left: "calc(50% - 450px)",
            width: "900px",
            fontFamily: "Montserrat",
          }}
        >
          <StyledDialogTitle>
            Support
          </StyledDialogTitle>
          <DialogContent>
            <Grid container spacing={2} display="flex">
              {this.school_name !== "" ? (
                <Grid item xs={12}>
                  <Typography variant="h6" fontSize={16}>
                    {this.school_name}
                  </Typography>
                  <Divider />
                </Grid>
              ) : null}
              {this.state.stats.map((stat) => {
                return (
                  <Grid
                    item
                    xs={12}
                    display="flex"
                    flexDirection="row"
                    key={stat.title}
                  >
                    <Box
                      color={themeColor}
                      fontWeight="bold"
                      display="relative"
                    >
                      {stat.title}{" "}
                      <Typography display="inline">{stat.value}</Typography>
                    </Box>
                  </Grid>
                );
              })}
              {this.props.screenType === DialogScreenType.managerScan ? (
                <Grid item xs={12}>
                  <StyledDialogOKButton
                    onClick={() => {
                      this.sharedStateService.clearAll();
                      window.location.reload();
                    }}
                  >
                    Clear Cache
                  </StyledDialogOKButton>
                  <Typography fontSize={12} sx={{ opacity: 0.5, mt: 1 }}>
                    {" "}
                    *Use only when required{" "}
                  </Typography>
                </Grid>
              ) : null}
            </Grid>
          </DialogContent>
          <DialogActions
            style={{ justifyContent: "center", margin: "0px 0px 20px 0px" }}
          >
            <StyledDialogOKButton
              onClick={() => this.close()}
            >
              Close
            </StyledDialogOKButton>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export default DialogSupport;
