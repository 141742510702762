import { Box, Grid, styled, Typography } from '@mui/material'
import { ImageComponent } from '../../components/Custom/ImageComponent'
import SharedStateService from '../../services/SharedStateService'
import { MenuItemSelectionType } from '../../utils/utils'
import useLongPress from '../../components/useLongPress'
// import { themeColor } from "../../utils/ThemeManager";

const StyledGridItem = styled(Grid)({
  width: 100,
  height: 100,
  margin: 4,
  position: 'relative',
  ':hover': {
    opacity: 0.9,
    cursor: 'pointer',
  },
})

interface IScreenProps {
  menuItem: ApiMenuItem
  menuCategories: ApiMenuCategory[]
  isOnline:boolean
  sittingId: string
  rule:ApiRule
  onFoodItemSelection:(item:any)=>void
  onShowFoodItemInfo:(item:ApiMenuItem)=>void
}

function PromoFoodMenuItem({ menuItem, menuCategories,rule, isOnline, sittingId, onFoodItemSelection,onShowFoodItemInfo }: IScreenProps) {
  const sharedStateService = new SharedStateService()

  // const menuItem = sharedStateService.getMenuItems().filter((item)=>item.id === menu.menuItemId)[0]

  const onLongPress = useLongPress();

  const menuCategory = () => {
    const allCategories: ApiMenuCategory[] = []
    menuItem.menuCategoryIds.forEach((categoryId) => {
      const category = menuCategories.filter((cat) => cat.id === categoryId)
      if (category.length > 0) {
        allCategories.push(category[0])
      }
    })

    return allCategories[0].defaultColour
  }

  const itemAvaiableToday = () => {
    const todayMenu = sharedStateService.getTodayMenu()
    const sittingMenuItems = todayMenu.filter((menu)=>menu.menuSittingId === sittingId);

    if(sittingMenuItems.length === 0) return false
    
    let available = true
    
    const filterItems = sittingMenuItems.filter(
      (item) => item.menuItemId === menuItem.id,
    )
    available = filterItems.length > 0

    return available
  }

  const categoryColor = menuCategory()
  const available = itemAvaiableToday()
  return (
    <StyledGridItem item>
      <Box
        boxShadow={4}
        borderRadius={2}
        sx={{
          backgroundColor: 'white',
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          opacity: available ? 1 : 0.3,
        }}
        onClick={() => {
          if(available){
            onFoodItemSelection({id:menuItem.id, item:menuItem, type:MenuItemSelectionType.promo})
          }
        }}
        {...onLongPress(()=>{onShowFoodItemInfo(menuItem)})}
      >
        <ImageComponent
          src={menuItem.imageUri} 
          alt={menuItem.shortName}
          width={100}
          height={100}
        />
        <Box
          sx={{
            backgroundColor: categoryColor,
            borderBottomRightRadius: 8,
            borderBottomLeftRadius: 8,
          }}
          position="absolute"
          bottom={0}
          left={0}
          height={34}
          width="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
        >
          <Typography
            fontSize={10}
            textAlign="center"
            color={'white'}
            sx={{
              display: '-webkit-box',
              overflow: 'hidden',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 2,
            }}
          >
            {menuItem.shortName}
          </Typography>
        </Box>
      </Box>
    </StyledGridItem>
  )
}

export default PromoFoodMenuItem
