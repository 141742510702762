import React from 'react';
import number_format from 'locutus/php/strings/number_format';
// import { BsCreditCard } from 'react-icons/bs'
import {
  Box,
  Button,
  Checkbox,
  // FormControl,
  Grid,
  // MenuItem,
  // Select,
  Stack,
  Typography,
} from '@mui/material';
import PubSub from 'pubsub-js';
import DialogInsufficientFunds from './DialogInsufficientFunds';
import DialogInsufficientSubsidy from './DialogInsufficientSubsidy';
import DialogPayment from './DialogPayment';
import DialogRefund from './DialogRefund';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import SharedStateService from '../../services/SharedStateService';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { themeColor } from '../../utils/ThemeManager';
import DialogAllergens from './DialogAllergens';
import DialogTopup from './DialogTopup';
// import EnvService from '../../services/EnvService'

interface PanelCurrentSaleProps {
  cart?: Cart;
  refundItems?: TransactionItem[];
  refundCart?: Cart;
  subsidy?: any;
  purse?: any;
  onPayment?: Function;
  onRefund?: Function;
  salesMode?: string;
  onAddSubsidyToPurse: (selectedMenuItem: ApiMenu) => void;
  onFoodItemRemove: (itemIndex: number) => void;
}

type PanelCurrentSaleState = {
  cart: Cart;
  showPaymentDialog: boolean;
  showRefundDialog: boolean;
  showInsufficientFundsDialog: boolean;
  showInsufficientSubsidyDialog: boolean;
  org: ApiOrgs;
  selectedRefundItems: string[];
  windowHeight: number;
};

class PanelCurrentSale extends React.Component<
  PanelCurrentSaleProps,
  PanelCurrentSaleState
> {
  // private scrollParentRef: React.RefObject<HTMLTableCellElement>;
  // private scrollRef: React.RefObject<HTMLDivElement>;
  // private showPaymentDialog: boolean;
  sharedStateService: SharedStateService;

  // cart: Cart;

  constructor(props) {
    super(props);
    this.sharedStateService = new SharedStateService();

    // this.cart = this.sharedStateService.getCart();
    // this.scrollParentRef = React.createRef();
    // this.scrollRef = React.createRef();
    // this.showPaymentDialog = false;

    this.state = {
      cart: this.sharedStateService.getCart(),
      showPaymentDialog: true,
      showRefundDialog: false,
      showInsufficientFundsDialog: false,
      showInsufficientSubsidyDialog: false,
      org: this.sharedStateService.getOrg(),
      selectedRefundItems: [],
      windowHeight: window.innerHeight,
    };
    this.payNow = this.payNow.bind(this);
    this.refundNow = this.refundNow.bind(this);
    this.refundDialogClose = this.refundDialogClose.bind(this);
    // this.payLater = this.payLater.bind(this)
    this.paymentDialogOpen = this.paymentDialogOpen.bind(this);
    this.onItemRemove = this.onItemRemove.bind(this);
    this.onSalesChange = this.onSalesChange.bind(this);
  }

  handleResize = () => {
    this.setState({ windowHeight: window.innerHeight });
  };

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);

    PubSub.subscribe('cart_updated', (msg: string, className: string) => {
      // const cart = this.sharedStateService.getCart();
      this.setState({
        cart: this.sharedStateService.getCart(),
      });
    });

    PubSub.subscribe(
      'dialog-refund-refund-now',
      (msg: string, className: string) => {
        this.refundNow();
      }
    );

    PubSub.subscribe('refund_initiated', (msg: string, className: string) => {
      this.onSalesChange('refund_sale');
    });

    PubSub.subscribe('refund_cancelled', (msg: string, className: string) => {
      this.onSalesChange('current_sale');
      this.setState({ selectedRefundItems: [] });
    });

    /*setTimeout(() => {
      const parentHeight = this.scrollParentRef.current.offsetHeight
      const scrollHeight = parentHeight - 60
      //this.scrollRef.current.style.height = windowHeight - 200 + 'px'
      this.scrollRef.current.style.height = scrollHeight + 'px'
    }, 1000)*/
    //setTimeout(() => this.payNow(), 1000);
  }

  componentWillUnmount() {
    // DEBUG: console.log("Staff. PanelCurrentSale did unmount");

    PubSub.unsubscribe('cart_updated');

    PubSub.unsubscribe('dialog-refund-refund-now');

    PubSub.unsubscribe('refund_initiated');

    PubSub.unsubscribe('refund_cancelled');
  }

  paymentDialogOpen() {
    // const cart = this.props.cart;
    if (this.state.cart?.purseAfter < 0 && this.state.cart.items.length > 0) {
      PubSub.publish('dialog-insufficient-funds-show', {});
      return;
    }
    // if (EnvService.isLocal()) {
    //   // Only leave confirmation while developing to not incidentally click
    //   PubSub.publish('dialog-payment-show', {})
    // } else {

    // On live it must charge straight away, as confirmation takes time
    this.payNow();

    // }
  }

  /**
   * Closes the payment dialog and triggers the payment
   */
  payNow() {
    // PubSub.publish("dialog-payment-close", {});
    this.props.onPayment();
  }

  /**
   * Closes the refund dialog and triggers the refund
   */
  refundNow() {
    PubSub.publish('dialog-refund-close', {});
    this.props.onRefund();
  }

  refundDialogClose() {
    PubSub.publish('dialog-refund-close', {});
  }

  refundDialogOpen() {
    PubSub.publish('dialog-refund-show', {});
  }

  onItemRemove(itemIndex) {
    this.props.onFoodItemRemove(itemIndex);
  }

  onRefundItemToggle(item: TransactionItem, action: string, quantity: number) {
    PubSub.publish('refund_item_toggled', { item, action, quantity });
  }

  onRefundPromoItemToggle(
    item: PromotionItem,
    action: string,
    quantity: number
  ) {
    PubSub.publish('refund_promoitem_toggled', { item, action, quantity });
  }

  onSalesChange(newSalesMode) {
    /*if(this.props.salesMode === 'current_sale' && newSalesMode === 'refund_sale'){
      PubSub.publish('refund_initiated',null)
    }else if(this.props.salesMode === 'refund_sale' && newSalesMode === 'current_sale'){
      PubSub.publish('refund_cancelled')
    }*/

    PubSub.publish('sale_mode_changed', newSalesMode);
  }

  manageSelection = (index: string) => {
    const { selectedRefundItems } = this.state;
    const selectedIndex = selectedRefundItems.indexOf(index);
    let newSelected: string[] = [];
    let addNew = false;
    if (selectedIndex === -1) {
      // If new
      newSelected = newSelected.concat(selectedRefundItems, index);
      addNew = true;
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedRefundItems.slice(1));
    } else if (selectedIndex === selectedRefundItems.length - 1) {
      newSelected = newSelected.concat(selectedRefundItems.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedRefundItems.slice(0, selectedIndex),
        selectedRefundItems.slice(selectedIndex + 1)
      );
    }

    this.setState({ selectedRefundItems: newSelected });

    const refundItems = this.props.refundItems;
    const cartRefundItems = this.props.refundCart.items;

    const filtered = refundItems.filter(
      (item) => parseInt(index) === item.index
    );
    const item = filtered[0];

    //Check if we already have the same item count more than one, means to update
    const filteredCartItems = cartRefundItems.filter(
      (cartItem) => cartItem.menu_item_id === item.id
    );
    let toUpdate = filteredCartItems.length > 0;

    //If the item exist in Cart use the previous quantity of the item from cart
    let quantity = toUpdate
      ? filteredCartItems[0].quantity
      : parseInt(item.quantity);
    if (toUpdate) {
      //If same item and adding new then quantity will increase, else decrease
      if (addNew) {
        quantity += 1;
      } else {
        if (quantity > 1) {
          quantity -= 1;
        } else {
          toUpdate = false;
        }
      }
    }

    const action = toUpdate ? 'update' : addNew ? 'add' : 'remove';
    this.onRefundItemToggle(item, action, quantity);
  };

  managePromoItemSelection = (index: string) => {
    const { selectedRefundItems } = this.state;
    const selectedIndex = selectedRefundItems.indexOf(index);
    let newSelected: string[] = [];
    let addNew = false;
    if (selectedIndex === -1) {
      // If new
      newSelected = newSelected.concat(selectedRefundItems, index);
      addNew = true;
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedRefundItems.slice(1));
    } else if (selectedIndex === selectedRefundItems.length - 1) {
      newSelected = newSelected.concat(selectedRefundItems.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedRefundItems.slice(0, selectedIndex),
        selectedRefundItems.slice(selectedIndex + 1)
      );
    }

    this.setState({ selectedRefundItems: newSelected });

    const refundItems = this.props.refundItems;
    const cartRefundItems = this.props.refundCart.items;

    const filtered = refundItems.map(
      (promoItem) =>
        promoItem.menuPromotionItems.filter(
          (item) => parseInt(index) === item.index
        )[0]
    );
    const item = filtered[0];

    //Check if we already have the same item count more than one, means to update
    const filteredCartItems = cartRefundItems.filter(
      (cartItem) => cartItem.menu_item_id === item.id
    );
    let toUpdate = filteredCartItems.length > 0;

    //If the item exist in Cart use the previous quantity of the item from cart
    let quantity = toUpdate
      ? filteredCartItems[0].quantity
      : parseInt(item.quantity);
    if (toUpdate) {
      //If same item and adding new then quantity will increase, else decrease
      if (addNew) {
        quantity += 1;
      } else {
        if (quantity > 1) {
          quantity -= 1;
        } else {
          toUpdate = false;
        }
      }
    }

    const action = toUpdate ? 'update' : addNew ? 'add' : 'remove';
    this.onRefundPromoItemToggle(item, action, quantity);
  };

  isSelected = (id: string) => {
    const indexOf = this.state.selectedRefundItems.indexOf(id);
    return indexOf !== -1;
  };

  handleClick = (event: React.MouseEvent<unknown>, index: string) => {
    this.manageSelection(index);
  };

  //   useMemo(()=>{
  //     doSomething() //Doesn't want until render is completed
  // }, [dep1, dep2]);

  render(): ReactJSXElement {
    // const [cart, setCart] = useState(0);
    const { org, windowHeight } = this.state;
    const supportedCurrencies = org.supportedCurrencies[0];
    // const cart = this.props.cart;
    const refundItems = this.props.refundItems;
    const refundCart = this.props.refundCart;
    // const subsidy = this.props.subsidy
    // const purse = this.props.purse
    const salesMode = this.props.salesMode;
    // const handleChange = (e) => {
    //   if (e.target.value === "refund_sale") {
    //     PubSub.publish("refund_initiated");
    //   } else {
    //     PubSub.publish("refund_cancelled");
    //   }
    // };

    const borderRightCss = '#F6F4F5 2px solid';

    const purseAfter =
      salesMode === 'current_sale'
        ? this.state.cart?.purseAfter
        : refundCart?.purseAfter;
    const subsidyAfter =
      salesMode === 'current_sale'
        ? this.state.cart?.subsidyAfter
        : refundCart?.subsidyAfter;

    const height = windowHeight - 321; //Deducting extra

    return (
      <>
        <Box>
          <Box
            position="absolute"
            zIndex={99}
            top={0}
            left={0}
            width={250}
            height={45}
            sx={{
              boxShadow:
                '0px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 0px rgba(0, 0, 0, 0.12)',
            }}
          >
            <Box
              sx={{
                background: '#ffffff',
                fontFamily: 'Montserrat',
                fontSize: 15,
                fontWeight: 'bold',
                textTransform: 'uppercase',
                color: '#333333',
                padding: '0px 10px',
                height: '43px',
                textAlign: 'left',
              }}
              alignItems="center"
              alignContent="center"
              display="flex"
              zIndex={100}
              borderBottom={`${themeColor} 2px solid`}
            >
              <Typography fontSize={18} fontWeight="bold">
                {salesMode !== 'refund_sale' ? 'Current Sale' : 'Refund Sale'}
              </Typography>
            </Box>
          </Box>
          <Box
            position="absolute"
            top={45}
            left={0}
            zIndex={98}
            width={250}
            height={height}
            borderRight={borderRightCss}
          >
            <Box
              sx={{
                background: '#ffffff',
                boxShadow:
                  '0px 0px 0px -2px rgba(0, 0, 0, 0.2), 0px 0px 2px rgba(0, 0, 0, 0.14), 0px 0px 5px rgba(0, 0, 0, 0.12)',
                fontFamily: 'Montserrat',
                fontSize: 15,
                fontWeight: 'bold',
                textTransform: 'uppercase',
                color: '#333333',
                verticalAlign: 'top',
              }}
            >
              <div
                // ref={this.scrollRef}
                style={{
                  height: `${height}px`,
                  overflow: 'hidden',
                  overflowY: 'auto',
                  lineHeight: 1.8,
                }}
              >
                {salesMode === 'refund_sale' && (
                  <>
                    {/* START: Refund Cart Items */}
                    {refundItems.map((item, index) => (
                      <Box
                        key={`${index}-${item.id}`}
                        margin="10px 0px 10px 0px"
                        px={1}
                        sx={{
                          backgroundColor:
                            item.type === 'menuPromotion'
                              ? '#fffff'
                              : index % 2
                              ? '#F8F8F8'
                              : '#fffff',
                        }}
                      >
                        {item.type === 'menuItem' ? (
                          <Grid
                            container
                            onClick={(event) => {
                              // this.handleClick(event, `${item.index}`);
                              this.manageSelection(`${item.index}`);
                            }}
                          >
                            <Grid
                              item
                              xs={10}
                              position="relative"
                              textAlign="left"
                            >
                              <Box
                                display="flex"
                                justifyContent="space-between"
                              >
                                <Box justifySelf="flex-start">
                                  <Typography
                                    fontSize={13}
                                    textAlign="left"
                                    sx={{
                                      display: 'inline',
                                      textTransform: 'capitalize',
                                    }}
                                  >
                                    {item.name}
                                  </Typography>
                                  <Typography fontSize={10} textAlign="left">
                                    {`QTY - ${item.quantity}`}
                                  </Typography>
                                </Box>
                                <Box
                                  justifySelf="flex-end"
                                  alignItems="center"
                                  justifyContent="center"
                                  display="flex"
                                >
                                  {/* <Typography sx={{display:'inline', mx:0.5}} fontSize={10} fontWeight="bold">x</Typography>
                                  <Typography sx={{display:'inline', mr:0.5}} fontSize={13} fontWeight="bold">{item.quantity}</Typography> */}
                                  <Box sx={{ display: 'inline' }} fontSize={12}>
                                    {supportedCurrencies.symbol}
                                    {number_format(item.purseValue / 100.0, 2)}
                                  </Box>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid
                              item
                              xs={2}
                              position="relative"
                              textAlign="right"
                              display="flex"
                            >
                              <Checkbox
                                sx={{
                                  color: '#417b5a',
                                  '&.Mui-checked': {
                                    color: '#417b5a',
                                  },
                                }}
                                checked={this.isSelected(`${item.index}`)}
                              />
                            </Grid>
                          </Grid>
                        ) : (
                          <Grid container>
                            <Grid item xs={12}>
                              <Typography
                                fontSize={13}
                                sx={{
                                  textTransform: 'capitalize',
                                  fontWeight: 'bold',
                                }}
                                textAlign="left"
                              >
                                {item.name}
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              {item.menuPromotionItems?.map(
                                (promotion, index) => (
                                  <Grid
                                    container
                                    key={`${index}-${promotion.id}`}
                                    onClick={(event) => {
                                      this.managePromoItemSelection(
                                        `${promotion.index}`
                                      );
                                    }}
                                    sx={{
                                      backgroundColor:
                                        index % 2 ? '#F8F8F8' : '#fffff',
                                    }}
                                  >
                                    <Grid
                                      item
                                      xs={10}
                                      position="relative"
                                      textAlign="left"
                                    >
                                      <Box
                                        display="flex"
                                        ml={1}
                                        mr={1}
                                        justifyContent="space-between"
                                      >
                                        <Box justifySelf="flex-start">
                                          <Typography
                                            fontSize={11}
                                            textAlign="left"
                                            sx={{
                                              display: 'inline',
                                              textTransform: 'capitalize',
                                            }}
                                          >
                                            {promotion.name}
                                          </Typography>
                                          <Typography
                                            fontSize={10}
                                            textAlign="left"
                                          >
                                            {`QTY - ${promotion.quantity}`}
                                          </Typography>
                                        </Box>
                                        <Box
                                          justifySelf="flex-end"
                                          alignItems="center"
                                          justifyContent="center"
                                          display="flex"
                                        >
                                          {/* <Typography sx={{display:'inline', mx:0.5}} fontSize={11} fontWeight="bold">x</Typography>
                                          <Typography sx={{display:'inline', mr:0.5}} fontSize={11} fontWeight="bold">{promotion.quantity}</Typography> */}
                                          <Box
                                            sx={{ display: 'inline' }}
                                            fontSize={12}
                                          >
                                            {supportedCurrencies.symbol}
                                            {number_format(
                                              item.purseValue / 100.0,
                                              2
                                            )}
                                          </Box>
                                        </Box>
                                      </Box>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={2}
                                      position="relative"
                                      textAlign="right"
                                      display="flex"
                                    >
                                      <Checkbox
                                        sx={{
                                          color: '#417b5a',
                                          '&.Mui-checked': {
                                            color: '#417b5a',
                                          },
                                          px: 1,
                                        }}
                                        checked={this.isSelected(
                                          `${promotion.index}`
                                        )}
                                      />
                                    </Grid>
                                  </Grid>
                                )
                              )}
                            </Grid>
                          </Grid>
                        )}

                        {/*refundCart.items.findIndex(
                          (i) => i.id === item.id,
                        ) < 0 ? (
                          <>
                          <Checkbox
                            sx={{float:'right', color:"#417b5a", px:1, mt:-1.2}}
                            
                            checked={this.isSelected(item.id)}
                          />
                            <span
                              style={{ float: 'right' }}
                              className="amount"
                            >
                              {supportedCurrencies.symbol}
                              {number_format(item.purseValue/100.0, 2)}
                            </span>
                          </>
                        ) : (
                          <Button
                            className="ButtonRefundItemToggle"
                            variant="contained"
                            onClick={() => {
                              this.onRefundItemToggle(item.id)
                            }}
                          >
                            Refund
                          </Button>
                          )*/}
                      </Box>
                    ))}
                    {/* END: Refund Cart Items */}
                  </>
                )}

                {salesMode === 'current_sale' && (
                  <>
                    {/* START: Sale Cart Items */}
                    {(this.state.cart?.items || []).map((item, index) => (
                      <Box
                        key={item.id}
                        margin="10px 0px 10px 0px"
                        px={1}
                        sx={{
                          backgroundColor: index % 2 ? '#F8F8F8' : '#fffff',
                        }}
                      >
                        <Grid container>
                          <Grid item xs={10}>
                            <Typography
                              textAlign="left"
                              fontSize={13}
                              sx={{ textTransform: 'capitalize' }}
                            >
                              {item.title}
                            </Typography>
                            <Typography
                              fontSize={12}
                              fontWeight={'bold'}
                              textAlign="left"
                              color={themeColor}
                            >
                              {supportedCurrencies.symbol}
                              {number_format(item.subtotal, 2)}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={2}
                            display="flex"
                            alignItems="center"
                            justifyItems="center"
                          >
                            <Button
                              onClick={() => {
                                this.onItemRemove(index);
                              }}
                            >
                              <DeleteRoundedIcon
                                sx={{ fontSize: 16, marginTop: -0.2 }}
                              />
                            </Button>
                          </Grid>
                        </Grid>
                      </Box>
                    ))}
                    {/* END: Sale Cart Items */}
                  </>
                )}
                {this.state.cart?.items?.length < 1 ? (
                  <Box
                    component="span"
                    sx={{ p: 2, }}
                  >
                    <Grid container spacing={3}>
                      <Grid item xs>
                        Nothing selected
                      </Grid>
                    </Grid>
                  </Box>
                ) : null}
              </div>
            </Box>
          </Box>
          {/* <Box
          position="absolute"
          top={38 + height + 8}
          left={0}
          width={250}
          height={20}
        >
          <Box sx={{ backgroundColor:'#ececec'}}>
            <Grid container>
              <Grid item xs={4} />
              <Grid item xs={8}>
                <Stack
                  direction="row"
                  spacing={2}
                  justifyContent="space-evenly"
                >
                  
                  <Box
                    sx={{
                      opacity: 0.7,
                      display: "flex",
                      justifyContent: "center",
                      flex: "1 0 0",
                    }}
                    fontSize={10}
                    fontWeight='normal'
                    color={themeColor}
                  >
                    BEFORE
                  </Box>
                  
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      flex: "1 0 0",
                    }}
                    fontSize={10}
                    fontWeight='normal'
                    color={themeColor}
                  >
                    AFTER
                  </Box>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </Box> */}
          <Box
            position="absolute"
            top={38 + height}
            left={0}
            width={250}
            height={30}
            borderRight={borderRightCss}
          >
            <Box className="BalanceRow">
              <Grid container>
                <Grid item xs={4}>
                  <Box width={70}>
                    {/* sx={{ color: "#417B5A" }} */}
                    Subsidy{' '}
                  </Box>
                </Grid>
                <Grid item xs={8}>
                  <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="space-evenly"
                  >
                    {/** BEFORE */}
                    <Box
                      sx={{
                        opacity: 0.7,
                        display: 'flex',
                        justifyContent: 'center',
                        flex: '1 0 0',
                      }}
                    >
                      {supportedCurrencies.symbol}
                      {number_format(this.state.cart?.subsidyBefore, 2)}
                    </Box>
                    {/** AFTER */}
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        flex: '1 0 0',
                      }}
                    >
                      {supportedCurrencies.symbol}
                      {number_format(subsidyAfter, 2)}
                    </Box>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box
            position="absolute"
            top={38 + height + 30}
            left={0}
            width={250}
            height={30}
            borderRight={borderRightCss}
          >
            <Box className="BalanceRow">
              <Grid container>
                <Grid item xs={4}>
                  <Box width={70}>Purse </Box>
                </Grid>
                <Grid item xs={8}>
                  <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="space-evenly"
                  >
                    {/** BEFORE */}
                    <Box
                      sx={{
                        opacity: 0.7,
                        display: 'flex',
                        justifyContent: 'center',
                        flex: '1 0 0',
                      }}
                      color={themeColor}
                    >
                      {supportedCurrencies.symbol}
                      {number_format(this.state.cart?.purseBefore, 2)}
                    </Box>
                    {/** AFTER */}
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        flex: '1 0 0',
                      }}
                      color={themeColor}
                    >
                      {supportedCurrencies.symbol}
                      {number_format(purseAfter, 2)}
                    </Box>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          </Box>

          {(!salesMode || salesMode === 'current_sale') && (
            <Box
              position="absolute"
              top={38 + height + 30 + 30}
              left={0}
              id="ButtonOrder"
              width={250}
              height={142}
              alignItems="center"
              display="flex"
              justifyContent="center"
              onClick={() => {
                this.paymentDialogOpen();
              }}
              borderRight={borderRightCss}
            >
              {this.state.cart?.items?.length < 1 ? (
              <div>Cancel</div>
              ) : (
              <div>
                Confirm {supportedCurrencies.symbol}
                {number_format(this.state.cart?.total || 0, 2)}
              </div>
              )}
              {/* Confirm {supportedCurrencies.symbol}
              {number_format(this.state.cart?.total || 0, 2)} */}
            </Box>
          )}

          {salesMode === 'refund_sale' && (
            <Box
              position="absolute"
              id="ButtonOrderRefund"
              top={38 + height + 30 + 30}
              width={250}
              height={142}
              alignItems="center"
              display="flex"
              justifyContent="center"
              borderRight={borderRightCss}
              onClick={() => {
                if (this.state.selectedRefundItems.length > 0) {
                  this.refundDialogOpen();
                } else {
                  this.setState({ selectedRefundItems: [] });
                  PubSub.publish('refund_cancelled');
                }
              }}
            >
              Refund {supportedCurrencies.symbol}
              {number_format(refundCart.total, 2)}
            </Box>
          )}
        </Box>
        {this.props.cart && (
          <>
            <DialogInsufficientFunds
              showDialog={this.state.showInsufficientFundsDialog}
              cart={this.props.cart}
              onPayment={() => {
                this.props.onPayment();
              }}
            ></DialogInsufficientFunds>
            <DialogTopup
              cart={this.props.cart}
              showDialog={false}
              onPayment={() => this.payNow()}
            ></DialogTopup>
            <DialogInsufficientSubsidy
              showDialog={this.state.showInsufficientSubsidyDialog}
              cart={this.props.cart}
              onAddSubsidyToPurse={(selectedMenuItem) => {
                this.props.onAddSubsidyToPurse(selectedMenuItem);
              }}
            />
            <DialogPayment
              showDialog={this.state.showPaymentDialog}
              onPay={() => {
                this.payNow();
              }}
            ></DialogPayment>
            <DialogRefund
              showDialog={this.state.showRefundDialog}
            ></DialogRefund>
            <DialogAllergens />
          </>
        )}
      </>
    );
  }
}

export default PanelCurrentSale;
