import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  styled,
  Typography,
} from '@mui/material'
import React from 'react'
import PubSub from 'pubsub-js'
import './DialogCustomerInfo.css'
import number_format from 'locutus/php/strings/number_format'

import { ReactJSXElement } from '@emotion/react/types/jsx-namespace'
import SharedStateService from '../../services/SharedStateService'
import { avatarIcon, formatDateTime } from '../../utils/utils'
import ApiService from '../../services/ApiService'
import EnvService from '../../services/EnvService'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { StyledDialogTitle } from '../../components/Dialog/StyledDialogTitle'
import { StyledDialogOKButton } from '../../components/Dialog/DialogButton'

const StyledGridItem = styled(Grid)({
 display:'flex',
 justifyContent:'flex-start',
 alignItems:'center'
});

type DialogCustomerInfoState = {
  // [x:string]:any,
  showDialog:boolean,
  org: ApiOrgs
  fetching: boolean
  syncing: boolean
  synced: boolean
}

type DialogCustomerInfoProps = {
  showDialog?:boolean,
}

class DialogCustomerInfo extends React.Component<DialogCustomerInfoProps, DialogCustomerInfoState> {
  private displayName: string
  private className: string
  private allergens: ApiAllergens[]
  private school_name: string
  private subsidy: string
  private lastActive: string
  private fob: string
  private sharedStateService: SharedStateService
  private apiService: ApiService
  private customer: Customer
  private avatar:string

  constructor(props) {
    super(props)
    this.apiService = new ApiService()
    this.sharedStateService = new SharedStateService()

    this.state = {
      showDialog: false, //props.showDialog,
      org: this.sharedStateService.getOrg(),
      fetching: true,
      syncing:false,
      synced:false
    }
    this.displayName = ''
    this.className = ''
    this.allergens = []
    this.school_name = ''
    this.fob = ''
    this.subsidy = '0.00'
    this.lastActive = 'never'
    this.avatar = 'default'
  }

  getUserAllergens(customer:Customer){
    const { allergenIds } = customer;
    if(allergenIds){
      const allAllergens = this.sharedStateService.getAllergens();
      const allergens: ApiAllergens[] = allAllergens.filter((allergen) => allergenIds.includes(allergen.id));
      return allergens
    }

    return []
  }

  componentDidMount() {
    PubSub.subscribe('dialog-customer-info-show', (msg: string, data: Customer) => {
      this.show()
      this.fetchLastTransaction();
    })
    PubSub.subscribe('dialog-customer-info-close', (msg: string, data: any) => {
      this.close()
    })
  }

  async fetchLastTransaction(){
    const customer = this.sharedStateService.getCustomer()
    try {
      const response: ApiFetchTransactionResponse = await this.apiService.fetchTransactionsByUserID(
        customer.id,
        1,
        1
      )
      if (response.status === 'success') {
        if(response.transactions.length>0){
          const transaction = response.transactions[0]
          if(transaction.receipt){
            this.displayStats(transaction.receipt.dateTime)
          }else{
            this.displayStats('NA')
          }
        }
      }else{
        this.displayStats('NA')
      }
    } catch (error) {
      this.displayStats('NA')
    }
     
  }

  displayStats = (lastActiveTime) =>{
    const data = this.sharedStateService.getCustomer()
    if(data){
      const schoolName = this.sharedStateService.getSchool()
          ? this.sharedStateService.getSchool().name
          : "";
        this.displayName = data.display_name
        this.className = data.class
        this.allergens = this.getUserAllergens(data)
        this.school_name = schoolName
        this.subsidy = number_format(data.subsidy, 2)
        this.fob = data.card_number
        this.lastActive = lastActiveTime === 'NA'?lastActiveTime:formatDateTime(lastActiveTime)
        this.avatar = data.avatar
    }


    this.setState({fetching:false})
  }

  componentWillUnmount() {
    PubSub.unsubscribe('dialog-customer-info-show');
    PubSub.unsubscribe('dialog-customer-info-close');
  }

  show() {
    this.setState({
      showDialog: true,
    })
  }

  close() {
    this.setState({
      showDialog: false,
    })
  }

  sync() {
    this.setState({syncing:true})
    const customer = this.sharedStateService.getCustomer()
    this.apiService.findCustomerByCard(customer.card_number).then((response)=>{
      const customer = response.customer;
      //Update user in storage
      let allUsers = this.sharedStateService.getSchoolUsers();

      const userIndex = allUsers.findIndex((user)=>user.id === customer.id);
      allUsers[userIndex]=customer

      //Update customer
      this.sharedStateService.setCustomer(customer);

      this.sharedStateService.setSchoolUsers(allUsers);

      PubSub.publish('update_customer_after_sync');
      this.setState({syncing:false, synced:true})
      setTimeout(() => {
        this.close();  
      }, 300);

      setTimeout(() => {
        this.setState({synced:false})
      }, 400);
      
    });
  }

  render(): ReactJSXElement {
    const { org, fetching, synced, syncing } = this.state
    const supportedCurrencies = org.supportedCurrencies[0]
    const syncButtonTitle=syncing?'Syncing':synced?'Synced':'Sync'
    return (
      <>
        <Dialog
          id="DialogCustomerInfo"
          open={this.state.showDialog}
          keepMounted
          aria-describedby="alert-dialog-slide-description"
          style={{
            position: 'absolute',
            left: 'calc(50% - 350px)',
            width: '700px',
            fontFamily: 'Montserrat',
          }}
        >
          <StyledDialogTitle>Customer Info</StyledDialogTitle>
          <DialogContent className="DialogContent">
          {fetching ? <Box display="flex" justifyContent="center" alignItems="center" height={200} width={450}><CircularProgress color='primary' /></Box>  : 
              <Box>
                <Grid container spacing={2}>
                  <StyledGridItem item xs={12} mb={2}>
                    {avatarIcon(this.avatar, 60, true)}
                    <Typography variant='h4' ml={1}>{this.displayName}</Typography>
                  </StyledGridItem>
                  <StyledGridItem item xs={12}>
                    <Typography key={1} fontWeight="bold">School - </Typography>
                    <Typography key={2} ml={1}>{this.school_name}</Typography>
                  </StyledGridItem>
                  <StyledGridItem item xs={12}>
                    <Typography key={1} fontWeight="bold">Class - </Typography>
                    <Typography key={2} ml={1}>{this.className}</Typography>
                  </StyledGridItem>
                  <StyledGridItem item xs={12}>
                    <Typography key={1} fontWeight="bold">FOB - </Typography>
                    <Typography key={2} ml={1}>{this.fob}</Typography>
                  </StyledGridItem>
                  <StyledGridItem item xs={12}>
                    <Typography  key={1} fontWeight="bold">Allergens - </Typography>
                    {
                      this.allergens.length>0?
                        <Box ml={1}>
                        {
                          this.allergens.map(({ name, embolden }, i) => (
                            <Typography display="inline" fontWeight={embolden ? 'bold' : 'normal'}>
                              {name}
                              {i < this.allergens.length - 1 ? ', ' : ''}
                            </Typography>
                          ))
                        }
                      </Box>
                      :
                      <Typography key={2} ml={1}>NA</Typography>
                    }
                    
                  </StyledGridItem>
                  <StyledGridItem item xs={12}>
                    <Typography  key={1} fontWeight="bold">Subsidy Left - </Typography>
                    <Typography key={2}  ml={1}>{supportedCurrencies.symbol}{this.subsidy}</Typography>
                  </StyledGridItem>
                  <StyledGridItem item xs={12}>
                    <Typography  key={1} fontWeight="bold">Last Active - </Typography>
                    <Typography  key={2} ml={1}>{this.lastActive}</Typography>
                  </StyledGridItem>
                </Grid>
              </Box>
            }
          </DialogContent>
          <DialogActions
            style={{ justifyContent: 'center', margin: '0px 0px 20px 0px' }}
          >
            <StyledDialogOKButton
              onClick={() => this.sync()}
              disabled={EnvService.isOffline()}
              endIcon={syncing?
                        <CircularProgress size={14} sx={{color:'white'}} />:
                        (synced ? <CheckCircleRoundedIcon sx={{fontSize:14,color:"white"}}  /> : null)}>
              {syncButtonTitle}
            </StyledDialogOKButton>
            <StyledDialogOKButton
              onClick={() => this.close()}
            >
              Close
            </StyledDialogOKButton>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

export default DialogCustomerInfo
