class AudioManager{
    static _theinstance = null;

    static sharedManager() {
        if (AudioManager._theinstance == null) {
            AudioManager._theinstance = new AudioManager();
        }

        return this._theinstance;
    }
    
// The browser will limit the number of concurrent audio contexts
// So be sure to re-use them whenever you can
private myAudioContext = null;

constructor() {
    this.myAudioContext = new AudioContext();
}

/**
 * Helper function to emit a beep sound in the browser using the Web Audio API.
 * 
 * @param {number} duration - The duration of the beep sound in milliseconds.
 * @param {number} frequency - The frequency of the beep sound.
 * @param {number} volume - The volume of the beep sound.
 * 
 * @returns {Promise} - A promise that resolves when the beep sound is finished.
 */
 beep(duration, frequency, volume){
    return new Promise((resolve, reject) => {
        // Set default duration if not provided
        duration = duration || 30;
        frequency = frequency || 950;
        volume = volume || 10;

        try{
            let oscillatorNode = this.myAudioContext.createOscillator();
            let gainNode = this.myAudioContext.createGain();
            oscillatorNode.connect(gainNode);

            // Set the oscillator frequency in hertz
            oscillatorNode.frequency.value = frequency;

            // Set the type of oscillator
            oscillatorNode.type= "sine";
            gainNode.connect(this.myAudioContext.destination);

            // Set the gain to the volume
            gainNode.gain.value = volume * 0.01;

            // Start audio with the desired duration
            oscillatorNode.start(this.myAudioContext.currentTime);
            oscillatorNode.stop(this.myAudioContext.currentTime + duration * 0.001);

            // Resolve the promise when the sound is finished
            oscillatorNode.onended = () => {
                resolve(0);
            };
        }catch(error){
            reject(error);
        }
    });
}   
}

export default AudioManager;