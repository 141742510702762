import { Button } from "@mui/material";
import { styled } from '@mui/material/styles';
import { themeColor } from "../../utils/ThemeManager";

export const StyledDialogOKButton = styled(Button)(({ theme }) => ({
    background: 'linear-gradient(180deg, #ff4747 0%, #d00000 100%)',
    boxShadow: '0px 3px 1px -2px rgba(0, 0, 0, 0.2),0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',
    borderRadius: '10px',
    fontWeight: 'bold',
    padding: '10px 15px',
    color:'white',
    height:'41px'
}));

export const StyledDialogCancelButton = styled(Button)(({ theme }) => ({
    border: `2px solid ${themeColor}`,
    color: themeColor,
    fontWeight: 'bold',
    padding: '10px 15px',
    borderRadius: '10px',
    height:'41px'
}));
