const messageSuffix = 'Electron service';
let electronApiAvailable = false;
let electronAPI;

class ElectronService {
    static init() {
        try {
            const windowObject: any = window;
            if (windowObject.electronAPI) {
              electronApiAvailable = true;

              electronAPI = windowObject.electronAPI;
            } else {
              console.warn('Electron API not available');
            }
        } catch (error) {
            console.error(`Error in initialising Electron service - ${messageSuffix}`, error);
        }
    }

    static recordTransaction(transaction) {
      try {
        if (electronApiAvailable && electronAPI) {
          electronAPI.recordTransaction(transaction);
        } else {
          console.warn('Electron API not available');
        }
      } catch (error) {
        console.error(`Error in recording transaction - ${messageSuffix}`, error);
      }
    }

    static recordCardScan(transaction) {
      try {
        if (electronApiAvailable && electronAPI) {
          electronAPI.recordCardScan(transaction);
        } else {
          console.warn('Electron API not available');
        }
      } catch (error) {
        console.error(`Error in recording card scan - ${messageSuffix}`, error);
      }
    }
}

export default ElectronService;
