import { Typography, styled } from "@mui/material";
import {  themeColor } from "../../utils/ThemeManager";

export const StyledDialogText = styled(Typography)(({ theme }) => ({
    textAlign: 'center',
    fontSize: '17px',
    fontWeight:300
}));

export const StyledDialogHighlightText = styled(Typography)(({ theme }) => ({
    textAlign: 'center',
    fontSize: '17px',
    color: themeColor,
    fontWeight:300
}));