import { createTheme, alpha } from '@mui/material';

export const themeColor = '#D00000';
export const warningColor = '#fcab47';
export const successColor = '#57B748';
export const promoColor = '#fff8dc';
export const refundColor = '#417b5a';
export const secondaryColor = '#333333';
const focusedColor = '#D00000';

export const appFonts = {
    Paytone: 'Paytone One',
    Montserrat: 'Montserrat',
};

const theme = createTheme({
  palette: {
    primary: {
      main: themeColor,
    },
    background: {
      default: themeColor,
    },
    secondary: {
      main: secondaryColor,
    },
    text: {
      primary: secondaryColor,
      secondary: themeColor,
    },
    action: {
      hover: alpha(themeColor, 0.08),
    },
    common: {
      white: '#ffffff',
    },
  },
  typography: {
    h1: {
      fontFamily: appFonts.Paytone,
    },
    h2: {
      fontFamily: appFonts.Paytone,
    },
    h3: {
      fontFamily: appFonts.Paytone,
    },
    h4: {
      fontFamily: appFonts.Paytone,
    },
    h5: {
      fontFamily: appFonts.Paytone,
    },
    h6: {
      fontSize: 20,
      fontFamily: appFonts.Paytone,
    },
    allVariants: {
      color: secondaryColor,
    },
    fontFamily: [appFonts.Montserrat, 'Open Sans'].join(','),
  },
  components: {
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:hover': {
            cursor: 'pointer',
          },
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '&$selected': {
            backgroundColor: themeColor,
            '&:hover': {
              backgroundColor: alpha(themeColor, 0.08),
            },
          },
          '&:hover': {
            backgroundColor: alpha(themeColor, 0.08),
          },
        },
        button: {
          '&:hover': {
            backgroundColor: alpha(themeColor, 0.08),
          },
          '&$selected': {
            backgroundColor: themeColor,
          },
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          color: secondaryColor,
          '&$selected': {
            color: themeColor,
            '&:hover': {
              color: themeColor,
            },
          },
          '&:hover': {
            color: themeColor,
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: secondaryColor,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: themeColor,
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          color: secondaryColor,
          '&$selected': {
            color: themeColor,
            '&:hover': {
              color: themeColor,
            },
          },
          '&:hover': {
            color: themeColor,
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          // input label when focused
          '& label.Mui-focused': {
            color: focusedColor,
          },
          // focused color for input with variant='standard'
          '& .MuiInput-underline:after': {
            borderBottomColor: focusedColor,
          },
          // focused color for input with variant='filled'
          '& .MuiFilledInput-underline:after': {
            borderBottomColor: focusedColor,
          },
          // focused color for input with variant='outlined'
          '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
              borderColor: focusedColor,
            },
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: focusedColor,
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            color: focusedColor,
          },
        },
      },
    },
  },

});

export { theme };
