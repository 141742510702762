import { IconButton, styled } from "@mui/material";

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
    color:'white',
    borderColor:'white !important',
    borderRadius: '50%',
    border: "white 2px solid",
    padding: 0,
    minWidth: 0,
    width: '46px',
    height: '46px',
    zIndex: 1050,
    boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12);'
}));

export const StyledIconWhiteButton = styled(IconButton)(({ theme }) => ({
    color:'white',
    borderColor:'white !important',
    borderRadius: '50%',
    border: "white 2px solid",
    padding: 0,
    minWidth: 0,
    width: '46px',
    height: '46px',
    zIndex: 1050,
    backgroundColor:'white',
    boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12);'
}));