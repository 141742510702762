import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React from "react";
import PubSub from "pubsub-js";
import "./DialogCustomerInfo.css";

import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import { StyledDialogTitle, StyledDialogTitleNormal } from "../../components/Dialog/StyledDialogTitle";
import { StyledDialogOKButton } from "../../components/Dialog/DialogButton";
import { StyledDialogHighlightText, StyledDialogText } from "../../components/Dialog/DialogText";

type DialogCustomerSessionState = {
  showDialog: boolean;
  secondsLeft: number;
};

type DialogCustomerSessionProps = {
  showDialog?: boolean;
};

class DialogCustomerSession extends React.Component<
  DialogCustomerSessionProps,
  DialogCustomerSessionState
> {
  private interval: any;

  constructor(props) {
    super(props);
    this.state = {
      showDialog: false,
      secondsLeft: 10,
    };
  }

  componentDidMount() {
    this.setState({ secondsLeft: 10 });
    PubSub.subscribe(
      "dialog-customer-session-show",
      (msg: string, data: any) => {
        this.show();
        this.interval = setInterval(() => {
          const secondsLeft = this.state.secondsLeft - 1;
          this.setState({ secondsLeft: secondsLeft });
          if (this.state.secondsLeft <= 0) {
            this.close();
          }
        }, 1000);
      }
    );
    PubSub.subscribe(
      "dialog-customer-session-close",
      (msg: string, data: any) => {
        this.close();
      }
    );
  }

  componentWillUnmount() {
    PubSub.unsubscribe(
      "dialog-customer-session-show"
    );
    PubSub.unsubscribe(
      "dialog-customer-session-close"
    );
    clearInterval(this.interval);
  }

  show() {
    this.setState({
      showDialog: true,
      secondsLeft: 10,
    });
  }

  onContinue() {
    PubSub.publish("customer-session-extend");
    this.close();
  }

  close() {
    
    this.setState({
      showDialog: false,
    });
    clearInterval(this.interval);

  }

  render(): ReactJSXElement {
    return (
      <>
        <Dialog
          id="DialogCustomerSession"
          open={this.state.showDialog}
          keepMounted
          aria-describedby="alert-dialog-slide-description"
          style={{
            position: "absolute",
            left: "calc(50% - 450px)",
            width: "900px",
            fontFamily: "Montserrat",
            zIndex:99999
          }}
        >
          <StyledDialogTitle>
            Session About to Expire
          </StyledDialogTitle>
          <DialogContent className="DialogContent">
            <Box sx={{pt:3, pb:1}} textAlign="center">
              <StyledDialogText sx={{display:'inline'}}>The transaction has been open for</StyledDialogText><StyledDialogHighlightText sx={{display:'inline'}}> 5 minutes</StyledDialogHighlightText><StyledDialogText sx={{display:'inline'}}>, please click continue
                to reset the timeout or this transaction will cancel.</StyledDialogText>
            </Box>
          </DialogContent>
          <DialogActions
            style={{ justifyContent: "center", margin: "0px 0px 20px 0px" }}
          >
            <StyledDialogOKButton
              onClick={() => this.onContinue()}
            >
              Continue ({this.state.secondsLeft})
            </StyledDialogOKButton>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export default DialogCustomerSession;
