import React from "react";
import "./App.css";
// import OperatorPages from "./pages/OperatorPages";
import PubSub from "pubsub-js";
import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import SharedStateService, { keyUpdateCartToCustomer } from "./services/SharedStateService";
import PageService from "./services/PageService";
import { Box, ThemeProvider } from "@mui/material";
import { theme } from "./utils/ThemeManager";
import ElectronService from "./services/ElectronService";
import ApiService from "./services/ApiService";
import EnvService from "./services/EnvService";
import NetworkStatus from "./components/Custom/NetworkStatus";
// import { withIdleTimer } from "react-idle-timer";
import ManagerSessionService from "./services/ManagerSessionService";
import OperatorSessionService from "./services/OperatorSessionService";
import SittingManager from "./services/SittingManager";
import InitPage from "./pages/InitPage/InitPage";
import { Routes, Route, MemoryRouter } from "react-router-dom";
import LoginPage from "./pages/LoginPage/LoginPage";

import KioskPage from "./pages/KioskPage/KioskPage";
import ManualEntryPage from "./pages/ManualEntryPage/ManualEntryPage";
import CardScanPage from "./pages/CardScanPage/CardScanPage";
import Navigate from "./components/Navigate";
import ScreensaverPage from "./pages/ScreensaverPage/ScreensaverPage";
import { OperationMode, removeEmptyFields } from "./utils/utils";
import { init } from "@sentry/electron/renderer";
import { init as reactInit } from "@sentry/react";

init(
  {
    dsn: "https://2518e7dd669c1ab1bc8b6bf81982d32d@o369035.ingest.sentry.io/4506283260051456",
  },
  // @TODO
  // reactInit({
  //   dsn: "dd",
  // })
);

/**
 * Sets the websocket connection that this application
 * will communicate on, with Electron and the customer
 * application
 */
// function setupWebSockets() {
//   let socket = new WebSocket("ws://localhost:32323");

//   /**
//    * Open connection, and ask for the computer name
//    */
//   socket.onopen = function (e) {
//     socket.send(JSON.stringify("send_systeminfo"));
//   };

//   /**
//    * When a message is received from the websocket connection
//    * it is published as a new command to the PubSub
//    * so that it can be acted upon by the Operator screen
//    * @param event
//    */
//   socket.onmessage = function (event: any) {
//     PubSub.publishSync("command_in", event.data);
//   };

//   socket.onclose = function (event) {
//     if (event.wasClean) {
//       console.log(
//         `[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`
//       );
//     } else {
//       // e.g. server process killed or network down
//       // event.code is usually 1006 in this case
//       console.log("[close] Connection died");
//     }
//   };

//   socket.onerror = function (error: any) {
//     console.log(`[error] ${error.message}`);
//   };

//   /**
//    * We subscribe to the command_out channel
//    * so that we can send commands to the websocket
//    * connection
//    */
//   PubSub.subscribe("command_out", (msg, command: any) => {
//     console.log("OUT COMMAND SENT: ", command);
//     socket.send(JSON.stringify(command));

  
//     // try{
//     //   socket.send(JSON.stringify(command));
//     //   console.log("OUT COMMAND SENT: ", command);
//     // }
//     // catch(e){
//     //   console.log(e);
//     // }
   
//   });
// }

/**
 * We subscribe to the command_in channel
 * and republish forward the recieved commands from
 * Electron and the customer application
 */
function subscribeToCommands() {
 PubSub.subscribe("command_in", (msg, data: string) => {
    if (!data.startsWith("{")) {
      return console.log("not a command: ", data);
    }

    const commandObject: any = JSON.parse(data);
    if (commandObject.command) {
      console.log("IN COMMAND RECEIVED: " + data);
      PubSub.publishSync(commandObject.command, commandObject.data ?? {});
    }
  });
}

/**
 * Helper method for development only
 * @param command
 */
function cmd(commandObject: any) {
  PubSub.publish("command_in", JSON.stringify(commandObject));
}

type AppState = {
  onKiosk: boolean;
  curPage: string
  showScreenSaver: boolean
};

type AppProps = {
};


class AppComponent extends React.Component<AppProps, AppState> {
  private apiService: ApiService;
  private managerSessionService: ManagerSessionService;
  private operatorSessionService: OperatorSessionService;
  private sharedStateService: SharedStateService;
  private pageService: PageService;
  private idleTimer: NodeJS.Timer;
  private inactivitySeconds: number = 0;
  private idleTimeoutSeconds = 30 * 60; // 30 minutes (screensaver wil start)
  private isIdle = false; // a flag to indicate if user is idle


  constructor(props) {
    super(props);

    this.state = {
      onKiosk: false,
      curPage: 'init',
      showScreenSaver: false
    };

    this.apiService = new ApiService();
    // this.managerSessionService = new ManagerSessionService();
    // this.operatorSessionService = new OperatorSessionService();
    this.pageService = new PageService();
    this.sharedStateService = new SharedStateService();
    // this.sharedStateService.clear();

    if (!this.sharedStateService.getOperationMode()) {
      this.sharedStateService.setOperationMode(OperationMode.local);
    }

    this.pageUpdated = this.pageUpdated.bind(this);
    this.idleTimer = null
    // this.handleOnAction = this.handleOnAction.bind(this)
    // this.handleOnActive = this.handleOnActive.bind(this)
    // this.handleOnIdle = this.handleOnIdle.bind(this)
    this.onIdle = this.onIdle.bind(this);
    this.onAction = this.onAction.bind(this);
    this.onActive = this.onActive.bind(this);

    this.listenToEvents();
    // setupWebSockets();
    subscribeToCommands();

    this.startApiTokenRefresh();

    this.startSyncPendingOrders();

    this.startInactivityListener();

    window.cmd = cmd; // Assign to global scope

    // this.managerSessionService.startMonitor();
    // this.operatorSessionService.startMonitor();

    SittingManager.sharedManager().initService();
  }

  componentDidMount() {
    //Customer screen should always start with Init Page
    this.pageService.setCustomerPage(this.pageService.CUSTOMER_INIT_PAGE);

  }

  /**
   * Listens for user inactivity
   * If the idle threshold is passed, the user is considered idle and onIdle is called
   * Any event (mouse clicks, keyboard clicks, etc) is considered unidle and onActive is called
   */
  startInactivityListener() {
    window.onload = () => {
      this.inactivitySeconds = 0;
      this.isIdle = false;
      this.onActive(null);
    }; // DOM Events
    document.onmousemove = () => {
      this.inactivitySeconds = 0;
      this.isIdle = false;
      this.onActive(null);
    };
    document.onkeypress = () => {
      this.inactivitySeconds = 0;
      this.isIdle = false;
      this.onActive(null);
    };
    setInterval(() => {
      this.inactivitySeconds = this.inactivitySeconds + 10;

      // console.log('inactivitySeconds => ',this.inactivitySeconds);
      // console.log('idleTimeoutSeconds => ',this.idleTimeoutSeconds);

      if (
        this.inactivitySeconds > this.idleTimeoutSeconds &&
        this.isIdle === false
      ) {
        this.isIdle = true;
        this.onIdle(); // only trigger once
      }
    }, 10 * 1000);
  }

  /**
   * We run the syncing of pending orders every 10 seconds
   */
  startSyncPendingOrders(): void {
    console.log('startSyncPendingOrders')
    setInterval(() => {
      this.syncPendingOrders();
    }, 10 * 1000); // every 10 seconds
  }

  /**
   * We refresh the API token every hour to prevent expiry
   */
  startApiTokenRefresh(): void {
    setInterval(() => {
      this.refreshApiToken();
    }, 1 * 60 * 1000); // every hour
  }

  /**
   * Events that the application should listen to
   */
  listenToEvents() {
    /**
     * When an unauthenticated message is published, the EPOS
     * should return to the initial state, waiting for the
     * manager to login
     */
    PubSub.subscribe("unauthenticated", (msg, data) => {
      if (this.state.curPage !== 'operator') {
        this.pageService.setOperatorPage(this.pageService.OPERATOR_INIT_PAGE);
        this.pageService.setCustomerPage(this.pageService.CUSTOMER_INIT_PAGE);
      }
      // PubSub.publish("terminate_timer", "");
    });

    /**
     * Forces page rerender when a page is updated
     */
    PubSub.subscribe("operator_page_updated", (msg, data) => {
      const page = this.pageService.getOperatorPage()

      if (page === this.pageService.OPERATOR_LOGIN_PAGE) {
        this.setState({ curPage: 'login' })
      } else if (page === this.pageService.OPERATOR_CARD_SCAN_PAGE) {
        this.setState({ curPage: 'cardscan' })
      } else if (page === this.pageService.OPERATOR_MANUAL_ENTRY_PAGE) {
        this.setState({ curPage: 'manualentry' })
      } else if (page === this.pageService.OPERATOR_KIOSK_PAGE) {
        this.setState({ curPage: 'kiosk' })
      } else if (page === this.pageService.OPERATOR_INIT_PAGE) {
        this.setState({ curPage: 'operator' })
      }

    });

    PubSub.subscribe("isonline", (msg, online: boolean) => {
      if (this.sharedStateService.getOperationMode() === OperationMode.online && !online) {
        this.sharedStateService.setOperationMode(OperationMode.local)
      }
    })

    /**
     * When a customer page is updated (from the PageService), we should send
     * it via websocket to the customer screen
     */
    PubSub.subscribe("customer_page_updated", (msg, page: string) => {
      PubSub.publish("command_out", {
        command: "set_customer_page",
        data: { page: page },
      });
    });

    /**
     * When a customer page is updated (from the PageService), we should send
     * it via websocket to the customer screen
     */
    PubSub.subscribe("cart_updated", (msg, command: string) => {
      console.log('CART UPDATED COMMAND => ',command);
      
      if(command === keyUpdateCartToCustomer){//blocks update if anything else
        console.log('command out cart_updated' + this.sharedStateService.getCart().items.length)
        PubSub.publish("command_out", {
          command: "cart_updated_to_customer",
          data: { cart: this.sharedStateService.getCart() },
        });
      }
    });

    /**
     * We subscribe to the system_info channel
     * so that we save the computer name and
     * use it when we generate the EPOS ID
     */
    PubSub.subscribe("computer_name", (msg, data: any) => {
      console.log("COMPUTER NAME ", data);

      this.sharedStateService.setSystemInfo(data);
    });

    //Use to show the Staging and Dev tags on screen
    PubSub.subscribe("onKiosk", (msg, value) => {
      this.setState({ onKiosk: value });
    });

    /**
     * We listen to the operator session expired event
     * then redirect to operator login page
     */
    PubSub.subscribe("operator_session_expired", (msg, data: any) => {
      // PubSub.publish("operator_session_stop");
      // this.sharedStateService.setOperatorSessionStartedAt(null);
      // console.log("OPERATOR SESSION EXPIRED");

      this.pageService.setOperatorPage(this.pageService.OPERATOR_INIT_PAGE);
      this.pageService.setCustomerPage(this.pageService.CUSTOMER_INIT_PAGE);

      // PubSub.publish("terminate_timer", "");
    });

    /**
     * We listen to the manager session expired event
     * then redirect to the EPOS init page
     */
    // PubSub.subscribe("manager_session_expired", (msg, data: any) => {
    //   // PubSub.publish("manager_session_stop");
    //   // PubSub.publish("operator_session_stop");
    //   // this.sharedStateService.setOperatorSessionStartedAt(null);
    //   // this.sharedStateService.setManagerSessionStartedAt(null);
    //   this.pageService.setOperatorPage(this.pageService.OPERATOR_INIT_PAGE);
    //   this.pageService.setCustomerPage(this.pageService.CUSTOMER_INIT_PAGE);

    //   // PubSub.publish("terminate_timer", "");
    // });

    /**
     * We send a shut down command to Electron
     */
    PubSub.subscribe("os_shut_down", (msg, data: any) => {
      PubSub.publish("command_out", "os_shut_down");
    });

    /**
     * Sets the customer page as set by the published event from the websocket
     */
    PubSub.subscribe("updateOrg", (msg, org) => {
      if (org) {
        console.log("SEND UPDATE ORG COMMAND");

        PubSub.publish("command_out", {
          command: "updateOrg",
          data: org,
        });
      }
    });

    // this.pageService.startSyncing();
  }

  /**
   * Update app UI, when page is updated
   * @param {String} msg
   * @param {Array} data
   */
  pageUpdated(msg: string, data: any) {
    this.forceUpdate();
  }

  /**
   * Refresh the API token, to keep it alive for at least 8 hours.
   *
   * Business logic
   * 1. The API token is recieved when the manager logs in
   * 2. The API token is short lived (1 hour)
   * 3. After 20 minutes we refresh the API token with new one
   * 4. We should only refresh if the API token is successfully generated
   */
  async refreshApiToken() {
    const managerToken = this.sharedStateService.getManagerToken();
    const eposId = this.sharedStateService.getEposId();

    if (!managerToken) {
      return; // no manager token, we cannot refresh something we do not have yet
    }

    const authTokenLastUpdated =
      this.sharedStateService.getAuthTokenLastUpdated();
    const timeNow = new Date().getTime();
    const diff = timeNow - authTokenLastUpdated;

    if (diff > 20 * 60 * 1000) {
      try {
        const response = await this.apiService.authenticateEpos(
          eposId,
          managerToken
        );

        if (response.status === ApiService.RESPONSE_SUCCESS) {
          this.sharedStateService.setAuthToken(response.authToken);
          this.sharedStateService.setAuthTokenLastUpdated(new Date().getTime());
          return true;
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  /**
   * Syncs the "pending orders" to the API
   *
   * These may occur when the Internet is cutoff
   * and the API is unreachable
   *
   * @returns
   */
  async syncPendingOrders() {
    const debug = false;

    if (debug) {
      console.log("Syncing pending orders at " + new Date());
    }

    if (navigator.onLine === false) {
      if (debug) {
        console.log("No internet. SKIPPED");
      }
      return false;
    }

    const allOrders = this.sharedStateService.getPendingOrders();

    //Fix for old bad transactions which are saved which don't expect customer id to 
    const pendingOrders = allOrders.filter((order) => order.customer_id !== "");

    if (!pendingOrders) {
      if (debug) {
        console.log("No pending orders. SKIPPED");
      }
      return false;
    }

    if (pendingOrders.length < 1) {
      if (debug) {
        console.log("No pending orders. SKIPPED");
      }
      return false;
    }

    const firstPendingOrder: PendingOrder = pendingOrders[0];

    if (debug) {
      console.log("FIRST PENDING ORDER: ", firstPendingOrder);
    }

    // Was it an offline customer pending order
    /*if (firstPendingOrder.customer_id === "") {
      // find customer by FOB
      const apiResponse = await this.apiService.findCustomerByCard(
        firstPendingOrder.customer_card_number
      );

      if (apiResponse.status !== ApiService.RESPONSE_SUCCESS) {
        console.log("FAILED TO FIND THE CUSTOMER: ", apiResponse);
        return;
      }

      console.log("FOUND CUSTOMER: ", apiResponse);

      firstPendingOrder.customer_id = apiResponse.customer.id;
    }*/

    // console.log("FIRST PENDING ORDER WITH CUSTOMER ID", firstPendingOrder);

    const cartItems = firstPendingOrder.items;

    //Grouping same cart items with quantity
    const cartItemsWithPromos = cartItems.filter((cartitem)=>cartitem.menuPromotionId !== undefined)
    const cartItemsWithoutPromos = cartItems.filter((cartitem)=>cartitem.menuPromotionId === undefined)

    let menuItems:ApiTransactionDetailItem[]=[]

    if(cartItemsWithoutPromos.length>0){
      //Grouping same cart items with quantity
      const quantities = cartItemsWithoutPromos.reduce((prev, curr) => {
        const id = curr.menu_item_id;
        if (!prev.hasOwnProperty(id)) {
          prev[id] = 0;
        }
        prev[id]++;
        return prev;
      }, {});

      const quantityExtended = Object.keys(quantities).map((k) => {
        return { id: k, quantity: quantities[k] };
      });

      menuItems = quantityExtended;
    }

    const uniquePromoIds:string[]=[...new Set(cartItemsWithPromos.map((promo)=>promo.menuPromotionId))];
    
    let menuItemsWithPromos:ApiTransactionDetailPromoItem[]=[];

    if(cartItemsWithPromos.length>0){
      menuItemsWithPromos=uniquePromoIds.map((promoId)=>{
        //Use the cart item related to the promo only
        const promoCartItems = cartItemsWithPromos.filter((cartItem)=>cartItem.menuPromotionId === promoId);

        //Grouping same cart items with quantity
        const quantities = promoCartItems.reduce((prev, curr) => {
          const id = curr.menu_item_id;
          if (!prev.hasOwnProperty(id)) {
            prev[id] = 0;
          }
          prev[id]++;
          return prev;
        }, {});

        const quantityExtended = Object.keys(quantities).map((k) => {
          return { id: k, quantity: quantities[k] };
        });

        return {id:promoId, items:quantityExtended}
      })
    }

    const metaData = this.sharedStateService.getSystemInfo();
    metaData.platform = "win32";
    metaData.offline = true
    // removeEmptyFields(metaData)

    if (menuItems.length === 0 && menuItemsWithPromos.length === 0) {
      //This is an unexpcted scenario, should not occur, but may happen in case of old data
      pendingOrders.splice(0, 1); // !!! remove the posted order, this updates the original array
      this.sharedStateService.setPendingOrders(pendingOrders);
    } else {
      if (firstPendingOrder.type === 'sale') {
        const details:ApiTransactionDetails = {menuId:firstPendingOrder.menuId, menuSittingId: firstPendingOrder.menuSittingId}
        if(menuItems.length > 0){
          details.menuItems = menuItems;
        }
        if(menuItemsWithPromos.length > 0){
          details.menuPromotions = menuItemsWithPromos;
        }

        removeEmptyFields(details);

        const request: ApiTransactionPostRequest = {
          organisationId: firstPendingOrder.organisationId,
          schoolId: firstPendingOrder.schoolId,
          userId: firstPendingOrder.customer_id,
          eposId: firstPendingOrder.eposId,
          eposUserId: firstPendingOrder.eposUserId,
          event: "epos",
          details,
          metaData,
        };

        console.log('PAYLOAD =>>>> ', request);
        

        const response: ApiResponse = await this.apiService.postTransactionWith422Retry(request);
        // if retry fails n times, response will be null, and we don't want to delete.
        // but this should not happen under normal circumstances, as the above tries up to 5 times anyway
        if (response) {
          if (response.status === ApiService.RESPONSE_SUCCESS) {
            pendingOrders.splice(0, 1); // !!! remove the posted order, this updates the original array
            this.sharedStateService.setPendingOrders(pendingOrders);
          } else if (response.status === ApiService.RESPONSE_TRANSACTION_ERROR) {
            console.log("Transaction Error", response);
            console.log('Error Remove from syncing');
            pendingOrders.splice(0, 1);
            //Remove the failed transaction as it has logged in the server already
            this.sharedStateService.setPendingOrders(pendingOrders);


            //Transactions related error codes, subjected to change
            // const errorCodes=['NTEC-1000','SUDC-1000','UNIT-1000','UNSEV-1000','NSF-1000','NPF-1000','TDE-1000','RCI-1000','TDI-1000','RIDE-1000','CRMI-1000','SPAR-1000','TFR-1000','CRPRI-1000'];


          } else {
            // In case of any other error the sync should retry
            console.log("API Error", response);

            // pendingOrders.splice(0, 1); // !!! remove the posted order, this updates the original array
            // //Move the active order to the end as it failed move to next
            // const newOrders = [...pendingOrders, firstPendingOrder];
            // this.sharedStateService.setPendingOrders(newOrders);
          }
        }

      } else {
        const payload: ApiRefundTransactionPostRequest = {
          organisationId: firstPendingOrder.organisationId,
          schoolId: firstPendingOrder.schoolId,
          userId: firstPendingOrder.customer_id,
          eposId: firstPendingOrder.eposId,
          eposUserId: firstPendingOrder.eposUserId,
          event: "refund",
          details: {
            transactionId: firstPendingOrder.transactionId,
            menuItems: menuItems,
          },
          metaData,
        };

        const response: ApiResponse = await this.apiService.postRefundTransaction(
          payload
        );

        if (response.status === ApiService.RESPONSE_SUCCESS) {
          pendingOrders.splice(0, 1); // !!! remove the posted order, this updates the original array
          this.sharedStateService.setPendingOrders(pendingOrders);
        } else if (response.status === ApiService.RESPONSE_TRANSACTION_ERROR) {
          console.log("Refund Transaction Error", response);
          console.log('Error Remove from syncing');
          pendingOrders.splice(0, 1);
          //Remove the failed transaction as it has logged in the server already
          this.sharedStateService.setPendingOrders(pendingOrders);
        }
      }
    }

    if (debug) {
      console.log("PENDING ORDERS END");
    }

    return;
  }

  /**
   * Starts the screensaver
   */
  screensaverStart() {
    // const pageService = new PageService();
    // pageService.setOperatorPage(pageService.OPERATOR_SCREENSAVER_PAGE);

    // After further 15 minutes of inactivity the screensaver
    // is enabled on the customer side of the EPOS
    // pageService.setCustomerPage(pageService.CUSTOMER_SCREENSAVER_PAGE);
    console.log("SHOW SCREENSAVER");

    this.setState({ showScreenSaver: true })
    PubSub.publish("command_out", {
      command: "toggle_screensaver",
      data: true,
    });
  }

  screensaverStartCustomerSide() { }

  /**
   * Ends the screensaver
   * @returns
   */
  screensaverEnd(): void {
    clearInterval(this.idleTimer)
    if (!this.state.showScreenSaver) return

    this.setState({ showScreenSaver: false })
    PubSub.publish("command_out", {
      command: "toggle_screensaver",
      data: false,
    });
    // const pageService = new PageService();
    // const sharedStateService = new SharedStateService();
    // const isScreensaverPage =
    //   sharedStateService.getOperatorPage() !==
    //   pageService.OPERATOR_SCREENSAVER_PAGE;

    // // Do not make any changes, if user is not on the screensaver page
    // if (isScreensaverPage) {
    //   return;
    // }

    // // If the user is not autorized set the manager scanner page
    // if (!sharedStateService.hasAuthToken()) {
    //   pageService.setCustomerPage(pageService.CUSTOMER_INIT_PAGE);
    //   return pageService.setOperatorPage(pageService.OPERATOR_INIT_PAGE);
    // }

    // pageService.setCustomerPage(pageService.CUSTOMER_LOCKED_PAGE);
    // return pageService.setOperatorPage(pageService.OPERATOR_LOGIN_PAGE);
  }

  onPrompt() {
    // Fire a Modal Prompt
    console.log("ON PROMPT");
  }

  onIdle() {
    console.log("ON IDLE");
    this.screensaverStart();
  }

  onActive(event) {
    // console.log("ON ACTIVE");
    this.screensaverEnd();
  }

  onAction(event) {
    // Do something when a user triggers a watched event

    // console.log("ON ACTION");
    this.screensaverEnd();
  }

  // componentDidMount() {
  // The IIdleTimer interface is supplied via props to your component
  // this.props.start()
  // }

  /**
   * Renders the customers pages
   * @returns
   */
  render(): ReactJSXElement {
    // Initialise Electron
    ElectronService.init();
    
    let style = {
      top: 4,
      right: this.state.onKiosk ? "calc(50% - 50px)" : 20,
      backgroundColor: "#fcab47",
      py: 1,
      px: 2,
      zIndex: 1199
    };
    if (EnvService.isStaging()) {
      style = { ...style, backgroundColor: "rgb(65, 105, 225)" };
    }
    const version = `v${this.sharedStateService.version()}`;
    return (
      <ThemeProvider theme={theme}>
        <MemoryRouter initialEntries={["/"]}>
          <Routes>
            <Route path="/" element={<InitPage />} />
            <Route path="operator" element={<InitPage />} />
            <Route path="login" element={<LoginPage />} />
            <Route path="cardscan" element={<CardScanPage />} />
            <Route path="manualentry" element={<ManualEntryPage />} />
            <Route path="kiosk" element={<KioskPage cart={this.sharedStateService.getCart()} customer={this.sharedStateService.getCustomer()} />} />
          </Routes>
          <Navigate page={this.state.curPage} />
        </MemoryRouter>
        {/* <OperatorPages /> */}
        {EnvService.isDev() ? (
          <Box position="absolute" sx={style}>
            LOCAL{" "}
            <Box display="inline" sx={{ fontSize: 10, alignItems: "center" }}>
              {version}
            </Box>
          </Box>
        ) : EnvService.isStaging() ? (
          <Box position="absolute" sx={style}>
            STAGING{" "}
            <Box display="inline" sx={{ fontSize: 10, alignItems: "center" }}>
              {version}
            </Box>
          </Box>
        ) : (
          <Box
            position="absolute"
            sx={{ top: 8, left: 10, fontSize: 10, alignItems: "center" }}
          >
            {version}
          </Box>
        )}
        {this.state.onKiosk ? null : (
          <Box
            position="absolute"
            sx={{ top: 8, left: 50, alignItems: "center" }}
          >
            <NetworkStatus global />
          </Box>
        )}
        {
          this.state.showScreenSaver ? <ScreensaverPage /> : null
        }
      </ThemeProvider>
    );
  }
}

const App = AppComponent;

export default App;
