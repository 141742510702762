import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import React from 'react'
import PubSub from 'pubsub-js'
import './DialogPayment.css'
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace'
import number_format from 'locutus/php/strings/number_format'
import SharedStateService from '../../services/SharedStateService'
import { connectReader, getTerminal } from '../../services/CardReaderService'
import ApiService from '../../services/ApiService'
import EnvService from '../../services/EnvService'
import { StyledDialogCancelButton, StyledDialogOKButton } from '../../components/Dialog/DialogButton'
import { StyledDialogHighlightText, StyledDialogText } from '../../components/Dialog/DialogText'
import { StyledDialogTitleNormal } from '../../components/Dialog/StyledDialogTitle'

type DialogInsufficientFundsState = {
  // [x:string]:any,
  showDialog?: boolean
  org: ApiOrgs
  maximumDeficit: number
  showCardOption: boolean
}

interface DialogInsufficientFundsProps {
  cart?: Cart
  showDialog: boolean
  onPayment?: Function;
}

class DialogInsufficientFunds extends React.Component<
  DialogInsufficientFundsProps,
  DialogInsufficientFundsState
> {
  private sharedStateService: SharedStateService
  apiService: ApiService

  constructor(props) {
    super(props)
    this.sharedStateService = new SharedStateService();
    this.apiService = new ApiService();
    this.state = {
      showDialog: false,
      org: this.sharedStateService.getOrg(),
      maximumDeficit: this.sharedStateService.getCustomer()?.maximumDeficit,
      showCardOption: false,
    }
  }

  componentDidMount() {
    PubSub.subscribe(
      'dialog-insufficient-funds-show',
      (msg: string, data: any) => {
        this.show()
      },
    )
    PubSub.subscribe(
      'dialog-insufficient-funds-close',
      (msg: string, className: string) => {
        this.closeDialog()
      },
    )
    this.showCardOptionIfReaderAvailable();
  }

  private async showCardOptionIfReaderAvailable() {

    try {
      const terminal = await getTerminal(async () => {
        const result = await this.apiService.createConnectionToken()
        console.log('result 5', result)
        return result.secret
      }, () => this.setState({ showCardOption: false }))
      console.log('terminal:')
      console.log(terminal)
      // this will simply never resolve if there's no reader connected.
      const reader = await connectReader(terminal, this.sharedStateService.getEposId(), EnvService.isLocal())
      console.log('reader 5', reader)
      console.log('reader connected!')
      // reader online - show card button
      this.setState({ showCardOption: true })
    }
    catch (e) {
      //No readers registered to this epos.
      console.log(e)
    }
  }

  /**
   * Shows the dialog
   */
  show(): void {
    this.setState({
      showDialog: true,
      showCardOption: false
    }, () => this.showCardOptionIfReaderAvailable())

  }

  /**
   * Closes the dialog
   */
  closeDialog(): void {
    this.setState({
      showDialog: false,
    })
  }

  /**
   * Publishes event that payment is clicked
   * and closes the dialog
   */
  payNow(): void {
    this.props.onPayment()
    this.closeDialog()
  }

  componentWillUnmount(): void {
    PubSub.unsubscribe(
      'dialog-insufficient-funds-show'
    )
    PubSub.unsubscribe(
      'dialog-insufficient-funds-close'
    )
  }
  /**
    * Publishes event that refund is clicked
    * and closes the dialog
    */
  payWithCard(): void {
    PubSub.publish('dialog-topup-show')
    this.closeDialog()
  }
  /**
   * Renders the dialog
   * @returns
   */
  render(): ReactJSXElement {
    const { org, maximumDeficit } = this.state
    const supportedCurrencies = org.supportedCurrencies[0]
    const purseAfter = this.props.cart.purseAfter * -1;
    return (
      <>
        <Dialog
          id="DialogPayment"
          open={this.state.showDialog}
          keepMounted
          onClose={() => this.closeDialog()}
        >
          <StyledDialogTitleNormal>INSUFFICIENT FUNDS</StyledDialogTitleNormal>
          <DialogContent sx={{ px: 2}}>
            {
              maximumDeficit > 0 && (purseAfter <= maximumDeficit) ?
                <DialogContentText className="DialogContentText">
                  <Box>
                    <StyledDialogText sx={{display:'inline'}}>
                      Please confirm that you authorise the overspend/credit of</StyledDialogText> {supportedCurrencies.symbol}
                      <StyledDialogHighlightText sx={{display:'inline'}}>{number_format(this.props.cart.total, 2)}</StyledDialogHighlightText> <StyledDialogText sx={{display:'inline'}}>leaving the purse at</StyledDialogText>
                      <StyledDialogHighlightText sx={{display:'inline'}}>-{supportedCurrencies.symbol}
                      {number_format(purseAfter, 2)}</StyledDialogHighlightText><StyledDialogText sx={{display:'inline'}}>. The maximum overspend is set at</StyledDialogText> <StyledDialogHighlightText sx={{display:'inline'}}>{supportedCurrencies.symbol}{number_format(maximumDeficit, 2)}</StyledDialogHighlightText><StyledDialogText sx={{display:'inline'}}>.</StyledDialogText>
                    
                  </Box>
                </DialogContentText>
                : <DialogContentText className="DialogContentText">
                  <StyledDialogText sx={{display:'inline'}}>
                    You have insufficient funds</StyledDialogText> <StyledDialogHighlightText sx={{display:'inline'}}>-{supportedCurrencies.symbol}{number_format(this.props.cart.purseAfter * -1, 2)}</StyledDialogHighlightText> <StyledDialogText sx={{display:'inline'}}>to pay the total of</StyledDialogText> <StyledDialogHighlightText sx={{display:'inline'}}>{supportedCurrencies.symbol}
                    {number_format(this.props.cart.total, 2)}</StyledDialogHighlightText><StyledDialogText sx={{display:'inline'}}>. The maximum overspend is set at</StyledDialogText> <StyledDialogHighlightText sx={{display:'inline'}}>-{supportedCurrencies.symbol}{number_format(maximumDeficit, 2)}</StyledDialogHighlightText><StyledDialogText sx={{display:'inline'}}>.</StyledDialogText>
                </DialogContentText>
            }
          </DialogContent>
          {
            maximumDeficit > 0 && (purseAfter <= maximumDeficit) ?
              <DialogActions className="DialogActions">

                <StyledDialogCancelButton
                  onClick={() => this.closeDialog()}
                >
                  Cancel
                </StyledDialogCancelButton>
                <StyledDialogOKButton
                  onClick={() => this.payNow()}
                >
                  Overspend
                </StyledDialogOKButton>
                {
                  this.state.showCardOption ?
                    <StyledDialogOKButton
                      onClick={() => this.payWithCard()}
                    >
                      Pay With Card
                    </StyledDialogOKButton> : null
                }

              </DialogActions>
              :
              <DialogActions className="DialogActions">
                <StyledDialogCancelButton
                  onClick={() => this.closeDialog()}
                >
                  Cancel
                </StyledDialogCancelButton>
                {
                  this.state.showCardOption ? <StyledDialogOKButton
                    onClick={() => this.payWithCard()}
                  >
                    Pay With Card
                  </StyledDialogOKButton> : null
                }
              </DialogActions>
          }
        </Dialog>
      </>
    )
  }
}

export default DialogInsufficientFunds
