// import { OperationMode } from "../utils/utils";

interface ISchoolEpos{
  schoolId:string
  eposId:string
}

export const keyUpdateCartToCustomer = "update-cart-customer"

class SharedStateService {
  private KEY_SHARED_STATE = "shared_state";
  private sharedState: any;
  constructor() {
    this.sharedState = null;
  }

  public version(): string {
    return "3.0.0";
  }

  public deleteCustomer(): void {
    return this.delete("customer");
  }

  public getAuthToken(): string | null {
    return this.get("auth_token");
  }

  public getAuthTokenLastUpdated(): number {
    return this.get("auth_token_last_updated");
  }

  public getCart(): Cart {
    return this.get("cart");
  }

  public getEposIds(): ISchoolEpos[] {
    return this.get("epos_ids");
  }

  public getSchoolEposId(schoolId:string): string {
    const eposIds = this.getEposIds();
    let eposId = '';
    const schoolEposIds =  eposIds.filter((schoolEposId)=>schoolEposId.schoolId === schoolId)
    if(schoolEposIds.length > 0){
      eposId = schoolEposIds[0].eposId
    }
    return eposId;
  }

  public getEposId(): string {
    return this.get("epos_id");
  }

  public dataFetched(): boolean {
    const val = this.get("data_fetched");
    return parseInt(val) === 1 ? true : false;
  }

  public setDataFetched(value): void {
    this.set("data_fetched", value);
  }

  public getComputerName(): string {
    const { computerName } = this.getSystemInfo();
    return computerName;
  }

  public getCustomer(): Customer {
    return this.get("customer");
  }

  public getCustomerPage(): string {
    return this.get("customer_page");
  }

  public getEpos(): ApiEpos {
    return this.get("epos");
  }

  public getEposLayouts(): ApiEposLayout[] {
    return this.get("eposLayouts");
  }

  public getMacAddress(): string {
    const { macAddress } = this.getSystemInfo();
    return macAddress;
  }

  public getManagerSessionStartedAt(): Date {
    const sessionStartedAt = this.get("manager_session_started_at");

    if (sessionStartedAt == null) {
      return null;
    }

    return new Date(sessionStartedAt);
  }

  public getManagerToken(): string {
    return this.get("manager_token");
  }

  public getMenuCategories(): ApiMenuCategory[] {
    return this.get("menuCategories");
  }

  public getMenuItems(): ApiMenuItem[] {
    return this.get("menuItems");
  }

  public getMenuPromos(): ApiMenuPromotion[] {
    return this.get("menuPromos");
  }

  public getOfflineTransactions(): any[] {
    return this.get("offlineTransactions");
  }

  public getOperators(): ApiEposUser[] {
    const schoolId = this.getSchoolId();
    let operators:ApiEposUser[] = this.get("operators");
    if(operators){
      operators = operators.filter((operator)=> operator.schoolIds && (operator.schoolIds.findIndex((id)=>id === schoolId) !== -1))
    }else{
      operators=[]
    }
    return operators;
  }

  public getOperator(): ApiEposUser {
    return this.get("operator");
  }

  public getOperationMode(): string {
    return this.get("operationMode");
  }

  public setOperationMode(mode:string): void {
    this.set("operationMode",mode);
  }

  public getOperatorSessionStartedAt(): Date {
    const sessionStartedAt = this.get("operator_session_started_at");

    if (sessionStartedAt == null) {
      return null;
    }

    return new Date(sessionStartedAt);
  }

  public getOperatorPage(): string {
    return this.get("operator_page");
  }

  public getOrg(): ApiOrgs {
    return this.get("organisation");
  }

  public getPendingOrders(): PendingOrder[] {
    const pendingOrders = this.get("pending_orders");

    if (pendingOrders) {
      return pendingOrders;
    }

    return [];
  }

  public getPurse(): ApiPurse[] {
    return this.get("purse");
  }

  public getSubsidies(): ApiSubsidy[] {
    return this.get("subsidies");
  }

  public getSchoolId(): string {
    return this.get("school_id");
  }

  public getSchool(): ApiSchool {
    return this.get("school");
  }

  public getSittings(): ApiMenuSitting[] {
    return this.get("sittings");
  }

  public getSystemInfo(forceLatest?: boolean): EposMeta {
    const info = this.get("system_info");
    if (info) return forceLatest ? { ...info, version: this.version() } : info;
    return {
      ...info,
      computerName: null,
      macAddress: "",
      os: "",
      ipAddress: "",
      platform: "",
      version: this.version(),
      offline: false,
    };
  }

  public getSuitabilities(): ApiSuitability[] {
    return this.get("suitabilities");
  }

  public getTodayMenu(): ApiMenu[] {
    return this.get("todaymenu");
  }

  public getAllergens(): ApiAllergens[] {
    return this.get("allergens");
  }

  public getIngredients(): ApiIngredient[] {
    return this.get("ingredients");
  }

  public getProductIngredients(): ApiProductIngredient[] {
    return this.get("productIngredients");
  }

  /**
   * Checks whether the auth token exists
   */
  public hasAuthToken(): boolean {
    const authToken = this.getAuthToken();
    if (authToken == null) {
      return false;
    }

    if (authToken === "") {
      return false;
    }

    return true;
  }

  public setAuthToken(authToken: string): void {
    return this.set("auth_token", authToken);
  }

  public setAuthTokenLastUpdated(authTokenLastUpdated: number): void {
    return this.set("auth_token_last_updated", authTokenLastUpdated);
  }

  public setCart(cart: Cart): void {
    return this.set("cart", cart);
  }

  /**
   * Deprecated use setSystemInfo instead
   * @param computerName
   * @returns
   */
  // public setComputerName(computerName: string): void {
  //   return this.set("computer_name", computerName);
  // }

  public setCustomer(customer: Customer): void {
    this.set("customer", customer);
  }

  public setCustomerPage(value: string): void {
    this.set("customer_page", value);
  }

  public setEpos(value): void {
    this.setEposId(value.id);
    this.set("epos", value);
  }

  public setSchoolEposId(schoolEpos: ISchoolEpos): void {
    let eposIds = this.getEposIds()
    eposIds = eposIds ? eposIds : []
    //Find if in any case any eposId exist for schoolid this scenario can only be possible if an epos app has been deleted from database
    const findIndex = eposIds.findIndex((eposId)=>eposId.schoolId === schoolEpos.schoolId);
    if(findIndex !== -1){
      //Exist, then update the new eposId for the school
      eposIds[findIndex].eposId=schoolEpos.eposId;
    }else{
      eposIds=[...eposIds,schoolEpos]
    }
    return this.set("epos_ids", [...eposIds]);
  }

  public setEposId(eposId: string): void {
    return this.set("epos_id", eposId);
  }

  public setMenuCategories(value): void {
    this.set("menuCategories", value);
  }

  public setMenuItems(value): void {
    this.set("menuItems", value);
  }

  public setMenuPromos(value): void {
    this.set("menuPromos", value);
  }

  public setAllergens(value): void {
    this.set("allergens", value);
  }

  public setProductIngredients(value): void {
    this.set("productIngredients", value);
  }

  public setIngredients(value): void {
    this.set("ingredients", value);
  }

  public setEposLayouts(value): void {
    this.set("eposLayouts", value);
  }

  public setSchoolUsers(value): void {
    this.set("users", value);
  }

  public getSchoolUsers(): Customer[] {
    return this.get("users");
  }

  public setOperators(users:ApiEposUser[]): void {
    this.set("operators", users);
  }

  /**
   * Deprecated. Use setSystemInfo instead
   * @param value
   */
  // public setMacAddress(value): void {
  //   this.set("macaddress", value);
  // }

  public setManagerSessionStartedAt(value: Date): void {
    if (value == null) {
      this.delete("manager_session_started_at");
      return;
    }
    this.set("manager_session_started_at", value.getTime());
  }

  public setManagerToken(managerToken: string): void {
    return this.set("manager_token", managerToken);
  }

  public setOfflineTransactions(value): void {
    this.set("offlineTransactions", value);
  }

  public setOperator(value: ApiEposUser): void {
    this.set("operator", value);
  }

  public setOperatorPage(value: string): void {
    this.set("operator_page", value);
  }

  public setOperatorSessionStartedAt(value: Date): void {
    if (value === null) {
      this.delete("operator_session_started_at");
      return;
    }
    this.set("operator_session_started_at", value.getTime());
  }

  public setOrg(value: ApiOrgs): void {
    this.set("organisation", value);
  }

  public setPendingOrders(value: PendingOrder[]): void {
    this.set("pending_orders", value);
  }

  public setPurse(value): void {
    this.set("purse", value);
  }

  public getSubsidy(): ApiSubsidy[] {
    return this.get("subsidy");
  }

  public setSubsidy(value): void {
    this.set("subsidy", value);
  }

  public setSubsidies(value): void {
    this.set("subsidies", value);
  }

  public setSchoolId(schoolId: string): void {
    return this.set("school_id", schoolId);
  }

  public setSchool(value): void {
    this.set("school", value);
  }

  public setSittings(value): void {
    this.set("sittings", value);
  }

  public setSystemInfo(info: EposMeta): void {
    info.version = this.version();
    return this.set("system_info", info);
  }

  public setTodayMenu(value): void {
    this.set("todaymenu", value);
  }

  public setCurrentSittingId(value): void {
    this.set("currentSittingId", value);
  }

  public getCurrentSittingId(): string {
    return this.get("currentSittingId");
  }

  public setLastTransactionTime(value): void {
    this.set("lastTransactionTime", value);
  }

  public getLastTransactionTime(): string {
    return this.get("lastTransactionTime");
  }

  public setSuitabilities(value): void {
    this.set("suitabilities", value);
  }

  readState() {
    const sharedState = localStorage.getItem(this.KEY_SHARED_STATE);

    if (typeof sharedState === "undefined") {
      this.sharedState = {};
      return;
    }

    if (sharedState == null) {
      this.sharedState = {};
      return;
    }

    this.sharedState = JSON.parse(sharedState);
  }

  logout() {
    this.delete("operator");
  }

  clear() {
    this.delete("auth_token");
    // this.delete("school_id");
    // this.delete('organisation')
    // this.delete("todaymenu");
    // this.delete("sittings");
    // this.delete("school");
    // this.delete("subsidy");
    // this.delete("purse");
    // this.delete("menuCategories");
    // this.delete("menuItems");
    // this.delete("menuPromos");
    // this.delete("eposLayouts");
  }

  clearSchoolData() {
    /** Used when new school registers */
    
    this.delete("todaymenu");
    this.delete("sittings");
    this.delete("eposLayouts");
    this.delete("operator");
    this.delete("operators");
    
  }

  clearAll() {
    this.delete("auth_token");
    this.delete("auth_token");
    this.delete("school_id");
    // this.delete('organisation')
    this.delete("todaymenu");
    this.delete("sittings");
    this.delete("school");
    this.delete("subsidies");
    this.delete("purse");
    this.delete("menuCategories");
    this.delete("menuItems");
    this.delete("menuPromos");
    this.delete("eposLayouts");
    // this.delete('pending_orders')
    this.delete("operator_page");
    this.delete("operator");
    this.delete("operators");
    this.delete("offlineTransactions");
    this.delete("manager_token");
    this.delete("manager_session_started_at");
    this.delete("macaddress");
    this.delete("allergens");
    this.delete("epos");
    this.delete("customer_page");
    this.delete("customer");
    this.delete("computer_name");
    this.delete("cart");
    this.delete("auth_token_last_updated");
    this.delete("operator_session_started_at");
    this.delete("lastTransactionTime");
  }

  clearOperator() {
    this.delete("customer");
    this.delete("operator");
    this.delete("subsidies");
    this.delete("lastTransactionTime");
  }

  delete(key: string) {
    this.readState(); // read state from local storage

    if (typeof this.sharedState[key] === "undefined") {
      return;
    }

    delete this.sharedState[key];
    localStorage.setItem(
      this.KEY_SHARED_STATE,
      JSON.stringify(this.sharedState)
    );
  }

  get(key: string): any {
    this.readState(); // read state from local storage

    if (typeof this.sharedState[key] === "undefined") {
      return null;
    }

    return this.sharedState[key];
  }

  set(key: string, value: any) {
    this.readState(); // read state from local storage
    this.sharedState[key] = value;
    localStorage.setItem(
      this.KEY_SHARED_STATE,
      JSON.stringify(this.sharedState)
    );
  }
}

export default SharedStateService;
