import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material";
import React from "react";
import PubSub from "pubsub-js";
import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import { StyledDialogTitle } from "../../components/Dialog/StyledDialogTitle";
import { StyledDialogCancelButton, StyledDialogOKButton } from "../../components/Dialog/DialogButton";

type DialogShutdownConfirmProps = {};

type DialogShutdownConfirmState = {
  showDialog: boolean;
};

class DialogShutdownConfirm extends React.Component<
  DialogShutdownConfirmProps,
  DialogShutdownConfirmState
> {
  constructor(props) {
    super(props);
    this.state = {
      showDialog: false,
    };
  }

  componentDidMount(): void {
    PubSub.subscribe("dialog_shutdown_show", (msg, value) => {
      this.show();
    });
  }

  componentWillUnmount(): void {
    PubSub.unsubscribe("dialog_shutdown_show");
  }

  show(): void {
    this.setState({
      showDialog: true,
    });
  }

  close(): void {
    this.setState({
      showDialog: false,
    });
  }

  confirmShutDown(): void {
    this.setState({
      showDialog: false,
    });
    PubSub.publish("os_shut_down", "");
  }

  render(): ReactJSXElement {
    return (
      <>
        <Dialog
          id="DialogShutdownConfirm"
          open={this.state.showDialog}
          // TransitionComponent={Transition}
          keepMounted
          // onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          style={{
            position: "absolute",
            left: "calc(50% - 450px)",
            width: "900px",
            fontFamily: "Montserrat",
          }}
        >
          <StyledDialogTitle>
            Confirm Shut Down
          </StyledDialogTitle>
          <DialogContent sx={{minWidth:300}}></DialogContent>
          <DialogActions
            style={{ justifyContent: "center", margin: "20px 0px 40px 0px" }}
          >
            <Stack spacing={2}>
              <StyledDialogOKButton
                onClick={() => this.confirmShutDown()}
              >
                Shut Down
              </StyledDialogOKButton>
              <StyledDialogCancelButton
                onClick={() => this.close()}
              >
                Cancel
              </StyledDialogCancelButton>
            </Stack>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export default DialogShutdownConfirm;
