/* eslint-disable no-loop-func */
import React from "react";
// import FoodTile from './FoodTile'
import PubSub from "pubsub-js";
// import { BsArrowLeft } from 'react-icons/bs'
import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import {
  Typography,
  Box,
  Grid,
  Button,
  IconButton,
  Divider,
} from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
// import { promoColor, themeColor } from '../../utils/ThemeManager'
import EposLayoutItem from "./EposLayoutItem";
import FoodMenuItem from "./FoodMenuItem";
import SharedStateService from "../../services/SharedStateService";
import FoodPromoItem from "./FoodPromoItem";
import { appFonts, promoColor, secondaryColor, themeColor } from "../../utils/ThemeManager";
// import CategoryRoundedIcon from '@mui/icons-material/CategoryRounded';
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import MenuBookRoundedIcon from "@mui/icons-material/MenuBookRounded";
// import NetworkStatus from "../../components/Custom/NetworkStatus";
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import PromoFoodMenuItem from "./PromoFoodMenuItem";
import LoyaltyRoundedIcon from '@mui/icons-material/LoyaltyRounded';
import HouseRoundedIcon from '@mui/icons-material/HouseRounded';
import { ImageComponent, ImageComponentWithShadow } from "../../components/Custom/ImageComponent";
// import ClearRoundedIcon from '@mui/icons-material/ClearRounded';

// Default Values
const homeId = "8888";
const allCategoryId = "9999";
const allPromoId = "9988";

// Subselection Values
// const subPromoId = "998";
const subCategoryId = "999";

enum PanelMenuButtonType{
  home,
  categories,
  promos,
}

enum EposLayoutType {
  layout7x6 = "7x6",
  layout6x6 = "6x6",
  layout6x5 = "6x5",
  layout5x6 = "5x6",
  layout5x5 = "5x5",
  layout9x6 = "9x6",
  layout10x6 = "10x6",
}

const StyledHiddenGridItem = styled(Grid)({
  width: 100,
  height: 100,
  margin: 4,
  position: "relative",
  opacity: 0,
});

const StyledGridItem = styled(Grid)({
  width: 120,
  height: 120,
  margin: 4,
  ":hover": {
    opacity: 0.9,
    cursor: "pointer",
  },
});

const StyledPromoGridItem = styled(Grid)({
  width: 120,
  height: 120,
  margin: 4,
  ":hover": {
    opacity: 0.9,
    cursor: "pointer",
  },
});

interface PanelMenuProps {
  foodCategories?: ApiMenuCategory[];
  // foodItems?: any;
  // foodItemTiles?: any;
  // foodMenus?: any
  // selectedFoodCategoryClass?: any;
  // selectedFoodMenuId?: any;
  selectedFoodCategoryId?: any;
  mealDealEnabled?: any;
  eposLayout: any;
  menuItems?: ApiMenuItem[];
  menuPromos?: ApiMenuPromotion[];
  selectedSittingId?: any;
  onFoodItemSelection:(item:any)=>void
  onCategorySelection:(categoryId:string)=>void
  onPromoItemSelection:(promoId:string)=>void
  onShowFoodItemInfo:(menu:ApiMenu)=>void
}

type PanelMenuState = {
  selectedFoodCategoryId?: any;

  selectedPromoId?: any;
  
  /* Used to store Promo category */
  selectedPromoCategory?: ApiMenuCategory;

  /* To keep track of what was previously showing in case Promo/All Category navigations */
  previousOption?: string;

  selectedPromo?: ApiMenuPromotion;

  todayMenu?: any;

  sittingId?: any;

  processing?: boolean;

  isOnline?: boolean;

  panelActive: boolean;

  activePanelButton: PanelMenuButtonType

  hasLayout: boolean
};

class PanelMenu extends React.Component<PanelMenuProps, PanelMenuState> {
  // private headerBack: string;
  // private header: string;
  sharedStateService: SharedStateService;

  constructor(props) {
    super(props);
    this.sharedStateService = new SharedStateService();

    this.state = {
      selectedFoodCategoryId: allCategoryId,
      selectedPromo: null,
      selectedPromoCategory: null,
      selectedPromoId: allPromoId,
      sittingId: props.selectedSittingId,
      processing: false,
      isOnline: true,
      panelActive: true,
      activePanelButton: PanelMenuButtonType.home,
      hasLayout: true
    };
    // window.addEventListener("resize", this.onHandleResize);
  }

  shouldComponentUpdate(nextProps: Readonly<PanelMenuProps>, nextState: Readonly<PanelMenuState>, nextContext: any): boolean {
    if(nextProps.selectedSittingId !== this.state.sittingId){
      this.setState({sittingId:nextProps.selectedSittingId})
    }
    return true
  }

  resetAll = () => {
    this.setState({
      selectedFoodCategoryId: allCategoryId,
      selectedPromo: null,
      selectedPromoCategory: null,
      selectedPromoId: allPromoId,
      processing: false,
      isOnline: true,
      panelActive: true,
      activePanelButton: PanelMenuButtonType.home,
      hasLayout: true
    })
  }

  componentDidMount() {
    // this.onHandleResize();

    //Used to toggle the Categories by default if no layout
    PubSub.subscribe("toggle_categories", (msg, value) => {
      this.setState({
        activePanelButton: (value === true)? PanelMenuButtonType.categories: PanelMenuButtonType.home,
        selectedFoodCategoryId: (value === true)? subCategoryId: allCategoryId,
        hasLayout: !value
      });
    });

    PubSub.subscribe("isonline", (msg, value) => {
      this.setState({ isOnline: value });
    });

    // PubSub.subscribe("selected_sittingId", (msg, {sittingId}) => {
    //   this.setState({ sittingId });
    // });

    PubSub.subscribe("refund_initiated", (msg: string, className: string) => {
      this.setState({ panelActive: false });
    });

    PubSub.subscribe("refund_cancelled", (msg: string, className: string) => {
      
      console.log('refund_cancelled');
      
      this.setState({ panelActive: true });
    });

    PubSub.subscribe("onKiosk", (msg, value) => {
      if(!value){
        this.resetAll();
      }
    });

  }

  processPromoSelection=(promoId:string)=>{
    if (promoId !== "") {
      const promo = this.props.menuPromos.filter(
        (promo) => promo.id === promoId
      )[0];
      this.setState({ ...this.state, selectedPromo: promo });
    } else {
      this.setState({ ...this.state, selectedPromo: null });
    }

    this.props.onPromoItemSelection(promoId)
  }

  componentWillUnmount(): void {

      //Used to toggle the Categories by default if no layout
      PubSub.unsubscribe("toggle_categories");
  
      PubSub.unsubscribe("isonline");

      // PubSub.unsubscribe("selected_sittingId");
  
      // PubSub.subscribe("selected_sittingId", (msg, {sittingId}) => {
      //   this.setState({ sittingId });
      // });
  
      PubSub.unsubscribe("refund_initiated");
  
      PubSub.unsubscribe("refund_cancelled");

  }

  foodAllergic = (item: ApiMenuItem) => {
    //To be implemented
  };

  onHandleResize(): void {
    const foodTiles = document.querySelector<HTMLElement>(
      "#PanelMenu .FoodTile"
    );
    if (foodTiles) {
      foodTiles.style.display = "none"; // hide tiles so that we can determin correct width, without stretching the container
    }
    const grid = document.querySelector<HTMLElement>(
      "#PanelMenu .grid-container"
    );
    const panelMenu = document.getElementById("PanelMenu");

    if (grid && panelMenu) {
      const width = panelMenu.offsetWidth;
      console.log(width);
      if (width < 280) {
        grid.style.gridTemplateColumns = "100%";
      } else if (width < 420) {
        grid.style.gridTemplateColumns = "50% 50%";
        grid.style.maxWidth = 10 + 115 + 20 + 115 + 10 + "px";
      } else if (width < 560) {
        grid.style.gridTemplateColumns = "33% 33% 33%";
        grid.style.maxWidth = 10 + 115 + 20 + 115 + 20 + 115 + 10 + "px";
      } else if (width < 700) {
        grid.style.gridTemplateColumns = "25% 25% 25% 25%";
        grid.style.maxWidth =
          10 + 115 + 20 + 115 + 20 + 115 + 20 + 115 + 10 + "px";
      } else {
        grid.style.gridTemplateColumns = "20% 20% 20% 20% 20%";
        grid.style.maxWidth =
          10 + 115 + 20 + 115 + 20 + 115 + 20 + 115 + 20 + 115 + 10 + "px";
      }
    }
    if (foodTiles) {
      document.querySelector<HTMLElement>(
        "#PanelMenu .FoodTile"
      ).style.display = "block";
    }
  }

  determineNumRows(numCols: number, items: any[]): number {
    let numRows: number = Math.floor(items.length / numCols);

    //Handle max cols in case of less data
    if (items.length < numCols) {
      numCols = items.length;
    }

    if (numRows === 0 && items.length > 0) {
      numRows = 1;
    }

    if (numRows === 0) {
      return null;
    }

    const diff = items.length - numCols * numRows;
    // If there's any difference add extra row
    if (diff > 0) {
      numRows += 1;
    }

    return numRows;
  }

  renderPromoItems(): any {
    // Find all categories and items related to the Promo

    let numCols: number = 7;
    

    const todayMenu = this.sharedStateService.getTodayMenu();

    if (!todayMenu) return null;

    const sittingMenuItems = todayMenu.filter(
      (menu) => menu.menuSittingId === this.state.sittingId
    );

    if (sittingMenuItems.length === 0) return null;

    const layoutButtons = [];

    const rulePromos:PromoMenu[] = [];

    // let ruleIndex = 1;
    //Promotions should be listed based on the Rules
    this.state.selectedPromo.rules.forEach((rule, index) => {
      const ruleIndex = index + 1
      let promoItems:ApiMenuItem[] = [];
      // Prepare categories menu items
      if (rule.menuCategoryIds) {
        rule.menuCategoryIds.forEach((catID) => {
          const filtered = sittingMenuItems.filter(
            (menuItem) =>
              menuItem.menuCategoryIds &&
              menuItem.menuCategoryIds.includes(catID)
          );
          const menuItems = this.props.menuItems;
          filtered.forEach((item) => {
            const foundItems = menuItems.filter(
              (data) => data.id === item.menuItemId
            );
            if(foundItems.length>0){
              const menuItem = foundItems[0]
              menuItem.rule = {...rule,id:`rule-${ruleIndex}`};
              promoItems.push(menuItem);
            }
          });
        });
      }
      //Prepare Menu Items
      if (rule.menuItemIds) {
        const menuItems = this.props.menuItems;
        rule.menuItemIds.forEach((menuItemID) => {
          const foundItems = menuItems.filter(
            (data) => data.id === menuItemID
          );
          if(foundItems.length>0){
            const menuItem = foundItems[0]
            menuItem.rule = {...rule,id:`rule-${ruleIndex}`};;
            promoItems.push(menuItem);
          }
        });
      }
    const uniqueItems = [...new Set(promoItems)];

    promoItems = uniqueItems

    rulePromos.push({ rule: { ...rule, id: `rule-${index}` }, menuItems: promoItems });

    });

  layoutButtons.push(
    <Grid
      container
      display="flex"
      justifyContent="center"
      flexDirection="column"
    >
      <Grid item xs={12}>
        <Typography pb={1} color={themeColor} fontWeight={'bold'}>{this.state.selectedPromo.name}</Typography>
        <Divider />
      </Grid>
    </Grid>
  )  

    
  rulePromos.forEach((item, index) => {
      const { rule, menuItems } = item;

      //Add Gap
      if (this.state.selectedPromo.rules.length > 1 && index !== 0) {
        layoutButtons.push(
          <Grid px={4} py={2}>
            {" "}
            <Divider />{" "}
          </Grid>
        );
      }

      layoutButtons.push(
        <Grid
          container
          display="flex"
          justifyContent="center"
          key={`promition-rule-${index}`}
          flexDirection="column"
        >
          <Typography fontWeight="bold">{rule.name}</Typography>
          <Box display="flex" justifyContent="center" py={1}>
              <Typography display="inline" fontSize={14}>Select</Typography>
              {/* <Typography display="inline" ml={0.5} fontSize={14} color="white">{`${rule.min} /`}</Typography> */}
              <Typography display="inline" ml={0.5} fontSize={14}> max of </Typography>
              <Typography display="inline" ml={0.5} fontSize={14} color={themeColor} fontWeight="bold">{`${rule.max}`}</Typography>
              <Typography display="inline" ml={0.5} fontSize={14}>{rule.max > 1 ? 'items' : 'item'}</Typography>
            </Box>
        </Grid>
      );

      if (menuItems.length > 0) {
        let numRows: number = this.determineNumRows(numCols, menuItems);
        let cols = numCols;

        //Handle max cols in case of less data
        if (menuItems.length < cols) {
          cols = menuItems.length;
        }
        let position = 0;
        const diff = menuItems.length - cols * numRows;
        for (let row = 0; row < numRows; row += 1) {
          const colButtons = [];
          const applicableCols =
            row === numRows - 1 ? (diff > 0 ? diff : cols) : cols;
          for (let col = 0; col < applicableCols; col += 1) {
            const menuItem = menuItems[position];

            if(menuItem){
              position += 1;
              colButtons.push(
                <PromoFoodMenuItem
                  isOnline={this.state.isOnline}
                  rule={rule}
                  menuItem={{...menuItem,rule:rule}}
                  key={`subcat-item-${menuItem.name}`}
                  menuCategories={this.props.foodCategories}
                  sittingId={this.state.sittingId}
                  onFoodItemSelection={this.props.onFoodItemSelection}
                  onShowFoodItemInfo={this.props.onShowFoodItemInfo}
                />
              );
            }
          }

          layoutButtons.push(
            <Grid
              container
              display="flex"
              justifyContent="center"
              key={`subcat-row-${(index+1)*(row+1)}`}
            > 
              {colButtons}
            </Grid>
          );
        }
      }
    });
    return layoutButtons;
  }

  rowsColsForLayout(){
    let numRows: number = 0;
    let numCols: number = 0;
    if(this.props.eposLayout){
      const { buttonGrid } = this.props.eposLayout;
        // Determine numRows and numCols by buttonGrid
        if (buttonGrid === EposLayoutType.layout6x6) {
          numRows = 6;
          numCols = 6;
        } else if (buttonGrid === EposLayoutType.layout7x6) {
          numCols = 7;
          numRows = 6;
        } else if (buttonGrid === EposLayoutType.layout6x5) {
          numCols = 6;
          numRows = 5;
        } else if (buttonGrid === EposLayoutType.layout5x6) {
          numCols = 5;
          numRows = 6;
        } else if (buttonGrid === EposLayoutType.layout5x5) {
          numRows = 5;
          numCols = 5;
        } else if (buttonGrid === EposLayoutType.layout9x6) {
          numRows = 6;
          numCols = 9;
        } else if (buttonGrid === EposLayoutType.layout10x6) {
          numRows = 6;
          numCols = 10;
        }
    }

      return {cols:numCols, rows:numRows}

  }

  renderLayoutItemTiles(): any {
    let buttons: ApiButton[] = [];
    let numRows: number = 0;
    let numCols: number = 0;
    if(!this.props.eposLayout) return null;
    if (this.props.eposLayout.buttons) {
      buttons = this.props.eposLayout.buttons;
      const {cols, rows} = this.rowsColsForLayout()
      numRows = rows;
      numCols = cols;
    }
    if (buttons.length > 0) {
      let position = 1;
      const layoutButtons = [];
      for (let row = 0; row < numRows; row += 1) {
        const colButtons = [];
        for (let col = 0; col < numCols; col += 1) {
          const button = buttons.filter(
            (button) => button.position === `${position}` && button.type !== 'blank'
          )[0];
          
          position += 1;
          colButtons.push(
            button ? (
              <EposLayoutItem
                button={button}
                key={`layout-button-${position}`}
                menuItems={this.props.menuItems || []}
                menuPromos={this.props.menuPromos || []}
                menuCategories={this.props.foodCategories || []}
                isOnline={this.state.isOnline}
                sittingId={this.state.sittingId}
                onFoodItemSelection={this.props.onFoodItemSelection}
                onPromoItemSelection={(promoId:string)=>{ this.processPromoSelection(promoId) }}
                onShowFoodItemInfo={this.props.onShowFoodItemInfo}
              />
            ) : (
              <StyledHiddenGridItem key={`${position}`} />
            )
          );
        }

        layoutButtons.push(
          <Grid
            container
            display="flex"
            justifyContent="center"
            key={`grid-${position}`}
          >
            {colButtons}
          </Grid>
        );
      }
      return layoutButtons;
    }

    return null;
  }

  renderItemTilesByCategory = (selectedCategoryId: string) => {
    /** Function to determine the menuItem and menuPromoItem based on selected category from the available menu*/
    const { selectedPromoCategory } = this.state;

    const todayMenu = this.sharedStateService.getTodayMenu();

    if (!todayMenu) return null;

    const sittingMenuItems = todayMenu.filter(
      (menu) => menu.menuSittingId === this.state.sittingId
    );

    if (sittingMenuItems.length === 0) return null;

    const filtered = sittingMenuItems.filter(
      (menuItem) =>
        menuItem.menuCategoryIds &&
        menuItem.menuCategoryIds.includes(selectedCategoryId)
    );

    if (filtered.length === 0) return null;

    const {cols} = this.rowsColsForLayout()

    let numCols: number = cols !== 0 ? cols : 7;
    let numRows: number = Math.floor(filtered.length / numCols);

    //Handle max cols in case of less data
    if (filtered.length < numCols) {
      numCols = filtered.length;
    }

    if (numRows === 0 && filtered.length > 0) {
      numRows = 1;
    }

    if (numRows === 0) {
      return null;
    }

    const diff = filtered.length - numCols * numRows;
    // If there's any difference add extra row
    if (diff > 0) {
      numRows += 1;
    }
    let position = 0;
    const layoutButtons = [];
    for (let row = 0; row < numRows; row += 1) {
      const colButtons = [];
      const cols = row === numRows - 1 ? (diff > 0 ? diff : numCols) : numCols;
      for (let col = 0; col < cols; col += 1) {
        const menu = filtered[position];
        //If the items are listed from Promo Category selection pass the promo category rule to the item for futher processing
        if (selectedPromoCategory) {
          menu.rule = selectedPromoCategory.rule;
        }
        position += 1;
        if (menu.menuItemId) {
          colButtons.push(
            <FoodMenuItem
              isOnline={this.state.isOnline}
              menu={menu}
              key={`subcat-item-${menu.menuItemId}`}
              menuCategories={this.props.foodCategories}
              onFoodItemSelection={this.props.onFoodItemSelection}
              onShowFoodItemInfo={this.props.onShowFoodItemInfo}
            />
          );
        } else {
          colButtons.push(
            <FoodPromoItem
              menu={menu}
              key={`subcat-item-${menu.menuPromotionId}`}
              onFoodItemSelection={this.props.onFoodItemSelection}
            />
          );
        }
      }

      layoutButtons.push(
        <Grid
          container
          display="flex"
          justifyContent="center"
          key={`subcat-row-${row}`}
        >
          {colButtons}
        </Grid>
      );
    }
    return layoutButtons;
  };

  itemsAvailableForCategory = (categoryId: string) => {
    const todayMenu = this.sharedStateService.getTodayMenu();

    if (!todayMenu) return null;

    const sittingMenuItems = todayMenu.filter(
      (menu) => menu.menuSittingId === this.state.sittingId
    );

    if (sittingMenuItems.length === 0) return null;

    const filtered = sittingMenuItems.filter(
      (menuItem) =>
        menuItem.menuCategoryIds &&
        menuItem.menuCategoryIds.includes(categoryId)
    );

    if (filtered.length === 0) return false;

    return true;
  };

  sittingHasPromo = () => {
    const todayMenu = this.sharedStateService.getTodayMenu();

    if (!todayMenu) return null;

    const sittingMenuItems = todayMenu.filter(
      (menu) => menu.menuSittingId === this.state.sittingId
    );

    if (sittingMenuItems.length === 0) return null;

    const filterItems = sittingMenuItems.filter(
      (item) => item.menuPromotionId !== null,
    )
    if (filterItems.length === 0) return false;

    return true;
   
  };

  promoAvailableForID = (promoId: string) => {
    const todayMenu = this.sharedStateService.getTodayMenu();

    if (!todayMenu) return null;

    const sittingMenuItems = todayMenu.filter(
      (menu) => menu.menuSittingId === this.state.sittingId
    );

    if (sittingMenuItems.length === 0) return null;

    const filterItems = sittingMenuItems.filter(
      (item) => item.menuPromotionId === promoId,
    )
    if (filterItems.length === 0) return false;

    return true;
   
  };

  renderCategoryTiles(): any {
    /** Function to show available categories when ALL selected  */

    let arrItems:any[] = [];
    arrItems=[...this.props.foodCategories];


    let numCols: number = 5;
    let numRows: number = Math.floor(arrItems.length / numCols);

    //Handle max cols in case of less data
    if (arrItems.length < numCols) {
      numCols = arrItems.length;
    }

    const diff = arrItems.length - numCols * numRows;
    // If there's any difference add extra row
    if (diff > 0) {
      numRows += 1;
    }
    let position = 0;
    const layoutButtons = [];
    for (let row = 0; row < numRows; row += 1) {
      const colButtons = [];
      for (let col = 0; col < numCols; col += 1) {
        if(position<arrItems.length){
          const category = arrItems[position];
          const itemsAvailable = this.itemsAvailableForCategory(category.id);
          colButtons.push(
            <StyledGridItem
              item
              key={category.id}
              onClick={() => {
                const categoryId = category.id;
                this.props.onCategorySelection(categoryId)
                this.setState({ selectedFoodCategoryId: categoryId });
              }}
              sx={{
                opacity: itemsAvailable ? 1.0 : 0.5,
                pointerEvents: itemsAvailable ? "all" : "none",
              }}
            >
              <Box
                boxShadow={2}
                borderRadius={2}
                sx={{
                  backgroundColor: category.defaultColour,
                  width: "100%",
                  height: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "table",
                  tableLayout: "fixed",
                }}
              >
                <Typography
                  sx={{
                    wordWrap: "break-word",
                    display: "table-cell",
                    verticalAlign: "middle",
                    overflow: "hidden",
                  }}
                  color="white"
                >
                  {category.name}
                </Typography>
              </Box>
            </StyledGridItem>
          );
          position += 1;
        }
      }
      layoutButtons.push(
        <Grid
          container
          display="flex"
          justifyContent="center"
          key={`cat-row-${row}`}
        >
          {colButtons}
        </Grid>
      );
    }
    return layoutButtons;
  }

  renderPromoTiles(): any {
    /** Function to show available promos  */

    // const todayMenu = this.sharedStateService.getTodayMenu();
    // const sittingMenuItems = todayMenu.filter(
    //   (menu) => menu.menuSittingId === this.state.sittingId
    // );

    // const promos = sittingMenuItems.filter(
    //   (item) => item.menuPromotionId !== null,
    // )

    // const filteredPromos = []

    // promos.forEach((promo)=>{
    //   this.props.menuPromos.forEach((promoItem)=>{
    //     if(promo.menuPromotionId === promoItem.id){
    //       filteredPromos.push(promoItem);
    //     }
    //   })
    // })

    if(!this.props.menuPromos)return null

    let arrItems:any[] = [];
    arrItems=[...this.props.menuPromos];


    let numCols: number = 5;
    let numRows: number = Math.floor(arrItems.length / numCols);

    //Handle max cols in case of less data
    if (arrItems.length < numCols) {
      numCols = arrItems.length;
    }

    const diff = arrItems.length - numCols * numRows;
    // If there's any difference add extra row
    if (diff > 0) {
      numRows += 1;
    }
    let position = 0;
    const layoutButtons = [];
    for (let row = 0; row < numRows; row += 1) {
      const colButtons = [];
      for (let col = 0; col < numCols; col += 1) {
        if(position<arrItems.length){
          const category = arrItems[position];
          const itemsAvailable = this.promoAvailableForID(category.id);
          colButtons.push(
            <StyledPromoGridItem
              item
              key={category.id}
              onClick={() => {
                this.setState({ ...this.state, selectedPromo: category });
                this.processPromoSelection(category.id);
              }}
              sx={{
                opacity: itemsAvailable ? 1.0 : 0.5,
                pointerEvents: itemsAvailable ? "all" : "none",
              }}
            >
              <Box
                boxShadow={2}
                borderRadius={2}
                sx={{
                  backgroundColor: promoColor,
                  width: "100%",
                  height: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "table",
                  tableLayout: "fixed",
                  overflow:'hidden',
                  position:'relative'
                }}
              > 
                {
                  category.imageUri?
                  <Box>
                    <ImageComponentWithShadow
                      src={category.imageUri} 
                      alt={category.name}
                      width={'100%'}
                      height={'100%'}
                    />
                    <Typography
                      fontSize={10}
                      color={'white'}
                      sx={{backgroundColor:themeColor, position:'absolute', bottom:0, left:0, right:0}}
                    >
                      {category.name}
                    </Typography>
                  </Box>
                  :<Typography
                      sx={{
                        wordWrap: "break-word",
                        display: "table-cell",
                        verticalAlign: "middle",
                        overflow: "hidden",
                      }}
                      fontSize={14}
                      color={secondaryColor}
                    >
                      {category.name}
                    </Typography>
                }
              </Box>
            </StyledPromoGridItem>
          );
          position += 1;
        }
      }
      layoutButtons.push(
        <Grid
          container
          display="flex"
          justifyContent="center"
          key={`cat-row-${row}`}
        >
          {colButtons}
        </Grid>
      );
    }
    return layoutButtons;
  }

  renderLayouts = () => {
    const {
      selectedFoodCategoryId,
      selectedPromo,
      activePanelButton,
    } = this.state;

    /**
     * Select category to list category items of it
     */
    const categoryIdForTiles = (activePanelButton === PanelMenuButtonType.categories) && (selectedFoodCategoryId !== subCategoryId)
    ? selectedFoodCategoryId : ""
      

    const showLayout =
      (activePanelButton === PanelMenuButtonType.home) && !selectedPromo;

    if (showLayout) {
      return this.renderLayoutItemTiles();
    } else if(activePanelButton === PanelMenuButtonType.promos && !selectedPromo){
      return this.renderPromoTiles();
    } else if (!selectedPromo && activePanelButton === PanelMenuButtonType.categories && categoryIdForTiles === "") {
      return this.renderCategoryTiles();
    }else if (categoryIdForTiles !== "" && !selectedPromo) {
      return this.renderItemTilesByCategory(categoryIdForTiles);
    } /*else if (selectedPromoCategory && selectedPromo) {
      return this.renderItemTilesByCategory(selectedPromoCategory.id);
    }*/ else if (selectedPromo) {
      return this.renderPromoItems();
    }

    return null;
  };

  
  /** This function list buttons on the top bar */
  renderAllCategory = () => {
    let categoriesToDisplay = [];

    if(this.state.hasLayout){
      categoriesToDisplay=[
        { id: homeId, name: "Home", defaultColour: themeColor, type:PanelMenuButtonType.home },
        { id: allCategoryId, name: "Categories", defaultColour: themeColor, type:PanelMenuButtonType.categories },
      ];
    }else{
      categoriesToDisplay=[
        { id: allCategoryId, name: "Categories", defaultColour: themeColor, type:PanelMenuButtonType.categories },
      ];
    }
    const hasPromos = this.props.menuPromos && this.props.menuPromos.length>0

    if(hasPromos){
      categoriesToDisplay=[...categoriesToDisplay,{ id: allPromoId, name: "Promos", defaultColour: themeColor, type:PanelMenuButtonType.promos } ];
    }
    const { activePanelButton } = this.state;
    return categoriesToDisplay.map((category) => {
      const selected = (category.type === activePanelButton);
      const opacity = 1.0;
      return (
        <Box
          key={category.type}
          sx={{
            backgroundImage: !selected
              ? "none"
              : "linear-gradient(to bottom, #ff4747 0%, #d00000 100%)",
            alignItems: "center",
            border: selected?`${alpha(themeColor,0.1)} 2px solid`:`${themeColor} 2px solid`,
            opacity: opacity,
            mr:0.5,
            ml:1,
            borderTopLeftRadius:10,
            borderTopRightRadius:10,
            height:'30px',
            borderBottom:'none',
            mt:1.4
          }}
          onClick={() => {
            this.setState({ processing: true });

            if(category.type === PanelMenuButtonType.promos){
                // Toggle Promo selection on/off
                if(this.state.selectedPromo){
                  this.setState({
                      selectedPromoId: allPromoId,
                      processing: false,
                      activePanelButton: PanelMenuButtonType.promos,
                      selectedPromo:null
                  })
                }else{
                  //This will reset to show all categories
                  this.setState({
                    selectedPromoId:allPromoId,
                    activePanelButton: PanelMenuButtonType.promos,
                    processing: false,
                    selectedPromo:null
                  });
                }
            }else if(category.type === PanelMenuButtonType.categories){
              const {selectedFoodCategoryId}=this.state
              const categoryIdForTiles =(selectedFoodCategoryId !== subCategoryId) ? (selectedFoodCategoryId === "") ? (this.state.hasLayout?allCategoryId : subCategoryId) : selectedFoodCategoryId : ""
                  // Toggle Promo selection on/off
                if(categoryIdForTiles !== ""){
                  this.setState({
                      selectedFoodCategoryId: "",
                      processing: false,
                      activePanelButton: PanelMenuButtonType.categories,
                      selectedPromo:null,
                      selectedPromoId:allPromoId,
                  })
                }else{
                  //This will reset to show all categories
                  this.setState({
                    selectedFoodCategoryId: this.state.hasLayout?allCategoryId:subCategoryId,
                    activePanelButton: PanelMenuButtonType.categories,
                    processing: false,
                    selectedPromo:null,
                    selectedPromoId:allPromoId,
                  });
                }
            }else{
              //Naviagte to Home
              this.setState({
                selectedFoodCategoryId: this.state.hasLayout?allCategoryId:subCategoryId,
                activePanelButton: this.state.hasLayout?PanelMenuButtonType.home:PanelMenuButtonType.categories,
                processing: false,
                selectedPromoId:allPromoId,
                selectedPromo:null,
              });
            }
          }}
        >
          <Button sx={{ color: !selected ? themeColor: "white", px:1, py:0.5}}>
            {category.type === PanelMenuButtonType.home?
              <HouseRoundedIcon sx={{ color: !selected ? themeColor : "white", fontSize: 18, px: 0.5 }} />:
              category.type === PanelMenuButtonType.categories?
              <MenuBookRoundedIcon
                sx={{ color: !selected ? themeColor : "white", fontSize: 18, px: 0.5 }}
              />:
              <LoyaltyRoundedIcon sx={{ color: !selected ? themeColor : "white", fontSize: 18, px: 0.5 }} />
            }
            {category.name}
          </Button>

          {/* <Button sx={{ color: "white", height: "33px" }}>
            {category.type === PanelMenuButtonType.categories ? (
            this.state.selectedFoodCategoryId !== allCategoryId ? (
              <ArrowBackRoundedIcon sx={{ color: "white" }} />
            ) : (
              <MenuBookRoundedIcon
                sx={{ color: "white", fontSize: 18, px: 0.5 }}
              />
            ) ) : this.state.selectedPromoId !== allPromoId ? (
              <ArrowBackRoundedIcon sx={{ color: "white" }} />
            ) : (
              <LoyaltyRoundedIcon sx={{ color: "white", fontSize: 18, px: 0.5 }} />
            ) }

            {(category.type === PanelMenuButtonType.categories && 
            this.state.selectedFoodCategoryId !== allCategoryId) || (category.type === PanelMenuButtonType.promos && 
              this.state.selectedPromoId !== allPromoId)
              ? null
              : category.name}
          </Button> */}
        </Box>
      );
    });
  };

  renderBackButton = () => {
    const { selectedPromoCategory } = this.state;
    return (
      <Box
        height="100%"
        sx={{
          backgroundImage:
            "linear-gradient(to bottom, #ff4747 0%, #d00000 100%)",
          alignItems: "center",
          borderRight: "white 1px solid",
        }}
        onClick={() => {
          if (selectedPromoCategory) {
            this.setState({ ...this.state, selectedPromoCategory: null });
          } else {
            this.processPromoSelection("")
            this.setState({ ...this.state, selectedPromo: null });
          }
        }}
      >
        <Button sx={{ color: "white", height: "33px" }}>
          <ArrowBackRoundedIcon sx={{ color: "white" }} />{" "}
          {selectedPromoCategory ? (
            <Typography color={"white"} fontSize={14}>
              {" "}
              {selectedPromoCategory.name}
            </Typography>
          ) : null}
        </Button>
      </Box>
    );
  };

  render(): ReactJSXElement {
    const { processing, selectedPromo, panelActive, activePanelButton } = this.state;
    const todayMenu = this.sharedStateService.getTodayMenu();
    const menuAvailable = todayMenu && todayMenu.length > 0;

    /*let categoriesPaths = 'Categories';
    let promoPaths = 'Promos';
    if(selectedPromo){
      promoPaths = `${promoPaths} / ${selectedPromo.name}`
    }

    if(selectedFoodCategoryId !== "" && selectedFoodCategoryId !== allCategoryId && selectedFoodCategoryId !== subCategoryId){
      const cats = this.props.foodCategories
      const filtered = cats.filter((cat)=>cat.id === selectedFoodCategoryId);
      if(filtered.length>0){
        categoriesPaths = `${categoriesPaths} / ${filtered[0].name}`
      }
      
    }*/
    // const title = (activePanelButton === PanelMenuButtonType.home) ? 'Home' : (activePanelButton === PanelMenuButtonType.categories) ? categoriesPaths : promoPaths
    const {selectedFoodCategoryId}=this.state
    const categoryIdForTiles =(selectedFoodCategoryId !== subCategoryId) ? (selectedFoodCategoryId === "") ? (this.state.hasLayout?allCategoryId : subCategoryId) : selectedFoodCategoryId : ""

    return (
      <Grid>
        <Grid container>
          <Grid item xs={12}>
            {/* <Box height="15px" sx={{ backgroundColor:'#F5F5F5' }} display="flex" justifyContent="flex-start" pl={1.5}>
              <Typography fontSize={12} textTransform="uppercase" color={ themeColor }>{title}</Typography>
            </Box> */}
            <Box
              sx={{
                height: "45px",
                boxShadow:
                  "0px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 0px rgba(0, 0, 0, 0.12)",
                cursor: "pointer",
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              <Grid container borderBottom={`${themeColor} 2px solid`}>
                <Grid
                  key="g1"
                  item
                  xs={selectedPromo?2:8}
                  display="flex"
                  sx={{
                    opacity: panelActive ? 1.0 : 0.0,
                    pointerEvents: panelActive ? "all" : "none",
                  }}
                >
                  {menuAvailable
                    ? this.renderAllCategory()
                    : null}
                </Grid>
                {/* {selectedPromo ? (
                  <Grid
                    item
                    xs
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    key="g2"
                  >
                    <Typography
                      variant="h6"
                      fontSize={16}
                      color={themeColor}
                    >{`Showing ${selectedPromo.name}`}</Typography>
                  </Grid>
                ) : ( */}
                  <Grid key="g3" item xs />
                {/* )} */}
                <Grid
                  item
                  xs={2}
                  height="45px"
                  display="flex"
                  justifyContent="flex-end"
                  key="g4"
                />
                <Grid
                  item
                  width="50px"
                  height="45px"
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                  key="g5"
                >
                  {
                    ((activePanelButton === PanelMenuButtonType.promos) && this.state.selectedPromo) || ((activePanelButton === PanelMenuButtonType.categories) && selectedFoodCategoryId !== "")?
                      <IconButton
                        color="primary"
                        onClick={() => {
                          if(activePanelButton === PanelMenuButtonType.promos){
                            // Toggle Promo selection on/off
                            if(this.state.selectedPromo){
                              this.setState({
                                  selectedPromoId: allPromoId,
                                  processing: false,
                                  activePanelButton: PanelMenuButtonType.promos,
                                  selectedPromo:null
                              })
                            }else{
                              this.setState({
                                selectedFoodCategoryId: this.state.hasLayout?allCategoryId:subCategoryId,
                                activePanelButton: this.state.hasLayout?PanelMenuButtonType.home:PanelMenuButtonType.categories,
                                processing: false,
                                selectedPromoId:allPromoId,
                              });
                            }
                          }else if(activePanelButton === PanelMenuButtonType.categories){
                            
                                // Toggle Promo selection on/off
                              if(categoryIdForTiles !== ""){
                                this.setState({
                                    selectedFoodCategoryId: "",
                                    processing: false,
                                    activePanelButton: PanelMenuButtonType.categories,
                                    selectedPromo:null,
                                    selectedPromoId:allPromoId,
                                })
                              }else{
                                this.setState({
                                  selectedFoodCategoryId: this.state.hasLayout?allCategoryId:subCategoryId,
                                  activePanelButton: this.state.hasLayout?PanelMenuButtonType.home:PanelMenuButtonType.categories,
                                  processing: false,
                                  selectedPromoId:allPromoId,
                                });
                              }
                          }else{
                            //Naviagte to Home
                            this.setState({
                              selectedFoodCategoryId: this.state.hasLayout?allCategoryId:subCategoryId,
                              activePanelButton: this.state.hasLayout?PanelMenuButtonType.home:PanelMenuButtonType.categories,
                              processing: false,
                              selectedPromoId:allPromoId,
                            });
                          }
                        }}
                      >
                        <ArrowBackIosNewOutlinedIcon />
                    </IconButton>
                    :null
                  }
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt={2}
          >
            <Grid
              container
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12}>
                {!panelActive ? (
                  <Box
                    height={"calc(100vh - 120px)"}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Button
                      variant="contained"
                      sx={{
                        cursor: "pointer",
                        background:
                          "linear-gradient(180deg, #87c09f 0%, #417b5a 100%)",
                        color: "white",
                        p: 4,
                        fontFamily: appFonts.Paytone,
                        fontSize: 20,
                      }}
                      onClick={() => {
                        PubSub.publish("refund_cancelled");
                      }}
                    >
                      Cancel Refund
                    </Button>
                  </Box>
                ) : processing ? null : menuAvailable ? (
                  this.renderLayouts()
                ) : (
                  <Box
                    height={"calc(100vh - 120px)"}
                    sx={{ opacity: 0.5 }}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Typography variant="h2">No Menu Today</Typography>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default PanelMenu;
