import { Button, Grid, Paper, Typography } from '@mui/material'
import { themeColor } from '../../utils/ThemeManager'
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded'
import { useEffect } from 'react'

interface IScreenProps {
  autoclose?: boolean
  message: string
  closeButtonText: string
  onClose: () => void
}

function AlertBox({
  autoclose,
  message,
  closeButtonText,
  onClose,
}: IScreenProps) {
  useEffect(() => {
    if (autoclose) {
      setInterval(() => {
        onClose()
      }, 3000)
    }
  })

  return (
    <Grid container>
      <Grid item xs={12} display="flex" justifyContent="center">
        <Paper sx={{ p: 2 }} elevation={5}>
          <ErrorRoundedIcon sx={{ color: themeColor, fontSize: 40 }} />
          <Typography color={themeColor} fontSize={18} fontWeight={500}>
            {message}
          </Typography>
          <Button variant="contained" sx={{ mt: 5 }} onClick={onClose}>
            {closeButtonText}
          </Button>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default AlertBox

AlertBox.defaultProps = {
  autoclose: true,
}
