import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  styled,
  Typography,
} from '@mui/material'
import React from 'react'
import PubSub from 'pubsub-js'
import './DialogCustomerInfo.css'
import number_format from 'locutus/php/strings/number_format'

import { ReactJSXElement } from '@emotion/react/types/jsx-namespace'
import SharedStateService from '../../services/SharedStateService'
// import { formatDateTime } from '../../utils/utils'
// import ApiService from '../../services/ApiService'
import { themeColor } from '../../utils/ThemeManager'
import ImageManager, { IImageProps } from '../../services/ImageManager'
import { ImageComponent } from '../../components/Custom/ImageComponent'
import { Stack } from '@mui/system'
import { StyledDialogOKButton } from '../../components/Dialog/DialogButton'
import { StyledDialogTitle } from '../../components/Dialog/StyledDialogTitle'

const StyledGridItem = styled(Grid)({
 display:'flex',
 justifyContent:'flex-start',
 alignItems:'center'
});

type DialogItemInfoState = {
  // [x:string]:any,
  showDialog:boolean,
  images:any[]
  org: ApiOrgs
  menuItemImages:IImageProps[]
}

type DialogItemInfoProps = {
  showDialog?:boolean,
}

class DialogItemInfo extends React.Component<DialogItemInfoProps, DialogItemInfoState> {
  private displayName: string
  
  private allergens: ApiAllergens[]
  private suitabilites: ApiSuitability[]
  private sharedStateService: SharedStateService
  private subsidy:number
  private purse:number
  private description:string
  private imageUri:string
  private categories:ApiMenuCategory[]
  private ingredients:ApiIngredient[]
  private productIngredients:ApiProductIngredient[]
  private menuItemID:string
  private menuItemName:string
  

  constructor(props) {
    super(props)
    this.sharedStateService = new SharedStateService()

    this.state = {
      showDialog: false, //props.showDialog,
      images:ImageManager.sharedManager().getSuitabilityImages(),
      org: this.sharedStateService.getOrg(),
      menuItemImages:ImageManager.sharedManager().getImages()
    }
    this.allergens = []
    this.suitabilites = []
    this.subsidy = 0
    this.purse = 0
    this.description=''
    this.imageUri=''
    this.categories=[]
    this.ingredients=[]
    this.productIngredients=[]
    this.menuItemID=''
    this.menuItemName=''
  }

  getImageById = (suitabilityId) =>{
    const image=this.state.images.filter((img)=>img.id === suitabilityId)[0]
    return image
  }

  getItemImageById = (itemId) =>{
    const image=this.state.menuItemImages.filter((img)=>img.id === itemId)[0]
    return image
  }

  findItemAllergens(menuItem: ApiMenuItem): ApiAllergens[] {
    const allAllergens = this.sharedStateService.getAllergens();
    const ingredients = this.sharedStateService.getIngredients();
    const productIngredients = this.sharedStateService.getProductIngredients();

    const allergens: ApiAllergens[] = [];

    let productIngredientAllergenIds:string[]=[];
    let ingredientAllergenIds:string[]=[];

    if(menuItem.ingredientIds){
      ingredients.forEach((ingredient)=>{
        menuItem.ingredientIds.forEach((ingredientId)=>{
          if(ingredientId === ingredient.id && ingredient.allergenIds){
            ingredientAllergenIds = [...ingredientAllergenIds, ...ingredient.allergenIds]
          }
        })
      })

      ingredientAllergenIds = [...new Set(ingredientAllergenIds)];
    }

    if(menuItem.productIngredientIds){
        productIngredients.forEach((productIngredient)=>{
        menuItem.productIngredientIds.forEach((productIngredientId)=>{
          if(productIngredientId === productIngredient.id && productIngredient.allergenIds){
            productIngredientAllergenIds = [...productIngredientAllergenIds, ...productIngredient.allergenIds]
          }
        })
      })

      productIngredientAllergenIds = [...new Set(productIngredientAllergenIds)];
    }
    
    allAllergens.forEach((allergen) => {
      const {id} = allergen
      if (
        productIngredientAllergenIds.includes(id)
      ) { allergens.push(allergen); }

      if (
        ingredientAllergenIds.includes(id)
      ) { allergens.push(allergen); }
    });

    return allergens;
  }

  findItemSuitabilities(menuItem:ApiMenuItem){
    const allSuitabilities = this.sharedStateService.getSuitabilities();
    const suitabilities: ApiSuitability[] = [];

    allSuitabilities.forEach((suitability) => {
      const {id} = suitability
      if (
        menuItem.suitabilityIds &&
        menuItem.suitabilityIds.includes(id)
      ) { suitabilities.push(suitability); }
    });

    return suitabilities;
  }

  componentDidMount() {
    PubSub.subscribe('dialog-item-info-show', (msg: string, data: ApiMenuItem) => {
      this.displayStats(data)
      this.show()
    })
  }

  displayStats = (menuItem:ApiMenuItem) =>{
    const data = menuItem
    this.displayName = data.name
    this.allergens = this.findItemAllergens(data)
    this.suitabilites = this.findItemSuitabilities(data)
    this.purse = data.defaultPurseValue
    this.subsidy = data.defaultSubsidyValue

    const item = this.sharedStateService.getMenuItems().filter((item)=>item.id === data.id)[0]

    this.menuItemName=item.shortName
    this.menuItemID=item.id

    this.description=item.description
    this.imageUri=item.imageUri

    const categories:ApiMenuCategory[]=[];
    const allCategories = this.sharedStateService.getMenuCategories();
    allCategories.forEach((category)=>{
      if(item.menuCategoryIds.includes(category.id)){
        categories.push(category);
      }
    })
    this.categories=categories

    const ingredients:ApiIngredient[]=[];
    const allIngredients = this.sharedStateService.getIngredients();
    allIngredients.forEach((ingredient)=>{
      if(item.ingredientIds){
        if(item.ingredientIds.includes(ingredient.id)){
          ingredients.push(ingredient);
        }
      }
    })
    this.ingredients=ingredients

    const productIngredients:ApiProductIngredient[]=[];
    const allProductIngredients = this.sharedStateService.getProductIngredients();
    allProductIngredients.forEach((ingredient)=>{
      if(item.productIngredientIds){
        if(item.productIngredientIds.includes(ingredient.id)){
          productIngredients.push(ingredient);
        }
      }
    })
    this.productIngredients=productIngredients
  }

  componentWillUnmount() {
    PubSub.unsubscribe('dialog-item-info-show');
  }

  show() {
    this.setState({
      showDialog: true,
    })
  }

  close() {
    this.setState({
      showDialog: false,
    })
  }

  chunk(arr, chunkSize) {
    var R = [];
    for (var i=0,len=arr.length; i<len; i+=chunkSize)
      R.push(arr.slice(i,i+chunkSize));
    return R;
  }

  render(): ReactJSXElement {
    const { org } = this.state
    const supportedCurrencies = org.supportedCurrencies[0]

    let ingredients = this.ingredients.map((item)=>item.name)
    ingredients = [...ingredients, ...this.productIngredients.map((item)=>item.name)]
    
    const specifications = [...this.productIngredients.map((item)=>item.specification)]

    const suitabilities = this.suitabilites.length>0?this.chunk(this.suitabilites,4):[]
    return (
      <>
        <Dialog
          id="DialogCustomerInfo"
          open={this.state.showDialog}
          keepMounted
          aria-describedby="alert-dialog-slide-description"
          style={{
            fontFamily: 'Montserrat',
          }}
        >
          <StyledDialogTitle>Item Info</StyledDialogTitle>
          <DialogContent className="DialogItemContent" sx={{width:'560px'}}>
            <Box>
              <Box display={"flex"}>
                <Box width={345} position="relative">
                    <Stack spacing={1}>
                      <Box>
                        <Typography variant='h5'>{this.displayName}</Typography>
                          <Box mt={0.8}>
                          {
                            this.categories.map((item) => (
                              <Typography
                                sx={{
                                  backgroundColor: `${item.defaultColour}`,
                                  borderRadius: 10,
                                  fontSize: '0.8rem',
                                  color: 'white',
                                  maxWidth: 'fit-content',
                                  display:'inline'
                                }}
                                p={0.6}
                                px={1}
                                textAlign="center"
                              >
                                {item.name}
                              </Typography>
                            ))
                          }
                        </Box>
                      </Box>
                      <Box>
                        <Grid container pl={0.5}>
                          <Grid item xs={2}>
                            <Box>
                              <Typography fontWeight="bold" color={ themeColor } fontSize={13}>Purse</Typography>
                              <Typography fontSize={13}>{supportedCurrencies.symbol}{number_format(this.purse, 2)}</Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={0.2}><Divider orientation='vertical' /></Grid>
                          <Grid item pl={2}>
                            <Box>
                              <Typography fontWeight="bold" color={ themeColor } fontSize={13}>Subsidy</Typography>
                              <Typography fontSize={13}>{supportedCurrencies.symbol}{number_format(this.subsidy, 2)}</Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                      <Box>
                        <Typography fontSize={11} pl={0.5}>{this.description}</Typography>
                      </Box>
                      <Box>
                        {
                          suitabilities.length>0?
                            <Box display="flex" pl={0.5} flexDirection={"column"}>
                            {
                              suitabilities.map((suitability)=>
                                <Box display="flex" mt={0.5}>
                                  {suitability.map(({ name, id }, i) => (
                                    <Box display="flex" flexDirection="row" sx={{mr:1}}>
                                      <ImageComponent
                                        src={name && this.getImageById(id).url}
                                        alt={name}
                                        width={16}
                                        height={16}
                                      />
                                      <Typography display="inline" sx={{ml:0.5}} fontWeight={'normal'} fontSize={12}>
                                        {name}
                                      </Typography>
                                    </Box>
                                  ))
                                  }
                                </Box>
                              )
                            }
                          </Box>
                          :
                          null
                        }
                      </Box>
                      <Box pl={0.5}>
                        <Typography color={ themeColor } fontSize={12}>Allergens</Typography>
                        {
                          this.allergens.length>0?
                            <Box>
                            {
                              this.allergens.map(({ name, embolden }, i) => (
                                <Typography display="inline" fontWeight={embolden ? 'bold' : 'normal'} fontSize={10}>
                                  {name}
                                  {i < this.allergens.length - 1 ? ', ' : ''}
                                </Typography>
                              ))
                            }
                          </Box>
                          :
                          <Typography fontSize={12}>NA</Typography>
                        }
                      </Box>
                    </Stack>
                </Box>
                <Box width={215} position="relative">
                      <Box position="absolute" top={0} right={0}>
                        <ImageComponent
                              src={this.menuItemName && this.getItemImageById(this.menuItemID).url}
                              alt={this.menuItemName}
                              width={210}
                              height={210}
                          />
                      </Box>
                  </Box>
              </Box>
              <Box width={'100%'} mt={1}>
                {ingredients.length>0 ? 
                    <Box>
                      <Typography pl={0.5} fontSize={12} color={themeColor}>Ingredients</Typography>
                      <Typography pl={0.5} fontSize={10}>{ingredients.join(", ")}</Typography>
                    </Box>
                  :null
                }
                {specifications.length>0?
                  <Box mt={ingredients.length>0 ? 1 : 0}>
                    <Typography pl={0.5} fontSize={12} color={themeColor}>Specifications</Typography>
                    <Typography pl={0.5} fontSize={10}>{specifications.join(", ")}</Typography>
                  </Box>
                  :null}
              </Box>
            </Box>
          </DialogContent>
          <DialogActions
            style={{ justifyContent: 'center', margin: '0px 0px 20px 0px' }}
          >
            <StyledDialogOKButton
              onClick={() => this.close()}
            >
              Close
            </StyledDialogOKButton>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

export default DialogItemInfo
