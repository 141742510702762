import {
  // Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  // Divider,
  Grid,
  List,
  ListItem,
  Typography,
} from '@mui/material'
import React from 'react'
import PubSub from 'pubsub-js'
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace'
// import { themeColor } from '../../utils/ThemeManager'
import SharedStateService from '../../services/SharedStateService'
import { StyledDialogTitle } from '../../components/Dialog/StyledDialogTitle'
import { StyledDialogOKButton } from '../../components/Dialog/DialogButton'

type DialogSupportState = {
  showDialog: boolean
}

class DialogHelp extends React.Component<{}, DialogSupportState> {
  sharedStateService: SharedStateService
  constructor(props) {
    super(props)
    this.sharedStateService = new SharedStateService()
    this.state = {
      showDialog: false,
    }
  }

  componentDidMount(): void {
    PubSub.subscribe('toggle_help', (msg, value) => {
      if (value === true) {
        this.show()
      } else {
        this.close()
      }
    })
  }

  componentWillUnmount(): void {
    PubSub.unsubscribe('toggle_help')
  }

  show(): void {
    this.setState({
      showDialog: true,
    })
  }

  close(): void {
    this.setState({
      showDialog: false,
    })
  }

  render(): ReactJSXElement {
    return (
      <>
        <Dialog
          id="DialogSupport"
          open={this.state.showDialog}
          // TransitionComponent={Transition}
          keepMounted
          // onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          style={{
            position: 'absolute',
            left: 'calc(50% - 450px)',
            width: '900px',
            fontFamily: 'Montserrat',
          }}
        >
          <StyledDialogTitle>
            Help
          </StyledDialogTitle>
          <DialogContent>
            <Grid container spacing={2} display="flex">
              <Grid item xs={12} display="flex">
                <List
                  sx={{
                    pl: 2,
                    listStyleType: 'disc',
                    '& .MuiListItem-root': {
                      display: 'list-item',
                    },
                  }}
                >
                  <ListItem sx={{ display: 'list-item' }}>
                    <Typography
                      display={'inline'}
                      sx={{ opacity: 0.5, fontWeight: 'bold' }}
                    >
                      Disabled
                    </Typography>{' '}
                    <Typography display={'inline'}>
                      Menu Categories indicate there are no items for the
                      category to display.
                    </Typography>
                  </ListItem>
                  <ListItem sx={{ display: 'list-item' }}>
                    <Typography
                      display={'inline'}
                      sx={{ opacity: 0.5, fontWeight: 'bold' }}
                    >
                      Disabled
                    </Typography>{' '}
                    <Typography display={'inline'}>
                      Menu Items indicate that the item(s) are not available in
                      the menu for the day.
                    </Typography>
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions
            style={{ justifyContent: 'center', margin: '0px 0px 20px 0px' }}
          >
            <StyledDialogOKButton
              onClick={() => this.close()}
            >
              Close
            </StyledDialogOKButton>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

export default DialogHelp
