import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  // DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import React from "react";
import PubSub from "pubsub-js";
import "./DialogPayment.css";
import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import { secondaryColor, themeColor } from "../../utils/ThemeManager";
import { StyledDialogCancelButton } from "../../components/Dialog/DialogButton";
import { StyledDialogTitleNormal } from "../../components/Dialog/StyledDialogTitle";
// import number_format from 'locutus/php/strings/number_format'

type DialogAllergensState = {
  showDialog?: boolean;
  allergens?: ApiAllergens[];
};

interface DialogAllergensProps {}

class DialogAllergens extends React.Component<
  DialogAllergensProps,
  DialogAllergensState
> {
  constructor(props) {
    super(props);
    this.state = {
      showDialog: false,
      allergens: [],
    };
  }

  componentDidMount(): void {
    PubSub.subscribe("dialog-allergens-show", (msg: string, data: any) => {
      this.setState({ allergens: data.allergens });
      this.show();
    });
  }

  /**
   * Shows the dialog
   */
  show(): void {
    this.setState({
      showDialog: true,
    });
  }

  /**
   * Closes the dialog
   */
  closeDialog(): void {
    this.setState({
      showDialog: false,
    });
  }

  componentWillUnmount(): void {
    PubSub.unsubscribe("dialog-allergens-show");
  }

  /**
   * Renders the dialog
   * @returns
   */
  render(): ReactJSXElement {
    const allergens = this.state.allergens
      .map((item) => `"${item.name}"`)
      .join(", ");
    const txtAllergens = ((this.state.allergens.length>1) ? 'allergens' : 'allergen')
    return (
      <>
        <Dialog
          id="DialogPayment"
          open={this.state.showDialog}
          keepMounted
          onClose={() => this.closeDialog()}
        >
          <StyledDialogTitleNormal>{txtAllergens.toUpperCase()} FOUND</StyledDialogTitleNormal>
          <DialogContent>
            <Box className="DialogContentText">
              <Box color={secondaryColor}>
                This item contain {txtAllergens}{" "}
                <Typography
                  display="inline"
                  color={themeColor}
                  fontWeight="bold"
                >
                  {allergens}
                </Typography>{" "}
                which have been set as unsuitable.
              </Box>
            </Box>
          </DialogContent>
          <DialogActions className="DialogActions">
            <StyledDialogCancelButton
              onClick={() => this.closeDialog()}
            >
              Close
            </StyledDialogCancelButton>
            {/* <Button
              className="ButtonPay"
              onClick={() => this.payNow()}
              variant="contained"
            >
              Still Proceed?
            </Button> */}
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export default DialogAllergens;
