// import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
// import reportWebVitals from "./reportWebVitals";
import { SnackbarProvider } from "notistack";
// import { MemoryRouter } from "react-router-dom";
// import * as Sentry from "@sentry/react";
// import { BrowserTracing } from "@sentry/tracing";

// Sentry.init({
//   dsn: "https://1ad880549f0b4f40b4318ef269c10f46@o369035.ingest.sentry.io/4504536225480704",
//   integrations: [new BrowserTracing()],
//   tracesSampleRate: 1.0,
//   beforeSend: (event) => {
//     if (window.location.hostname === 'localhost') {
//       return null;
//     }
//     return event;
//   },
// });



const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React.StrictMode>
    <SnackbarProvider maxSnack={3}>
      {/* After 15 minutes of inactivity screensaver will be triggered */}
      {/* <App timeout={15 * 60 * 1000} /> */}
      <App />
      {/* <BrowserRouter>
        <App />
      </BrowserRouter> */}
      {/* <MemoryRouter>
        <App />
      </MemoryRouter> */}
    </SnackbarProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
