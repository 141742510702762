import React from 'react';
import './KioskPage.css';
import './PanelCurrentSale.css';
// import LunchClubLogo from '../../components/LunchClubLogo/LunchClubLogo'
// import SchoolFoodLogo from '../../components/SchoolFoodLogo/SchoolFoodLogo'
import Layout from '../../components/Layout/Layout';
// import FoodService from '../../services/FoodService'
import PageService from '../../services/PageService';
import SharedStateService, {
  keyUpdateCartToCustomer,
} from '../../services/SharedStateService';
import PanelHeader from './PanelHeader';
import PanelStaffMenu from './PanelStaffMenu';
import PanelCurrentSale from './PanelCurrentSale';
import PanelMenu from './PanelMenu';
// import IconMenu from "../../components/menu-bar/IconMenu";
import ApiService from '../../services/ApiService';
import PubSub from 'pubsub-js';
import uniqid from 'locutus/php/misc/uniqid';
import { withSnackbar } from 'notistack';
import DialogCustomerInfo from './DialogCustomerInfo';
import DialogHelp from './DialogHelp';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { OrderProcessType, ProcessMode } from './DialogOrdering';
// import DialogSupport, { DialogScreenType } from "./DialogSupport";
import DialogSystemDown from './DialogSystemDown';

import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { Box, Typography } from '@mui/material';
import { MenuItemSelectionType, removeEmptyFields } from '../../utils/utils';
import DialogTransactions from './DialogTransactions';
import DialogPromoExhausted from './DialogPromoExhausted';
import EnvService from '../../services/EnvService';
import CustomerSessionService from '../../services/CustomerSessionService';
import DialogPaymentError from './DialogPaymentError';
import DialogCustomerSession from './DialogCustomerSession';
import SittingManager from '../../services/SittingManager';
import { DialogScreenType } from '../../components/DialogSupport/DialogSupport';
import AudioManager from '../../services/AudioManager';
import DialogItemInfo from './DialogItemInfo';
import DialogSupportKiosk from '../../components/DialogSupport/DialogSupportKiosk';
import DialogPromoDisallow from './DialogPromoDisallow';
import { themeColor } from '../../utils/ThemeManager';
import DialogScanError from './DialogScanError';
import ElectronService from '../../services/ElectronService';

interface KioskPageProps {
  enqueueSnackbar?: Function;
  customer?: Customer;
  cart?: Cart;
  onClose: Function;
}

type KioskPageState = {
  currentTime: any;
  foodCategories?: any;
  menuSittings?: ApiMenuSitting[];
  eposLayout?: ApiEposLayout;
  refundCart?: Cart;
  refundItems?: any;
  mealDealEnabled?: boolean;
  salesMode?: any;
  showDialogDailySummary?: boolean;
  showDialogDailyTransactions?: boolean;
  showDialogSupport?: boolean;
  showDialogSystemDown?: boolean;
  showMenu?: boolean;
  showMenuReciept?: boolean;
  showMenuCalculator?: boolean;
  selectedFoodCategoryId?: any;
  selectedFoodItemId?: any;
  selectedFoodMenuId?: any;
  selectedMenuSittingId?: any;
  cart?: Cart;
  menuItems?: ApiMenuItem[];
  menuPromos?: ApiMenuPromotion[];
  selectedOperator?: ApiEposUser;
  school?: ApiSchool;
  org?: ApiOrgs;
  supportedCurrency?: ApiCurrency;
  kioskSessionStartedAt: Date;
  selectedPromo?: ApiMenuPromotion;
  activeRefundTransaction?: Transaction;
};

class KioskPage extends React.Component<KioskPageProps, KioskPageState> {
  private SALES_MODE_SALE = 'current_sale';
  private SALES_MODE_REFUND = 'refund_sale';
  private pageService = new PageService();
  private sharedStateService = new SharedStateService();
  private customerSessionService = new CustomerSessionService();
  private apiService = new ApiService();
  private activeTransaction = false;
  private menuItemSelectionTime: number = 0;

  constructor(props) {
    super(props);

    this.onSystemDownSaveTransaction =
      this.onSystemDownSaveTransaction.bind(this);

    this.onPayment = this.onPayment.bind(this);
    this.state = {
      kioskSessionStartedAt: new Date(),
      currentTime: null,
      eposLayout: this.fetchEposLayoutsForSitting(
        this.sharedStateService.getCurrentSittingId()
      ),
      foodCategories: this.sharedStateService.getMenuCategories(),
      menuSittings: this.sharedStateService.getSittings(),
      menuItems: this.sharedStateService.getMenuItems(),
      menuPromos: this.sharedStateService.getMenuPromos(),
      school: this.sharedStateService.getSchool(),
      selectedMenuSittingId: this.sharedStateService.getCurrentSittingId(),
      selectedOperator: this.sharedStateService.getOperator(),
    };
  }

  componentDidUpdate(
    prevProps: Readonly<KioskPageProps>,
    prevState: Readonly<KioskPageState>,
    snapshot?: any
  ): void {
    const customer = this.sharedStateService.getCustomer();
    const org = this.sharedStateService.getOrg();
    const supportedCurrency = org.supportedCurrencies[0];

    if (!this.props.customer || this.props.customer === prevProps.customer)
      return;
    const newState = {
      cart:
        this.processSittingSelection(
          this.sharedStateService.getCurrentSittingId(),
          this.props.cart
        ) || this.props.cart,
      currentTime: '00:00:00',
      foodCategories: this.sharedStateService.getMenuCategories(),
      menuSittings: this.sharedStateService.getSittings(),
      menuItems: this.sharedStateService.getMenuItems(),
      menuPromos: this.sharedStateService.getMenuPromos(),
      // pricings: [],
      eposLayout: this.fetchEposLayoutsForSitting(
        this.sharedStateService.getCurrentSittingId()
      ),
      // foodItems: [],
      refundCart: {
        menuId: null,
        currencySymbol: supportedCurrency.symbol,
        items: [],
        purseBefore: customer.available_balance,
        purseAfter: customer.available_balance,
        subsidyBefore: customer.subsidy,
        subsidyAfter: 0.0,
        totalPurse: customer.available_balance,
        totalSubsidy: customer.subsidy,
        total: 0.0,
      },
      refundItems: [],
      // foodMenus: [],
      mealDealEnabled: false,
      salesMode: this.SALES_MODE_SALE,
      showDialogDailySummary: false,
      showDialogDailyTransactions: false,
      showDialogSupport: false,
      showDialogSystemDown: false,
      showMenu: false,
      showMenuReciept: false,
      showMenuCalculator: false,
      //selectedFoodCategoryClass: null,
      selectedFoodCategoryId: null,
      selectedFoodItemId: null,
      selectedFoodMenuId: null,
      selectedMenuSittingId: this.sharedStateService.getCurrentSittingId(),
      // foodItemTiles: [],
      selectedOperator: this.sharedStateService.getOperator(),
      school: this.sharedStateService.getSchool(),
      org,
      supportedCurrency,
      selectedPromo: null,
      activeRefundTransaction: null,
      kioskSessionStartedAt: this.state.kioskSessionStartedAt,
    };

    this.customerSessionService.start(); // we start the customer session service
    this.setState(newState);
  }

  /**
   * This method gets triggered when the page is mounted (shown)
   *
   * 1. Starts the sessions
   * 2. Subscribed to listen to important events
   */
  componentDidMount() {
    //this.processSittingSelection(this.sharedStateService.getCurrentSittingId());

    // this.pageService.setCustomerPage(this.pageService.CUSTOMER_TILL_PAGE);

    // DEBUG: console.log("Staff. KioskPage did mount");

    /*     if (this.state.customer === null) {
          // something has gone wrong, no customer present
          this.pageService.setOperatorPage(this.pageService.OPERATOR_LOGIN_PAGE);
          this.pageService.setCustomerPage(this.pageService.CUSTOMER_LOCKED_PAGE);
        }
     */
    PubSub.subscribe('refund_item_toggled', (msg, payload) => {
      const { item, action, quantity } = payload;
      this.onRefundItemToggled(item, action, quantity);
    });

    PubSub.subscribe('refund_promoitem_toggled', (msg, payload) => {
      const { item, action, quantity } = payload;
      this.onRefundPromoItemToggled(item, action, quantity);
    });

    PubSub.subscribe('sale_mode_changed', (msg, newSalesMode) => {
      this.onSalesModeChange(newSalesMode);
    });

    PubSub.subscribe('refund_initiated', (msg, transaction: Transaction) => {
      if (transaction) {
        this.setState({ activeRefundTransaction: transaction });
        this.fetchRefundItems(transaction);
      }
    });

    PubSub.subscribe('refund_cancelled', (msg, transaction: Transaction) => {
      this.emptyRefundCart();
      this.setState({ activeRefundTransaction: null });
    });

    // PubSub.subscribe("selected_sittingId", (msg, { sittingId }) => {
    //   this.processSittingSelection(sittingId);
    // });

    const self = this; // !!! important

    PubSub.subscribe('customer_session_expire_warning', (msg, data) => {
      PubSub.publish('dialog-customer-session-show');
    });

    PubSub.subscribe('customer_session_expired', (msg, data) => {
      this.onCustomerSessionExpired(self);
    });

    SittingManager.sharedManager().setCallback((id) => {
      this.setState({
        cart: this.processSittingSelection(id, this.state.cart),
      });
    });

    PubSub.subscribe('update_customer_after_sync', (msg, data) => {
      this.updateCartAfterSync();
    });

    PubSub.subscribe('handle_scan_error', (msg, data) => {
      PubSub.publish('dialog-scan-error-show', data.message);
    });
  }

  processSittingSelection = (sittingId, cart) => {
    //this.setState({ selectedMenuSittingId: sittingId });
    //Find menuid from menu for the selected sitting

    //Determining available subsidy of user based on selected sittingID

    /*const subsidies = this.sharedStateService.getSubsidies();

      const userSubsidies = subsidies.filter(
        (subsidy) => subsidy.userId === this.state.customer.id
      );

      if (userSubsidies.length > 0) {
        const filteredSittingSubsidies = userSubsidies.filter(
          (subsidy) => subsidy.menuSittingId === sittingId
        );

        if (filteredSittingSubsidies.length > 0) {
          const userSubsidy = filteredSittingSubsidies[0];

          const subsidyBalance = userSubsidy.subsidyBalance;

          let customer = { ...this.state.customer };
          customer = { ...customer, subsidy: subsidyBalance };

          cartSubsidyAfter = 0.0;
          cartSubsidyBefore = subsidyBalance;
          cartTotalSubsidy = subsidyBalance;

          let cart = { ...this.state.cart };
          cart = {
            ...cart,
            subsidyBefore: subsidyBalance,
            totalSubsidy: subsidyBalance,
          };

          let refundCart = { ...this.state.refundCart };
          refundCart = {
            ...refundCart,
            subsidyBefore: subsidyBalance,
            totalSubsidy: subsidyBalance,
          };

          this.setState({
            customer,
            cart,
            refundCart,
            customerInitialSubsidy: subsidyBalance,
          });
        }
      }*/

    const todayMenu = this.sharedStateService.getTodayMenu();

    if (todayMenu) {
      const sittingMenuItems = todayMenu.filter(
        (menu) => menu.menuSittingId === sittingId
      );
      this.fetchEposLayoutsForSitting(sittingId);

      if (sittingMenuItems.length > 0) {
        const menuId = sittingMenuItems[0].id;
        const cartPurseBefore = cart.purseBefore;
        const cartPurseAfter = cart.purseAfter;
        const cartTotalPurse = cart.totalPurse;
        const cartSubsidyBefore = cart.subsidyBefore;
        const cartSubsidyAfter = cart.subsidyAfter;
        const cartTotalSubsidy = cart.totalSubsidy;
        const newCart = {
          menuId: menuId,
          currencySymbol: cart.currencySymbol,
          items: [],
          purseBefore: cartPurseBefore,
          purseAfter: cartPurseAfter,
          subsidyBefore: cartSubsidyBefore,
          subsidyAfter: cartSubsidyAfter,
          totalPurse: cartTotalPurse,
          totalSubsidy: cartTotalSubsidy,
          total: 0.0,
        };
        this.sharedStateService.setCart(newCart);
        /* this.setState(
          {
            cart,
            // cart: newCart,
          }
        ); */
        return newCart;
      }
    }
    return cart;
  };

  /**
   * This method gets triggered when the page is unmounted (removed)
   *
   * 1. Ends the customer sessions
   */
  componentWillUnmount() {
    this.customerSessionService.stop(); // we stop the customer session
    // DEBUG: console.log("Staff. KioskPage did unmount");

    PubSub.unsubscribe('refund_item_toggled');

    PubSub.unsubscribe('sale_mode_changed');

    PubSub.unsubscribe('refund_initiated');

    PubSub.unsubscribe('refund_cancelled');

    PubSub.unsubscribe('customer_session_expire_warning');

    PubSub.unsubscribe('customer_session_expired');

    PubSub.unsubscribe('update_customer_after_sync');

    PubSub.unsubscribe('handle_scan_error');
  }

  onPromoIdSelection = (promoId: string) => {
    if (promoId !== '') {
      const promo = this.state.menuPromos.filter(
        (promo) => promo.id === promoId
      )[0];
      this.setState({ ...this.state, selectedPromo: promo });
    } else {
      this.setState({ ...this.state, selectedPromo: null });
    }
  };

  /**
   * Called when the "customer_session_expired" event is published
   * @param self
   */
  onCustomerSessionExpired(self: any): void {
    console.log('CUSTOMER SESSION EXPIRED');
    self.onSwitchStudent();
  }

  /**
   * Fetches the EPOS layout for the specified MenuSitting ID
   * @param menuSittingId
   * @returns
   */
  fetchEposLayoutsForSitting(menuSittingId: string): ApiEposLayout {
    const allLayouts = this.sharedStateService.getEposLayouts();
    if (allLayouts) {
      const filtered = allLayouts.filter(
        (layout) => layout.menuSittingId === menuSittingId
      );
      if (filtered.length > 0) {
        /*  this.setState({
           eposLayout: filtered[0],
         }); */

        //Hide categories by default if layout available
        PubSub.publish('toggle_categories', false);
        return filtered[0];
      } else {
        //Show categories by default if no layout
        PubSub.publish('toggle_categories', true);
      }
    } else {
      //Show categories by default if no layout
      PubSub.publish('toggle_categories', true);
    }
  }

  /**
   * Pulls all the items that can be refunded from the API
   * @returns
   */
  async fetchRefundItems(transaction: Transaction) {
    const refundItems: TransactionItem[] = [];

    let index = 0;
    let promoIndex = 0;
    transaction.receipt.items.forEach((item) => {
      if (item.type === 'menuItem') {
        const quantity = parseInt(item.quantity);
        if (quantity === 1) {
          item.index = index;
          refundItems.push(item);
          index++;
        } else {
          for (let i = 0; i < quantity; i++) {
            const newItem = { ...item, quantity: `1` };
            newItem.index = index;
            refundItems.push(newItem);
            index++;
          }
        }
      } else {
        const modifiedPromoItems: PromotionItem[] = [];
        item.menuPromotionItems.forEach((promoItem) => {
          const quantity = parseInt(promoItem.quantity);
          if (quantity === 1) {
            const menuItem: PromotionItem = {
              ...promoItem,
              purseValue: item.purseValue,
              subsidyValue: item.subsidyValue,
            };
            menuItem.index = index + (promoIndex + 1) * 100;
            menuItem.promotionId = item.id;
            modifiedPromoItems.push(menuItem);
            promoIndex++;
          } else {
            for (let i = 0; i < quantity; i++) {
              const newItem = { ...promoItem, quantity: `1` };
              const menuItem: PromotionItem = {
                ...newItem,
                purseValue: item.purseValue,
                subsidyValue: item.subsidyValue,
              };
              menuItem.index = index + (promoIndex + 1) * 100;
              menuItem.promotionId = item.id;
              modifiedPromoItems.push(menuItem);
              promoIndex++;
            }
          }
        });
        item.menuPromotionItems = [...modifiedPromoItems];
        refundItems.push(item);
      }
    });

    this.setState({
      refundItems,
    });
  }

  /**
   * Finds a menu item by its ID
   * @param menuItemId
   * @returns ApiMenuItem|null
   */
  findMenuItem(menuItemId: string): ApiMenuItem | null {
    const filter = this.state.menuItems.filter(
      (menuItem) => menuItem.id === menuItemId
    );
    if (filter.length > 0) {
      return filter[0];
    }

    return null;
  }

  /**
   * Shows the daily transactions dialog
   */
  async onShowTransactions(): Promise<void> {
    this.beep();
    PubSub.publish('dialog-show-transactions', {});
  }

  /**
   * Food category is selected by ID
   * This should happen after a food category class is selected
   *
   * @param {*} categoryId
   */
  onFoodCategorySelected(categoryId): void {
    this.setState({
      selectedFoodCategoryId: categoryId,
    });
  }

  /**
   * Checks if this is an offline customer
   * @returns boolean
   */
  isOfflineCustomer(): boolean {
    const customer = this.sharedStateService.getCustomer();
    return customer.type === 'offline';
  }

  recalculateCart(cartItems: CartItem[]): Cart {
    const customer = this.sharedStateService.getCustomer();
    const customerInitialPurse = customer.available_balance;
    const customerInitialSubsidy = customer.subsidy;
    const purseBefore = customerInitialPurse;
    const subsidyBefore = customerInitialSubsidy;
    let totalPurse = customerInitialPurse;
    let totalSubsidy = customerInitialSubsidy;
    let total = 0;

    cartItems.forEach((cartItem: CartItem) => {
      if (cartItem.subsidy_value > 0) {
        if (this.isOfflineCustomer()) {
          // when offline, subsidy gets cslculated to purse
          totalPurse = totalPurse - cartItem.subsidy_value;
        } else {
          totalSubsidy = totalSubsidy - cartItem.subsidy_value;
        }
      }
      if (cartItem.purse_value > 0) {
        totalPurse = totalPurse - cartItem.purse_value;
      }
      total += cartItem.price_per_item;
    });

    if (totalSubsidy < 0) {
      throw new Error('SubsidyCannotBeNegativeException');
    }

    const cart: Cart = {
      menuId: this.state.cart.menuId,
      currencySymbol: this.state.supportedCurrency.symbol,
      items: cartItems,
      purseBefore: purseBefore,
      purseAfter: totalPurse,
      subsidyBefore: subsidyBefore,
      subsidyAfter: totalSubsidy,
      totalPurse: totalPurse,
      totalSubsidy: totalSubsidy,
      total: total,
    };
    return cart;
  }

  /**
   * Checks that the subsidy will remain positive
   * after the menu is added.
   *
   * Note!!! Subsidy cannot be negative at any point!!!
   *
   * @param menu
   * @returns
   */
  isSubsidyEnoughForMenuItem(menu: ApiMenu): boolean {
    const customer = this.sharedStateService.getCustomer();
    if (customer.type === 'offline') {
      return true; // when offline we should charge all to purse, which is why subsidy does not matter
    }

    const isSubsidyItem = menu.subsidyValue > 0;
    if (!isSubsidyItem) {
      return true;
    }

    const subsidyResult = this.state.cart.totalSubsidy - menu.subsidyValue;

    console.log('SUBSIDY WILL REMAIN AFTER', subsidyResult);

    if (subsidyResult >= 0) {
      console.log('SUBSIDY WILL BE ENOUGH', 'TRUE');
      return true;
    }

    console.log('SUBSIDY WILL BE ENOUGH', 'FALSE');
    return false;
  }

  /**
   * Checks that if menuItem contains allergns matching students
   *
   *
   * Note!!! Subsidy cannot be negative at any point!!!
   *
   * @param menu
   * @returns
   */
  findItemAllergens(menu: ApiMenu): ApiAllergens[] {
    const customer = this.sharedStateService.getCustomer();
    const { allergenIds } = customer;
    const allAllergens = this.sharedStateService.getAllergens();
    const allergens: ApiAllergens[] = [];

    if (allergenIds) {
      //Find menuitem in current menu
      allergenIds.forEach((id) => {
        if (
          menu.productIngredientAllergenIds &&
          menu.productIngredientAllergenIds.includes(id)
        ) {
          const filtered = allAllergens.filter(
            (allergen) => allergen.id === id
          );
          if (filtered.length > 0) {
            if (allergens.filter((item) => item.id === id).length === 0)
              allergens.push(filtered[0]);
          }
        }

        if (
          menu.ingredientAllergenIds &&
          menu.ingredientAllergenIds.includes(id)
        ) {
          const filtered = allAllergens.filter(
            (allergen) => allergen.id === id
          );
          if (filtered.length > 0) {
            if (allergens.filter((item) => item.id === id).length === 0)
              allergens.push(filtered[0]);
          }
        }
      });
    }

    return allergens;
  }

  /**
   * Food item is selected by ID
   *
   * @param {*} itemId
   */
  onShowFoodItemInfo(menuItem): void {
    this.beep();
    const menuItemId = menuItem.id;

    let selectedMenuItem = this.sharedStateService
      .getMenuItems()
      .find((i) => i.id === menuItemId);

    if (!selectedMenuItem) {
      return;
    }

    PubSub.publish('dialog-item-info-show', selectedMenuItem);
  }

  /**
   * Food item is selected by ID
   *
   * @param {*} itemId
   */
  onFoodItemSelected(menuItem, overridesubsidy?: boolean): void {
    try {
      if (this.state.cart) {
        const cartUpdate0 = performance.now();

        const cartItemsParsing0 = performance.now();
        let cartItems = JSON.parse(JSON.stringify(this.state.cart.items)); // clone, important !!!
        const cartItemsParsing1 = performance.now();
        console.log(
          `Cart items parsing took ${
            cartItemsParsing1 - cartItemsParsing0
          } milliseconds.`
        );

        const { selectedPromo } = this.state;
        this.beep();
        const menuItemId = overridesubsidy ? menuItem.menuItemId : menuItem.id;

        console.log('MENU ITEM SELECTED', menuItemId);

        const setSelectedFoodItemState0 = performance.now();
        this.setState({
          selectedFoodItemId: menuItemId,
        });
        const setSelectedFoodItemState1 = performance.now();
        console.log(
          `Setting selected food item state took ${
            setSelectedFoodItemState1 - setSelectedFoodItemState0
          } milliseconds.`
        );

        const findMenu0 = performance.now();
        let selectedMenuItem = this.sharedStateService
          .getTodayMenu()
          .find((i) => i.menuItemId === menuItemId);
        const findMenu1 = performance.now();
        console.log(
          `Finding item from menu took ${findMenu1 - findMenu0} milliseconds.`
        );

        if (!selectedMenuItem) {
          return;
        }

        // console.log("TODAY MENU", todayMenu);

        //If the Promo is selected and an item from the promo is been selected apply the values of the promo
        if (selectedPromo) {
          selectedMenuItem.subsidyValue = selectedPromo.subsidyValue;
          selectedMenuItem.purseValue = selectedPromo.purseValue;

          //Check if the selected item follows the rule
          const itemRule: ApiRule = menuItem.item.rule;

          // let itemFromDifferentRule = false;
          let timesAdded = 0;
          cartItems.forEach((item) => {
            //item.rule denotes the item blongs to the promo
            if (item.rule && item.rule.id === itemRule.id) {
              timesAdded += 1;
            } /*else{
              itemFromDifferentRule = true;
            }*/
          });

          /*if(itemFromDifferentRule){
            //Disallow selection from different rule
            PubSub.publish("dialog-promo-disallow-selection", {});
            return;
          }else */

          if (timesAdded >= itemRule.max) {
            //Check if promo rules follow
            PubSub.publish('dialog-promo-exhausted', {});
            return;
          }
        }

        if (overridesubsidy) {
          selectedMenuItem.purseValue = selectedMenuItem.subsidyValue;
          selectedMenuItem.subsidyValue = 0;
        }

        const findAllergens0 = performance.now();
        const allergens = this.findItemAllergens(selectedMenuItem);
        const findAllergens1 = performance.now();
        console.log(
          `Finding allergens took ${
            findAllergens1 - findAllergens0
          } milliseconds.`
        );

        if (allergens.length > 0) {
          PubSub.publish('dialog-allergens-show', { allergens });
          return;
        }

        const haveEnoughSubsidy0 = performance.now();
        const haveEnoughSubsidy =
          this.isSubsidyEnoughForMenuItem(selectedMenuItem);
        console.log('ENOUGH SUBSIDY', haveEnoughSubsidy);
        const haveEnoughSubsidy1 = performance.now();
        console.log(
          `Finding subsidy took ${
            haveEnoughSubsidy1 - haveEnoughSubsidy0
          } milliseconds.`
        );

        if (haveEnoughSubsidy === false) {
          PubSub.publish('dialog-insufficient-subsidy-show', {
            selectedMenuItem:
              menuItem.type === MenuItemSelectionType.promo
                ? { ...menuItem, ...selectedMenuItem }
                : selectedMenuItem,
          });
          return;
        }

        let subtotal = selectedMenuItem.purseValue;
        if (this.isOfflineCustomer() && selectedMenuItem.subsidyValue > 0) {
          subtotal = selectedMenuItem.subsidyValue; // if offline purse is charged, not subsidy
        }

        const preparingCartItem0 = performance.now();
        const cartItem: CartItem = {
          id: uniqid('id_', true),
          title: selectedMenuItem.menuItemName,
          menu_item_id: menuItemId,
          quantity: 1,
          price_per_item: selectedMenuItem.purseValue,
          subtotal: subtotal,
          purse_value: selectedMenuItem.purseValue,
          subsidy_value: selectedMenuItem.subsidyValue,
        };
        const preparingCartItem1 = performance.now();
        console.log(
          `Preparing cart item took ${
            preparingCartItem1 - preparingCartItem0
          } milliseconds.`
        );

        if (menuItem.type === MenuItemSelectionType.promo) {
          cartItem.rule = menuItem.item.rule;
          cartItem.menuPromotionId = selectedPromo.id;
        }

        cartItems.push(cartItem);

        try {
          const recalculatedCart0 = performance.now();
          const recalculatedCart = this.recalculateCart(cartItems);
          console.log('Recalculated cart', recalculatedCart);
          const recalculatedCart1 = performance.now();
          console.log(
            `Recalculating cart took ${
              recalculatedCart1 - recalculatedCart0
            } milliseconds.`
          );

          const setCartState0 = performance.now();
          this.setState(
            {
              cart: recalculatedCart,
            },
            () => {
              console.log(
                'TIME CART UPDATE => ',
                new Date(),
                new Date().getMilliseconds()
              );

              const setCart0 = performance.now();
              this.sharedStateService.setCart(this.state.cart);
              PubSub.publish('cart_updated', keyUpdateCartToCustomer);
              const setCart1 = performance.now();
              console.log(
                `Setting cart took ${setCart1 - setCart0} milliseconds.`
              );
            }
          );
          const setCartState1 = performance.now();
          console.log(
            `Setting cart state took ${
              setCartState1 - setCartState0
            } milliseconds.`
          );

          console.log(
            'TIME CART UPDATE => ',
            new Date(),
            new Date().getMilliseconds()
          );
        } catch (err) {
          console.log('ERROR: ', err);
          // if (err.message === 'SubsidyCannotBeNegativeException') {
          //   PubSub.publish('dialog-insufficient-subsidy-show', {})
          // }
        }

        const cartUpdate1 = performance.now();
        console.log(
          `Cart update took ${cartUpdate1 - cartUpdate0} milliseconds.`
        );
        this.menuItemSelectionTime =
          Math.round((cartUpdate1 - cartUpdate0) * 100) / 100;
      } else {
        console.error('Cart is empty, unable to select food item.');
      }
    } catch (error) {
      console.error('Error in selecting food item', error);
    }
  }

  /**
   * Food item is selected by ID
   *
   * @param {*} itemId
   */
  onFoodItemRemoved(itemIndex) {
    let newCartItems = this.state.cart.items.filter(
      (item, index) => index !== itemIndex
    );

    const recalculatedCart = this.recalculateCart(newCartItems);
    console.log('Recalculated cart', recalculatedCart);

    this.setState(
      {
        cart: recalculatedCart,
      },
      () => {
        this.sharedStateService.setCart(this.state.cart);
        PubSub.publish('cart_updated', keyUpdateCartToCustomer);
      }
    );

    return;
  }

  /**
   * Update cart with latest Purse after sync
   */

  updateCartAfterSync() {
    let cartItems = JSON.parse(JSON.stringify(this.state.cart.items)); // clone, important !!!
    try {
      const recalculatedCart = this.recalculateCart(cartItems);
      console.log('Recalculated cart after Sync => ', recalculatedCart);

      this.setState(
        {
          cart: recalculatedCart,
        },
        () => {
          console.log(
            'TIME CART UPDATE after Sync => ',
            new Date(),
            new Date().getMilliseconds()
          );

          this.sharedStateService.setCart(this.state.cart);
          PubSub.publish('cart_updated', keyUpdateCartToCustomer);
        }
      );
    } catch (err) {
      console.log('ERROR: ', err);
    }
  }

  /**
   * When food menu is selected
   * we empty the selected food menu ID
   *
   * @param {*} categoryClass
   */
  // onFoodMenuSelected(menuId) {
  //   console.log('MENU ID SELECTED', menuId)
  //   //const menu = this.state.foodMenus.find((menu) => menu.id === menuId)
  //   //const categoryClass = menu.sitting_name || 'Undefined'
  //   this.setState({
  //     selectedFoodMenuId: menuId,
  //     //selectedFoodCategoryClass: categoryClass,
  //     selectedFoodCategoryId: null,
  //   })
  //   //   console.log(categoryClass)
  //   //   const categories = this.state.foodCategories
  //   //   const filteredCategories = categories.filter((cat) => {
  //   //     return cat.class === categoryClass
  //   //   })
  //   //   console.log('Apply Filter')
  //   //   console.log(filteredCategories)
  //   //   // this.setState({ foodItemTiles: filteredCategories })
  // }

  onMealDealToggle() {
    const mealDealEnabled = !this.state.mealDealEnabled;
    console.log(mealDealEnabled);
    this.setState({ mealDealEnabled: mealDealEnabled });
  }

  onPanelRecieptMenuHide() {
    this.setState({ showMenuReciept: false });
  }

  onPanelRecieptMenuShow() {
    this.setState({ showMenuReciept: true });
  }

  onPanelRecieptMenuToggle() {
    if (this.state.showMenuReciept) {
      this.onPanelRecieptMenuHide();
    } else {
      this.onPanelRecieptMenuShow();
    }
  }

  onPanelStaffMenuShow() {
    this.setState({ showMenu: true });
  }

  onPanelStaffMenuHide() {
    this.setState({ showMenu: false });
  }

  onPanelStaffMenuToggle(): void {
    if (this.state.showMenu) {
      this.onPanelStaffMenuHide();
    } else {
      this.onPanelStaffMenuShow();
    }
  }

  onPanelCalculatorMenuShow(): void {
    this.setState({ showMenuCalculator: true });
  }

  onPanelCalculatorMenuHide() {
    this.setState({ showMenuCalculator: false });
  }

  onPanelCalculatorMenuToggle() {
    if (this.state.showMenuCalculator) {
      this.onPanelCalculatorMenuHide();
    } else {
      this.onPanelCalculatorMenuShow();
    }
  }

  prepareCartForSave(): OrderItem[] {
    const orderItems: OrderItem[] = this.state.cart.items.map(
      (item: CartItem) => {
        const orderItem: OrderItem = {
          id: item.id,
          name: item.title,
          unit_price: item.price_per_item,
          title: item.title,
          menu_item_id: item.menu_item_id,
          quantity: item.quantity,
          price_per_item: item.price_per_item,
          subtotal: item.subtotal,
          purse_value: item.purse_value,
          subsidy_value: item.subsidy_value,
          rule: item.rule,
          menuPromotionId: item.menuPromotionId,
        };
        return orderItem;
      }
    );

    return orderItems;
  }

  prepareRefundForSave(): OrderItem[] {
    const orderItems: OrderItem[] = this.state.refundCart.items.map(
      (item: CartItem) => {
        const orderItem: OrderItem = {
          id: item.id,
          name: item.title,
          unit_price: item.price_per_item,
          title: item.title,
          menu_item_id: item.menu_item_id,
          quantity: item.quantity,
          price_per_item: item.price_per_item,
          subtotal: item.subtotal,
          purse_value: item.purse_value,
          subsidy_value: item.subsidy_value,
          rule: item.rule,
          menuPromotionId: item.menuPromotionId,
        };
        return orderItem;
      }
    );

    return orderItems;
  }

  // As a backup
  recordTransactionToDevice(transaction) {
    try {
      ElectronService.recordTransaction(transaction);
    } catch (error) {
      console.error('Error in recording transaction to device', error);
    }
  }

  /**
   * Executes when the "Charge" button is clicked.
   *
   * Business logic:
   * 1. If no items selected. Transaction is closed. Customer scan page is shown
   * 2. Order is pushed to the API. If successful, transaction is closed. Customer scan page is shown
   * 3. Order is not successfully. Display an error.
   *
   * @returns
   */
  async onPayment() {
    if (this.activeTransaction) return;
    this.activeTransaction = true;

    this.beep();

    const cartItems = this.sharedStateService.getCart().items;

    // If no items in the cart. Transaction is closed. Customer scan page is shown for next customer
    if (cartItems.length < 1) {
      this.onSwitchStudent();
      return; // !!! Important
    }

    // console.log("CURRENT CART", this.state.cart);
    // console.log("CURRENT STATE", this.state);

    // PubSub.publish("order-processes", {
    //   status: "Processing",
    //   type: OrderProcessType.init,
    //   processMode: ProcessMode.order
    // });

    //Split CartItems with or without Promotion

    const cartItemsWithPromos = cartItems.filter(
      (cartitem) => cartitem.menuPromotionId !== undefined
    );
    const cartItemsWithoutPromos = cartItems.filter(
      (cartitem) => cartitem.menuPromotionId === undefined
    );

    //Grouping same cart items with quantity
    const quantities = cartItemsWithoutPromos.reduce((prev, curr) => {
      const id = curr.menu_item_id;
      if (!prev.hasOwnProperty(id)) {
        prev[id] = 0;
      }
      prev[id]++;
      return prev;
    }, {});

    const quantityExtended = Object.keys(quantities).map((k) => {
      return { id: k, quantity: quantities[k] };
    });

    const menuItems = quantityExtended;

    let menuItemsWithPromos: ApiTransactionDetailPromoItem[] = [];

    if (cartItemsWithPromos.length > 0) {
      const uniquePromoIds: string[] = [
        ...new Set(cartItemsWithPromos.map((promo) => promo.menuPromotionId)),
      ];

      menuItemsWithPromos = uniquePromoIds.map((promoId) => {
        //Use the cart item related to the promo only
        const promoCartItems = cartItemsWithPromos.filter(
          (cartItem) => cartItem.menuPromotionId === promoId
        );

        //Grouping same cart items with quantity
        const quantities = promoCartItems.reduce((prev, curr) => {
          const id = curr.menu_item_id;
          if (!prev.hasOwnProperty(id)) {
            prev[id] = 0;
          }
          prev[id]++;
          return prev;
        }, {});

        const quantityExtended = Object.keys(quantities).map((k) => {
          return { id: k, quantity: quantities[k] };
        });

        return { id: promoId, items: quantityExtended };
      });
    }

    if (menuItems.length > 0 || menuItemsWithPromos.length > 0) {
      const details: ApiTransactionDetails = {
        menuId: this.state.cart.menuId,
        menuSittingId: this.state.selectedMenuSittingId,
      };
      if (menuItems.length > 0) {
        details.menuItems = menuItems;
      }
      if (menuItemsWithPromos.length > 0) {
        details.menuPromotions = menuItemsWithPromos;
      }

      removeEmptyFields(details);

      const metaData = this.sharedStateService.getSystemInfo();
      metaData.platform = 'win32';
      metaData.offline = EnvService.isOffline();
      removeEmptyFields(metaData);

      const customer = this.sharedStateService.getCustomer();

      const request: ApiTransactionPostRequest = {
        organisationId: this.state.org.id,
        schoolId: this.sharedStateService.getSchoolId(),
        userId: customer.id,
        eposId: this.sharedStateService.getEposId(),
        eposUserId: this.sharedStateService.getOperator().id,
        event: 'epos',
        details,
        metaData,
      };

      // Are we offline? Ask operator to save order offline for later processing
      if (EnvService.isOffline()) {
        this.recordTransactionToDevice({
          createdAt: new Date(),
          ...request,
        });
        // || this.sharedStateService.getOperationMode() === OperationMode.local
        // this.setState({ showDialogSystemDown: true });
        this.onSystemDownSaveTransaction();
        return; // important
      }

      const response: ApiResponse =
        await this.apiService.postTransactionWith422Retry(request);

      const transactionObject = {
        createdAt: new Date(),
        ...request,
        transactionStatus: response?.status || 'Error in posting transaction to API',
      };

      if (
        response &&
        response.status &&
        response.status === ApiService.RESPONSE_SUCCESS
      ) {
        this.recordTransactionToDevice({
          ...transactionObject,
          transactionId: response?.id || 'No transaction ID found',
        });

        //Update the purse of the user stored
        const customer = this.sharedStateService.getCustomer();

        this.updateCustomerPurseToStorage(customer, this.state.cart.purseAfter);

        this.onSwitchStudent();

        /*PubSub.publish("order-processes", {
            status: "Success",
            type: OrderProcessType.success,
            processMode: ProcessMode.order
          });

          setTimeout(() => {
            // stop customer session, prepare EPOS for next customer
            this.onSwitchStudent();
            setTimeout(() => {
              this.closeOrderProcess(OrderProcessType.success,"Success", ProcessMode.order);
            }, 50);
            
          }, 300);*/
      } else {
        console.log('API Error', response);

        this.recordTransactionToDevice(transactionObject);

        if (response.message.length > 0) {
          PubSub.publish('dialog-payment-error-show', response.message);
        }
        /*const code = response.code;
        let msg = "Failed to Process!";
        if (code === "NTEC-1000" || code === "SUDCT-1000") {
          msg = response.message;
        }

        if (msg.length > 0) {
          PubSub.publish("dialog-payment-error-show", msg);
          // PubSub.publish("order-processes", {
          //   status: msg,
          //   type: OrderProcessType.failed,
          //   processMode: ProcessMode.order
          // });
          // this.closeOrderProcess(OrderProcessType.failed,msg,ProcessMode.order);
        }*/

        this.activeTransaction = false;
      }
    }

    // this.props.enqueueSnackbar('This payment failed', {
    //   anchorOrigin: {
    //     vertical: 'top',
    //     horizontal: 'right',
    //   },
    //   variant: 'info',
    // })
  }

  closeOrderProcess(type: OrderProcessType, status, processMode: ProcessMode) {
    setTimeout(
      () => {
        PubSub.publish('order-processes', {
          status,
          type: OrderProcessType.void,
          processMode,
        });
      },
      type === OrderProcessType.failed ? 1500 : 100
    );
    //Keep the time low to save transaction time
  }

  async onRefund() {
    if (this.activeTransaction) return;
    this.activeTransaction = true;

    this.beep();

    const { refundCart, activeRefundTransaction } = this.state;

    const cartItems = refundCart.items;

    // If no items in the cart. Transaction is closed. Customer scan page is shown for next customer
    if (cartItems.length < 1) {
      PubSub.publish('refund_cancelled');
      return; // !!! Important
    }

    // Are we offline?
    if (EnvService.isOffline()) {
      // || this.sharedStateService.getOperationMode() === OperationMode.local
      // this.setState({ showDialogSystemDown: true });
      this.onSystemDownSaveTransaction();
      return; // important
    }

    // PubSub.publish("order-processes", {
    //   status: "Processing",
    //   type: OrderProcessType.init,
    //   processMode: ProcessMode.refund
    // });

    //Grouping same cart items with quantity
    /*const quantities = cartItems.reduce((prev, curr) => {
      const id = curr.menu_item_id;
      if (!prev.hasOwnProperty(id)) {
        prev[id] = 0;
      }
      prev[id]++;
      return prev;
    }, {});

    const quantityExtended = Object.keys(quantities).map((k) => {
      return { id: k, quantity: quantities[k] };
    });*/

    const cartItemsWithPromos = cartItems.filter(
      (cartitem) => cartitem.menuPromotionId !== undefined
    );
    const cartItemsWithoutPromos = cartItems
      .filter((cartitem) => cartitem.menuPromotionId === undefined)
      .map((item) => {
        return { id: item.menu_item_id, quantity: item.quantity };
      });

    let menuItemsWithPromos: ApiTransactionDetailPromoItem[] = [];

    if (cartItemsWithPromos.length > 0) {
      const uniquePromoIds: string[] = [
        ...new Set(cartItemsWithPromos.map((promo) => promo.menuPromotionId)),
      ];

      menuItemsWithPromos = uniquePromoIds.map((promoId) => {
        //Use the cart item related to the promo only
        const promoCartItems = cartItemsWithPromos.filter(
          (cartItem) => cartItem.menuPromotionId === promoId
        );
        return {
          id: promoId,
          items: promoCartItems.map((item) => {
            return { id: item.menu_item_id, quantity: item.quantity };
          }),
        };
      });
    }

    const menuItems = cartItemsWithoutPromos;

    if (menuItems.length > 0 || menuItemsWithPromos.length > 0) {
      const details: ApiRefundTransactionDetails = {
        transactionId: activeRefundTransaction.id,
      };
      if (menuItems.length > 0) {
        details.menuItems = menuItems;
      }
      if (menuItemsWithPromos.length > 0) {
        details.menuPromotions = menuItemsWithPromos;
      }
      const metaData = this.sharedStateService.getSystemInfo();
      metaData.platform = 'win32';
      metaData.offline = false;
      removeEmptyFields(metaData);

      const payload: ApiRefundTransactionPostRequest = {
        organisationId: this.state.org.id,
        schoolId: this.sharedStateService.getSchoolId(),
        userId: this.sharedStateService.getCustomer().id,
        eposId: this.sharedStateService.getEposId(),
        eposUserId: this.sharedStateService.getOperator().id,
        event: 'refund',
        details,
        metaData,
      };

      const response: ApiResponse = await this.apiService.postRefundTransaction(
        payload
      );

      if (response.status === ApiService.RESPONSE_SUCCESS) {
        this.onSwitchStudent();
        PubSub.publish('refund_cancelled');

        /*
        PubSub.publish("order-processes", {
          status: "Success",
          type: OrderProcessType.success,
          processMode: ProcessMode.refund,
        });
        
        setTimeout(() => {
          // stop customer session, prepare EPOS for next customer
          this.onSwitchStudent();

          setTimeout(() => {
            this.closeOrderProcess(OrderProcessType.success, "Success", ProcessMode.refund);
          }, 50);
        }, 300);*/
      } else {
        console.log('API Error', response);

        if (response.errorStatusCode === 422) {
          PubSub.publish(
            'dialog-payment-error-show',
            'There was an error while Refunding!!'
          );
        }

        this.activeTransaction = false;
      }

      /*const refundItems = this.state.refundCart.items.map((item) => {
        const id = item.title;
        const title = item.title;
        const unit_price = item.price_per_item;

        const refundItem = {
          id,
          title,
          //subtotal: unit_price,
          subtotal: unit_price,
        };
        return refundItem;
      });

      this.apiService.refundCreate(this.state.customer.id, refundItems);

      this.props.enqueueSnackbar("The refund feature is in TODO status", {
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
        variant: "info",
      });*/
    }
  }

  onPrintReciept(): void {
    this.beep();
    this.props.enqueueSnackbar('The print reciept feature is in TODO status', {
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
      },
      variant: 'info',
    });
    this.onPanelRecieptMenuHide();
  }

  recalculateRefundCart(refundCartItems: CartItem[]): Cart {
    const customer = this.sharedStateService.getCustomer();
    const customerInitialPurse = customer.available_balance;
    const customerInitialSubsidy = customer.subsidy;
    const purseBefore = customerInitialPurse;
    const subsidyBefore = customerInitialSubsidy;
    let totalPurse = customerInitialPurse;
    let totalSubsidy = customerInitialSubsidy;
    let total = 0;

    refundCartItems.forEach((item: CartItem) => {
      if (item.subsidy_value > 0) {
        totalSubsidy =
          totalSubsidy + (item.subsidy_value / 100.0) * item.quantity;
      }
      if (item.purse_value > 0) {
        totalPurse = totalPurse + (item.purse_value / 100.0) * item.quantity;
      }
      total += (item.purse_value / 100.0) * item.quantity;
    });

    const cart: Cart = {
      menuId: this.state.cart.menuId,
      currencySymbol: this.state.supportedCurrency.symbol,
      items: refundCartItems,
      purseBefore: purseBefore,
      purseAfter: totalPurse,
      subsidyBefore: subsidyBefore,
      subsidyAfter: totalSubsidy,
      totalPurse: totalPurse,
      totalSubsidy: totalSubsidy,
      total: total,
    };
    return cart;
  }

  onRefundItemToggled(
    item: TransactionItem,
    action: string,
    quantity: number
  ): void {
    // const item:TransactionItem = this.state.refundItems.find((i) => i.id === itemId)

    if (item === undefined) {
      return;
    }

    let refundCartItems = this.state.refundCart.items;
    // let refundCartTotal = this.state.refundCart.total

    const inCartIndex = refundCartItems.findIndex((i) => i.id === item.id);

    if (inCartIndex > -1 && action === 'remove') {
      refundCartItems.splice(inCartIndex, 1);
    } else {
      if (action === 'add') {
        const cartItem: CartItem = {
          id: item.id,
          title: item.name,
          menu_item_id: item.id,
          quantity: quantity,
          price_per_item: item.purseValue,
          subtotal: item.purseValue,
          purse_value: item.purseValue,
          subsidy_value: item.subsidyValue,
        };
        refundCartItems.push(cartItem);
      } else if (action === 'update') {
        const cartItem = refundCartItems[inCartIndex];
        cartItem.quantity = quantity;
        refundCartItems[inCartIndex] = cartItem;
      }
    }

    const cart = this.recalculateRefundCart(refundCartItems);
    // // parseFloat(item.price)
    // refundCartTotal = refundCartItems.reduce((total, item) => {
    //   return total + parseFloat(item.purse_value)
    // }, 0)

    this.setState(
      {
        refundCart: cart,
        // cart: newCart,
      },
      () => {
        this.sharedStateService.set('refundCart', this.state.refundCart);
        PubSub.publish('refund_cart_updated', '');
      }
    );
  }

  onRefundPromoItemToggled(
    item: PromotionItem,
    action: string,
    quantity: number
  ): void {
    // const item:TransactionItem = this.state.refundItems.find((i) => i.id === itemId)

    if (item === undefined) {
      return;
    }

    let refundCartItems = this.state.refundCart.items;
    // let refundCartTotal = this.state.refundCart.total

    const inCartIndex = refundCartItems.findIndex((i) => i.id === item.id);

    if (inCartIndex > -1 && action === 'remove') {
      refundCartItems.splice(inCartIndex, 1);
    } else {
      if (action === 'add') {
        const cartItem: CartItem = {
          id: item.id,
          title: item.name,
          menu_item_id: item.id,
          quantity: quantity,
          price_per_item: item.purseValue,
          subtotal: item.purseValue,
          purse_value: item.purseValue,
          subsidy_value: item.subsidyValue,
          menuPromotionId: item.promotionId,
        };
        refundCartItems.push(cartItem);
      } else if (action === 'update') {
        const cartItem = refundCartItems[inCartIndex];
        cartItem.quantity = quantity;
        refundCartItems[inCartIndex] = cartItem;
      }
    }

    const cart = this.recalculateRefundCart(refundCartItems);
    // // parseFloat(item.price)
    // refundCartTotal = refundCartItems.reduce((total, item) => {
    //   return total + parseFloat(item.purse_value)
    // }, 0)

    this.setState(
      {
        refundCart: cart,
        // cart: newCart,
      },
      () => {
        this.sharedStateService.set('refundCart', this.state.refundCart);
        PubSub.publish('refund_cart_updated', '');
      }
    );
  }

  onSalesModeChange(salesMode): void {
    this.setState({ salesMode: salesMode });
  }

  onSystemDownSaveTransaction(): void {
    const { activeRefundTransaction } = this.state;
    const mode = this.state.salesMode;

    let items: any = [];
    let type = '';

    if (mode === this.SALES_MODE_SALE) {
      items = this.prepareCartForSave();
      type = 'sale';
    } else if (mode === this.SALES_MODE_REFUND) {
      items = this.prepareRefundForSave();
      type = 'refund';
    } else {
      this.props.enqueueSnackbar('sales mode unrecognized: ' + mode, {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        variant: 'info',
      });
    }

    let pendingOrders = this.sharedStateService.getPendingOrders();

    const cart = this.sharedStateService.getCart();
    const customer = this.sharedStateService.getCustomer();
    // let cartItems = this.state.cart.items
    let cartTotal = cart.total;
    let cartTotalSubsidy = cart.totalSubsidy;
    let cartTotalPurse = cart.totalPurse;
    let cartSubsidyBefore = cart.subsidyBefore;
    let cartSubsidyAfter = cart.subsidyAfter;
    let cartPurseBefore = cart.purseBefore;
    let cartPurseAfter = cart.purseAfter;
    const pendingOrder: PendingOrder = {
      organisationId: this.state.org.id,
      schoolId: this.sharedStateService.getSchoolId(),
      eposId: this.sharedStateService.getEposId(),
      eposUserId: this.sharedStateService.getOperator().id,
      menuId: cart.menuId,
      menuSittingId: this.state.selectedMenuSittingId,
      customer_id: customer.id,
      customer_card_number: customer.card_number,
      type: type,
      items: items,
      purseAfter: cartPurseAfter,
      purseBefore: cartPurseBefore,
      subsidyBefore: cartSubsidyBefore,
      subsidyAfter: cartSubsidyAfter,
      total: cartTotal,
      totalPurse: cartTotalPurse,
      totalSubsidy: cartTotalSubsidy,
    };
    if (type === 'refund') {
      pendingOrder.transactionId = activeRefundTransaction.id;
    }
    pendingOrders.push(pendingOrder);

    //Update the purse of the user offline
    this.updateCustomerPurseToStorage(customer, cartPurseAfter);

    this.sharedStateService.setPendingOrders(pendingOrders);

    if (type === 'sale') {
      // stop customer session, prepare EPOS for next customer
      this.onSwitchStudent();
    } else {
      PubSub.publish('refund_cancelled');
    }
  }

  updateCustomerPurseToStorage(customer: Customer, updatedPurse: number) {
    //Update the purse of the user offline
    customer.available_balance = updatedPurse;

    let allUsers = this.sharedStateService.getSchoolUsers();

    const userIndex = allUsers.findIndex((user) => user.id === customer.id);
    allUsers[userIndex] = customer;

    this.sharedStateService.setSchoolUsers(allUsers);
  }

  onSupport = () => {
    this.beep();
    PubSub.publish('dialog-support-show-kiosk', '');
  };

  emptyCart(broadcast: boolean): void {
    this.setState(
      {
        cart: {
          menuId: this.state.cart.menuId,
          currencySymbol: this.state.supportedCurrency.symbol,
          items: [],
          purseBefore: 0.0,
          purseAfter: 0.0,
          subsidyBefore: 0.0,
          subsidyAfter: 0.0,
          total: 0.0,
          totalPurse: 0.0,
          totalSubsidy: 0.0,
        },
      },
      () => {
        this.sharedStateService.set('cart', this.state.cart);
        if (broadcast) {
          PubSub.publish('cart_updated', keyUpdateCartToCustomer);
        }
      }
    );
  }

  emptyRefundCart(): void {
    console.log('EMPTY REFUND CART');

    this.setState(
      {
        refundItems: [],
        refundCart: {
          menuId: this.state.cart.menuId,
          currencySymbol: this.state.supportedCurrency.symbol,
          items: [],
          purseBefore: 0.0,
          purseAfter: 0.0,
          subsidyBefore: 0.0,
          subsidyAfter: 0.0,
          total: 0.0,
          totalPurse: 0.0,
          totalSubsidy: 0.0,
        },
      },
      () => {
        this.sharedStateService.set('refundCart', this.state.refundCart);
        PubSub.publish('refund_cart_updated', '');
      }
    );
  }

  /**
   * Closes the customer session, and prepares the EPOS for the next customer
   * 1. Clears the cart data
   * 2. Deletes the customer data
   * 3. Returns to the card scan page
   */
  onSwitchStudent(): void {
    console.log('SWITCH STUDENT');
    this.activeTransaction = false;

    PubSub.publish('onKiosk', false);
    // PubSub.publish("terminate_timer", "");
    this.beep();
    this.emptyCart(false); // make sure we clean the cart
    this.emptyRefundCart(); // make sure we clean the refund cart
    this.sharedStateService.deleteCustomer(); // make sure we clean the student
    this.props.onClose();
    // this.pageService.setOperatorPage(this.pageService.OPERATOR_CARD_SCAN_PAGE);
    this.pageService.setCustomerPage(this.pageService.CUSTOMER_CARD_SCAN_PAGE);
    this.setState({ showMenu: false, selectedPromo: null });
    this.customerSessionService.stop();
  }

  onLogout(): void {
    this.activeTransaction = false;
    PubSub.publish('onKiosk', false);
    // PubSub.publish("terminate_timer", "");
    this.beep();
    this.sharedStateService.clearOperator();
    this.pageService.setOperatorPage(this.pageService.OPERATOR_LOGIN_PAGE);
    this.pageService.setCustomerPage(this.pageService.CUSTOMER_HOME_PAGE);
    this.setState({ showMenu: false });
  }

  beep(): void {
    AudioManager.sharedManager().beep();
    // const sound: HTMLVideoElement = document.getElementById(
    //   "audiobeep"
    // ) as HTMLVideoElement;
    // if (sound) {
    //   sound.play();
    // }
  }

  render(): ReactJSXElement {
    // if (!this.state.customer) return null;
    //console.log('RENDER')
    //console.log(this.state.showDialogSupport)
    // // 1. Prepare menu items
    // const menuItems = []
    // this.state.foodMenus.forEach((menu) => {
    //   menuItems.push(
    //     <div
    //       key={menu.id}
    //       className="MenuItem"
    //       style={{ flex: 1 }}
    //       onClick={() => {
    //         this.onFoodMenuSelected(menu.id)
    //       }}
    //     >
    //       {menu.name}
    //     </div>,
    //   )
    // })
    const customer = this.sharedStateService.getCustomer();
    const salesMode = this.state.salesMode;
    const purse = customer ? customer.available_balance : 0;
    const subsidy = customer ? customer.subsidy : 0;
    // const firstName = this.state.student ? this.state.student.first_name : 0
    // const lastName = this.state.student ? this.state.student.last_name : 0
    // const className = this.state.student ? this.state.student.class : 0
    // const lastSeen = this.state.student ? this.state.student.last_seen : ''
    // console.log(this.state.refundItems)

    // 2. Render page

    const height = window.innerHeight;
    return (
      <div
        id="KioskPage"
        className={this.state.salesMode}
        // style={{ backgroundImage: `url(${background})` }}
      >
        <DialogItemInfo />
        <DialogPaymentError
          onPay={() => {
            this.onPayment();
          }}
        />
        <DialogScanError
          onCloseKiosk={() => {
            this.onSwitchStudent();
          }}
        />
        <DialogPromoExhausted />
        <DialogPromoDisallow />
        <DialogHelp />
        {/* <DialogOrdering /> */}
        <DialogTransactions />
        <DialogCustomerSession />
        <DialogSupportKiosk
          showDialog={!!this.state.showDialogSupport}
          screenType={DialogScreenType.kiosk}
        />
        <DialogSystemDown
          showDialog={this.state.showDialogSystemDown || false}
          onSave={this.onSystemDownSaveTransaction}
          onClose={() => {
            this.activeTransaction = false;
          }}
        />
        <DialogCustomerInfo />
        <Layout
          top={
            <PanelHeader
              student={customer}
              sittings={this.state.menuSittings || []}
              onSittingChange={(sittingId) => {
                this.setState({
                  selectedMenuSittingId: sittingId,
                  cart: this.processSittingSelection(
                    sittingId,
                    this.state.cart
                  ),
                  eposLayout: this.fetchEposLayoutsForSitting(sittingId),
                }); //this.sharedStateService.getCurrentSittingId(),
              }}
            ></PanelHeader>
          }
          center={
            <>
              <table
                style={{ width: '100%', height: '100%' }}
                cellPadding={0}
                cellSpacing={0}
              >
                <tbody>
                  <tr>
                    <td
                      style={{
                        width: 250,
                        minWidth: 250,
                        position: 'relative',
                      }}
                    >
                      {
                        <PanelCurrentSale
                          cart={this.state.cart}
                          refundItems={this.state.refundItems}
                          refundCart={this.state.refundCart}
                          purse={purse}
                          subsidy={subsidy}
                          salesMode={salesMode}
                          onPayment={() => {
                            this.onPayment();
                          }}
                          onRefund={() => {
                            this.onRefund();
                          }}
                          onAddSubsidyToPurse={(selectedMenuItem) => {
                            this.onFoodItemSelected(selectedMenuItem, true);
                          }}
                          onFoodItemRemove={(itemIndex) => {
                            this.onFoodItemRemoved(itemIndex);
                          }}
                        />
                      }
                      <Box
                        position="absolute"
                        id="Footer"
                        top={height - 37 - 44}
                        left={0}
                        width={250}
                        height={38}
                        display="flex"
                        borderRight={`#F6F4F5 2px solid`}
                      >
                        <div
                          className="MenuItem"
                          onClick={() => {
                            this.onPanelStaffMenuToggle();
                          }}
                        >
                          {this.state.showMenu && (
                            <PanelStaffMenu
                              onDailySummary={() => {
                                // this.onDailySummary()
                              }}
                              onDailyTransaction={() => {
                                this.onShowTransactions();
                              }}
                              onHide={() => {
                                this.onPanelStaffMenuHide();
                              }}
                              onLogout={() => {
                                this.onLogout();
                              }}
                              onSwitchStudent={() => {
                                this.onSwitchStudent();
                              }}
                              onSupport={() => {
                                this.onSupport();
                              }}
                            ></PanelStaffMenu>
                          )}
                          <MenuRoundedIcon sx={{ fontSize: 20 }} />
                        </div>
                        <Box
                          display="flex"
                          flex={'1 0 0'}
                          alignItems={'center'}
                          justifyContent="center"
                          mr={'48px'}
                        >
                          <Typography
                            fontSize={15}
                            color={'white'}
                            sx={{ textTransform: 'uppercase' }}
                            fontWeight={400}
                          >
                            {this.state.selectedOperator
                              ? this.state.selectedOperator.name
                              : 'NA'}
                            {this.menuItemSelectionTime !== 0
                              ? ` - ${this.menuItemSelectionTime} ms`
                              : null}
                          </Typography>
                        </Box>
                      </Box>
                    </td>
                    <td style={{ verticalAlign: 'top' }}>
                      <PanelMenu
                        foodCategories={this.state.foodCategories}
                        // foodMenus={this.state.foodMenus}
                        // selectedFoodCategoryClass={
                        //   this.state.selectedFoodCategoryClass
                        // }
                        selectedFoodCategoryId={
                          this.state.selectedFoodCategoryId
                        }
                        onShowFoodItemInfo={(item) => {
                          this.onShowFoodItemInfo(item);
                        }}
                        onFoodItemSelection={(item) => {
                          this.onFoodItemSelected(item);
                        }}
                        onCategorySelection={(categoryId) =>
                          this.onFoodCategorySelected(categoryId)
                        }
                        mealDealEnabled={this.state.mealDealEnabled}
                        eposLayout={this.state.eposLayout}
                        menuItems={this.state.menuItems || []}
                        menuPromos={this.state.menuPromos}
                        selectedSittingId={this.state.selectedMenuSittingId}
                        onPromoItemSelection={(promoId) => {
                          this.onPromoIdSelection(promoId);
                        }}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </>
          }
          bottom={<div />}
        ></Layout>
      </div>
    );
  }
}

export default withSnackbar(KioskPage);
